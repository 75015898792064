import React, { useEffect,useState } from "react";
import { useDispatch } from "react-redux";
import {
    getReviewDetailByOrderId,
    updateProductRating
} from "../../Redux/Actions/reviewAction"
import { useNavigate, useParams } from "react-router";
import "../../Styles/profile.scss";
import StarRating from "../../Components/StarRating/starRating";

const Review_Detail = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const orderId = params.orderId;
    const [orderDetail, setOrderDetail] = useState([]);
    const [isReported, setIsReported] = useState();
    const [vendorComment, setVendorComment] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        fetchReviewDetail();
    }, []);

    const fetchReviewDetail = async () => {
        const response = await dispatch(getReviewDetailByOrderId(orderId));
        if (response?.data?.data) {
            await setOrderDetail([response.data.data]);
            setIsReported(response?.data?.data.is_reported)
            setVendorComment(response?.data?.data.vendor_comment)
        }
    };

    const handleChange = (e, key) => {

        if (key == "handleIsReported") {
            const value = +e.target.value
            if (value === 1) {
                setIsReported(value)
            } else {
                setIsReported(value)
            }

        }

        else if (key == "handleVendorComment") {
            const value = e.target.value
            setVendorComment(value)
        }
    }

    const handleSubmit = async () => {
        const data = {
            isReported,
            vendorComment: vendorComment.trim()
        }
        setShowLoader(true)
        const res = await dispatch(updateProductRating(orderId, data));
        if (res?.data?.data?.statusCode == 201) {
            setShowLoader(false)
            navigate('/reviews')
        }

    }

    return (

        <div className="admin-card">
            <div className="admin-card-head">
                <h2>Review Detail</h2>
            </div>

            <div className="admin-card-box">
                {/* <OrderDetail /> */}
                <div className="cart-box w-100">
                    <div className="cart-box-detail w-100">
                        <div className="cart-box-img">
                            <img src={orderDetail[0]?.image} />
                        </div>
                        <div className="cart-box-item-detail w-100">
                            <h2>{orderDetail[0]?.product_name}</h2>
                        </div>
                    </div>
                </div>
                <p>
                    <strong>Customer name : </strong>

                    {orderDetail[0]?.customer_name}

                </p>
                <p className="d-flex align-items-center">
                    <strong>Ratings : </strong>
                    <StarRating
                        name="hover-feedback"
                        value={orderDetail[0]?.rating ? orderDetail[0]?.rating : 0}
                        isHalf={true}
                        readOnly
                    />
                </p>
                <p>
                    <strong>Commented on : </strong>
                    {orderDetail[0]?.created_ts ? new Date(orderDetail[0]?.created_ts).toISOString().substring(0, 10) : null}
                </p>
                <p>
                    <strong>Report the review : </strong>
                    <div className="custom-radio-input">
                        <p className="container-sub mr-2">
                            No
                            <span>
                                <input
                                    type="radio"
                                    value="0"
                                    onChange={(event) =>
                                        handleChange(event, "handleIsReported")
                                    }
                                    checked={isReported === 0}
                                    name="isActiveRadio"
                                />
                            </span>
                            <span className="checkmark"></span>
                        </p>

                        <p className="container-sub mr-2">
                            Yes
                            <span>
                                <input
                                    type="radio"
                                    value="1"
                                    onChange={(event) =>
                                        handleChange(event, "handleIsReported")
                                    }
                                    checked={isReported === 1}
                                    name="isActiveRadio"
                                />
                            </span>
                            <span className="checkmark"></span>
                        </p>
                    </div>
                </p>
                <p>
                    <strong>Comment : </strong>

                    {orderDetail[0]?.comment}

                </p>

                <p>
                    <strong>Add Comment : </strong>
                    <div className="form-group">
                        <textarea
                            rows="5"
                            cols="10"
                            onChange={(e) => handleChange(e, "handleVendorComment")}
                            value={vendorComment}
                            className="form-control form-textarea"
                            placeholder="enter your text here..."
                        ></textarea>
                    </div>

                </p>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-btn text-center">
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="orange-outline-btn"
                            >
                                submit
                                {(() => {
                                    if (showLoader) {
                                        return (
                                            <span className="spinner-border spinner-border-sm ml-1"></span>
                                        );
                                    }
                                })()}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Review_Detail;
