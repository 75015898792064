import React from "react";
import { Link } from "react-router-dom";
import "./signUp.scss";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signup, vemail, login, logout } from "../../Redux/Actions/authAction";
import { getPageContent } from "../../Redux/Actions/pageAction";
import withNavigate from "../../Components/customHooks/navigate";
class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        isacceptedtnc: false,
      },
      content: "",
      title: "",
      errorData: {},
      vcodeFlag: false,
      isLogedIn: false,
      successMessage: "",
      errorMessage: "",
      showLoader: false,
      tncVisible: false,
    };
  }

  validateUserInfo = () => {
    let isFormValid = true;
    let formValues = this.state.formValues;
    this.state.errorData = {
      firstname: [],
      lastname: [],
      email: [],
      isacceptedtnc: [],
      password: [],
      confirmPassword: [],
    };

    if (formValues.firstname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.firstname = ["First Name field cannot be empty."];
    } else if (formValues.firstname.search("^[a-zA-Z]+$")) {
      isFormValid = false;
      this.state.errorData.firstname = ["First Name only contain Characters"];
    }

    if (formValues.lastname.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.lastname = ["Last Name field cannot be empty."];
    } else if (formValues.lastname.search("^[a-zA-Z]+$")) {
      isFormValid = false;
      this.state.errorData.lastname = ["Last Name only contain Characters"];
    }

    if (formValues.email.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.email = ["Email field cannot be empty."];
    } else if (formValues.email.search("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")) {
      isFormValid = false;
      this.state.errorData.email = ["Enter correct Email Address"];
    }

    if (!formValues.isacceptedtnc) {
      isFormValid = false;
      this.state.errorData.isacceptedtnc = [
        "Terms & Conditions must be accepted.",
      ];
    }

    if (formValues.password.trim().length < 8) {
      isFormValid = false;
      this.state.errorData.password = [
        "Password should be at least 8 characters.",
      ];
    } else if (
      formValues.password.search("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$")
    ) {
      isFormValid = false;
      this.state.errorData.password = [
        "Password must contain Special , Capital Character and Number",
      ];
    }

    if (formValues.password.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.password = ["Password field cannot be empty."];
    }

    if (formValues.password.trim() !== formValues.confirmPassword.trim()) {
      isFormValid = false;
      this.state.errorData.confirmPassword = [
        "Password confirmation does not match password.",
      ];
    }

    if (formValues.confirmPassword.trim().length === 0) {
      isFormValid = false;
      this.state.errorData.confirmPassword = [
        "Confirm Password field cannot be empty.",
      ];
    }

    let tempState = this.state;

    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  handleSignUp = async (event) => {
    event.preventDefault();

    await this.setState({
      successMessage: "",
      errorMessage: "",
      errorData: {},
      showLoader: true,
    });

    // event.persist()

    // validate input fields here
    if (this.validateUserInfo()) {
      await this.props.signup({ ...this.state.formValues });
      if (this.props.isRegistered) {
        await this.setState({
          successMessage: "Your account has been registered successfully!",
          showLoader: false,
        });

        await this.props.login({ ...this.state.formValues });
        if (this.props.isLoggedIn) {
          localStorage.setItem("sellerId", this.props.authData?.id);
          localStorage.setItem(
            "sellerData",
            JSON.stringify(this.props.authData)
          );
          localStorage.setItem("token", this.props.authData?.token);
          localStorage.removeItem("companyId");
          this.setState({ isLogedIn: true, showLoader: false });
          this.props.navigate("/onboard-step");
        } else {
          this.setState({
            errorData: this.props.errorData,
            errorMessage: this.props.errorMessage,
            showLoader: false,
          });
        }
      } else {
        let message = "";

        if (this.props.code === "ER_DUP_ENTRY") {
          message = "Email is already registered with different account!";
        }
        await this.setState({
          errorData: this.props.errorData,
          errorMessage: message,
          showLoader: false,
        });
      }
    } else {
      // input validations failed
      await this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = (event, field) => {
    if (
      this.state.errorData &&
      this.state.errorData[field] &&
      this.state.errorData[field][0]
    ) {
      this.state.errorData[field][0] = null;
    }
    const test = this.state;
    if (field == "isacceptedtnc") {
      test.formValues[field] = event.target.checked;
      if (field == "isacceptedtnc") {
        test.tncVisible = event.target.checked;
      }
    } else {
      test.formValues[field] = event.target.value;
    }
    this.setState({ ...test });
  };

  onCancelTnC = () => {
    this.state.formValues.isacceptedtnc = false;

    this.setState({ ...this.state });
  };

  onAcceptTnC = () => {
    this.state.formValues.isacceptedtnc = true;

    this.setState({ ...this.state });
  };

  fetchPageContent = async () => {
    const result = await this.props.getPageContent(
      "terms-and-condition-for-vendor"
    );
    if (result?.data?.data) {
      this.setState({
        content: result.data.data.content,
        title: result.data.data.title,
      });
    }
  };

  render() {
    return (
      <div>
        <main className="mainContainer">
          <div className="container">
            <div className="signup">
              <form>
                <div className="signup-head">
                  <h2>CREATE NEW VENDOR ACCOUNT</h2>
                  <p>
                    Creating a vendor account is easy. Just fill in the form
                    below.
                  </p>
                </div>
                {(() => {
                  if (
                    this.state.successMessage !== "" &&
                    this.state.successMessage !== undefined
                  ) {
                    return (
                      <div className="alert alert-success">
                        {this.state.successMessage}
                      </div>
                    );
                  } else if (
                    this.state.errorMessage !== "" &&
                    this.state.errorMessage !== undefined
                  ) {
                    return (
                      <div className="alert alert-danger">
                        {this.state.errorMessage}
                      </div>
                    );
                  }
                })()}

                <div className="signup-mid">
                  <h3>PERSONAL INFORMARTION</h3>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={this.state.formValues.firstname}
                          onChange={(event) =>
                            this.handleChange(event, "firstname")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData?.firstname &&
                            this.state.errorData.firstname[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={this.state.formValues.lastname}
                          onChange={(event) =>
                            this.handleChange(event, "lastname")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData?.lastname &&
                            this.state.errorData.lastname[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          value={this.state.formValues.email}
                          onChange={(event) =>
                            this.handleChange(event, "email")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData?.email &&
                            this.state.errorData.email[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          value={this.state.formValues.password}
                          onChange={(event) =>
                            this.handleChange(event, "password")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData?.password &&
                            this.state.errorData.password[0]}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          value={this.state.formValues.confirmPassword}
                          onChange={(event) =>
                            this.handleChange(event, "confirmPassword")
                          }
                        />
                        <span className="form-field-error">
                          {this.state.errorData?.confirmPassword &&
                            this.state.errorData.confirmPassword[0]}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="signup-trums-check">
                    <div className="sign-check">
                      <label className="custom-check">
                        Terms of Use
                        <input
                          type="checkbox"
                          data-toggle="modal"
                          data-target="#exampleModal"
                          checked={this.state.formValues.isacceptedtnc}
                          onChange={(event) =>
                            this.handleChange(event, "isacceptedtnc")
                          }
                          onClick={this.fetchPageContent}
                        />
                        <span className="checkmark"></span>
                      </label>
                      {/* {(() => {
                        if (this.state.tncVisible) {
                          return <Link className="tnc-condition-link" to={"#"}>Go to terms & conditions</Link>
                        }
                      })()} */}

                      <span className="form-field-error">
                        {this.state.errorData?.isacceptedtnc &&
                          this.state.errorData.isacceptedtnc[0]}
                      </span>
                    </div>
                    {/* <div className="sign-check">
                      <label className="custom-check">SIGN UP FOR NEWSLETTER
                    <input type="checkbox"
                          checked={this.state.formValues.newsletter}
                          onChange={(event) =>
                            this.handleChange(event, "newsletter")
                          } />
                        <span className="checkmark"></span>
                      </label>
                    </div> */}
                  </div>

                  <div
                    className="modal fade modal-blog"
                    id="exampleModal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog  modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Terms & Conditions
                          </h5>
                          <button
                            type="button"
                            onClick={this.onCancelTnC}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="modal-blog-detail">
                            <div className="container-fluid row">
                              <h1>
                                <b>{this.state.title}</b>
                              </h1>

                              <div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content
                                      ? this.state.content
                                      : `-`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>

                          <label
                            className="custom-check"
                            style={{ marginTop: "20px" }}
                          >
                            accept terms of use
                            <input type="checkbox" defaultChecked={true} />
                            <span className="checkmark"></span>
                          </label>
                          <div className="button-center">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={this.onCancelTnC}
                              data-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this.onAcceptTnC}
                              data-dismiss="modal"
                            >
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="signup-bottom">
                  <div className="signup-submit">
                    <button
                      type="submit"
                      onClick={(event) => this.handleSignUp(event)}
                    >
                      continue
                      {(() => {
                        if (this.state.showLoader) {
                          return (
                            <span className="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                  </div>
                  <div className="already-account-link">
                    <p>
                      already have an account?{" "}
                      <Link to={"/login"}> login now</Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    isRegistered: state.authReducer.isRegistered,
    code: state.authReducer.code,
    isLoggedIn: state.authReducer.isLoggedIn,
    authData: state.authReducer.authData,
    errorData: state.commonReducer.errorData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { signup, vemail, login, logout, getPageContent },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(SignUp));
