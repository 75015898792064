import React, { Component, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  installWooCommerce,
  getWooCommerceDetails,
  getWooCommerceProducts,
  postCategoriesToSubsciety,
  addCustomersToWooCommerce,
} from "../../Redux/Actions/userAction";
import { decryptAccessToken } from "../../Utils/helper";

const Shopify = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    client_key: "",
    client_secret: "",
    shop: "",
    vendor_id: localStorage.getItem("sellerData")
      ? JSON.parse(localStorage.getItem("sellerData")).id
      : null,
  });
  const [savedStore, setSavedStore] = useState(null);
  const [isActive, setIsActive] = useState(0);
  const [client_key, setClientKey] = useState(null);
  const [client_secret, setClientSecret] = useState(null);

  useEffect(() => {
    (async () => {
      fetchWoocommerceDetails();
    })();
  }, []);

  const fetchWoocommerceDetails = async () => {
    const res = await dispatch(
      getWooCommerceDetails(state.vendor_id, "woocommerce")
    );
    if (res?.data.isFound) {
      const client_key = decryptAccessToken(res.data.data.client_key)
      const client_secret = decryptAccessToken(res.data.data.client_secret)
      setSavedStore(res.data?.data.shop);
      setIsActive(res.data?.data.is_active);
      setClientKey(client_key);
      setClientSecret(client_secret);
      setState((prevState) => ({
        ...prevState,
        client_key: client_key,
        client_secret: client_secret,
        shop: res.data?.data.shop,
      }));
    }
  };

  const [callbackUrl, setCallbackUrl] = useState(null);
  const handleChange = (e, key) => {
    let newVal = e.target.value;
    setState((prev) => ({
      ...prev,
      [key]: newVal,
    }));
  };
  function isValidURL(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  const [clientKeyError, setClientKeyError] = useState("");
  const [clientSecretError, setClientSecretError] = useState("");
  const [shopError, setShopError] = useState("");
  const [reqMessage, setreqMessage] = useState("");
  const validateForm = async () => {
    let isValid = true;
    setClientKeyError("");
    setClientSecretError("");
    setShopError("");
    if (state.client_key.trim() === "") {
      isValid = false;
      setClientKeyError("please enter details in required field");
    }

    if (state.client_secret.trim() === "") {
      isValid = false;
      setClientSecretError("please enter details in required field");
    }
    if (state.shop === "") {
      isValid = false;
      setShopError("please enter details in required field");
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValidForm = await validateForm();
    if (isValidForm) {
      const res = await dispatch(installWooCommerce(state));
      if (res) {
        setCallbackUrl(res.data);
        fetchWoocommerceDetails();
      }
    }
  };

  // const handleGetCustomers = async (e) => {
  //   e.preventDefault();
  //   if (accessToken && savedStore) {
  //     const res = await dispatch(
  //       getCustomerFromShopify({
  //         accessToken,
  //         shop: savedStore,
  //         vendor_id: state.vendor_id,
  //       })
  //     );
  //     if (res.data && !res.data.isError) {
  //       setreqMessage("request submitted successfully");
  //       setTimeout(() => {
  //         setreqMessage("");
  //       }, 5 * 1000);
  //     }
  //   }
  // };

  // const handleGetCategories = async (e) => {
  //   e.preventDefault();
  //   if (accessToken && savedStore) {
  //     const res = await dispatch(
  //       getCategoriesFromShopify({
  //         accessToken,
  //         shop: savedStore,
  //         vendor_id: state.vendor_id,
  //       })
  //     );
  //     if (res.data && !res.data.isError) {
  //       setreqMessage("request submitted successfully");
  //       setTimeout(() => {
  //         setreqMessage("");
  //       }, 5 * 1000);
  //     }
  //   }
  // };

  const handleAddCustomers = async (e) => {
    e.preventDefault();
    if (isActive && savedStore) {
      const res = await dispatch(
        addCustomersToWooCommerce({
          client_key,
          client_secret,
          shop: savedStore,
          vendor_id: state.vendor_id,
        })
      );
      if (res.data && !res.data.isError) {
        setreqMessage("request submitted successfully");
        setTimeout(() => {
          setreqMessage("");
        }, 5 * 1000);
      }
    }
  };

  // const handleAddCategories = async (e) => {
  //   e.preventDefault();
  //   if (accessToken && savedStore) {
  //     const res = await dispatch(
  //       addCategoriesToShopify({
  //         accessToken,
  //         shop: savedStore,
  //         vendor_id: state.vendor_id,
  //       })
  //     );
  //     if (res.data && !res.data.isError) {
  //       setreqMessage("request submitted successfully");
  //       setTimeout(() => {
  //         setreqMessage("");
  //       }, 5 * 1000);
  //     }
  //   }
  // };

  // const handleAddProducts = async (e) => {
  //   e.preventDefault();
  //   if (accessToken && savedStore) {
  //     const res = await dispatch(
  //       addProductsToShopify({
  //         accessToken,
  //         shop: savedStore,
  //         vendor_id: state.vendor_id,
  //       })
  //     );
  //     if (res.data && !res.data.isError) {
  //       setreqMessage("request submitted successfully");
  //       setTimeout(() => {
  //         setreqMessage("");
  //       }, 5 * 1000);
  //     }
  //   }
  // };

  const connectShopifyAccount = async (url) => {
    window.open(url, "_blank");
    setCallbackUrl(null);
    fetchWoocommerceDetails();
  };
  const handleGetProducts = async (e) => {
    e.preventDefault();
    if (isActive && savedStore) {
      const res = await dispatch(
        getWooCommerceProducts({
          client_key,
          client_secret,
          shop: savedStore,
          vendor_id: state.vendor_id,
        })
      );
      if (res && res.data && !res.data.isError) {
        setreqMessage("request submitted successfully");
        setTimeout(() => {
          setreqMessage("");
        }, 5 * 1000);
      }
    }
  };
  const handleGetCategories = async (e) => {
    e.preventDefault();
    if (isActive && savedStore) {
      const res = await dispatch(
        postCategoriesToSubsciety({
          client_key,
          client_secret,
          shop: savedStore,
          vendor_id: state.vendor_id,
        })
      );
      if (res.data && !res.data.isError) {
        setreqMessage("request submitted successfully");
        setTimeout(() => {
          setreqMessage("");
        }, 5 * 1000);
      }
    }
  };
  return (
    <div>
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>WOOCOMMERCE ACCOUNT CONNECT</h2>
        </div>
        <div className="admin-card-box">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Consumer key</label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.client_key}
                    onChange={(e) => handleChange(e, "client_key")}
                  />
                </div>
                <span className="form-field-error custom-error">
                  {clientKeyError}
                </span>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Consumer secret</label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.client_secret}
                    onChange={(e) => handleChange(e, "client_secret")}
                  />
                </div>
                <span className="form-field-error custom-error">
                  {clientSecretError}
                </span>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Store url</label>
                  <input
                    type="text"
                    className="form-control"
                    value={state.shop}
                    onChange={(e) => handleChange(e, "shop")}
                  />
                </div>
                <span className="form-field-error custom-error">
                  {shopError}
                </span>
              </div>
            </div>

            <div className="form-btn mb-2">
              <button type="submit" value={"submit"} className="orange-outline-btn">
                save
              </button>
            </div>

          </form>

          {/* {callbackUrl && connectShopifyAccount(callbackUrl)} */}
          <div className="form-btn mb-2 text-center">
            {isActive === 1 && (
              <p style={{ fontSize: "14px", color: "#0000FF" }}>
                {" "}
                Your Woocommerce account connected successfully
              </p>
            )}

            {reqMessage && (
              <div className="success-msg mb-3">
                <p>{reqMessage}</p>
              </div>
            )}

            <div>
              {isActive === 1 && (
                <div className="group-btn-main-list">
                  <div className="row">
                    <div className="col-md-3 p-0">
                      <div className="border-list-button">
                        <button
                          className="orange-btn"
                          onClick={handleGetProducts}
                        >
                          {" "}
                          Import  products from Woo-commerce
                        </button>
                      </div>
                    </div>

                    <div className="col-md-3 p-0">
                      <div className="border-list-button">
                        <button
                          className="orange-btn"
                          onClick={handleGetCategories}
                        >
                          {" "}
                          Import categories from Woo-commerce
                        </button>
                      </div>
                    </div>
                    <div className="col-md-3 p-0">
                      <div className="border-list-button">
                        <button
                          className="orange-btn"
                          onClick={handleAddCustomers}
                        >
                          {" "}
                          Add Customer to <br></br> Subsciety
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
          <a href={process.env.REACT_APP_WOOCOMMERCE_DOCUMENT_URL} download={process.env.REACT_APP_WOOCOMMERCE_DOCUMENT_URL} target="_blank" className="step-link"> 
            <span className="pdf-icon-tag"><i className="fas fa-file"></i></span> Step by step woocommerce connect document</a>
        </div>
      </div>
    </div>
  );
};

export default Shopify;
