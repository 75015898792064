import React from "react";

const CommonCheckbox = (props) => {
  const { label, value, onClick, checked, onChange } = props;

  return (
    <>
      <label className="custom-checkbox">
      {label ? (
        <label>
          {label}
        </label>
      ) : null}
        <input type="checkbox" onClick={onClick} checked={checked} onChange={onChange} />
        <span className="checkmark" value={value}></span>
      </label>
    </>
  );
};

export default React.memo(CommonCheckbox);
