import React from "react";

export const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 11.25C13.5508 11.25 14.8125 9.98831 14.8125 8.4375C14.8125 6.88669 13.5508 5.625 12 5.625C10.4492 5.625 9.1875 6.88669 9.1875 8.4375C9.1875 9.98831 10.4492 11.25 12 11.25ZM12 7.5C12.5169 7.5 12.9375 7.92056 12.9375 8.4375C12.9375 8.95444 12.5169 9.375 12 9.375C11.4831 9.375 11.0625 8.95444 11.0625 8.4375C11.0625 7.92056 11.4831 7.5 12 7.5Z"
        fill="#333333"
      />
      <path
        d="M20.4375 8.4375C20.4375 3.78506 16.6524 0 12 0C7.34756 0 3.5625 3.78506 3.5625 8.4375C3.5625 10.278 4.14478 12.0272 5.24644 13.496L9.74916 19.4991C10.278 20.2041 11.1195 20.625 12 20.625C12.8805 20.625 13.722 20.2041 14.2509 19.499L18.7536 13.496C19.8552 12.0272 20.4375 10.278 20.4375 8.4375ZM17.2536 12.3709L12.751 18.3739C12.5716 18.6129 12.2979 18.75 12 18.75C11.7021 18.75 11.4284 18.6129 11.2491 18.374L6.74639 12.3709C5.89013 11.2293 5.4375 9.86911 5.4375 8.4375C5.4375 4.81894 8.38144 1.875 12 1.875C15.6186 1.875 18.5625 4.81894 18.5625 8.4375C18.5625 9.86911 18.1099 11.2293 17.2536 12.3709Z"
        fill="#333333"
      />
      <path
        d="M16.6875 23.0625C16.6875 22.5447 16.2678 22.125 15.75 22.125H8.25C7.73222 22.125 7.3125 22.5447 7.3125 23.0625C7.3125 23.5803 7.73222 24 8.25 24H15.75C16.2678 24 16.6875 23.5803 16.6875 23.0625Z"
        fill="#333333"
      />
    </svg>
  );
};
