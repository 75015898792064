import React from "react";
import "./Onboarding.scss";
import CommonButton from "../../Components/FormElements/CommonButton";
import { BackIcon } from "../../Components/svgElements/BackIcon";
import { PrimaryCheckIcon } from "../../Components/svgElements/PrimaryCheckIcon";
import HeaderParent from "../../Components/Header/HeaderParent";
import StepsView from "./StepsView";
import { useNavigate } from "react-router";

const OnboardingSubscription = (props) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/payment-configuration");
  };

  const handleSubmit = () => {
    navigate("/store-preview");
  };

  const cancelPage = () => {
    navigate("/");
  };

  return (
    <>
    <HeaderParent/>
    <div className="container">
      <div className="onboarding-page">
        <div className="page-heading">
          <button
            onClick={goBack}
            className="theme-button dark-outline-btn radius-sm back-btn"
          >
            <BackIcon />
          </button>
          <h2>Onboarding</h2>
        </div>
        <StepsView step={4} />
        <div className="page-inner">
              <div className="onboarding-subscription-section">
                <div className="section-heading">
                  <h3>Start up your business today</h3>
                  <p>Choose a plan that suits you the best.</p>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-5 col-xl-4">
                    <div className="onboarding-subscription-card">
                      <div className="card-top">
                        <div className="name">
                          <h2>Starter</h2>
                          <p>10% + Transaction Fee</p>
                        </div>
                        <div className="price">
                          <h3>$0</h3>
                          <p>Monthly</p>
                        </div>
                      </div>
                      <div className="card-mid">
                        <h3>Plan Includes:</h3>
                        <div className="item">
                          <PrimaryCheckIcon />
                          <p>Free Account Setup</p>
                        </div>
                        <div className="item">
                          <PrimaryCheckIcon />
                          <p>Publish On Marketplace</p>
                        </div>
                        <div className="item">
                          <PrimaryCheckIcon />
                          <p>Analytics Dashboard</p>
                        </div>
                        <div className="item">
                          <PrimaryCheckIcon />
                          <p>Dedicated Vendor Support</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <div className="action-btn">
          <CommonButton
            value="Continue"
            onClick={handleSubmit}
            buttonClass="theme-button primary-btn radius-btn"
          />
          <CommonButton
            value="Cancel"
            onClick={cancelPage}
            buttonClass="theme-button dark-outline-btn radius-btn"
          />
        </div>
      </div>
    </div>
    </>
  );
};

export default OnboardingSubscription;
