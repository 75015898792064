import React, { PureComponent } from "react";
import "../../Styles/dashboard.scss";
import CommonButton from "../FormElements/CommonButton";
import logo from "../../assets/images-2/logo.png";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from '../../Redux/Actions/authAction';
import { Link } from "react-router-dom";

class HeaderParent extends PureComponent {
  handleLogout = async (event) => {
    event.preventDefault();
    this.props.logout();
  };

  render() {
    return (
      <div className="header-parent">
        <div className="container">
          <div className="header-inner">
            <div className="header-logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="logout-btn">
              <CommonButton
                value="Logout"
                onClick={(event) => this.handleLogout(event)}
                buttonClass="theme-button primary-btn radius-btn"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logout }, dispatch)
}

export default connect(null, mapDispatchToProps)(HeaderParent)
