import React from "react";

export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style={{
        stroke: "none",
      }}
    >
      <g opacity="0.6">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.82219 10.3353C7.37326 10.2985 7.84976 10.7155 7.8865 11.2665L8.49975 20.4653C8.61956 22.2625 8.70494 23.5129 8.89235 24.4537C9.07415 25.3664 9.32794 25.8494 9.69248 26.1905C10.057 26.5314 10.5559 26.7526 11.4786 26.8733C12.4298 26.9977 13.6832 26.9997 15.4842 26.9997H16.5154C18.3165 26.9997 19.5698 26.9977 20.521 26.8733C21.4437 26.7526 21.9426 26.5314 22.3072 26.1905C22.6717 25.8494 22.9254 25.3664 23.1073 24.4537C23.2948 23.5129 23.3801 22.2625 23.4998 20.4653L24.1132 11.2665C24.1498 10.7155 24.6264 10.2985 25.1774 10.3353C25.7285 10.372 26.1454 10.8485 26.1088 11.3996L25.4908 20.6685C25.3768 22.3789 25.2848 23.7604 25.0688 24.8445C24.8442 25.9716 24.4624 26.913 23.6736 27.6509C22.8848 28.3889 21.92 28.7073 20.7805 28.8564C19.6844 28.9997 18.2998 28.9997 16.5857 28.9997H15.414C13.6998 28.9997 12.3152 28.9997 11.2192 28.8564C10.0796 28.7073 9.11491 28.3889 8.32611 27.6509C7.53732 26.913 7.15543 25.9716 6.9309 24.8445C6.71494 23.7604 6.62286 22.3789 6.50886 20.6685L5.89092 11.3996C5.85419 10.8485 6.27114 10.372 6.82219 10.3353Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8069 3.00005L13.7457 3.00003C13.4572 2.99984 13.2058 2.99968 12.9684 3.03759C12.0307 3.18733 11.2192 3.7722 10.7806 4.61445C10.6696 4.82765 10.5903 5.06619 10.4992 5.33996L10.4798 5.39811L10.3504 5.78645C10.3251 5.86241 10.318 5.88336 10.3119 5.90032C10.0784 6.54581 9.47301 6.98213 8.78681 6.99952C8.76877 6.99997 8.74668 7.00005 8.66661 7.00005H4.6665C4.11422 7.00005 3.6665 7.44777 3.6665 8.00005C3.6665 8.55233 4.11422 9.00005 4.6665 9.00005H8.67804H8.70036H23.2996H23.3218H27.3333C27.8856 9.00005 28.3333 8.55233 28.3333 8.00005C28.3333 7.44777 27.8856 7.00005 27.3333 7.00005H23.3333C23.2532 7.00005 23.2312 6.99997 23.213 6.99952C22.5269 6.98213 21.9216 6.54579 21.688 5.90029C21.682 5.88344 21.6748 5.86204 21.6496 5.78645L21.5201 5.39811L21.5006 5.33992C21.4096 5.06615 21.3302 4.82764 21.2193 4.61445C20.7806 3.7722 19.9692 3.18733 19.0314 3.03759C18.7941 2.99968 18.5428 2.99984 18.2542 3.00003L18.1929 3.00005H13.8069ZM12.1926 6.58064C12.1403 6.72539 12.0794 6.86536 12.0107 7.00005H19.9892C19.9205 6.86536 19.8597 6.72541 19.8073 6.58067L19.7557 6.42955L19.6226 6.03057C19.5012 5.66588 19.4732 5.59151 19.4454 5.53819C19.2992 5.25744 19.0286 5.06248 18.7161 5.01256C18.6568 5.00308 18.5773 5.00005 18.1929 5.00005H13.8069C13.4225 5.00005 13.3432 5.00308 13.2838 5.01256C12.9712 5.06248 12.7007 5.25744 12.5545 5.53819C12.5268 5.59151 12.4988 5.66589 12.3772 6.03057L12.2441 6.42979C12.2241 6.48989 12.2085 6.53669 12.1926 6.58064Z"
        />
      </g>
    </svg>
  );
};
