import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const getUserDetail = (sellerData) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/${sellerData.id}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    if(res?.data?.data){
      dispatch({
        type: "SETUSERINFO",
        payload: res.data.data,
      });
    }
    return res;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const setUserInfo = (userInfo) => async (dispatch) => {
  dispatch({
    type: "SETUSERINFO",
    payload: userInfo,
  });
};

export const updateProfile = (data, endPoint) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }

    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/${data.userId}/${endPoint}`,
      data: formData,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({
      type: "SETUSERINFO",
      payload: res.data.data,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/forgotPassword`,
      data: {
        email,
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const resetPassword =
  (newPassword, confirmNewPassword, resetKey) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/seller/resetPassword`,
        data: {
          newPassword,
          confirmNewPassword,
          resetKey,
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const changePassword =
  (
    email,
    oldPassword,
    newPassword,
    confirmNewPassword,
    socialVerificationData
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/seller/change-password`,
        data: {
          email,
          oldPassword,
          newPassword,
          confirmNewPassword,
          socialVerificationData,
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const getCountriesList = () => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-countries-list`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    console.log("error to get countries list", error);
    dispatch(handleError(error));
  }
};

export const checkOldPassword = (userId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/old-password/${userId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res.data;
  } catch (error) {
    console.log("error to get countries list", error);
    dispatch(handleError(error));
  }
};

export const getStatesList = (countryId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-states-list/${countryId}`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
  } catch (error) {
    console.log("error to get states list", error);
    dispatch(handleError(error));
  }
};

export const getCitiesList = (stateId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-cities-list/${stateId}`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    console.log("res from get cities list", res);
    return res;
  } catch (error) {
    console.log("error to get cities list", error);
    dispatch(handleError(error));
  }
};

export const getCitiesListByStateCode = (stateCode) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/cart/get-cities-by-state/${stateCode}`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    console.log("res from get cities list by state code", res);
    return res;
  } catch (error) {
    console.log("error to get cities list by state code", error);
    dispatch(handleError(error));
  }
};

export const connectStripeAccount =
  (email, userId, stripe_vendor_account_id, emailChange) =>
  async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/stripe/accountLinks`,
        data: { email, userId, stripe_vendor_account_id, emailChange },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res?.data;
    } catch (error) {
      dispatch(handleError(error));
      return error?.response?.data;
    }
  };

export const checkStripeDetailsCompleted =
  (accountId, userId) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/stripe/getStripeVendorAccountDetails/${accountId}/${userId}`,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
    } catch (error) {
      console.log("error to get countries list", error);
      dispatch(handleError(error));
    }
  };

export const installShopify = (shopify) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/shopify/installApp`,
      data: shopify,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getShopifyDetails = (vendorId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/shopify/vendor/${vendorId}/shopify`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getCustomerFromShopify = (shopify_details) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/shopify/getCustomers`,
      data: shopify_details,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getProductsFromShopify = (shopify_details) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/shopify/getCustomers`,
      data: shopify_details,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getCategoriesFromShopify =
  (shopify_details) => async (dispatch) => {
    try {
      dispatch({ type: "LOADING_STARTED" });
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/seller/shopify/getAllCollects`,
        data: shopify_details,
      });
      dispatch({ type: "LOADING_COMPLETED" });
      return res;
      // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const addCategoriesToShopify = (shopify_details) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/shopify/createCollectsOnShopify`,
      data: shopify_details,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const addProductsToShopify = (shopify_details) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/shopify/createProduct`,
      data: shopify_details,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const addCustomersToShopify = (shopify_details) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/shopify/createCustomer`,
      data: shopify_details,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const installWooCommerce = (shopify) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/woocommerce/installApp`,
      data: shopify,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getWooCommerceDetails = (vendorId, type) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/woocommerce/getWooCommerceDetails/${vendorId}/${type}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getWooCommerceProducts = (storeDetails) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/woocommerce/getProducts`,
      data: storeDetails,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const postCategoriesToSubsciety = (storeDetails) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/woocommerce/postCategoriesToSubsciety`,
      data: storeDetails,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const addCustomersToWooCommerce = (storeDetails) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/woocommerce/postFromWoocommerce`,
      data: storeDetails,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const installZoomApp = (userId, code) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "get",
      url: `${URL}/api/v1/seller/zoom/installApp?code=${code}&userId=${userId}`,
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res?.data;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const createMeeting = (userId, productId) => async (dispatch) => {
  try {
    dispatch({ type: "LOADING_STARTED" });
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/zoom/createMeetings/${userId}/${productId}`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    dispatch({ type: "LOADING_COMPLETED" });
    return res?.data.data;
    // dispatch({ type: "FORGOT_PASSWORD", payload: { data: res.data } });
  } catch (error) {
    dispatch(handleError(error));
  }
};
