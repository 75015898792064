  import React from 'react';

  import {  useLocation, useNavigate } from 'react-router-dom';

  import { PrimaryCheckboxIcon } from '../../Components/svgElements/PrimaryCheckboxIcon';

  const ProductHeader = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const {
      isFilled,
      productId,
      categoryId,
      sectionStatus = {},
    } = props.state;

    const handleClick = (route, condition) => {
      if (condition && productId) {
        navigate(route, {
          state: { productId: productId, sectionStatus: sectionStatus,categoryId:categoryId ,isFilled},
        });
      } else if (condition && !productId) {
        if (route === "/category-selection") {
          navigate(route, {
            state: {
              sectionStatus: sectionStatus,
              categoryId: categoryId,
            },
          });
        }
      }
    };
    
    return (
      <div className="onboarding-tab">
        <ul>
          <li
            onClick={() =>
              handleClick(
                "/category-selection",
                sectionStatus.categorySelection
              )
            }
            className={
              sectionStatus.categorySelection
                ? "active"
                : location.pathname === "/category-selection"
                ? "half-active"
                : ""
            }
          >
            {sectionStatus.categorySelection && <PrimaryCheckboxIcon />}
            Category Selection
          </li>
          <li
            onClick={() =>
              handleClick("/basic-details", sectionStatus.basicDetails)
            }
            className={
              sectionStatus.basicDetails
                ? "active"
                : location.pathname === "/basic-details"
                ? "half-active"
                : ""
            }
          >
            {sectionStatus.basicDetails && <PrimaryCheckboxIcon />}
            Basic Details
          </li>

          {categoryId === 3 ? (
            <li
              onClick={() =>
                handleClick(
                  "/product-configuration-session",
                  sectionStatus.productConfigurationSession
                )
              }
              className={
                sectionStatus.productConfigurationSession
                  ? "active"
                  : location.pathname === "/product-configuration-session"
                  ? "half-active"
                  : ""
              }
            >
              {sectionStatus.productConfigurationSession && (
                <PrimaryCheckboxIcon />
              )}
              Product Configuration
            </li>
          ) : (
            <li
              onClick={() =>
                handleClick(
                  "/product-configuration",
                  sectionStatus.productConfiguration
                )
              }
              className={
                sectionStatus.productConfiguration
                  ? "active"
                  : location.pathname === "/product-configuration"
                  ? "half-active"
                  : ""
              }
            >
              {sectionStatus.productConfiguration && <PrimaryCheckboxIcon />}
              Product Configuration
            </li>
          )}
          {categoryId === 3 ? (
            <li
              onClick={() =>
                handleClick(
                  "/subscription-content",
                  sectionStatus.subscriptionContent
                )
              }
              className={
                sectionStatus.subscriptionContent
                  ? "active"
                  : location.pathname === "/subscription-content"
                  ? "half-active"
                  : ""
              }
            >
              {sectionStatus.subscriptionContent && <PrimaryCheckboxIcon />}
              Subscription Content
            </li>
          ) : (
            <li
              onClick={() =>
                handleClick(
                  "/product-attributes",
                  sectionStatus.productAttribute
                )
              }
              className={
                sectionStatus.productAttribute
                  ? "active"
                  : location.pathname === "/product-attributes"
                  ? "half-active"
                  : ""
              }
            >
              {sectionStatus.productAttribute && <PrimaryCheckboxIcon />}
              Product Attributes
            </li>
          )}
          <li
            onClick={() =>
              handleClick("/marketing-content", sectionStatus.marketingContent)
            }
            className={
              sectionStatus.marketingContent
                ? "active"
                : location.pathname === "/marketing-content"
                ? "half-active"
                : ""
            }
          >
            {sectionStatus.marketingContent && <PrimaryCheckboxIcon />}
            Marketing Content
          </li>
        </ul>
      </div>
    );
  }

  export default ProductHeader;