import axios from "axios";
import {handleError} from "./commonAction";

const URL = process.env.REACT_APP_API_URL;

export const addFiles = (type, files) => async (dispatch) => {
    try {
        switch (type){
            case "videos":
                dispatch({
                    type: "VIDEOS",
                    payload: {files: files }
                })
                break;
            case "images":
                dispatch({
                    type: "IMAGES",
                    payload: {files: files }
                })
                break;
            case "documents":
                dispatch({
                    type: "DOCUMENTS",
                    payload: {files: files }
                })
                break;
            case "acsCatVideos":
                dispatch({
                    type: "ACS_CAT_VIDEOS",
                    payload: {files: files }
                })
                break;
            case "acsCatAudios":
                dispatch({
                    type: "ACS_CAT_AUDIOS",
                    payload: {files: files }
                })
                break;
            case "acsCatImages":
                dispatch({
                    type: "ACS_CAT_IMAGES",
                    payload: {files: files }
                })
                break;
            case "acsCatDocuments":
                dispatch({
                    type: "ACS_CAT_DOCUMENTS",
                    payload: {files: files }
                })
                break;
        }
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const addFilesError = (type, errors) => async (dispatch) => {
    try {
        switch (type){
            case "videos":
                dispatch({
                    type: "ERROR_VIDEOS",
                    payload: {errors: errors }
                })
                break;
            case "images":
                dispatch({
                    type: "ERROR_IMAGES",
                    payload: {errors: errors }
                })
                break;
            case "documents":
                dispatch({
                    type: "ERROR_DOCUMENTS",
                    payload: {errors: errors }
                })
                break;
            case "acsCatVideos":
                dispatch({
                    type: "ERROR_ACS_CAT_VIDEOS",
                    payload: {errors: errors }
                })
                break;
            case "acsCatAudios":
                dispatch({
                    type: "ERROR_ACS_CAT_AUDIOS",
                    payload: {errors: errors }
                })
                break;
            case "acsCatImages":
                dispatch({
                    type: "ERROR_ACS_CAT_IMAGES",
                    payload: {errors: errors }
                })
                break;
            case "acsCatDocuments":
                dispatch({
                    type: "ERROR_ACS_CAT_DOCUMENTS",
                    payload: {errors: errors }
                })
                break;
        }
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const addMoreFiles = (type, files) => async (dispatch) => {
    try {
        switch (type){
            case "images":
                dispatch({
                    type: "ADD_IMAGES",
                    payload: {files: [ files ]}
                });
                break;
            case "documents":
                dispatch({
                    type: "ADD_DOCUMENTS",
                    payload: {files: [ files ]}
                });
                break;
            case "videos":
                dispatch({
                    type: "ADD_VIDEOS",
                    payload: {files: [ files ]}
                });
                break;
            case "acsCatVideos":
                dispatch({
                    type: "ADD_ACS_CAT_VIDEOS",
                    payload: {files: [ files ]}
                });
                break;
            case "acsCatAudios":
                dispatch({
                    type: "ADD_ACS_CAT_AUDIOS",
                    payload: {files: [ files ]}
                });
                break;
            case "acsCatImages":
                dispatch({
                    type: "ADD_ACS_CAT_IMAGES",
                    payload: {files: [ files ]}
                });
                break;
            case "acsCatDocuments":
                dispatch({
                    type: "ADD_ACS_CAT_DOCUMENTS",
                    payload: {files: [ files ]}
                });
                break;
        }
    } catch (error) {
        dispatch(handleError(error));
    }
}

export const updateAttibute = (productId,variantCombination) => async (dispatch) => {
    try {
        const res = await axios({
          method: "post",
          url: `${URL}/api/v1/seller/product/update-attribute`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {productId,variantCombination}
        });
        
        return res;
      } catch (error) {
        dispatch(handleError(error));
      }
   }

    export const uploadAttibute = (base64,index,slug) => async (dispatch) => {
    try {
        const res = await axios({
          method: "post",
          url: `${URL}/api/v1/seller/product/upload-attribute`,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          data: {base64,index,slug}
        });
        
        return res;
      } catch (error) {
        dispatch(handleError(error));
      }
    }
    
export const uploadProductFiles = (pathUrl, path, query, type, index, data) => async (dispatch) => {
    try {
        const url = `${URL}${pathUrl}${path ? "/" + path.join("/") : ""}${ query ? (new URLSearchParams(query)).toString() : "" }`;
        const formData = new FormData();
            formData.append('file', data.file); // Append the file (compressed image)
            formData.append('type', type);
            formData.append('productId', data.productId);
            formData.append('fileUploadPathEndPoint', data.fileUploadPathEndPoint);
            formData.append('isPrivate', data.isPrivate);
            formData.append('fileName', data.fileName ? data.fileName : null);
        const res = await axios({
            method: 'post',
            url: url,
            data:formData,
            headers: {
                 'Content-Type': 'multipart/form-data',
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
        return res.data;
    }
    catch (error) {
        dispatch(handleError(error));
        return  {isError: true, response: error}
    }
}


export const uploadFiles = (pathUrl, path, query, type, index, data) => async (dispatch) => {
    try {
        const url = `${URL}${pathUrl}${path ? "/" + path.join("/") : ""}${ query ? (new URLSearchParams(query)).toString() : "" }`;
        const res = await axios({
            method: 'post',
            url: url,
            data: data,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            onUploadProgress: function( progressEvent ) {
                const uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                if (uploadPercentage >= 100){
                    dispatch({
                        type: "UPDATE_FILE",
                        payload: {
                            type: type,
                            index: index,
                            key: 'uploadPercentage',
                            value: 95
                        }
                    });
                }else{
                    dispatch({
                        type: "UPDATE_FILE",
                        payload: {
                            type: type,
                            index: index,
                            key: 'uploadPercentage',
                            value: uploadPercentage
                        }
                    });
                }
            }
        })
        dispatch({
            type: "UPDATE_FILE",
            payload: {
                type: type,
                index: index,
                key: 'uploadPercentage',
                value: 100
            }
        });
        return res;
    }
    catch (error) {
        dispatch(handleError(error));
        return  {isError: true, response: error}
    }
}