import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    installZoomApp
} from "../../Redux/Actions/userAction";

function Zoom(props) {
    let dispatch = useDispatch()
    let sellerId = localStorage.getItem("sellerId");
    const params = new URLSearchParams(props.location.search);
    const code = params.get('code')
    useEffect(() => {
        const AccessZoom = async () => {
            const res = await dispatch(installZoomApp(sellerId, code))
            if (!res.isError) {
                alert(res.message)
            } else {
                alert(res.message)
            }
            props.navigate('/zoom-meeting')
        }
        AccessZoom()
    }, [])

    return (
        <div>
            user successfully connected
        </div>
    )
}

export default Zoom
