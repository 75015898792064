import React from "react";

const TextareaBox = (props) => {
  const {
    label,
    value,
    onChange,
    parentClass = "form-group",
    inputClass = "form-control",
    required = true,
    error,
    placeholder,
    rows,
    cols,
    ...extraProps
  } = props;

  return (
    <div className={parentClass}>
      {label ? (
        <label>
          {label}
          {required ? <sup>*</sup> : null}
        </label>
      ) : null}
      <textarea
        value={value || ''}
        onChange={onChange}
        className={inputClass}
        placeholder={placeholder}
        rows={rows} 
        cols={cols}
        {...extraProps}
      ></textarea>
      <span className="form-field-error">{error ? error : ""}</span>
    </div>
  );
};

export default React.memo(TextareaBox);
