import React from "react";

export const FileDownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style={{ stroke: "none" }}
    >
      <g clipPath="url(#clip0_736_27)">
        <path d="M20.6194 0.184815C20.4727 0.0302736 20.2715 -0.0625 20.063 -0.0625H7.57984C5.27637 -0.0625 3.375 1.8313 3.375 4.13453V27.7403C3.375 30.0438 5.27637 31.9376 7.57984 31.9376H24.5227C26.8262 31.9376 28.7276 30.0438 28.7276 27.7403V8.99636C28.7276 8.79543 28.6348 8.60231 28.5035 8.45534L20.6194 0.184815ZM20.8435 2.66602L26.1228 8.20802H22.691C21.6707 8.20802 20.8435 7.38869 20.8435 6.36842V2.66602ZM24.5227 30.3917H7.57984C6.13453 30.3917 4.9209 29.1937 4.9209 27.7403V4.13453C4.9209 2.68921 6.12671 1.4834 7.57984 1.4834H19.2976V6.36842C19.2976 8.2466 20.8128 9.75393 22.691 9.75393H27.1817V27.7403C27.1817 29.1937 25.9759 30.3917 24.5227 30.3917Z" />
        <path d="M22.4126 25.0583H9.68994C9.26489 25.0583 8.91699 25.406 8.91699 25.8313C8.91699 26.2563 9.26489 26.6043 9.68994 26.6043H22.4204C22.8455 26.6043 23.1934 26.2563 23.1934 25.8313C23.1934 25.406 22.8455 25.0583 22.4126 25.0583Z" />
        <path d="M15.4869 22.2835C15.6339 22.438 15.8348 22.5308 16.0511 22.5308C16.2677 22.5308 16.4686 22.438 16.6153 22.2835L21.1449 17.4216C21.4386 17.1123 21.4154 16.6177 21.1063 16.3318C20.797 16.0381 20.3023 16.061 20.0164 16.3704L16.8241 19.7944V11.354C16.8241 10.9287 16.4762 10.5811 16.0511 10.5811C15.6261 10.5811 15.2782 10.9287 15.2782 11.354V19.7944L12.0936 16.3704C11.7999 16.0613 11.3128 16.0381 11.0037 16.3318C10.6947 16.6255 10.6715 17.1126 10.9652 17.4216L15.4869 22.2835Z" />
      </g>
      <defs>
        <clipPath id="clip0_736_27">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
