import React from "react";
import { Modal } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { addAttribute } from "../../Redux/Actions/attributeAction";
import { useDispatch } from "react-redux";
import CommonButton from "../../Components/FormElements/CommonButton";
function AddAttribute(props) {
  const dispatch = useDispatch();
  const { showAttributeModel, handleClose, onSubmit } = props;

  // State Hooks
  const [attributeTitle, setAttributeTitle] = React.useState("");
  const [variationValues, setVariationValues] = React.useState([]);
  const [errorData, setErrorData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  // Handle Input Change
  const handleChange = (e, key) => {
    const { value } = e.target;
    setErrorData((prevErrorData) => ({
      ...prevErrorData,
      [key]: null,
    }));
    setAttributeTitle(value);
  };

  // Handle Select Change for Variations
  const handleAttributeCategoryChange = (selectedOptions) => {
    setVariationValues(selectedOptions || []);
  };

  // Form Validation
  const validateForm = () => {
    let errors = {};
    let isFormValid = true;

    if (!attributeTitle.trim()) {
      isFormValid = false;
      errors.attributeTitle = "Attribute title field cannot be empty.";
    } else if (
      attributeTitle.includes(`"`) ||
      attributeTitle.includes(`'`) ||
      attributeTitle.includes("`")
    ) {
      isFormValid = false;
      errors.attributeTitle = "This field cannot contain quotes.";
    }

    if (variationValues.length === 0) {
      isFormValid = false;
      errors.variationValues = "At least one variant value is required.";
    }

    setErrorData(errors);
    return isFormValid;
  };

  // Handle Form Submit
  const handleSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      let obj = [];
      variationValues.map((variation) => {
        obj.push({
          label: variation.value,
          value: variation.value,
          __isNew__: true,
        });
        return obj;
      });

      const payload = {
        attributeTitle,
        variation_values: obj,
      };
      const userId = localStorage.getItem("sellerId");

      try {
        const res = await dispatch(addAttribute(payload, userId));
        if (res && res.data) {
          setVariationValues([]);
          setAttributeTitle("");
          onSubmit();
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error adding attribute:", error);
      }
    }
  };

  const handleCloseModel = () => {
   setVariationValues([]);
  setAttributeTitle("");
  }

  const AddAttributeModel = () => (
    <Modal
      show={showAttributeModel}
      onHide={handleClose}
      className="theme-modal add-product-page"
      centered
    >
      <Modal.Body>
        <div className="heading text-center pb-4">
          <h2>Add New Attribute</h2>
        </div>
        <div className="form-group">
          <label>
            Attribute Title<sup>*</sup>
          </label>
          <input
            placeholder="Size"
            type="text"
            value={attributeTitle}
            onChange={(e) => handleChange(e, "attributeTitle")}
            className="form-control"
          />
          {errorData.attributeTitle && (
            <span className="form-field-error">{errorData.attributeTitle}</span>
          )}
        </div>
        <div className="form-group">
          <label>
            Variations<sup>*</sup>
          </label>
          <CreatableSelect
            isMulti
            name="value"
            value={variationValues}
            onChange={handleAttributeCategoryChange}
            isOptionUnique={true}
            className="basic-multi-select"
            classNamePrefix="select"
          />
          {errorData.variationValues && (
            <span className="form-field-error">
              {errorData.variationValues}
            </span>
          )}
        </div>
        <div className="button-center d-flex">
          <CommonButton
            value="Submit"
            loading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit}
            buttonClass="theme-button primary-btn radius-btn m-1"
          />
          <CommonButton
            value="Cancel"
            disabled={isLoading}
            onClick={() => { handleClose(); handleCloseModel() }}
            buttonClass="theme-button dark-outline-btn radius-btn m-1"
          />
        </div>
      </Modal.Body>
    </Modal>
  );

  return <div className="seminar-section">{AddAttributeModel()}</div>;
}

export default AddAttribute;
