import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import ReactCrop from "react-image-crop";
import { useDispatch } from "react-redux";

import RadioBox from "../../Components/FormElements/RadioBox";
import InputBox from "../../Components/FormElements/InputBox";
import SelectBox from "../../Components/FormElements/SelectBox";
import TextareaBox from "../../Components/FormElements/TextareaBox";
import CommonButton from "../../Components/FormElements/CommonButton";

import { BackIcon } from "../../Components/svgElements/BackIcon";
import { ImageIcon } from "../../Components/svgElements/ImageIcon";
import { PrimaryInfoIcon } from "../../Components/svgElements/PrimaryInfoIcon";
import useForm from "../../Components/customHooks/useForm";
import { DISCOUNT_TYPE, File_SIZE } from "../../Constant/Constant";

import {
  addProductBasicDetails,
  editProductBasicDetails,
  getProductDetailNew,
} from "../../Redux/Actions/productAction";

import "./AddProduct.scss";
import "react-image-crop/dist/ReactCrop.css";
import ProductHeader from "./ProductHeader";

const BasicDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productId = location.state.productId;
  const status = location.state.sectionStatus;
  const categoryId = location.state.categoryId;
  const isCategoryChanged = location.state.isCategoryChanged;
  const [sectionStatus, setSectionStatus] = React.useState();
  const [productDetail, setProductDetail] = React.useState({});
  React.useEffect(() => {
    if (status) {
      setSectionStatus(status);
    }
  }, [status]);

  const { formData, onChangeHandler, setFormData } = useForm({
    productName: "",
    productImage: "",
    productDescription: "",
  });
  const [crop, setCrop] = React.useState({
    unit: "%",
    width: 64,
    height: 36,
    x: 25,
    y: 25,
    aspect: 16 / 9,
  });
  const [image, setImage] = React.useState({
    preview: "",
    base64: "",
  });
  const [discount, setDiscount] = React.useState({
    type: 0,
    price: 0,
  });
  const [isSale, setIsSale] = React.useState(false);
  const [basePrice, setBasePrice] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [errorData, setErrorData] = React.useState({});
  const [showCropper, setShowCropper] = React.useState(true);

  const setProductState = React.useCallback(
    (product) => {
      setFormData({
        productName: product?.name,
        productImage: product?.imageName,
        productDescription: product?.description,
      });
      setDiscount({
        type: product?.price_type,
        price: product.price_type === 2
        ? Math.round(
            100 * (1 - product.sale_price / product.price)
          )
        : product.sale_price,
      });
      setIsSale(product?.is_sale);
      setBasePrice(product?.price);
    },
    [setFormData]
  );

  React.useEffect(() => {
    if(!isCategoryChanged){
      fetchProductDetails();
    }
  }, []);

  const fetchProductDetails = async () => {
    if (productId) {
      localStorage.removeItem("categoryId");
      const { data } = await dispatch(getProductDetailNew(productId));
      if (data.data) {
        setProductDetail(data.data);
        setProductState(data.data);
      }
    }
  };

  React.useEffect(() => {
    if (formData.productName?.length) {
      errorData.productName = "";
    }
    if (formData.productDescription?.length) {
      errorData.productDescription = "";
    }
    if (formData.productImage?.length) {
      errorData.productImage = "";
      errorData.imageError = "";
    }
    if (basePrice) {
      errorData.basePrice = "";
    }
    if (isSale) {
      if (discount.type) {
        errorData.discountType = "";
      }
      if (discount.price) {
        errorData.discountPrice = "";
      }
    }
  }, [formData, errorData, basePrice, isSale, discount.type, discount.price]);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files?.length > 0) {
      if (e.target.files[0].size > File_SIZE) {
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          imageError: "File size should not be more then 10MB.",
        }));
        return null;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setFormData((prevData) => ({
          ...prevData,
          productImage: reader.result,
        }));
        setImage((prevImage) => ({ ...prevImage, preview: reader.result }));
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    setShowCropper(true);
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL();
      resolve(dataURL);
    });
  };

  const onCropComplete = async (crop) => {
    if (image.base64 && crop.width && crop.height) {
      const croppedImageURL = await getCroppedImg(
        image.base64,
        crop,
        "newFile.jpeg"
      );
      setFormData({ ...formData, productImage: croppedImageURL });
    }
  };

  const onImageLoaded = (e) => {
    setImage({ ...image, base64: e.target });
  };

  const validateInfo = () => {
    let isFormValid = true;
    let errorObj = {};
    if (!formData.productName.trim().length) {
      isFormValid = false;
      errorObj["productName"] = `${categoryId !== 3 ? "Product" : "Company"
        } name is required.`;
    }
    if (!formData.productDescription.trim().length) {
      isFormValid = false;
      errorObj["productDescription"] = "Product description is required.";
    }
    if (!formData.productImage.trim().length) {
      isFormValid = false;
      errorObj["productImage"] = "Product image is required.";
    }
    if (categoryId !== 3 && (!basePrice || Number(basePrice) <= 0)) {
      isFormValid = false;
      errorObj["basePrice"] =
        "Base price is required";
    }
    if (categoryId !==3 && isSale) {
      if (!discount.type) {
        isFormValid = false;
        errorObj["discountType"] = "Discount type is required.";
      }
      if (!discount.price) {
        isFormValid = false;
        errorObj["discountPrice"] = "Discount price is required.";
      }
    }
    setErrorData(errorObj);
    return isFormValid;
  };

  const handleSubmit = async () => {
    if (validateInfo()) {
      setLoading(true);
      let result;
      if (!productId) {
        const state = {
          ...formData,
          isSale,
          basePrice,
          discount,
          categoryId,
        };
        result = await dispatch(addProductBasicDetails(state));
      } else {
        const state = {
          ...formData,
          isSale,
          basePrice,
          discount,
          categoryId,
          productId,
          subscriptionCostEditedArray : null,
          stripeProductId: productDetail && productDetail?.stripe_product_id
        };
        result = await dispatch(editProductBasicDetails(state));
      }
      setLoading(false);
      if (result.status === 201) {
        const productId = result.data.data.productId
          ? result.data.data.productId
          : productDetail?.id;
        const updatedSectionStatus = {
          ...sectionStatus,
          basicDetails: true,
        };
        setSectionStatus(updatedSectionStatus);
        navigate(
          categoryId === 3
            ? "/product-configuration-session"
            : "/product-configuration",
          {
            state: {
              categoryId: categoryId,
              productId: productId,
              sectionStatus: updatedSectionStatus,
              isCategoryChanged
            },
          }
        );
      }
    }
  };
  return (
    <>
      <div className="add-product-page">
        <div className="page-heading">
          <button
            onClick={() =>
              navigate("/category-selection", {
                state: {
                  sectionStatus: sectionStatus,
                  productId: productId,
                  categoryId,
                },
              })
            }
            className="theme-button dark-outline-btn radius-sm back-btn"
          >
            <BackIcon />
          </button>
          <h2>Add Product</h2>
        </div>
        <ProductHeader
          state={{
            productId: productId,
            categoryId: categoryId,
            sectionStatus: status,
            productDetail,
          }}
        />
        <div className="white-card">
          <div className="select-category-section">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <InputBox
                  type="text"
                  maxLength={1000}
                  name="productName"
                  onChange={onChangeHandler}
                  value={formData.productName}
                  error={errorData?.productName}
                  placeholder="Enter product name"
                  label={`${
                    categoryId !== 3 ? "Product Name" : "Company Name"
                  }`}
                />
              </div>
              {categoryId !== 3 && (
                <div className="col-md-6 col-lg-6">
                  <InputBox
                    label="Base Price"
                    name="price"
                    value={basePrice}
                    type="number"
                    error={errorData?.basePrice}
                    placeholder="Enter base price"
                    onChange={(e) =>
                      setBasePrice(e.target.value > 0 ? e.target.value : 0)
                    }
                  />
                </div>
              )}
              <div className="col-md-12 col-lg-12">
                <div className="form-inner-hrading mb-2">
                  {categoryId !== 3 && (
                    <div className="name">
                      <h4>Product On Sale</h4>
                      <div className="tooltip-box">
                        <div class="tooltip-custom">
                          <PrimaryInfoIcon />
                          <div class="together-tooltip">
                            <p>
                              Offer discount on the Base Price of the product.
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {categoryId !== 3 && (
                    <div className="inline-radio">
                      <RadioBox
                        label="Yes"
                        value={isSale}
                        checked={isSale}
                        onChange={() => setIsSale(true)}
                      />
                      <RadioBox
                        label="No"
                        value={isSale}
                        checked={!isSale}
                        onChange={() => {
                          setIsSale(false);
                          setDiscount({ type: 0, price: 0 });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="form-group zindex-2">
                  {isSale ? (
                    <SelectBox
                      required
                      parentClass="mb-0"
                      label="Discount Type"
                      value= {DISCOUNT_TYPE.filter((item)=> {return(item.value === discount.type ? item.label : "")})}
                      options={DISCOUNT_TYPE}
                      onChange={(data) =>
                        setDiscount({ ...discount, type: data.value })
                      }
                      error={errorData?.discountType}
                    />
                  ) : null}
                  {categoryId !== 3 && (
                    <div className="input-note">
                      <p>
                        <b>Note:</b> After initial approval, price changes will
                        be reflected after 10 days.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {isSale ? (
                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <InputBox
                    parentClass="mb-0"
                      type="number"
                      value={discount.price}
                      label="Discount Amount"
                      error={errorData?.discountPrice}
                      placeholder="Enter discount amount"
                      onChange={(e) =>
                        setDiscount((prevData) => ({
                          ...prevData,
                          price: e.target.value > 0 ? e.target.value : 0,
                        }))
                      }
                    />
                    {isSale && basePrice && discount.price && discount.price > 0
                      ? discount.type === 2
                        ? "Discounted Price : " +
                          (basePrice - (basePrice * discount.price) / 100)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "Discounted Price : " +
                          (basePrice - discount.price)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : null}
                  </div>
                </div>
              ) : null}

              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <div className="d-flex g-2">
                    <label>
                      Upload Image<sup>*</sup>
                    </label>
                    <div className="tooltip-box pl-2">
                      <div class="tooltip-custom">
                        <PrimaryInfoIcon />
                        <div class="together-tooltip">
                          <p>
                            Upload the primary image for your product.
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                <div className="mb-1">
                  <div className="upload-box">
                    <input
                      id="file"
                      type="file"
                      accept="image/*"
                      name="productImage"
                      className="inputfile"
                      onChange={onSelectFile}
                      data-multiple-caption="{count} files selected"
                    />

                    <div className="box-content">
                      <ImageIcon />
                      <p>Upload Image</p>
                        <p> Image format along with max size</p>
                      <span>
                        (.jpeg, .jpg or .png - max file size 10 mb)
                      </span>
                    </div>
                  </div>
                  <span className="form-field-error">
                    {errorData.imageError
                      ? errorData.imageError
                      : errorData?.productImage
                      ? errorData?.productImage
                      : ""}
                  </span>
                  </div>
                  <div className="crop-img-box">
                    <div className="row">
                      <div className="col-md-4 col-lg-3">
                        {formData.productImage && (
                          <div className="box-img">
                            <img
                              className="w-100"
                              alt="Crop"
                              src={formData.productImage}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        {formData.productImage &&
                          showCropper &&
                          !productDetail?.imageName && (
                            <div className="crop-image-view">
                              <ReactCrop
                                crop={crop}
                                onChange={(crop) => setCrop(crop)}
                                onComplete={onCropComplete}
                              >
                                <img
                                  src={image.preview}
                                  alt=""
                                  onLoad={(e) => onImageLoaded(e)}
                                />
                              </ReactCrop>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="mt-4">
                      {formData.productImage && showCropper && (
                        <>
                          <button
                            type="button"
                            className="mr-2"
                            onClick={() => setShowCropper(false)}
                          >
                            Done
                          </button>
                          {/* <button
                            onClick={() => setShowCropper(false)}
                            type="button"
                          >
                            Cancel
                          </button> */}
                        </>
                      )}
                    </div>
                  </div>
                
                </div>
              </div>

              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <div className="d-flex g-2">
                    <label>
                      Product Description<sup>*</sup>
                    </label>
                    <div className="tooltip-box pl-2">
                      <div class="tooltip-custom">
                        <PrimaryInfoIcon />
                        <div class="together-tooltip">
                          <p>
                            Enter a detailed description of the product
                            including key features, specifications and benefits.
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TextareaBox
                    name="productDescription"
                    value={formData.productDescription}
                    parentClass="mb-0"
                    error={errorData?.productDescription}
                    placeholder="Enter product description"
                    rows="3"
                    maxLength={500}
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="action-btn">
            <CommonButton
              value="Continue"
              loading={loading}
              disabled={loading}
              onClick={() => handleSubmit()}
              buttonClass="theme-button primary-btn radius-btn"
            />
            <CommonButton
              value="Cancel"
              disabled={loading}
              onClick={() => {
                localStorage.removeItem("categoryId");
                navigate("/product-list");
              }}
              buttonClass="theme-button dark-outline-btn radius-btn"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicDetails;
