import React from 'react'
import moment from "moment";
import { Modal } from "react-bootstrap";
import TimePicker from "react-time-picker";
import DatePicker from "react-date-picker";
import CommonCheckbox from '../../Components/FormElements/CommonCheckbox';
import { PrimaryInfoIcon } from '../../Components/svgElements/PrimaryInfoIcon';


function OneTimeEvent(props) {

    const freeSeminarModal = () => (
        <Modal
            show={props.showHideFreeSeminarModal 
                ? props.showHideFreeSeminarModal: props.isFreeSeminar
            }
            onHide={() => props.handleFreeSeminarModalShowHide(2)}
            className="calendar-modal"
            centered
        >
            <Modal.Header className="calendar-header">
                <span onClick={() => props.handleFreeSeminarModalShowHide(2)}>
                    <i className="fas fa-times"></i>
                </span>
                {props.reqMessage && (
                    <div className="success-msg">
                        <p>{props.reqMessage}</p>
                    </div>
                )}
                <h5 className="text-center w-100 m-0">
                    {!!!props.isFreeSeminarCreated
                        ? `Create Free One-Time Session`
                        : `Edit Seminar Details`}
                </h5>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            {props.successMessage && (
                                <div className="success-msg">
                                    <p>{props.successMessage}</p>
                                </div>
                            )}
                            {/* <p className="text-center">
                                Select time slot for free one-time session
                            </p> */}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>
                                <p className="form-field-error">
                                    {props.eventNameError}
                                </p>
                                <b>Event Name:</b>
                            </label>
                            <input
                                placeholder="Enter Event Name"
                                value={props.formData["name"]}
                                onChange={(e) => props.setEventName(e.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="col-md-12 dash-date">
                        <div className="form-group">
                            <label>
                                <p className="form-field-error">
                                    {props.popupErrorMessage}
                                </p>
                                <b>Date</b>
                            </label>
                            <DatePicker
                                className="form-control"
                                dayPlaceholder={"dd"}
                                monthPlaceholder={"mm"}
                                yearPlaceholder={"yyyy"}
                                value={
                                    props.formData.date &&
                                        props.formData.date.length > 0
                                        ? new Date(props.formData.date)
                                        : ""
                                }
                                onChange={(event) => props.handleDateTimeChange(event, "date")}
                            // maxDate={this.state.endDate && this.state.endDate.length > 0 ? new Date(this.state.endDate) : new Date()}
                            />
                        </div>
                    </div>
                    <>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    <b>START TIME</b>
                                </label>
                                <TimePicker
                                    value={props.formData["start"]}
                                    format="hh:mm a"
                                    locale="sv-sv"
                                    name="start"
                                    disableClock={true}
                                    onChange={(e) => props.handleDateTimeChange(e, "start")}
                                    required={true}
                                    clearIcon={null}
                                    className="form-control"
                                />
                                <span className="clock-icon">
                                    <i class="far fa-clock"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>
                                    <b>END TIME</b>
                                </label>
                                <TimePicker
                                    value={props.formData["end"]}
                                    format="hh:mm a"
                                    locale="sv-sv"
                                    name="end"
                                    disableClock={true}
                                    onChange={(e) => props.handleDateTimeChange(e, "end")}
                                    required={true}
                                    clearIcon={null}
                                    className="form-control"
                                />
                                <span className="clock-icon">
                                    <i class="far fa-clock"></i>
                                </span>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <input
                                    type="checkbox" 
                                    checked={props.isFreePriceChecked}
                                    onChange={(e) => props.hidePrice(e)}
                                    value={props.isFreePriceChecked}
                                />
                            
                                <label className='ml-2'>
                                    <b> Free</b>
                                </label>
                            </div>
                        </div>

                        {!props.isFreePriceChecked && (
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>
                                        <p className="form-field-error">
                                            {props.eventPriceError}
                                        </p>
                                        <b>Meeting Price:</b>
                                    </label>
                                    <input
                                        placeholder="Enter Event Price"
                                        type="number"
                                        min={0}
                                        value={props.formData.price}
                                        onChange={(e) => props.setEventPrice(+e.target.value)}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}

                        <div className="col-md-12">
                            <div className="form-group">
                                <label>
                                    <b>Meeting DESCRIPTION :</b>
                                </label>
                                <textarea
                                    placeholder="Event Description"
                                    value={props.formData["note"]}
                                    onChange={(e) => props.setEventDescription(e.target.value)}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </>
                </div>
                <div className="button-center">
                    <button
                        // onClick={() => this.handleSubmitFreeSeminar()}
                        onClick={() => props.handleSaveChanges()}
                        className="orange-btn"
                    >
                        save changes
                        {(() => {
                            if (props?.showDenyLoader) {
                                return (
                                    <span class="spinner-border spinner-border-sm ml-1"></span>
                                );
                            }
                        })()}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );

    return (

        <div className="seminar-section">


            <div className="d-flex g-2 mb-2">
                <CommonCheckbox
                  label="Create One Time Session"
                  checked={props.isFreeSeminarCreated}
                  onChange={(e) => props.handleClickOnFreeSeminar(e)}
                />
                <div className="tooltip-box pl-2">
                  <div class="tooltip-custom">
                    <PrimaryInfoIcon />
                    <div class="together-tooltip">
                      <p>
                      Arrange a single, one-time meeting or event.
                        <span class="arrow-down"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            <div className="form-group">       
                    {props.isFreeSeminarCreated &&
                        props.formData?.start?.length > 0 && (
                            <div className="seminar-info">
                            <ul>
                                {props.formData?.name?.length > 0 && (
                                    <li>
                                        <h3>Meeting Name:</h3>
                                        <p className="space-description">
                                            {props.formData.name}
                                        </p>
                                    </li>
                                )}
                                <li>
                                    <h3>Meeting Date :</h3>
                                    <p className="space-description">{props.formData.date}</p>
                                </li>
                                <li>
                                    <h3>Meeting Time :</h3>
                                    <p className="space-description">
                                        {moment(props.formData.start, "hh:mm").format(
                                            "hh:mm A"
                                        )}{" "}
                                        to{" "}
                                        {moment(props.formData.end, "hh:mm").format(
                                            "hh:mm A"
                                        )}
                                    </p>
                                </li>
                                {props.formData?.link?.length > 0 && (
                                    <li>
                                        <h3>Meeting Link :</h3>
                                        <a target="_blank" href={props.formData.link}>
                                            Join now
                                        </a>
                                    </li>
                                )}

                                {!props.isFreePriceChecked &&  (
                                    <li>
                                        <h3>Meeting Price:</h3>
                                        <p className="space-description">
                                            {props.formData.price}
                                        </p>
                                    </li>
                                )}

                                {props.formData?.note?.length > 0 && (
                                    <li>
                                        <h3> Description :</h3>
                                        <p className="space-description">
                                            {props.formData.note}
                                        </p>
                                    </li>
                                )}
                            </ul>
                            </div>
                        )}
            </div>

            {props.isFreeSeminarCreated && (
                <button
                    type="button"
                    onClick={props.handleCreateFreeSeminar}
                    className="orange-outline-btn"
                >
                    Edit seminar details
                </button>
            )}
            <span className="form-field-error">
                {props.errorData?.freeSeminar &&
                    props.errorData.freeSeminar[0]}
            </span>
            {freeSeminarModal()}
        </div>
    )
}

export default OneTimeEvent