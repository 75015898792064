import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import "./Onboarding.scss";
import InputBox from "../../Components/FormElements/InputBox";
import CommonButton from "../../Components/FormElements/CommonButton";
import { updateProfile } from "../../Redux/Actions/userAction";
import HeaderParent from "../../Components/Header/HeaderParent";
import StepsView from "./StepsView";

const PersonalDetails = (props) => {
  const authData = useSelector((state) => state.userReducer.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    userId: "",
    addressId: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [errorData, setErrorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const stateDep = authData?.firstName;
  const setUserState = React.useCallback(() => {
    setState({
      userId: authData?.id,
      addressId: authData && authData.addressId ? authData.addressId : '',
      firstName: authData?.firstname,
      lastName: authData?.lastname,
      email: authData?.email,
      phoneNumber: authData?.phonenumber,
    });
  }, [authData]);

  useEffect(() => {
    setUserState();
  }, [stateDep, setUserState]);

  const setStateValues = (key, e) => {
    let value = e.target?.value;
    if (key === "phoneNumber") {
      value = e?.replace(/\D+/g, "");
    }
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  const validateUserInfo = () => {
    let isFormValid = true;
    const regex = /['`"]/;
    let errorObj = {};
    if (!state.firstName.trim().length) {
      isFormValid = false;
      errorObj["firstName"] = "First name field can not be empty.";
    } else if (regex.test(state.firstName)) {
      isFormValid = false;
      errorObj["firstName"] = "This field can not contain quotes";
    }

    if (!state.lastName.trim().length) {
      isFormValid = false;
      errorObj["lastName"] = "Last name field can not be empty.";
    } else if (regex.test(state.lastName)) {
      isFormValid = false;
      errorObj["lastName"] = "This field can not contain quotes";
    }
    if (!state.phoneNumber || !state.phoneNumber.trim().length) {
      isFormValid = false;
      errorObj["phoneNumber"] = "Phone number field can not be empty.";
    } else if(Number(state.phoneNumber.trim().length) < 11){
      isFormValid = false;
      errorObj["phoneNumber"] = "Phone number should be minimum of 10 digit.";
    }
    setErrorData(errorObj);
    return isFormValid;
  };

  const handleSubmit = async () => {
    if (validateUserInfo()) {
      setLoading(true);
      const result = await dispatch(
        updateProfile({ ...state }, "updateProfile-step-one")
      );
      setLoading(false);
      if (result.status === 201) {
        setSuccessMessage("Profile updated successfully!");
        navigate("/store-details");
      } else {
        setErrorMessage("Something went wrong!");
      }
    }
  };

  const cancelPage = () => {
    navigate("/");
  };

  return (
    <>
    <HeaderParent/>
    <div className="container">
      <div className="onboarding-page">
        {successMessage ? (
          <div className="alert alert-success">{successMessage}</div>
        ) : errorMessage ? (
          <div className="alert alert-danger">{errorMessage}</div>
        ) : null}
        <div className="page-heading">
          <h2>Onboarding</h2>
        </div>
        <StepsView step={1} />
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <InputBox
                    label="First Name"
                    value={state.firstName}
                    onChange={(event) => setStateValues("firstName", event)}
                    error={errorData?.firstName}
                    placeholder="Enter first name"
                  />
                </div>
                <div className="col-md-6 col-lg-6">
                  <InputBox
                    label="Last Name"
                    value={state.lastName}
                    onChange={(event) => setStateValues("lastName", event)}
                    error={errorData?.lastName}
                    placeholder="Enter last name"
                  />
                </div>
                <div className="col-md-6 col-lg-6">
                  <InputBox
                    label="Email"
                    value={state.email}
                    onChange={(event) => setStateValues("email", event)}
                    disabled
                    placeholder="Enter email address"
                  />
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <label>
                      Phone<sup>*</sup>
                    </label>
                    <PhoneInput
                      className="phone-input"
                      country={"us"}
                      placeholder="Phone Number"
                      onlyCountries={["us"]}
                      specialLabel=""
                      value={state.phoneNumber}
                      onChange={(event) => setStateValues("phoneNumber", event)}
                    />
                    <span className="form-field-error">
                      {errorData?.phoneNumber ? errorData?.phoneNumber : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="action-btn">
          <CommonButton
            value="Continue"
            loading={loading}
            onClick={handleSubmit}
            buttonClass="theme-button primary-btn radius-btn"
          />
          <CommonButton
            value="Cancel"
            onClick={cancelPage}
            buttonClass="theme-button dark-outline-btn radius-btn"
          />
        </div>
      </div>
    </div>
    </>
  );
};

export default PersonalDetails;
