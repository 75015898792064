import React, { Component } from "react";
import "../../Styles/order-list.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getOneOnOneMeetingList,
  searchOneOnOneMeeting,
} from "../../Redux/Actions/oneOnOneMeetingAction";
import { debounce } from "lodash";

class OneOnOneMeetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxLimit: 10,
      offset: 0,
      prevOffset: 0,
      items: [],
      searchKeyword: "",
      hasMore: true,
      showLoader: false,
      isChecked: false,
    };
  }

  componentDidMount() {
    this.fetchMoreData(true);
  }

  fetchMoreData = async (firstLoad) => {
    this.setState({
      showLoader: true,
    });
    if (this.state.offset !== this.state.prevOffset || firstLoad) {
      if (
        this.state.hasMore &&
        this.state.maxLimit &&
        (this.state.maxLimit === "all" ||
          this.state.maxLimit > this.state.offset)
      ) {
        let result;

        if (this.state.searchKeyword && this.state.searchKeyword.length > 0) {
          result = await this.props.searchOneOnOneMeeting(
            localStorage.getItem("sellerId"),
            this.state.offset,
            this.state.searchKeyword.trim().replace(/[^a-zA-Z 0-9.]+/g, "")
          );
        } else {
          result = await this.props.getOneOnOneMeetingList(
            localStorage.getItem("sellerId"),
            this.state.offset
          );
        }
        if (result?.data?.data?.length > 0) {
          this.setState((prevState) => {
            return {
              ...prevState,
              prevOffset: prevState.offset,
              offset: prevState.offset + 10,
              items: prevState.items.concat(result.data.data),
              showLoader: false,
            };
          });
        } else {
          this.setState({ hasMore: false, showLoader: false });
        }
      } else {
        this.setState({
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  handleChange = async (event, field) => {
    event.preventDefault();
    if (field === "maxLimit") {
      await this.setState({
        [field]:
          event.target.value === "all"
            ? event.target.value
            : +event.target.value,
      });
      this.fetchMoreData(false);
    } else {
      await this.setState({
        [field]: event.target.value,
        offset: 0,
        prevOffset: 0,
        hasMore: true,
        maxLimit: 10,
      });

      this.debounceSearchCall();
    }
  };

  debounceSearchCall = debounce(() => {
    this.setState({
      items: [],
    });
    this.fetchMoreData(true);
  }, 1000);

  handleCheck = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };

  render() {
    const orderItems = this.state.items && this.state.items.length > 0 ? (
      this.state.items.map((i) => {
        return (
          <tr key={i.id}>
            <td align="center" key={i.id}>
              {i.id ? i.id : i.id}
            </td>
            <td>{i.userName ? i.userName : "-"}</td>
            <td style={{ display: "grid" }}>
              <Link to={`/edit-product/${i.slug}`} className="orange">
                {i.productName ? i.productName : "-"}
              </Link>
            </td>
            <td>
              {i.order_id ? (
                <Link className="orange" to={`/orders/${i.order_id}`}>
                  {i.order_id}
                </Link>
              ) : (
                "-"
              )}
            </td>
            <td>{i.statusHistory?.split(",")[0]}</td>
            <td>{i.created_ts ? i.created_ts.slice(0, 10) : "-"}</td>
            <td style={{ display: "grid" }}>
              <Link
                to={{
                  pathname: `/one-on-one-meetings/${i.id}`,
                  state: i.email,
                }}
                className="orange"
              >
                edit
              </Link>
            </td>
          </tr>
        );
      })
    ) : (
      <p className="mx-auto"> There is no data found </p>
    );
    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>one on one meetings list</h2>
        </div>
        <div className="admin-card-box">
          <div className="vendor-table">
            <div className="vendor-table-head">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="entries-drop">
                    <label>show</label>
                    <select
                      className="entries-controls"
                      onChange={(e) => this.handleChange(e, "maxLimit")}
                      value={this.state.maxLimit}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="all">All</option>
                    </select>
                    <p>Entries</p>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="vendor-table-head-right">
                    <div className="vendor-table-search mr-2">
                      <input
                        className="form-control"
                        onChange={(e) => this.handleChange(e, "searchKeyword")}
                        value={this.state.searchKeyword}
                        type="text"
                        placeholder="search"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vendor-table-body">
              <div className="table-responsive">
                <InfiniteScroll
                  dataLength={this.state.items.length}
                  next={() => this.fetchMoreData(false)}
                  hasMore={this.state.hasMore}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <td align="center">id</td>
                        <td>user name</td>
                        <td>product name</td>
                        <td>order id</td>
                        <td>status</td>
                        <td>Date</td>
                        <td>action</td>
                      </tr>
                    </thead>
                    <tbody>{orderItems}</tbody>
                    {this.state.showLoader ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getOneOnOneMeetingList, searchOneOnOneMeeting },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(OneOnOneMeetings);
