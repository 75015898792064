import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "./zoom.scss";
import { getZoomDetails, updateZoomDetails, installZoomApp } from "../../Redux/Actions/zoomAction";

const ZoomMeeting = (props) => {
    const dispatch = useDispatch();
    const zoomLink = useRef(null)
    const [data, setData] = useState({
        storeId: "",
        zoomUrl: "",
        isZoomInstalled: false,
        zoomApiKey: "",
        zoomApiSecret: "",
        error: {},
    });

    const [intervalState, setIntervalState] = useState(null)
    const [firstLoad, setFirstLoad] = useState(true)
    let intervalData=null;

    useEffect( () => {
        fetchInitialData(true);
        intervalData = setInterval(async ()=>{
            await fetchInitialData(false);
        },4000)
        setIntervalState(intervalData)
        return () => clearInterval(intervalData);
    }, []);


    useEffect(()=>{
        if(intervalState && !data.isZoomInstalled && !firstLoad){
            clearInterval(intervalState);
        }else if(!firstLoad){
            fetchInitialData();
        }
    },[intervalState])

    const fetchInitialData = async (fLoad) => {
        const res = await dispatch(getZoomDetails(localStorage.getItem("sellerId")));
        if (res) {
            setData({
                ...data,
                storeId: res?.storeId,
                isZoomInstalled: res?.isZoomInstalled,
                zoomApiKey: res?.zoomApiKey,
                zoomApiSecret: res?.zoomApiSecret
            });
        }
        setFirstLoad(fLoad)
    };

    const handleChange = (event, field) => {
        if (
            data.error &&
            data.error[field] &&
            data.error[field][0]
        ) {
            data.error[field] = [];
        }
        data[field] = event.target.value;
        setData({ ...data });
    };

    const validateFormInfo = () => {
        let isFormValid = true;
        data.error = {
            zoomApiKey: [],
            zoomApiSecret: []
        };

        if (!data.zoomApiKey || data.zoomApiKey.trim().length === 0) {
            isFormValid = false;
            data.error.zoomApiKey = ["Zoom Api Key field cannot be empty."];
        }
        if (!data.zoomApiSecret || data.zoomApiSecret.trim().length === 0) {
            isFormValid = false;
            data.error.zoomApiSecret = ["Zoom Api Secret field cannot be empty."];
        }

        setData({ ...data })

        return isFormValid;
    };

    const connectZoomAccount = async (event) => {
        event.preventDefault();
        const res = await dispatch(installZoomApp());
        setData({ ...data, zoomUrl: res })
        zoomLink.current.click()
    };

    return (
        <div className="admin-card">
            <div className="admin-card-head">
                <h2>Zoom Meeting Connect</h2>
            </div>
            <div className="admin-card-box">
                <div className="row">
                    {!data.isZoomInstalled ? (
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <button
                                    onClick={connectZoomAccount}
                                    className="orange-outline-btn"
                                >
                                    Connect Zoom Account
                                </button>
                                {data.zoomUrl && (
                                    <label>
                                        <a href={data.zoomUrl} ref={zoomLink} style={{textTransform:"capitalize"}}>
                                            {/* Click here connect zoom account
                                            <span className="ml-2">
                                                <i className="fas fa-external-link-alt"></i>
                                            </span> */}
                                        </a>
                                    </label>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="col-lg-6 col-md-6">
                            <p style={{ fontSize: "14px", color: "#0000FF" , textTransform:"capitalize" }}>
                                Your Zoom account connected successfully
                            </p>
                            <div className="form-btn mb-2">
                                <button type="submit" value={"submit"} className="orange-outline-btn">
                                    <a href='https://marketplace.zoom.us/user/installed' style={{ textDecoration: "none !important", color: '#e25e53' , textTransform:"capitalize" }} target="_blank"> Uninstall zoom app</a>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ZoomMeeting;