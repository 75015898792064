import React from "react";

const useForm = (data) => {
  const [formData, setFormData] = React.useState({
    ...data,
  });
  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setFormData((pre) => ({ ...pre, [name]: value }));
  };
  return { formData, onChangeHandler, setFormData };
};

export default useForm;
