import React from "react";
import "./AddProduct.scss";

import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import accessCategory from "../../assets/images-2/access-category.png";
import customizeCategory from "../../assets/images-2/customize-category.png";
import replenishCategory from "../../assets/images-2/replenish-category.png";

import CommonButton from "../../Components/FormElements/CommonButton";

import { BackIcon } from "../../Components/svgElements/BackIcon";

import ProductHeader from "./ProductHeader";
import { PRODUCT_CATEGORIES } from "../../Constant/Constant";
import { getProductDetailNew } from "../../Redux/Actions/productAction";

const CategorySelection = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const productId = location?.state?.productId;
  const isFilled = location.state?.isFilled;

  const [error, setError] = React.useState("")
  const [categoryId, setCategoryId] = React.useState();
  const [productDetail, setProductDetail] = React.useState({});
  const [sectionStatus, setSectionStatus] = React.useState({
    categorySelection: false,
    basicDetails: false,
    productConfiguration: false,
    subscriptionContent: false,
    productAttribute: false,
    marketingContent: false,
    productConfigurationSession: false,
  });

  

  React.useEffect(() => {
    setError("")
    if(Object.keys(productDetail).length > 0){
      if(categoryId !== productDetail?.selectedCategories[0]?.productCategoryId){
        setSectionStatus({
          categorySelection: false,
          basicDetails: false,
          productConfiguration: false,
          productAttribute: false,
          marketingContent: false,
          subscriptionContent:false,
          productConfigurationSession:false,
        });
    }}
  }, [categoryId, productDetail])

  React.useEffect(() => {
    fetchProductDetails();
  }, []);

  const setProductState = React.useCallback(
    (product) => {
      setCategoryId(product?.selectedCategories[0]?.productCategoryId)
    }, []);

  const fetchProductDetails = async () => {
    if (productId) {
      localStorage.removeItem('categoryId');
      const { data } = await dispatch(getProductDetailNew(productId));
      if (data.data) {
        setSectionStatus({
          categorySelection:
            !!data.data.selectedCategories[0].productCategoryId,
          basicDetails: !!data.data.description,
          productConfiguration: !!(data.data.order_limit !== null),
          subscriptionContent: !!((
              data?.data?.access_cat_videos?.length > 2 ||
              data?.data?.access_cat_audios?.length > 2 ||
              data?.data?.access_cat_images?.length > 2 ||
              data?.data?.access_cat_documents?.length > 2 ||
              data?.data?.access_cat_titles?.length > 14
            )
          ),
          productAttribute: !!( data.data.is_variant !== null),
          marketingContent: !!(
            data?.data?.galleryImages?.length > 2 ||
            data?.data?.gallery_videos?.length > 2  
          ),
          productConfigurationSession:
            data.data.selectedCategories[0].productCategoryId === 3 &&
            (!isFilled === false ||
              isFilled === undefined ||
              isFilled === true),
        });
        setProductState(data.data);
        setProductDetail(data.data);
      }
    } else {
      const storedCategoryId = localStorage.getItem("categoryId");
      if (storedCategoryId) {
        setCategoryId(Number(storedCategoryId));
      }
    }
  };

  const handleChange = (name) => {
    PRODUCT_CATEGORIES.forEach((item) => {
      if (item.name === name) {
        setCategoryId(item.value)
      }
    })
  }

  const handleClick = () => {
    let isCategoryChanged= false;
    if (!categoryId) {
      setError("Category is required.")
    } else {
      if(Object.keys(productDetail).length > 0){
        if(categoryId !== productDetail.selectedCategories[0].productCategoryId){
          isCategoryChanged = true;
        }
      }
      const updatedSectionStatus = {
        ...sectionStatus,
        categorySelection: true,
      };
      setSectionStatus(updatedSectionStatus);
      localStorage.setItem('categoryId', categoryId);
      navigate("/basic-details", {
        state: {
          categoryId: categoryId
            ? categoryId
            : productDetail?.selectedCategories[0]?.productCategoryId,
          productId,
          sectionStatus: updatedSectionStatus,  // As useState update asyncronously
          isCategoryChanged
        },
      });
    }
  }

  return (
    <>
      <div className="add-product-page">
        <div className="page-heading">
          <button onClick={() => navigate("/product-list")} className="theme-button dark-outline-btn radius-sm back-btn">
            <BackIcon />
          </button>
          <h2>Add Product</h2>
        </div>
        <div className="white-card">
          <ProductHeader
            state={{
              categoryId: categoryId,
              productId: productDetail?.id,
              sectionStatus: sectionStatus
            }}/>
          <div className="select-category-section">
            <div className="section-heading">
              <h3>Select Parent Category</h3>
            </div>
            <div className="row">
              <div className="col-md-4 col-lg-4">
                <div className="category-card">
                  <div className="card-img">
                    <img
                      src={accessCategory}
                      alt="category"
                    />
                  </div>
                  <div className="card-content">
                    <div onClick={() => handleChange("access")} className="name-inline">
                      <div className="name">
                        <h4>Access</h4>
                      </div>
                      <label className="custon-radio circle-radio">
                        <input
                          readOnly
                          type="radio"
                          name="radio"
                          value="monthly"
                          checked={categoryId === 3}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <p>Offer one on one or group classes, lessons, clubs, guides, content, how-tos, webinars, and much more. All through secure online video conferencing with Zoom Communications.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="category-card">
                  <div className="card-img">
                    <img
                      src={customizeCategory}
                      alt="category"
                    />
                  </div>
                  <div className="card-content">
                    <div onClick={() => handleChange("customize")} className="name-inline">
                      <div className="name">
                        <h4>Customize</h4>
                      </div>
                      <label className="custon-radio circle-radio">
                        <input
                          readOnly
                          type="radio"
                          name="radio"
                          value="monthly"
                          checked={categoryId === 119}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <p>Offer customized accessories, clothing, home goods, health & wellness plans, content, recipes, how to’s, special occasion gifts, and many more curated offerings.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="category-card">
                  <div className="card-img">
                    <img
                      src={replenishCategory}
                      alt="category"
                    />
                  </div>
                  <div className="card-content">
                    <div onClick={() => handleChange("replenish")} className="name-inline">
                      <div className="name">
                        <h4>Replenish</h4>
                      </div>
                      <label className="custon-radio circle-radio">
                        <input
                          readOnly
                          type="radio"
                          name="radio"
                          value="monthly"
                          checked={categoryId === 101}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <p>Offer home goods, decor, beauty, women’s, men’s, and baby care, clothing, pet needs, health & wellness supplements, grocery, and many more products.</p>
                  </div>
                </div>
              </div>
            </div>
            {error ? <span className="form-field-error">{error}</span> : null}

          </div>
          <div className="action-btn">
            <CommonButton
              value="Continue"
              buttonClass="theme-button primary-btn radius-btn"
              onClick={() => handleClick()}
            />
            <CommonButton
              value="Cancel"
              onClick={() =>{ localStorage.removeItem('categoryId');
                navigate("/product-list")}}
              buttonClass="theme-button dark-outline-btn radius-btn"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CategorySelection;
