import React from "react";

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle cx="20" cy="20" r="18.209" fill="white" />
      <path
        d="M19.9994 1.6665C16.3734 1.6665 12.8288 2.74174 9.8139 4.75623C6.799 6.77072 4.44917 9.634 3.06157 12.984C1.67396 16.334 1.3109 20.0202 2.01829 23.5765C2.72569 27.1328 4.47177 30.3995 7.03573 32.9635C9.5997 35.5274 12.8664 37.2735 16.4227 37.9809C19.979 38.6883 23.6652 38.3252 27.0152 36.9376C30.3652 35.55 33.2285 33.2002 35.243 30.1853C37.2575 27.1704 38.3327 23.6258 38.3327 19.9998C38.327 15.1393 36.3936 10.4795 32.9567 7.04255C29.5197 3.60562 24.8599 1.67224 19.9994 1.6665ZM27.0694 24.7148C27.2285 24.8686 27.3555 25.0525 27.4429 25.2558C27.5302 25.4592 27.5762 25.6779 27.5781 25.8992C27.58 26.1205 27.5379 26.3399 27.4541 26.5448C27.3703 26.7496 27.2465 26.9357 27.09 27.0922C26.9335 27.2487 26.7474 27.3724 26.5426 27.4562C26.3378 27.54 26.1183 27.5822 25.897 27.5803C25.6757 27.5783 25.457 27.5324 25.2537 27.445C25.0503 27.3577 24.8664 27.2307 24.7127 27.0715L19.9994 22.3565L15.286 27.0715C14.9717 27.3751 14.5507 27.5431 14.1137 27.5393C13.6767 27.5355 13.2587 27.3602 12.9497 27.0512C12.6406 26.7422 12.4654 26.3242 12.4616 25.8872C12.4578 25.4502 12.6258 25.0292 12.9294 24.7148L17.6427 19.9998L12.9294 15.2848C12.7702 15.1311 12.6432 14.9472 12.5559 14.7438C12.4685 14.5405 12.4225 14.3218 12.4206 14.1005C12.4187 13.8792 12.4609 13.6597 12.5447 13.4549C12.6285 13.2501 12.7522 13.064 12.9087 12.9075C13.0652 12.751 13.2513 12.6273 13.4561 12.5435C13.6609 12.4597 13.8804 12.4175 14.1017 12.4194C14.323 12.4213 14.5417 12.4673 14.745 12.5547C14.9484 12.642 15.1323 12.769 15.286 12.9282L19.9994 17.6432L24.7127 12.9282C24.8664 12.769 25.0503 12.642 25.2537 12.5547C25.457 12.4673 25.6757 12.4213 25.897 12.4194C26.1183 12.4175 26.3378 12.4597 26.5426 12.5435C26.7474 12.6273 26.9335 12.751 27.09 12.9075C27.2465 13.064 27.3703 13.2501 27.4541 13.4549C27.5379 13.6597 27.58 13.8792 27.5781 14.1005C27.5762 14.3218 27.5302 14.5405 27.4429 14.7438C27.3555 14.9472 27.2285 15.1311 27.0694 15.2848L22.356 19.9998L27.0694 24.7148Z"
        fill="#333333"
      />
    </svg>
  );
};
