import React from "react";

import moment from "moment";
import Calendar from "./Calendar";
import { cloneDeep, isInteger } from "lodash";
import { useDispatch } from "react-redux";
import TimePicker from "react-time-picker";
import { confirmAlert } from "react-confirm-alert";
import { useLocation, useNavigate } from "react-router";

import InputBox from "../../Components/FormElements/InputBox";
import SelectBox from "../../Components/FormElements/SelectBox";
import CommonButton from "../../Components/FormElements/CommonButton";
import CommonCheckbox from "../../Components/FormElements/CommonCheckbox";
import ProductHeader from "./ProductHeader";

import { AddIcon } from "../../Components/svgElements/AddIcon";
import { BackIcon } from "../../Components/svgElements/BackIcon";
import { PrimaryInfoIcon } from "../../Components/svgElements/PrimaryInfoIcon";

import OneTimeEvent from "../One-Time-Event/OneTimeEvent";

import { MONTHLY } from "../../Constant/Constant";
import {
  createFreeSeminar,
  getProductDetailNew,
  addGroupAndOneOnOneMeetingDetails,
  getGroupAndOneOnOneMeetingDetails,
  addProductConfigurationSession,
} from "../../Redux/Actions/productAction";
import { validateCancelSlot } from "../../Redux/Actions/meetingActions";
import { getChildCategories, hierarchyList } from "../../Redux/Actions/searchCategoryAction";

import "./AddProduct.scss";

const weekDays = {
  Sun: "Sunday",
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
};

const selectOneOnOneFrequency = [
  "All",
  "One_Time",
  "Weekly",
  "Bi_Weekly",
  "Monthly",
];

const selectGroupFrequency = ["Weekly", "Bi weekly", "Monthly"];
const weekOfMonth = ["First", "Second", "Third"];

const ProductConfigurationSession = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const status = location.state.sectionStatus;
  const isCategoryChanged = location.state.isCategoryChanged;

  const [loading, setLoading] = React.useState(false);
  const [productDetail, setProductDetail] = React.useState({});
  const [allCategories, setAllCategories] = React.useState([]);
  const [isFreeSeminar, setFreeSeminar] = React.useState(false);
  // const [isEditMeeting, setEditMeeting] = React.useState(false);
  const [showCalendarLoader, setCalendarLoader] = React.useState(false);
  const [showHideFreeSeminarModal, setFreeSeminarModal] = React.useState(false);
  const [isFilled, setIsFilled] = React.useState();
  const [state, setState] = React.useState({
    meetingDate: moment().format("YYYY-MM-DD"),
    meetingId: "",
    meetingStartTime: "",
    meetingEndTime: "",
    zoom_host_url: null,
    slots: {},
    selectedFrequency: {
      All: false,
      One_Time: false,
      Weekly: false,
      Bi_Weekly: false,
      Monthly: false,
    },
    formData: {
      start: "",
      end: "",
      date: "",
      name: "",
      price: null,
    },
    oneOnOneId: null,
    selectedSlots: {},
    isWeekDaySelected: {
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
    },
    trialCost: 0,
    subscriptionCost: 0,
    sessionHour: "00",
    sessionMinute: "00",
    groupMeetingDetails: [
      {
        id: null,
        meetingName: "",
        subscriptionCost: 0,
        sessionHour: "00",
        sessionMinute: "00",
        meetingMonthlyStartTime: "",
        meetingMonthlyEndTime: "",
        meetingMonthlyWeekDay: "",
        meetingMonthlyWeekOfMonth: "",
        groupMeetingFrequency: "",
        link: "",
        host_link: "",
        isMonthlyGroupFrequencyChecked: false,
        isWeekDaySelected: {
          Sun: false,
          Mon: false,
          Tue: false,
          Wed: false,
          Thu: false,
          Fri: false,
          Sat: false,
        },

        slots: {},
        selectedSlots: {},
      },
    ],
    errorData: {
      selectedOneOnOneMeetingSlots: {
        Sun: [],
        Mon: [],
        Tue: [],
        Wed: [],
        Thu: [],
        Fri: [],
        Sat: [],
      },
    },
    deletedGroupMeetingsIdArray: [],
    isOneOnOneMeetingDetailsEdited: false,
    isOneOnOneMeetingChecked: false,
    isGroupMeetingChecked: false,
    isGroupMeetingDetailsEdited: false,
    isSeminarEdited: false,
    reqMessage: "",
    successMessage: "",
    popupErrorMessage: "",
    eventNameError: "",
    eventPriceError: "",
    isFreeSeminarCreated: false,
    isFreePriceChecked: false,
    showDenyLoader: false,
  });
  const productId = location?.state?.productId;
  const categoryId = location?.state?.categoryId;

  let editedMeetingFromForm = [];
  let editedMeetingFromCalendar = [];
  let editedGroupMeetingFromForm = [];
  let canceledMeetingFromCalendar = [];
  let subscriptionCostEditedArray = [];
  let sessionMinutes = ["00", "15", "30", "45"];
  let sessionHours = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const [sectionStatus, setSectionStatus] = React.useState();
  React.useEffect(() => {
    if (status) {
      setSectionStatus(status);
    }
  }, [status]);

  React.useEffect(() => {
    let errorData = { ...state.errorData };
    let hasErrorChanged = false;

    // Clear the error if either one of the meeting options is checked
    if (state.isGroupMeetingChecked || state.isOneOnOneMeetingChecked) {
      if (errorData["missingDetail"]) {
        errorData["missingDetail"] = "";
        hasErrorChanged = true;
      }
    }
    // Clear selectedFrequency error if any frequency is selected
    if (state.isOneOnOneMeetingChecked) {
      if (
        state.selectedFrequency.All ||
        state.selectedFrequency.Monthly ||
        state.selectedFrequency.Weekly ||
        state.selectedFrequency.Bi_Weekly ||
        state.selectedFrequency.One_Time
      ) {
        if (errorData["selectedFrequency"]) {
          errorData["selectedFrequency"] = "";
          hasErrorChanged = true;
        }
      }
      if (state.subscriptionCost > 0) {
        errorData["subscriptionCost"] = "";
        hasErrorChanged = true;
      }
      if (state.trialCost > 0) {
        errorData["trialCost"] = "";
        hasErrorChanged = true;
      }
      if (state.sessionMinute !== "00" || state.sessionHour !== "00") {
        errorData["sessionDuration"] = "";
        hasErrorChanged = true;
      }
      if (Object.entries(state.slots).length > 0) {
        errorData["oneOnOneSlots"] = "";
        hasErrorChanged = true;
      }
    }
    if (state.isGroupMeetingChecked && state.groupMeetingDetails) {
      state.groupMeetingDetails.forEach((item) => {
        if (item.groupMeetingFrequency) {
          if (errorData["groupFrequency"]) {
            errorData["groupFrequency"] = "";
            hasErrorChanged = true;
          }
        }
        if (item.meetingName) {
          if (errorData["groupMeetingName"]) {
            errorData["groupMeetingName"] = "";
            hasErrorChanged = true;
          }
        }
        if (item.subscriptionCost > 0) {
          errorData["groupSubscriptionCost"] = "";
          hasErrorChanged = true;
        }
        if (item.sessionMinute !== "00" || item.sessionHour !== "00") {
          errorData["groupSessionDuration"] = "";
          hasErrorChanged = true;
        }
        if (Object.entries(item.slots).length > 0) {
          errorData["groupMeetingSlots"] = "";
          hasErrorChanged = true;
        }
        if (item.isMonthlyGroupFrequencyChecked) {
          if (item.meetingMonthlyWeekOfMonth) {
            errorData["meetingMonthlyWeekOfMonth"] = "";
            hasErrorChanged = true;
          }
          if (item.meetingMonthlyWeekDay) {
            errorData["meetingMonthlyWeekDay"] = "";
            hasErrorChanged = true;
          }
          if (!item.meetingMonthlyStartTime) {
            errorData["meetingMonthlyStartTime"] = "";
            hasErrorChanged = true;
          }
          if (!item.meetingMonthlyEndTime) {
            errorData["meetingMonthlyEndTime"] = "";
            hasErrorChanged = true;
          }
        }
      });
    }

    // Only update local state if there was a change in errorData
    if (hasErrorChanged) {
      updateLocalState({ errorData });
    }
  }, [
    state.isGroupMeetingChecked,
    state.isOneOnOneMeetingChecked,
    state.selectedFrequency.All,
    state.selectedFrequency.Bi_Weekly,
    state.selectedFrequency.Monthly,
    state.selectedFrequency.One_Time,
    state.selectedFrequency.Weekly,
    state.sessionHour,
    state.sessionMinute,
    state.subscriptionCost,
    state.trialCost,
    state.slots,
    state.groupMeetingDetails,
  ]);

  const validateInfo = () => {
    let isFormError = true;
    let errorData = { ...state.errorData };

    if (!state.isGroupMeetingChecked && !state.isOneOnOneMeetingChecked) {
      isFormError = false;
      errorData["missingDetail"] =
        "Create at least one meeting either one-on-one or group";
    }
    if (state.isOneOnOneMeetingChecked) {
      if (
        !state.selectedFrequency.All &&
        !state.selectedFrequency.Monthly &&
        !state.selectedFrequency.Weekly &&
        !state.selectedFrequency.Bi_Weekly &&
        !state.selectedFrequency.One_Time
      ) {
        isFormError = false;
        errorData["selectedFrequency"] = "Frequency is required";
      }
      if (state.trialCost === 0) {
        isFormError = false;
        errorData["trialCost"] = "Trial or One-Time cost is required";
      }
      if (state.subscriptionCost === 0) {
        isFormError = false;
        errorData["subscriptionCost"] = "Subscription cost is required";
      }
      if (state.sessionMinute === "00" && state.sessionHour === "00") {
        isFormError = false;
        errorData["sessionDuration"] = "Session duration is required";
      }
      if (Object.entries(state.slots).length === 0) {
        isFormError = false;
        errorData["oneOnOneSlots"] = "Create a time slot for a meeting";
      }
      Object.entries(state.slots).forEach(([weekDay, slotsArray]) => {
        slotsArray.forEach((startEndTimeArray) => {
          const slotsNumber = calculateHourMinuteDiff(startEndTimeArray, -1);
          if (
            startEndTimeArray[0] === 0 ||
            startEndTimeArray[1] === 0 ||
            !slotsNumber ||
            !isInteger(slotsNumber)
          ) {
            isFormError = false;
            state.errorData.selectedOneOnOneMeetingSlots[weekDay].push([
              "The selected slot should be as per the session duration",
            ]);
          } else {
            state.errorData.selectedOneOnOneMeetingSlots[weekDay].push([]);
          }
        });
      });
    }
    if (state.isGroupMeetingChecked) {
      errorData.selectedGroupMeetingSlots =
        errorData.selectedGroupMeetingSlots || [];
      errorData.groupMonthlyTime = errorData.groupMonthlyTime || [];
      state.groupMeetingDetails.forEach((item, index) => {
        if (!errorData.selectedGroupMeetingSlots[index]) {
          errorData.selectedGroupMeetingSlots[index] = {
            Sun: [],
            Mon: [],
            Tue: [],
            Wed: [],
            Thu: [],
            Fri: [],
            Sat: [],
          };
        }
        if (!item.groupMeetingFrequency) {
          isFormError = false;
          errorData["groupFrequency"] = "Frequency is required";
        }
        if (!item.meetingName) {
          isFormError = false;
          errorData["groupMeetingName"] = "Meeting name is required";
        }
        if (item.subscriptionCost === 0) {
          isFormError = false;
          errorData["groupSubscriptionCost"] = "Subscription cost is required";
        }
        if (item.sessionHour === "00" && item.sessionMinute === "00") {
          isFormError = false;
          errorData["groupSessionDuration"] = "Session duration is required";
        }
        if (!item.isMonthlyGroupFrequencyChecked) {
          if (Object.entries(item.slots).length === 0) {
            isFormError = false;
            errorData["groupMeetingSlots"] = "Create a time slot for a meeting";
          }
          Object.entries(item.slots).forEach(([weekDay, slotsArray]) => {
            slotsArray.forEach((startEndTimeArray) => {
              const slotsNumber = calculateHourMinuteDiff(
                startEndTimeArray,
                index
              );
              if (
                startEndTimeArray[0] === 0 ||
                startEndTimeArray[1] === 0 ||
                !slotsNumber ||
                !isInteger(slotsNumber)
              ) {
                isFormError = false;
                errorData.selectedGroupMeetingSlots[index][weekDay].push([
                  "The selected slot should be as per the session duration",
                ]);
              } else {
                errorData.selectedGroupMeetingSlots[index][weekDay].push([]);
              }
            });
          });
        }
        if (item.isMonthlyGroupFrequencyChecked) {
          if (!item.meetingMonthlyWeekOfMonth) {
            isFormError = false;
            errorData["meetingMonthlyWeekOfMonth"] =
              "Week of month is required";
          }
          if (!item.meetingMonthlyWeekDay) {
            isFormError = false;
            errorData["meetingMonthlyWeekDay"] = "Week day is required";
          }
          if (!item.meetingMonthlyStartTime) {
            isFormError = false;
            errorData["meetingMonthlyStartTime"] = "Start time is required";
          }
          if (!item.meetingMonthlyEndTime) {
            isFormError = false;
            errorData["meetingMonthlyEndTime"] = "End time is required";
          }
          const slotsNumber = calculateHourMinuteDiff(
            [item.meetingMonthlyStartTime, item.meetingMonthlyEndTime],
            index
          );
          if (
            (!item.meetingMonthlyEndTime?.split(":")[0] &&
              !item.meetingMonthlyEndTime?.split(":")[1]) ||
            (!item.meetingMonthlyStartTime?.split(":")[0] &&
              !item.meetingMonthlyStartTime?.split(":")[1]) ||
            !slotsNumber ||
            !isInteger(slotsNumber)
          ) {
            isFormError = false;
            errorData.groupMonthlyTime[index] = [
              "The selected slot should be as per the session duration",
            ];
          } else {
            errorData.groupMonthlyTime[index] = [];
          }
        }
      });
    }
    updateLocalState({ errorData });
    return isFormError;
  };

  React.useEffect(() => {
    if(!isCategoryChanged){
      fetchProductDetails();
    }
    handleAddSearchCategory();
  }, []);

  const updateLocalState = (remaining) => {
    setState((preState) => ({
      ...preState,
      ...remaining,
    }));
  };

  const showLoaderForCalendar = (loader) => {
    setCalendarLoader(loader);
  };

  /**
   * The function `handleSearchCategoryChange` updates the list of categories based on the selected
   * category and its child categories.
   * @param e - The parameter `e` in the `handleSearchCategoryChange` function is typically an event
   * object, such as a change event that is triggered when a user interacts with a form input or select
   * element. It contains information about the event that occurred, such as the target element and the
   * value of the element
   * @param index - The `index` parameter in the `handleSearchCategoryChange` function likely
   * represents the index of the category being modified in the `allCategories` array. This index is
   * used to access and update specific category information within the array.
   */
  const handleSearchCategoryChange = async (e, index) => {
    const preAll = [...allCategories];
    const result = await dispatch(
      getChildCategories({ categoryId: categoryId, childCategoryId: e.id })
    );
    if (!result.isError) {
      preAll[index].latestOptions = result.data;
      preAll[index].selectedValues.push(e);
    }
    setAllCategories(preAll);
  };

  /**
   * The function `handleAddSearchCategory` asynchronously adds search category to the existing list
   * of categories.
   */
  const handleAddSearchCategory = async () => {
    const preAll = [...allCategories];
    const result = await dispatch(
      getChildCategories({ categoryId: categoryId })
    );
    if (!result?.isError) {
      preAll.push({ selectedValues: [], latestOptions: result?.data });
    }
    setAllCategories(preAll);
  };

  /**
   * The function `removeSearchCategory` removes a selected category at a specific index and updates the
   * available options based on the selected values.
   * @param index - The `index` parameter in the `removeSearchCategory` function represents the index of
   * the category you want to remove from the list of all categories.
   * @param cIndex - The `cIndex` parameter in the `removeSearchCategory` function represents the index
   * of the category that you want to remove from the selected values array within a specific category
   * in the `allCategories` object.
   */
  const removeSearchCategory = async (index, cIndex) => {
    const preAll = cloneDeep(allCategories);
    let getPreId = 0;
    let params = { categoryId: categoryId };
    if (cIndex > 0) {
      getPreId =
        preAll[index].selectedValues[cIndex > 0 ? cIndex - 1 : cIndex].id;
      params["childCategoryId"] = getPreId;
    }
    const result = await dispatch(getChildCategories(params));
    if (!result.isError) {
      preAll[index].selectedValues.splice(cIndex);
      preAll[index].latestOptions = result.data;
    }
    setAllCategories(preAll);
  };

  const handleOpenMeetingSchedule = (event) => {
    // let isEdit = false;
    if (event) {
      let startTime = moment(new Date(event.start), "hh:mm A").format("HH:mm");
      let endTime = moment(new Date(event.end), "hh:mm A").format("HH:mm");
      let meetingDate = moment(event.start, "YYYY-MM-DD").format("YYYY-MM-DD");
      // isEdit = true;
      updateLocalState({
        meetingStartTime: startTime,
        meetingEndTime: endTime,
        meetingDate: meetingDate,
        meetingId: event.meetingId,
      });
    } 
    // else {
    //   isEdit = false;
    // }
    // setEditMeeting(isEdit);
  };

  const getGroupAndOneOnOneMeetingData = async (productId) => {
    const res = await dispatch(getGroupAndOneOnOneMeetingDetails(productId));

    let slots = { ...state.slots };
    let oneOnOneSelectedWeekDays = {
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
    };
    let selectedFrequency = { ...state.selectedFrequency };
    let oneOnOneTrialCost = 0;
    let oneOnOneSubscriptionCost = 0;
    let oneOneOneSessionHour = "00";
    let oneOneOneSessionMinute = "00";
    let id = null;

    let groupMeetingDetails = [];

    if (res.data.data.length) {
      const groupOneOnOneMeetingData = res.data.data;

      let errorData = { ...state.errorData };

      groupOneOnOneMeetingData.forEach((data) => {
        if (data.type === "group") {
          if (!!!errorData["groupMeetingDetails"]) {
            errorData["groupMeetingDetails"] = [
              {
                meetingName: [],
                subscriptionCost: [],
                sessionHour: [],
                sessionMinute: [],
                meetingMonthlyStartTime: [],
                meetingMonthlyEndTime: [],
                meetingMonthlyWeekDay: [],
                meetingMonthlyWeekOfMonth: [],
                groupMeetingFrequency: [],
                isWeekDaySelected: [],
                slots: [],
              },
            ];
          } else {
            errorData["groupMeetingDetails"].push({
              meetingName: [],
              subscriptionCost: [],
              sessionHour: [],
              sessionMinute: [],
              meetingMonthlyStartTime: [],
              meetingMonthlyEndTime: [],
              meetingMonthlyWeekDay: [],
              meetingMonthlyWeekOfMonth: [],
              groupMeetingFrequency: [],
              isWeekDaySelected: [],
              slots: [],
            });
          }
        }
      });

      updateLocalState({ errorData });

      groupOneOnOneMeetingData.forEach((item) => {
        if (item.type === "one_on_one") {
          if (item.status === "enable") {
            updateLocalState({
              isOneOnOneMeetingChecked: true,
            });
          }
          let meetingSlot = item.availability
            ? JSON.parse(item.availability)
            : null;
          let sessionDuration = item.session_duration
            ? JSON.parse(item.session_duration)
            : null;

          if (!Object.keys(meetingSlot).length)
            updateLocalState({
              isOneOnOneMeetingChecked: false,
            });
          if (meetingSlot) {
            slots = { ...meetingSlot };
            Object.entries(slots).map(([weekDay, timeSlot]) => {
              oneOnOneSelectedWeekDays[weekDay] = true;
            });
          }
          if (sessionDuration) {
            oneOneOneSessionHour = sessionDuration.sessionHour
              ? sessionDuration.sessionHour
              : "";
            oneOneOneSessionMinute = sessionDuration.sessionMinute
              ? sessionDuration.sessionMinute
              : "";
          }

          let oneOnOneFreq = item.frequency.split(",");
          oneOnOneFreq.forEach((frequency) => {
            selectedFrequency[frequency] = true;
          });

          oneOnOneTrialCost = item.one_time_cost;
          oneOnOneSubscriptionCost = item.subscription_cost;
          id = item.id;
        } else if (item.type === "group") {
          if (item.status === "enable") {
            updateLocalState({
              isGroupMeetingChecked: true,
            });
          }

          let tempGroupMeetingObj = {
            id: null,
            meetingName: "",
            subscriptionCost: 0,
            sessionHour: "00",
            sessionMinute: "00",
            meetingMonthlyStartTime: "",
            meetingMonthlyEndTime: "",
            meetingMonthlyWeekDay: "",
            link: "",
            host_link: "",
            meetingMonthlyWeekOfMonth: "",
            groupMeetingFrequency: "",
            isMonthlyGroupFrequencyChecked: false,
            isWeekDaySelected: {
              Sun: false,
              Mon: false,
              Tue: false,
              Wed: false,
              Thu: false,
              Fri: false,
              Sat: false,
            },
            selectedSlots: {},

            slots: {},
          };

          let groupSessionDuration = item.session_duration
            ? JSON.parse(item.session_duration)
            : null;
          let slots = item.availability ? JSON.parse(item.availability) : null;

          tempGroupMeetingObj["id"] = item.id;
          tempGroupMeetingObj["meetingName"] = item.group_meeting_name;
          tempGroupMeetingObj["subscriptionCost"] = item.subscription_cost;
          tempGroupMeetingObj["sessionHour"] = groupSessionDuration.sessionHour;
          tempGroupMeetingObj["sessionMinute"] =
            groupSessionDuration.sessionMinute;
          tempGroupMeetingObj["groupMeetingFrequency"] = item.frequency;
          tempGroupMeetingObj["slots"] = { ...slots };
          tempGroupMeetingObj["selectedSlots"] = { ...slots };

          if (item.frequency !== "Monthly") {
            tempGroupMeetingObj["isMonthlyGroupFrequencyChecked"] = false;

            Object.entries(slots).forEach(([weekDay, timeSlot]) => {
              tempGroupMeetingObj["isWeekDaySelected"][weekDay] = true;
            });
            groupMeetingDetails.push({ ...tempGroupMeetingObj });
          } else {
            tempGroupMeetingObj["meetingMonthlyStartTime"] = slots.startTime;
            tempGroupMeetingObj["meetingMonthlyEndTime"] = slots.endTime;
            tempGroupMeetingObj["meetingMonthlyWeekOfMonth"] =
              slots.weekOfMonth;
            tempGroupMeetingObj["meetingMonthlyWeekDay"] = slots.weekDay;
            tempGroupMeetingObj["link"] = slots.link ? slots.link : null;
            tempGroupMeetingObj["host_link"] = slots.host_link
              ? slots.host_link
              : null;
            tempGroupMeetingObj["isRunningSubscriptionCancelled"] =
              !!slots.isRunningSubscriptionCancelled
                ? slots.isRunningSubscriptionCancelled
                : 0;
            tempGroupMeetingObj["isMonthlyGroupFrequencyChecked"] = true;
            groupMeetingDetails.push({ ...tempGroupMeetingObj });
          }
        }
      });
    }

    updateLocalState({
      oneOnOneId: id,
      slots: slots,
      selectedSlots: slots,
      isWeekDaySelected: { ...oneOnOneSelectedWeekDays },
      selectedFrequency: selectedFrequency,
      trialCost: oneOnOneTrialCost,
      subscriptionCost: oneOnOneSubscriptionCost,
      sessionHour: oneOneOneSessionHour,
      sessionMinute: oneOneOneSessionMinute,
      groupMeetingDetails: groupMeetingDetails.length
        ? [...groupMeetingDetails]
        : [...state.groupMeetingDetails],
    });
    return res;
  };

  const setGroupAndOneOnOneMeetingDetailsFromCalendar = (data, original) => {
    if (data.oneOnOneMeetingDetails) {
      let oneOnOneMeetingDetails = data.oneOnOneMeetingDetails;
      updateLocalState({
        isOneOnOneMeetingDetailsEdited: true,
        isOneOnOneMeetingChecked: true,
        oneOnOneId: oneOnOneMeetingDetails.id,
        isWeekDaySelected: oneOnOneMeetingDetails.isWeekDaySelected,
        selectedFrequency: oneOnOneMeetingDetails.isFrequencySelected,
        slots: oneOnOneMeetingDetails.slots,
        sessionHour: oneOnOneMeetingDetails.sessionHour,
        sessionMinute: oneOnOneMeetingDetails.sessionMinute,
        trialCost: oneOnOneMeetingDetails.trialCost,
        subscriptionCost: oneOnOneMeetingDetails.subscriptionCost,
        selectedSlots: oneOnOneMeetingDetails.slots,
      });
    }
    if (data.groupMeetingDetails) {
      let meetingDetails = data.groupMeetingDetails;

      let tempGroupMeetingDetails = [...state.groupMeetingDetails];

      if (
        data?.groupMeetingDetails?.groupMeetingFrequency?.toLowerCase() !==
        "monthly"
      ) {
        let incomingTime =
          original &&
          Object.keys(original).length &&
          Object.values(original["slots"])[0][0];
        let newTimeSlots = Object.values(meetingDetails["slots"])[0][0];
        if (incomingTime) {
          state.groupMeetingDetails.forEach((item) => {
            if (item.meetingName === original.meetingName) {
              let tempSlots = { ...item.slots };
              Object.entries(item.slots).forEach(([weekDay, timeslotArray]) => {
                if (original && weekDay === Object.keys(original["slots"])[0]) {
                  timeslotArray.forEach((timeslot, index) => {
                    let startTimeHour = +timeslot[0].split(":")[0];
                    let startTimeMinute = +timeslot[0].split(":")[1];
                    let endTimeHour = +timeslot[1].split(":")[0];
                    let endTimeMinute = +timeslot[1].split(":")[1];

                    let incomingStartTimeHour = +incomingTime[0].split(":")[0];
                    let incomingStartTimeMinute =
                      +incomingTime[0].split(":")[1];
                    let incomingEndTimeHour = +incomingTime[1].split(":")[0];
                    let incomingEndTimeMinute = +incomingTime[1].split(":")[1];
                    if (
                      startTimeHour === incomingStartTimeHour &&
                      startTimeMinute === incomingStartTimeMinute &&
                      endTimeHour === incomingEndTimeHour &&
                      endTimeMinute === incomingEndTimeMinute
                    ) {
                      tempSlots[weekDay][index] = newTimeSlots;
                    } else {
                      tempSlots[weekDay][index] = tempSlots[weekDay][index]
                        ? [...tempSlots[weekDay][index], timeslot]
                        : [[timeslot]];
                    }
                  });
                } else {
                  tempSlots[weekDay] = timeslotArray;
                }
              });
            }
          });
        } else {
          if (state.isGroupMeetingChecked)
            tempGroupMeetingDetails.push(meetingDetails);
          else tempGroupMeetingDetails = [meetingDetails];
        }
      } else if (
        data?.groupMeetingDetails?.groupMeetingFrequency?.toLowerCase() ===
        "monthly"
      ) {
        if (original) {
          state.groupMeetingDetails.forEach((item, index) => {
            if (item.meetingName === original.meetingName) {
              let originalStartTimeHour =
                +original.meetingMonthlyStartTime.split(":")[0];
              let originalStartTimeMinute =
                +original.meetingMonthlyStartTime.split(":")[1];
              let originalEndTimeHour =
                +original.meetingMonthlyStartTime.split(":")[0];
              let originalEndTimeMinute =
                +original.meetingMonthlyStartTime.split(":")[1];

              let currentStartTimeHour =
                +item.meetingMonthlyStartTime.split(":")[0];
              let currentStartTimeMinute =
                +item.meetingMonthlyStartTime.split(":")[1];
              let currentEndTimeHour =
                +item.meetingMonthlyStartTime.split(":")[0];
              let currentEndTimeMinute =
                +item.meetingMonthlyStartTime.split(":")[1];

              if (
                originalStartTimeHour === currentStartTimeHour &&
                originalStartTimeMinute === currentStartTimeMinute &&
                originalEndTimeHour === currentEndTimeHour &&
                originalEndTimeMinute === currentEndTimeMinute
              ) {
                item["meetingMonthlyEndTime"] =
                  data?.groupMeetingDetails?.meetingMonthlyEndTime;
                item["meetingMonthlyStartTime"] =
                  data?.groupMeetingDetails?.meetingMonthlyStartTime;
              }
            }
          });
        } else {
          if (state.isGroupMeetingChecked)
            tempGroupMeetingDetails.push(meetingDetails);
          else tempGroupMeetingDetails = [meetingDetails];
        }
      }
      updateLocalState({
        isGroupMeetingDetailsEdited: true,
        isGroupMeetingChecked: true,
        groupMeetingDetails: tempGroupMeetingDetails,
      });

      let errorData = { ...state.errorData };
      if (!!!errorData["groupMeetingDetails"]) {
        errorData["groupMeetingDetails"] = [
          {
            meetingName: [],
            subscriptionCost: [],
            sessionHour: [],
            sessionMinute: [],
            meetingMonthlyStartTime: [],
            meetingMonthlyEndTime: [],
            meetingMonthlyWeekDay: [],
            meetingMonthlyWeekOfMonth: [],
            groupMeetingFrequency: [],
            isWeekDaySelected: [],
            slots: [],
          },
        ];
      } else {
        errorData["groupMeetingDetails"].push({
          meetingName: [],
          subscriptionCost: [],
          sessionHour: [],
          sessionMinute: [],
          meetingMonthlyStartTime: [],
          meetingMonthlyEndTime: [],
          meetingMonthlyWeekDay: [],
          meetingMonthlyWeekOfMonth: [],
          groupMeetingFrequency: [],
          isWeekDaySelected: [],
          slots: [],
        });
      }
      updateLocalState({ errorData });
    }
  };

  const updateSelectedEvent = (body) => {
    editedMeetingFromCalendar.push(body);
  };

  const deleteSelectedEvent = (body) => {
    canceledMeetingFromCalendar.push(body);
  };

  const validateCancelSlots = async (body, isReschedule) => {
    const res = await dispatch(validateCancelSlot(body));

    if (!isReschedule) {
      if (res?.isError) {
        return await new Promise((resolve, reject) =>
          confirmAlert({
            title: "",
            message: `${
              res.message.includes("Cancelled")
                ? res.message
                : `${res.message}, Do you wish to cancel ?`
            }`,
            buttons: res.message.includes("Cancelled")
              ? [
                  {
                    label: "Ok",
                    onClick: () => {
                      resolve(false);
                    },
                  },
                ]
              : [
                  {
                    label: "Yes",
                    onClick: () => {
                      let tempBody = {
                        ...body,
                        isRunningSubscriptionCancelled: 1,
                      };
                      deleteSelectedEvent(tempBody);
                      resolve(false);
                    },
                  },
                  {
                    label: "No",
                    onClick: () => {
                      resolve(false);
                    },
                  },
                ],
          })
        );
      } else {
        deleteSelectedEvent(body);
        return true;
      }
    } else {
      if (res?.isError && res.type === 2) {
        return await new Promise((resolve, reject) =>
          confirmAlert({
            title: "",
            message: `${
              res.message.includes("Cancelled")
                ? res.message
                : `${res.message}, Do you wish to cancel ?`
            }`,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  resolve(false);
                },
              },
            ],
          })
        );
      } else {
        return true;
      }
    }
  };

  const calculateHourMinuteDiff = (startEndTimeArray, index) => {
    let numberOfSlots = null;
    let sessionDuration = 1;

    if (startEndTimeArray[0] && startEndTimeArray[1]) {
      let startTimeHour = startEndTimeArray[0].split(":")[0];
      let startTimeMinute = startEndTimeArray[0].split(":")[1];
      let endTimeHour = startEndTimeArray[1].split(":")[0];
      let endTimeMinute = startEndTimeArray[1].split(":")[1];

      const start = new Date("August 19, 1975 23:15:30");
      const end = new Date("August 19, 1975 23:15:30");
      start.setMinutes(startTimeMinute);
      start.setHours(startTimeHour);

      end.setMinutes(endTimeMinute);
      end.setHours(endTimeHour);

      let timeDifferenceFromDates = end - start;

      if (timeDifferenceFromDates < 0) {
        return numberOfSlots;
      }

      let timeSlotDiff = parseFloat(
        (timeDifferenceFromDates * (0.001 / 3600)).toFixed(2)
      );

      if (index !== -1) {
        sessionDuration =
          parseInt(state.groupMeetingDetails[index]["sessionHour"]) +
          parseFloat(
            (+state.groupMeetingDetails[index]["sessionMinute"] / 60).toFixed(2)
          );
      } else {
        sessionDuration =
          parseInt(state.sessionHour) +
          parseFloat((state.sessionMinute / 60).toFixed(2));
      }
      numberOfSlots = timeSlotDiff / sessionDuration;
    }

    return numberOfSlots;
  };

  const fetchProductDetails = async () => {
    if (productId) {
      localStorage.removeItem("categoryId");
      const { data } = await dispatch(getProductDetailNew(productId));
      if (data.data) {
        await fetchSearchHierarchyList(data.data.searchCategoryId);
        setProductDetail(data.data);
        const seminarDetails =
        data.data.free_seminar_details &&
        data.data.free_seminar_details.length
          ? JSON.parse( data.data.free_seminar_details)
          : null;
        updateLocalState({
          zoom_host_url: data.data.zoom_host_url,
          formData: {
            start: seminarDetails && seminarDetails.start,
            end: seminarDetails && seminarDetails.end,
            date: seminarDetails && seminarDetails.date,
            link: seminarDetails && seminarDetails.host_link,
            note: seminarDetails && seminarDetails.note,
            name: seminarDetails && seminarDetails.name,
            price: seminarDetails && seminarDetails.price,
          },
          isFreePriceChecked:
          seminarDetails && seminarDetails.price === "" ? true : false,
          isFreeSeminarCreated: seminarDetails ? true : false,
        });
      }
    }
  };

  const validateCancelSlotFromForm = async (body) => {
    return await new Promise((resolve, reject) =>
      confirmAlert({
        title: "",
        message: `You have running subscriptions for this meeting.`,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              resolve(false);
            },
          },
        ],
      })
    );
  };

  const handleIsOneOnOneMeeting = async (e) => {
    let isBookingReceived = false;
    const checked = e.target?.checked;

    try {
      if (state.selectedSlots && Object.keys(state.selectedSlots).length > 0) {
        const slotKeys = Object.keys(state.selectedSlots);

        for (const key of slotKeys) {
          const items = state.selectedSlots[key];

          for (const item of items) {
            if (item[7]) {
              isBookingReceived = true;
              await validateCancelSlotFromForm();
              break; // Exit the inner loop if a booking is found
            }
          }

          if (isBookingReceived) {
            break; // Exit the outer loop if a booking is found
          }
        }
      }
    } catch (err) {
      console.error(err, "ERROR");
    }
    updateLocalState({
      isOneOnOneMeetingChecked: checked,
      isOneOnOneMeetingDetailsEdited: true,
    });
  };

  const handleChangeChk = async (e, key, weekDay, frequency) => {
    let checked = e.target.checked;
    try {
      if (!checked && frequency === "All") {
        if (state.selectedSlots) {
          if (
            Object.keys(state.selectedSlots) &&
            Object.keys(state.selectedSlots)?.length > 0
          ) {
            for (const value of Object.keys(state.selectedSlots)) {
              for (const item of state.selectedSlots[value]) {
                if (item[7]) {
                  await validateCancelSlotFromForm();
                  return null;
                }
              }
            }
          }
        }
      } else if (state.selectedSlots) {
        if (
          Object.keys(state.selectedSlots) &&
          Object.keys(state.selectedSlots)?.length > 0
        ) {
          for (const value of Object.keys(state.selectedSlots)) {
            for (const item of state.selectedSlots[value]) {
              if (
                item[7] &&
                item[8] &&
                item[8].toLowerCase().replace(/ /g, "_") ===
                  frequency.toLowerCase().replace(/ /g, "_")
              ) {
                await validateCancelSlotFromForm();
                return null;
              }
            }
          }
        }
      } else if (weekDay) {
        if (weekDay && state.selectedSlots[weekDay]) {
          for (const value of state.selectedSlots[weekDay]) {
            if (value[7]) {
              await validateCancelSlotFromForm();
              return null;
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

    updateLocalState({
      isOneOnOneMeetingDetailsEdited: true,
    });
    if (key === "isWeekDaySelected") {
      let tempArray = { ...state.isWeekDaySelected };
      let tempOneOnOneMeetingTimeSlotArray = {
        ...state.slots,
      };
      tempArray[weekDay] = checked;
      if (checked) {
        tempOneOnOneMeetingTimeSlotArray[weekDay] = [[0, 0]];
      } else {
        delete tempOneOnOneMeetingTimeSlotArray[weekDay];
      }
      updateLocalState({
        isWeekDaySelected: { ...tempArray },
        slots: { ...tempOneOnOneMeetingTimeSlotArray },
      });
      return;
    } else if (key === "selectedFrequency") {
      let tempFrequencyArray = { ...state.selectedFrequency };
      tempFrequencyArray[`${frequency}`] = checked;

      let isAllFrequencySelected = 0;
      Object.entries(tempFrequencyArray)
        .slice(1, 5)
        .forEach(([freq, bool]) => {
          if (bool) {
            isAllFrequencySelected += 1;
          }
        });

      if (isAllFrequencySelected === 4) {
        tempFrequencyArray["All"] = true;
      } else {
        tempFrequencyArray["All"] = false;
      }
      updateLocalState({
        selectedFrequency: { ...tempFrequencyArray },
      });
      return;
    } else if (key === "allFrequencySelected") {
      let tempFrequencyArray = { ...state.selectedFrequency };
      selectOneOnOneFrequency.forEach((item) => {
        tempFrequencyArray[item] = e.target.checked;
      });
      updateLocalState({
        selectedFrequency: { ...tempFrequencyArray },
      });
    }
    updateLocalState({
      [key]: state[key] === 0 ? 1 : 0,
    });
  };

  const handleSessionHourMinuteTrialSubscriptionCostChange = (e, key) => {
    let value;
    if (key === "sessionHour" || key === "sessionMinute") {
      value = e.value;
    } else {
      value = e.target.value > 0 ? e.target.value : 0;
    }
    updateLocalState({
      [key]: value,
      isOneOnOneMeetingDetailsEdited: true,
    });
    if ((key = "subscriptionCost")) {
      if (state.oneOnOneId) subscriptionCostEditedArray.push(state.oneOnOneId);
    }
  };

  const handleOneOnOneMeetingTimeChange = (e, weekDay, index, type, slots) => {
    let payload = {};
    let isUpdate = true;
    try {
      if (editedMeetingFromForm.length > 0) {
        let tempOneOnOneMeetingSlots1 = { ...state.selectedSlots };
        for (let item of editedMeetingFromForm) {
          if (
            item?.weekDay === weekDay &&
            item?.index === index &&
            item?.tempOneOnOneMeetingSlots ===
              tempOneOnOneMeetingSlots1[weekDay][index]
          ) {
            if (type === "start") {
              item.tempOneOnOneMeetingSlots[0] = e;
              item.slot[0].start = e;
            } else {
              item.slot[0].end = e;
            }
            item.tempOneOnOneMeetingSlots[1] = e;
            isUpdate = false;
          }
        }
      }
      if (isUpdate) {
        payload = {
          originalTimeSlot: [
            {
              start: slots[0],
              end: slots[1],
            },
          ],
          seller_id: productDetail.userId,
          product_id: productDetail.id,
          weekDay: weekDay,
          index: index,
          slot: [
            {
              start: slots[0],
              end: slots[1],
            },
          ],
          structureId: state.oneOnOneId,
        };
      }
    } catch (error) {
      console.log("error", error);
    }
    updateLocalState({
      isOneOnOneMeetingDetailsEdited: true,
    });
    let tempOneOnOneMeetingSlots = { ...state.slots };
    state.errorData.selectedOneOnOneMeetingSlots = {
      Sun: [],
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
    };
    // state.errorData["mainError"] = [];
    if (type === "start") {
      tempOneOnOneMeetingSlots[weekDay][index][0] = e;
    } else {
      tempOneOnOneMeetingSlots[weekDay][index][1] = e;
    }
    try {
      if (isUpdate) {
        payload["tempOneOnOneMeetingSlots"] =
          tempOneOnOneMeetingSlots[weekDay][index];
        payload["slot"][0].start = tempOneOnOneMeetingSlots[weekDay][index][0];
        payload["slot"][0].end = tempOneOnOneMeetingSlots[weekDay][index][1];
        editedMeetingFromForm.push({ ...payload });
      }
    } catch (err) {
      console.log(err);
    }

    updateLocalState({
      slots: { ...tempOneOnOneMeetingSlots },
    });
  };

  const handleAddDeleteOneOnOneTimeSlot = async (
    weekDay,
    index,
    action,
    slots
  ) => {
    updateLocalState({ isOneOnOneMeetingDetailsEdited: true });
    let tempOneOnOneMeetingTimeSlot = { ...state.slots };
    state.errorData.selectedOneOnOneMeetingSlots = {
      Sun: [],
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
    };

    if (action === "add") {
      tempOneOnOneMeetingTimeSlot[weekDay] &&
        tempOneOnOneMeetingTimeSlot[weekDay].push([0, 0]);
    } else if (action === "delete") {
      try {
        if (state.selectedSlots && state.selectedSlots[weekDay]) {
          for (let slot of state.selectedSlots[weekDay]) {
            if (
              slot &&
              slot.length > 0 &&
              slot[0] === slots[0] &&
              slot[1] === slots[1] &&
              slot[7]
            ) {
              await validateCancelSlotFromForm();
              return null;
            }
          }
        }
      } catch (error) {
        console.log(error);
      }

      if (tempOneOnOneMeetingTimeSlot[weekDay].length === 1) {
        delete tempOneOnOneMeetingTimeSlot[weekDay];
        let tempWeekDaySelected = { ...state.isWeekDaySelected };
        tempWeekDaySelected[weekDay] = false;
        updateLocalState({
          isWeekDaySelected: { ...tempWeekDaySelected },
        });
      } else {
        tempOneOnOneMeetingTimeSlot[weekDay] &&
          tempOneOnOneMeetingTimeSlot[weekDay].splice(index, 1);
        if (tempOneOnOneMeetingTimeSlot[weekDay].length === 0) {
          let tempWeekDaySelected = { ...state.isWeekDaySelected };
          tempWeekDaySelected[weekDay] = false;
          updateLocalState({
            isWeekDaySelected: { ...tempWeekDaySelected },
          });
        }
      }
    }
    updateLocalState({
      slots: { ...tempOneOnOneMeetingTimeSlot },
    });
  };

  const handleFreeSeminarModalShowHide = (key) => {
    setFreeSeminar(false);
    if (key === 1 || key === 2) {
      setFreeSeminarModal(false);
    } else {
      setFreeSeminarModal(false);
      updateLocalState({
        formData: {
          start: "",
          end: "",
          date: "",
          name: "",
        },
      });
    }
  };

  const fetchSearchHierarchyList = async (searchCategoryId) => {
    if (!searchCategoryId) {
      return null;
    }
    const response = await dispatch(hierarchyList([searchCategoryId]));
    if (response && Object.keys(response.data).length !== 0) {
      let tempArray = [];
      Object.entries(response.data).map((item) => {
        tempArray.push({ latestOptions: [], selectedValues: item[1] });
      });
      setAllCategories(tempArray);
    }
  };

  const handleSubmitFreeSeminar = async (productId) => {
    const timezone = JSON.parse(localStorage.getItem("sellerData")).timezone;
    const res = await dispatch(
      createFreeSeminar(
        state.formData,
        productDetail.userId,
        productId,
        timezone,
        MONTHLY,
      )
    );
    if (res?.status === 201) {
      updateLocalState({
        reqMessage: "Seminar created successfully",
      });

      setTimeout(() => {
        updateLocalState({
          reqMessage: "",
        });
        handleFreeSeminarModalShowHide();
      }, 1500);
      // fetchProductDetails();
    }
  };

  const addGroupAndOneOnOneMeetingData = async (meetingDetails, productId) => {
    dispatch(addGroupAndOneOnOneMeetingDetails(meetingDetails, productId));
  };

  const handleSubmit = async () => {
    if (validateInfo()) {
      setLoading(true);
      const selectedCategoryIds = allCategories.map(
        (item) => item?.selectedValues[item.selectedValues?.length - 1]?.id
      );
      await dispatch(addProductConfigurationSession({productTimeFrame: MONTHLY, selectedCategoryIds: selectedCategoryIds.join(","), productId}))
      if (canceledMeetingFromCalendar.length) {
        for (const item of canceledMeetingFromCalendar) {
          await dispatch(deleteSelectedEvent(item));
        }
      }
      let isOneOnOneMeetingChecked = state.isOneOnOneMeetingChecked;
      let isGroupMeetingChecked = state.isGroupMeetingChecked;

      if (
        state.isOneOnOneMeetingDetailsEdited ||
        state.isGroupMeetingDetailsEdited
      ) {
        await addGroupAndOneOnOneMeetingData(
          {
            oneOnOneMeetingDetails: isOneOnOneMeetingChecked
              ? {
                  id: state.oneOnOneId,
                  isWeekDaySelected: state.isWeekDaySelected,
                  isFrequencySelected: state.selectedFrequency,
                  slots: state.slots,
                  sessionHour: state.sessionHour,
                  sessionMinute: state.sessionMinute,
                  trialCost: state.trialCost,
                  subscriptionCost: state.subscriptionCost,
                }
              : 0,
            groupMeetingDetails: isGroupMeetingChecked
              ? state.groupMeetingDetails
              : 0,
            deletedGroupMeetingsIdArray: state.deletedGroupMeetingsIdArray,
            subscriptionCostEditedArray: subscriptionCostEditedArray
                .length
                ? JSON.stringify(subscriptionCostEditedArray)
                : null,
          },
          productId
        );
      }
      if (
        state.isSeminarEdited ||
        state.formData.start === "" ||
        state.formData.end === "" ||
        state.formData.date === "" ||
        state.formData.name === ""
      )
        await handleSubmitFreeSeminar(productId);
      const updatedSectionStatus = {
        ...sectionStatus,
        productConfigurationSession: true,
      };
      setSectionStatus(updatedSectionStatus);
      setIsFilled(true);
      navigate("/subscription-content", {state:{isCategoryChanged,productId:productId, categoryId: categoryId, sectionStatus: updatedSectionStatus}});
    }
    setLoading(false);
  };

  const handleClickOnFreeSeminar = (e) => {
    updateLocalState({
      isFreeSeminarCreated: e.target.checked,
    });
    if (e.target.checked) {
      setFreeSeminarModal(true);
    } else {
      handleFreeSeminarModalShowHide();
    }
  };

  const handleDateTimeChange = (e, key) => {
    updateLocalState({
      popupErrorMessage: "",
    });
    const { formData } = state;
    if (key === "date") {
      formData[key] = e ? moment(e).format("YYYY-MM-DD") : "";
    } else {
      formData[key] = e;
    }
    updateLocalState({
      ...formData,
    });
  };

  const handleSaveChanges = () => {
    const { formData } = state;
    const startTimeDateMilliseconds = new Date(
      formData.date?.split("-")[0],
      formData.date?.split("-")[1] - 1,
      formData.date?.split("-")[2],
      formData.start?.split(":")[0],
      formData.start?.split(":")[1]
    ).getTime();
    const endTimeDateMilliseconds = new Date(
      formData.date?.split("-")[0],
      formData.date?.split("-")[1] - 1,
      formData.date?.split("-")[2],
      formData.end?.split(":")[0],
      formData.end?.split(":")[1]
    ).getTime();
    if (
      !!!startTimeDateMilliseconds ||
      !!!endTimeDateMilliseconds ||
      startTimeDateMilliseconds >= endTimeDateMilliseconds ||
      startTimeDateMilliseconds <= Date.now()
    ) {
      updateLocalState({
        showDenyLoader: false,
        popupErrorMessage: "enter valid date and time",
      });
      return;
    }
    if (state.formData.name === "" || state.formData.name === null) {
      updateLocalState({
        eventNameError: "enter event name",
        showDenyLoader: false,
      });
      return;
    }
    if (
      (!state.isFreePriceChecked && state.formData.price === "") ||
      state.formData.price == null
    ) {
      updateLocalState({
        showDenyLoader: false,
        eventPriceError: "enter the event price",
      });
      return;
    }
    handleFreeSeminarModalShowHide(1);
    updateLocalState({ isSeminarEdited: true });
  };

  const setEventDescription = (note) => {
    updateLocalState({
      formData: {
        ...state.formData,
        note,
      },
    });
  };

  const setEventName = (name) => {
    updateLocalState({
      eventNameError: "",
      formData: {
        ...state.formData,
        name,
      },
    });
  };

  const setEventPrice = (price) => {
    updateLocalState({
      eventPriceError: "",
      formData: {
        ...state.formData,
        price,
      },
    });
  };

  const hidePrice = (e) => {
    if (e.target.checked) {
      updateLocalState({
        formData: {
          ...state.formData,
          price: "",
        },
      });
    }
    updateLocalState({
      eventPriceError: "",
      isFreePriceChecked: e.target.checked,
    });
  };

  const handleIsGroupMeeting = (e) => {
    const checked = e.target?.checked;
    updateLocalState({
      isGroupMeetingChecked: checked,
    });
  };

  const handleGroupMeetingFrequencyChange = async (
    frequency,
    index,
    groupMeetingDetails
  ) => {
    let flag = false;
    try {
      if (
        groupMeetingDetails &&
        groupMeetingDetails?.id &&
        groupMeetingDetails?.slots
      ) {
        let isBookingReceived = false;
        if (frequency !== "Monthly") {
          Object.entries(groupMeetingDetails?.slots).forEach(([slotsArray]) => {
            slotsArray &&
              slotsArray.map(async (i) => {
                if (i[7]) {
                  isBookingReceived = true;
                  await validateCancelSlotFromForm();
                }
              });
          });
        } else {
          if (groupMeetingDetails?.selectedSlots?.isBooked) {
            isBookingReceived = true;
            await validateCancelSlotFromForm();
          }
        }
        if (isBookingReceived) {
          return null;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    if (!flag) {
      updateLocalState({
        isGroupMeetingDetailsEdited: true,
      });
      let tempGroupMeetingDetails = [...state.groupMeetingDetails];
      let errorData = { ...state.errorData };

      tempGroupMeetingDetails[index]["groupMeetingFrequency"] = frequency;
      tempGroupMeetingDetails[index]["slots"] = {};
      Object.entries(
        state.groupMeetingDetails[index]["isWeekDaySelected"]
      ).forEach(([weekDay]) => {
        tempGroupMeetingDetails[index]["isWeekDaySelected"][weekDay] = false;
      });
      tempGroupMeetingDetails[index]["isMonthlyGroupFrequencyChecked"] =
        frequency === "Monthly" ? true : false;
      updateLocalState({
        groupMeetingDetails: [...tempGroupMeetingDetails],
        errorData: { ...errorData },
      });
    }
  };

  const handleGroupMeetingDetailsChange = (e, key, index) => {
    if (key === "subscriptionCost") {
      subscriptionCostEditedArray.push(state.groupMeetingDetails[index]?.id);
    }
    let payload = {};
    let isUpdate = true;
    let tempGroupMeetingDetails = [...state.groupMeetingDetails];
    try {
      if (editedGroupMeetingFromForm.length > 0) {
        for (let item of editedGroupMeetingFromForm) {
          if (
            item?.weekDay ===
              tempGroupMeetingDetails[index]["meetingMonthlyWeekDay"]?.slice(
                0,
                3
              ) &&
            item?.index === index
          ) {
            if (key === "meetingMonthlyStartTime") {
              item.slot[0].start = e;
              item.tempGroupMeetingSlot.startTime = e;
            } else if (key === "meetingMonthlyEndTime") {
              item.slot[0].end = e;
              item.tempGroupMeetingSlot.endTime = e;
            }
            isUpdate = false;
          }
        }
      }
      if (isUpdate) {
        payload = {
          orginalTimeSlot: [
            {
              start: tempGroupMeetingDetails[index]?.slots?.startTime,
              end: tempGroupMeetingDetails[index]?.slots?.endTime,
            },
          ],
          seller_id: productDetail.userId,
          product_id: productDetail.id,
          weekDay: tempGroupMeetingDetails[index][
            "meetingMonthlyWeekDay"
          ]?.slice(0, 3),
          index: index,
          slot: [
            {
              start: tempGroupMeetingDetails[index]?.meetingMonthlyStartTime,
              end: tempGroupMeetingDetails[index]?.meetingMonthlyEndTime,
            },
          ],
          structureId: tempGroupMeetingDetails[index]?.id,
        };
      }
    } catch (error) {
      console.log("error", error);
    }
    try {
      if (isUpdate) {
        payload["tempGroupMeetingSlot"] = {
          ...tempGroupMeetingDetails[index]["slots"],
          endTime: tempGroupMeetingDetails[index].meetingMonthlyEndTime,
          weekOfMonth: tempGroupMeetingDetails[index].meetingMonthlyWeekOfMonth,
          weekDay: tempGroupMeetingDetails[index].meetingMonthlyWeekDay,
          startTime: tempGroupMeetingDetails[index].meetingMonthlyStartTime,
        };
        payload["slot"][0].start =
          tempGroupMeetingDetails[index]?.meetingMonthlyStartTime;
        payload["slot"][0].end =
          tempGroupMeetingDetails[index]?.meetingMonthlyEndTime;
        editedGroupMeetingFromForm.push({ ...payload });
      }
    } catch (error) {
      console.log("error", error);
    }

    updateLocalState({
      isGroupMeetingDetailsEdited: true,
    });
    let errorData = { ...state.errorData };
    if (key === "meetingMonthlyStartTime" || key === "meetingMonthlyEndTime") {
      tempGroupMeetingDetails[index][key] = e;
      if (errorData["groupMeetingDetails"]) {
        errorData["groupMeetingDetails"][index][key] = [];
      }
      updateLocalState({
        groupMeetingDetails: [...tempGroupMeetingDetails],
        errorData: { ...errorData },
      });
      return;
    }
    if (key === "sessionMinute" || key === "sessionHour") {
      tempGroupMeetingDetails[index][key] = e.value;
    } else {
      tempGroupMeetingDetails[index][key] = e.target.value;
    }
    updateLocalState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
      errorData: { ...errorData },
    });
  };

  const handleAddNewGroupMeeting = () => {
    let tempGroupMeetingDetails = [...state.groupMeetingDetails];
    tempGroupMeetingDetails.push({
      id: null,
      meetingName: "",
      subscriptionCost: 0,
      sessionHour: "00",
      sessionMinute: "00",
      meetingMonthlyStartTime: "",
      meetingMonthlyEndTime: "",
      groupMeetingFrequency: "",
      isMonthlyGroupFrequencyChecked: false,
      isWeekDaySelected: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      slots: {},
    });
    updateLocalState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
    });
  };

  const handleDeleteNewGroupMeeting = async (index, groupMeetingDetails) => {
    let flag = false;
    if (groupMeetingDetails && groupMeetingDetails?.id) {
      let isBookingReceived = false;
      if (groupMeetingDetails.groupMeetingFrequency !== "Monthly") {
        Object.entries(groupMeetingDetails?.slots).forEach(([, slotsArray]) => {
          slotsArray &&
            slotsArray.map(async (i) => {
              if (i[7]) {
                isBookingReceived = true;
                await validateCancelSlotFromForm();
              }
            });
        });
      } else {
        if (groupMeetingDetails?.selectedSlots?.isBooked) {
          isBookingReceived = true;
          await validateCancelSlotFromForm();
        }
      }
      if (isBookingReceived) {
        return null;
      }
    }
    if (!flag) {
      updateLocalState({
        isGroupMeetingDetailsEdited: true,
      });
      let tempGroupMeetingDetails = [...state.groupMeetingDetails];
      let deletedGroupMeetingsIdArray = [...state.deletedGroupMeetingsIdArray];
      if (tempGroupMeetingDetails[index]["id"] !== null) {
        deletedGroupMeetingsIdArray.push(tempGroupMeetingDetails[index]["id"]);
      }
      tempGroupMeetingDetails.splice(index, 1);
      updateLocalState({
        groupMeetingDetails: [...tempGroupMeetingDetails],
        deletedGroupMeetingsIdArray,
      });
    }
  };

  const handleGroupWeeklyBiweeklySlotChange = async (
    e,
    weekDay,
    index,
    groupMeetingDetails
  ) => {
    let flag = false;
    const checked = e.target.checked;
    try {
      if (
        groupMeetingDetails &&
        groupMeetingDetails?.id &&
        groupMeetingDetails?.slots &&
        groupMeetingDetails?.selectedSlots[weekDay]
      ) {
        let isBookingReceived = false;
        if (groupMeetingDetails.groupMeetingFrequency !== "Monthly") {
          Object.entries(groupMeetingDetails?.slots).forEach(([slotsArray]) => {
            slotsArray &&
              slotsArray.map(async (i) => {
                if (i[7]) {
                  isBookingReceived = true;
                  await validateCancelSlotFromForm();
                }
              });
          });
        } else {
          if (groupMeetingDetails?.selectedSlots?.isBooked) {
            isBookingReceived = true;
            await validateCancelSlotFromForm();
          }
        }
        if (isBookingReceived) {
          return null;
        }
      }
    } catch (err) {
      console.error(err);
    }
    if (!flag) {
      updateLocalState({
        isGroupMeetingDetailsEdited: true,
      });
      let errorData = { ...state.errorData };
      let tempArray = [...state.groupMeetingDetails];
      tempArray[index]["isWeekDaySelected"][weekDay] = checked;

      if (checked) {
        tempArray[index]["slots"][weekDay] = [[0, 0]];
      } else {
        delete tempArray[index]["slots"][weekDay];
      }
      updateLocalState({
        groupMeetingDetails: [...tempArray],
        errorData: { ...errorData },
      });
    }
  };

  const handleGroupMeetingTimeChange = (
    e,
    weekDay,
    position,
    type,
    index,
    slots,
    mettingDetail
  ) => {
    let payload = {};
    let isUpdate = true;
    try {
      if (editedGroupMeetingFromForm.length > 0) {
        let tempGroupMeetingDetails = [...state.groupMeetingDetails];
        for (let item of editedGroupMeetingFromForm) {
          if (
            item?.weekDay === weekDay &&
            item?.index === position &&
            item?.tempGroupMeetingSlot ===
              tempGroupMeetingDetails[index]["slots"][weekDay][position]
          ) {
            if (type === "start") {
              item.slot[0].start = e;
              item.tempGroupMeetingSlot[0] = e;
            } else {
              item.slot[0].end = e;
              item.tempGroupMeetingSlot[1] = e;
            }
            isUpdate = false;
          }
        }
      }
      if (isUpdate) {
        payload = {
          orginalTimeSlot: [
            {
              start: slots[0],
              end: slots[1],
            },
          ],
          seller_id: productDetail.userId,
          product_id: productDetail.id,
          weekDay: weekDay,
          index: position,
          slot: [
            {
              start: slots[0],
              end: slots[1],
            },
          ],
          structureId: mettingDetail?.id,
        };
      }
    } catch (error) {
      console.log("error", error);
    }
    updateLocalState({
      isGroupMeetingDetailsEdited: true,
    });

    let tempGroupMeetingDetails = [...state.groupMeetingDetails];
    if (type === "start") {
      tempGroupMeetingDetails[index]["slots"][weekDay][position][0] = e;
    } else {
      tempGroupMeetingDetails[index]["slots"][weekDay][position][1] = e;
    }
    try {
      if (isUpdate) {
        payload["tempGroupMeetingSlot"] =
          tempGroupMeetingDetails[index]["slots"][weekDay][position];
        payload["slot"][0].start =
          tempGroupMeetingDetails[index]["slots"][weekDay][position][0];
        payload["slot"][0].end =
          tempGroupMeetingDetails[index]["slots"][weekDay][position][1];
        editedGroupMeetingFromForm.push({ ...payload });
      }
    } catch (error) {
      console.log("error", error);
    }
    updateLocalState({
      groupMeetingDetails: [...tempGroupMeetingDetails],
    });
  };

  const handleAddDeleteGroupTimeSlot = async (
    weekDay,
    position,
    action,
    index,
    mettingDetail,
    slotsBlock
  ) => {
    updateLocalState({
      isGroupMeetingDetailsEdited: true,
    });
    let tempGroupMeetingSlots = [...state.groupMeetingDetails];

    if (action === "add") {
      tempGroupMeetingSlots[index]["slots"][weekDay] &&
        tempGroupMeetingSlots[index]["slots"][weekDay].push([0, 0]);
    } else if (action === "delete") {
      try {
        const slots = mettingDetail?.selectedSlots[weekDay];
        if (slotsBlock[0] && slotsBlock[1] && slots && slots?.length > 0) {
          if (slots[position][7]) {
            await validateCancelSlotFromForm();
            return null;
          }
        }
        if (
          slotsBlock[0] &&
          slotsBlock[1] &&
          slotsBlock[7] &&
          mettingDetail &&
          mettingDetail?.id
        ) {
          let isBookingReceived = false;
          if (mettingDetail.groupMeetingFrequency !== "Monthly") {
            Object.entries(mettingDetail?.slots).forEach(([slotsArray]) => {
              slotsArray &&
                slotsArray.map(async (i) => {
                  if (i[7]) {
                    isBookingReceived = true;
                    await validateCancelSlotFromForm();
                  }
                });
            });
          } else {
            if (mettingDetail?.selectedSlots?.isBooked) {
              isBookingReceived = true;
              await validateCancelSlotFromForm();
            }
          }
          if (isBookingReceived) {
            return null;
          }
        }
      } catch (error) {
        console.log("error", error);
      }
      if (tempGroupMeetingSlots[index]["slots"][weekDay].length === 1) {
        delete tempGroupMeetingSlots[index]["slots"][weekDay];
        let tempweekDaySelected = {
          ...state.groupMeetingDetails[index]["isWeekDaySelected"],
        };
        tempweekDaySelected[weekDay] = false;
        tempGroupMeetingSlots[index]["isWeekDaySelected"] = {
          ...tempweekDaySelected,
        };
      } else {
        tempGroupMeetingSlots[index]["slots"][weekDay] &&
          tempGroupMeetingSlots[index]["slots"][weekDay].splice(position, 1);
        if (tempGroupMeetingSlots[index]["slots"][weekDay].length === 0) {
          let tempweekDaySelected = {
            ...state.groupMeetingDetails[index]["isWeekDaySelected"],
          };
          tempweekDaySelected[weekDay] = false;
          tempGroupMeetingSlots[index]["isWeekDaySelected"] = {
            ...tempweekDaySelected,
          };
        }
      }
    }
    updateLocalState({
      groupMeetingDetails: [...tempGroupMeetingSlots],
    });
  };

  const handleGroupMeetingMonthlyWeekDayAndWeekChange = async (
    e,
    key,
    index,
    groupMeetingDetails
  ) => {
    let flag = false;
    try {
      if (
        groupMeetingDetails &&
        groupMeetingDetails?.id &&
        groupMeetingDetails?.slots
      ) {
        let isBookingReceived = false;
        if (groupMeetingDetails.groupMeetingFrequency !== "Monthly") {
          Object.entries(groupMeetingDetails?.slots).forEach(([slotsArray]) => {
            slotsArray &&
              slotsArray.map(async (i) => {
                if (i[7]) {
                  isBookingReceived = true;
                  await validateCancelSlotFromForm();
                }
              });
          });
        } else {
          if (groupMeetingDetails?.selectedSlots?.isBooked) {
            isBookingReceived = true;
            await validateCancelSlotFromForm();
          }
        }
        if (isBookingReceived) {
          return null;
        }
      }
    } catch (err) {
      console.log(err);
    }
    if (!flag) {
      updateLocalState({
        isGroupMeetingDetailsEdited: true,
      });
      let tempGroupMeetingDetails = [...state.groupMeetingDetails];
      tempGroupMeetingDetails[index][key] = e.value;
      updateLocalState({
        groupMeetingDetails: [...tempGroupMeetingDetails],
      });
    }
  };

  return (
    <>
      <div className="add-product-page">
        <div className="page-heading">
          <button
            onClick={() =>
              navigate("/basic-details", {
                state: { sectionStatus: sectionStatus, categoryId, productId },
              })
            }
            className="theme-button dark-outline-btn radius-sm back-btn"
          >
            <BackIcon />
          </button>
          <h2>Add Product</h2>
        </div>
        <div className="white-card">
          <ProductHeader
            state={{
              productId: productId,
              categoryId: categoryId,
              sectionStatus: status,
              isFilled,
            }}
          />
          
          <div className="select-category-section">
            {/* <div className="primary-note input-note mb-4">
              <p>
                <b>Note:</b> Content uploaded on this page will only be shown to
                the active subscribers.
              </p>
            </div> */}

            <div className="form-group">
              {allCategories.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex g-2 justify-content-between">
                      <div className="d-flex g-2">
                        <label>
                          Search Category {index + 1}
                          <sup>*</sup>
                        </label>
                        <div className="tooltip-box pl-2">
                          <div class="tooltip-custom">
                            <PrimaryInfoIcon />
                            <div class="together-tooltip">
                              <p>
                              Select a category to filter your product. This will help customers find items relevant to their needs more easily.
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul className="search-pills-category mt-1">
                      {item?.selectedValues.map((category, cIndex) => (
                        <li key={cIndex}>
                          {category?.name}
                          <span>
                            <i
                              className="fas fa-times"
                              onClick={() =>
                                removeSearchCategory(index, cIndex)
                              }
                            />
                          </span>
                        </li>
                      ))}
                    </ul>
                    <SelectBox
                      required
                      parentClass="mb-2"
                      placeholder="Select Search Category"
                      options={item?.latestOptions?.map((i) => {
                        return { ...i, label: i.name };
                      })}
                      onChange={(e) => handleSearchCategoryChange(e, index)}
                      value=""
                    />
                  </div>
                );
              })}
              <button
                onClick={handleAddSearchCategory}
                type="button"
                className="link-button primary-btn"
              >
                <AddIcon /> Add New Category
              </button>
            </div>

            <div className="form-group bb1">
              <Calendar
                showLoaderForCalander={(loader) =>
                  showLoaderForCalendar(loader)
                }
                showHideFreeSeminarModal={showHideFreeSeminarModal}
                showSeminarPopup={() => setFreeSeminar(true)}
                handleCreateFreeSeminar={() => setFreeSeminarModal(true)}
                title={productDetail.name}
                productId={productDetail.id}
                seller_id={productDetail.userId}
                isOneOnOneMeetingChecked={state.isOneOnOneMeetingChecked}
                handleOpenMeetingSchedule={handleOpenMeetingSchedule}
                zoomLink={state.zoom_host_url}
                getGroupAndOneOnOneMeetingDetails={
                  getGroupAndOneOnOneMeetingData
                }
                setGroupAndOneOnOneMeetingDetailsFromCalendar={
                  setGroupAndOneOnOneMeetingDetailsFromCalendar
                }
                updateSelectedEvent={updateSelectedEvent}
                deleteSelectedEvent={deleteSelectedEvent}
                validateCancelSlot={validateCancelSlots}
                calculateHourMinuteDiff={calculateHourMinuteDiff}
                freeSeminarDetails={state.formData}
                isWeekDaySelected={state.isWeekDaySelected}
                // handleSubmit={handleSubmit}
              />
            </div>
            <div className="d-flex g-2 mb-2">
              <CommonCheckbox
                label="Create one on one session"
                onChange={handleIsOneOnOneMeeting}
                value={state.isOneOnOneMeetingChecked}
                checked={state.isOneOnOneMeetingChecked}
              />
              <div className="tooltip-box pl-2">
                <div class="tooltip-custom">
                  <PrimaryInfoIcon />
                  <div class="together-tooltip">
                    <p>
                    Set up one time or recurring individual meetings.
                      <span class="arrow-down"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {state.isOneOnOneMeetingChecked ? (
              <div className="one-on-one-meeting">
                <div className="form-inner-hrading mb-2">
                  <div className="name">
                    <h4>Select Frequency</h4>
                  </div>
                  <div className="inline-radio">
                    {selectOneOnOneFrequency.map((frequency, index) => {
                      return (
                        <CommonCheckbox
                          key={index}
                          label={frequency.replace("_", " ")}
                          checked={state.selectedFrequency[frequency]}
                          onChange={(e) => {
                            handleChangeChk(
                              e,
                              frequency === `All`
                                ? `allFrequencySelected`
                                : `selectedFrequency`,
                              "",
                              frequency
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                  <span className="form-field-error">
                    {state.errorData && state.errorData["selectedFrequency"]
                      ? state.errorData["selectedFrequency"]
                      : ""}
                  </span>
                </div>
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    <InputBox
                      label="Trial or One-Time Cost"
                      type="number"
                      placeholder="Enter cost here"
                      value={state.trialCost}
                      onChange={(e) =>
                        handleSessionHourMinuteTrialSubscriptionCostChange(
                          e,
                          "trialCost"
                        )
                      }
                      min={0}
                      error={state.errorData["trialCost"]}
                    />
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <InputBox
                      label="Subscription Cost"
                      type="number"
                      placeholder="Enter Cost Here"
                      value={state.subscriptionCost}
                      onChange={(e) =>
                        handleSessionHourMinuteTrialSubscriptionCostChange(
                          e,
                          "subscriptionCost"
                        )
                      }
                      min={0}
                      error={state.errorData["subscriptionCost"]}
                    />
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <div className="form-group">
                      <label>
                        Session Duration<sup>*</sup>
                      </label>
                    <div className="row">
                      <div className="col-md-6">
                        <SelectBox
                        parentClass="mb-0"
                          required
                          placeholder="Hours"
                          options={sessionHours.map((hour) => ({
                            label: hour,
                            value: hour,
                          }))}
                          value={{
                            label: state.sessionHour,
                            value: state.sessionHour,
                          }}
                          onChange={(e) =>
                            handleSessionHourMinuteTrialSubscriptionCostChange(
                              e,
                              "sessionHour"
                            )
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <SelectBox
                        parentClass="mb-0"
                          required
                          placeholder="Minutes"
                          options={sessionMinutes.map((min) => ({
                            label: min,
                            value: min,
                          }))}
                          value={{
                            label: state.sessionMinute,
                            value: state.sessionMinute,
                          }}
                          onChange={(e) =>
                            handleSessionHourMinuteTrialSubscriptionCostChange(
                              e,
                              "sessionMinute"
                            )
                          }
                        />
                      </div>
                    </div>
                    <span className="form-field-error">
                        {state.errorData["sessionDuration"]
                          ? state.errorData["sessionDuration"]
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-inner-hrading mb-2">
                  <div className="name">
                    <h4>Available Days</h4>
                  </div>
                  <div className="inline-radio">
                    {Object.entries(weekDays).map(([weekDay]) => {
                      return (
                        <CommonCheckbox
                          checked={state.isWeekDaySelected[weekDay]}
                          label={weekDay}
                          onChange={(e) =>
                            handleChangeChk(e, "isWeekDaySelected", weekDay)
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="form-inner-hrading mb-3">
                  <div className="name">
                    <h4>Available Slots</h4>
                  </div>
                  <div className="slots-card-list">
                    {Object.entries(weekDays).map(([weekDay, weekDayVal]) => (
                      <div
                        className={`slots-card ${
                          state.isWeekDaySelected[weekDay] ? "active-card" : ""
                        }`}
                      >
                        <div className="slots-card-name">
                          <h3>{weekDayVal}</h3>
                        </div>
                        {state.slots[weekDay]?.length > 0 &&
                          state.slots[weekDay]?.map((timeSlot, index) => {
                            return (
                              <>
                              <div key={index} className="slots-card-mid">
                                <div className="card-date">
                                  <TimePicker
                                      value={timeSlot[0]}
                                      format="hh:mm a"
                                      name="start"
                                      disableClock={true}
                                      onChange={(e) =>
                                        handleOneOnOneMeetingTimeChange(
                                          e,
                                          weekDay,
                                          index,
                                          "start",
                                          timeSlot
                                        )
                                      }
                                      required={true}
                                      clearIcon={null}
                                      className="form-control"
                                    />
                                    <div className="breaker">
                                      <span>To</span>
                                    </div>
                                    <TimePicker
                                      value={timeSlot[1]}
                                      format="hh:mm a"
                                      name="start"
                                      disableClock={true}
                                      onChange={(e) =>
                                        handleOneOnOneMeetingTimeChange(
                                          e,
                                          weekDay,
                                          index,
                                          "end",
                                          timeSlot
                                        )
                                      }
                                      required={true}
                                      clearIcon={null}
                                      className="form-control"
                                    />
                                </div>
                                <div className="delete-btn">
                                  <p
                                    //   //  ${
                                    //   // !(
                                    //   //   index ===
                                    //   //   state.slots[weekDay]?.length - 1
                                    //   // )
                                    //     // ? 
                                       
                                    //     // : ``
                                    // }`}
                                    onClick={() =>
                                      handleAddDeleteOneOnOneTimeSlot(
                                        weekDay,
                                        index,
                                        "delete",
                                        timeSlot
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-trash "
                                      aria-hidden="true"
                                    />
                                    Delete
                                    {/* {!(
                                      index ===
                                      state.slots[weekDay]?.length - 1
                                    )
                                      ? `Delete`
                                      : ``} */}
                                  </p>                           
                                </div>
                               
                              </div>
                              {index ===
                                    state.slots[weekDay]?.length - 1 && (
                                    <div className="add-slots-btn">
                                        <p
                                          onClick={() =>
                                            handleAddDeleteOneOnOneTimeSlot(
                                              weekDay,
                                              index,
                                              "add"
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-plus-square mr-2"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Add new
                                        </p>
                                    </div>
                                  )}
                                <span className="form-field-error">
                                  {!!state.errorData
                                    ?.selectedOneOnOneMeetingSlots &&
                                  state.errorData?.selectedOneOnOneMeetingSlots[
                                    weekDay
                                  ] &&
                                  state.errorData.selectedOneOnOneMeetingSlots[
                                    weekDay
                                  ][index]?.length
                                    ? state.errorData
                                        .selectedOneOnOneMeetingSlots[weekDay][
                                        index
                                      ][0]
                                    : null}
                                </span>
                              </>
                            );
                          })}
                      </div>
                    ))}
                  </div>
                  <div>
                    <span className="form-field-error">
                    {state.errorData && state.errorData["oneOnOneSlots"]
                      ? state.errorData["oneOnOneSlots"]
                      : ""}
                  </span>
                  </div>
                </div>
               
              </div>
            ) : null}
            <div>
              <div className="d-flex g-2 mb-2">
                <CommonCheckbox
                  label="Group Meeting"
                  value={state.isGroupMeetingChecked}
                  onChange={handleIsGroupMeeting}
                  checked={state.isGroupMeetingChecked}
                />
                <div className="tooltip-box pl-2">
                  <div class="tooltip-custom">
                    <PrimaryInfoIcon />
                    <div class="together-tooltip">
                      <p>
                      Schedule sessions/meetings with multiple individuals.
                        <span class="arrow-down"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
             
              {state.isGroupMeetingChecked
                ? state.groupMeetingDetails.map(
                    (groupMeetingDetails, index) => {
                      return (
                        <div className="group-session-section">
                        <div key={index}>
                          <div className="form-inner-hrading mb-2">
                            <div className="name">
                              <h4>Select Frequency</h4>
                            </div>
                            <div className="inline-radio">
                              {selectGroupFrequency.map((frequency, FIndex) => {
                                return (
                                  <CommonCheckbox
                                    key={FIndex}
                                    label={frequency}
                                    checked={
                                      groupMeetingDetails[
                                        "groupMeetingFrequency"
                                      ] === frequency
                                    }
                                    onChange={() =>
                                      handleGroupMeetingFrequencyChange(
                                        frequency,
                                        index,
                                        groupMeetingDetails
                                      )
                                    }
                                  />
                                );
                              })}
                            </div>
                            <span className="form-field-error">
                              {state.errorData &&
                              state.errorData["groupFrequency"]
                                ? state.errorData["groupFrequency"]
                                : ""}
                            </span>
                          </div>
                          <div className="row">
                            <div className="col-md-4 col-lg-4">
                              <InputBox
                                type="text"
                                label="Meeting Name"
                                placeholder="Enter Metting Name"
                                value={groupMeetingDetails["meetingName"]}
                                onChange={(e) =>
                                  handleGroupMeetingDetailsChange(
                                    e,
                                    "meetingName",
                                    index
                                  )
                                }
                                maxLength={50}
                                error={state.errorData["groupMeetingName"]}
                              />
                            </div>
                            <div className="col-md-4 col-lg-4">
                              <InputBox
                                label="Subscription Cost"
                                type="number"
                                placeholder="Enter Cost Here"
                                value={groupMeetingDetails["subscriptionCost"]}
                                onChange={(e) =>
                                  handleGroupMeetingDetailsChange(
                                    e,
                                    "subscriptionCost",
                                    index
                                  )
                                }
                                min={0}
                                error={state.errorData["groupSubscriptionCost"]}
                              />
                            </div>
                            <div className="col-md-4 col-lg-4">
                              <div className="form-group">
                                <label>
                                  Session Duration<sup>*</sup>
                                </label>
                              <div className="row">
                                <div className="col-md-6">
                                  <SelectBox
                                    parentClass="mb-0"
                                    required
                                    placeholder="Hours"
                                    options={sessionHours.map((hour) => ({
                                      label: hour,
                                      value: hour,
                                    }))}
                                    value={{
                                      label: groupMeetingDetails["sessionHour"],
                                      value: groupMeetingDetails["sessionHour"],
                                    }}
                                    onChange={(e) =>
                                      handleGroupMeetingDetailsChange(
                                        e,
                                        "sessionHour",
                                        index
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-md-6">
                                  <SelectBox
                                  parentClass="mb-0"
                                    required
                                    placeholder="Minutes"
                                    options={sessionMinutes.map((min) => ({
                                      label: min,
                                      value: min,
                                    }))}
                                    value={{
                                      label:
                                        groupMeetingDetails["sessionMinute"],
                                      value:
                                        groupMeetingDetails["sessionMinute"],
                                    }}
                                    onChange={(e) =>
                                      handleGroupMeetingDetailsChange(
                                        e,
                                        "sessionMinute",
                                        index
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <span className="form-field-error">
                                {state.errorData["groupSessionDuration"]
                                  ? state.errorData["groupSessionDuration"]
                                  : ""}
                              </span>
                              </div>
                            </div>
                          </div>
                          {!groupMeetingDetails[
                            "isMonthlyGroupFrequencyChecked"
                          ] ? (
                            <>
                              <div className="form-inner-hrading mb-2">
                                <div className="name">
                                  <h4>Available Days</h4>
                                </div>
                                <div className="inline-radio">
                                  {Object.entries(weekDays).map(([weekDay]) => {
                                    return (
                                      <CommonCheckbox
                                        checked={
                                          groupMeetingDetails[
                                            "isWeekDaySelected"
                                          ][weekDay]
                                        }
                                        label={weekDay}
                                        onChange={(e) =>
                                          handleGroupWeeklyBiweeklySlotChange(
                                            e,
                                            weekDay,
                                            index,
                                            groupMeetingDetails
                                          )
                                        }
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                              <div className="form-inner-hrading mb-3">
                                <div className="name">
                                  <h4>Available Slots</h4>
                                </div>
                                <div className="slots-card-list">
                                  {Object.entries(weekDays).map(
                                    ([weekDay, weekDayVal]) => (
                                      <div
                                        className={`slots-card ${
                                          groupMeetingDetails[
                                            "isWeekDaySelected"
                                          ][weekDay]
                                            ? "active-card"
                                            : ""
                                        }`}
                                      >
                                        <div className="slots-card-name">
                                          <h3>{weekDayVal}</h3>
                                        </div>
                                        {groupMeetingDetails["slots"][weekDay]
                                          ?.length > 0 &&
                                          groupMeetingDetails["slots"][
                                            weekDay
                                          ]?.map((timeSlot, position) => {
                                            return (
                                              <div>
                                              <div
                                                key={position}
                                                className="slots-card-mid"
                                              >
                                                <div className="card-date">
                                                  <TimePicker
                                                    value={timeSlot[0]}
                                                    format="hh:mm a"
                                                    name="start"
                                                    disableClock={true}
                                                    onChange={(e) =>
                                                      handleGroupMeetingTimeChange(
                                                        e,
                                                        weekDay,
                                                        position,
                                                        "start",
                                                        index,
                                                        timeSlot,
                                                        groupMeetingDetails
                                                      )
                                                    }
                                                    required={true}
                                                    clearIcon={null}
                                                    className="form-control"
                                                  />
                                                  <div className="breaker">
                                                    <span>To</span>
                                                  </div>
                                                  <TimePicker
                                                    value={timeSlot[1]}
                                                    format="hh:mm a"
                                                    name="start"
                                                    disableClock={true}
                                                    onChange={(e) =>
                                                      handleGroupMeetingTimeChange(
                                                        e,
                                                        weekDay,
                                                        position,
                                                        "end",
                                                        index,
                                                        timeSlot,
                                                        groupMeetingDetails
                                                      )
                                                    }
                                                    required={true}
                                                    clearIcon={null}
                                                    className="form-control"
                                                  />
                                                </div>
                                                <div className="delete-btn">
                                                  <p
                                                    //    ${
                                                    //   !(
                                                    //     position ===
                                                    //     groupMeetingDetails[
                                                    //       "slots"
                                                    //     ][weekDay]?.length -
                                                    //       1
                                                    //   )
                                                    //     ? `theme-delete mb-0`
                                                    //     : ``
                                                    // }`}
                                                    onClick={() =>
                                                      handleAddDeleteGroupTimeSlot(
                                                        weekDay,
                                                        position,
                                                        "delete",
                                                        index,
                                                        groupMeetingDetails,
                                                        timeSlot
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-trash "
                                                      aria-hidden="true"
                                                    />
                                                    Delete
                                                    {/* {!(
                                                      position ===
                                                      groupMeetingDetails[
                                                        "slots"
                                                      ][weekDay]?.length -
                                                        1
                                                    )
                                                      ? `Delete`
                                                      : ``} */}
                                                  </p>
                                                </div>
                                              </div>
                                              {position ===
                                                    groupMeetingDetails[
                                                      "slots"
                                                    ][weekDay]?.length -
                                                      1 && (
                                                      <div className="add-slots-btn">
                                                        <p
                                                          onClick={() =>
                                                            handleAddDeleteGroupTimeSlot(
                                                              weekDay,
                                                              position,
                                                              "add",
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <i
                                                            className="fa fa-plus-square"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                          Add new
                                                        </p>
                                                    </div>
                                                  )}
                                                <span className="form-field-error">
                                                  {!!state.errorData
                                                    ?.selectedGroupMeetingSlots &&
                                                  state.errorData
                                                    ?.selectedGroupMeetingSlots[
                                                    index
                                                  ][weekDay] &&
                                                  state.errorData
                                                    .selectedGroupMeetingSlots[
                                                    index
                                                  ][weekDay][index]?.length
                                                    ? state.errorData
                                                        .selectedGroupMeetingSlots[
                                                        index
                                                      ][weekDay][index][0]
                                                    : null}
                                                </span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    )
                                  )}
                                </div>
                                <div>
                                  <span className="form-field-error">
                                    {state.errorData &&
                                    state.errorData["groupMeetingSlots"]
                                      ? state.errorData["groupMeetingSlots"]
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="row">
                                
                                <div className="col-md-4">
                                  <SelectBox
                                    label="Week"
                                    required
                                    placeholder="Week"
                                    options={weekOfMonth.map((week) => ({
                                      label: week,
                                      value: week,
                                    }))}
                                    value={{
                                      label:
                                        groupMeetingDetails[
                                          "meetingMonthlyWeekOfMonth"
                                        ],
                                      value:
                                        groupMeetingDetails[
                                          "meetingMonthlyWeekOfMonth"
                                        ],
                                    }}
                                    onChange={(e) =>
                                      handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                        e,
                                        "meetingMonthlyWeekOfMonth",
                                        index,
                                        groupMeetingDetails
                                      )
                                    }
                                    error={
                                      state.errorData[
                                        "meetingMonthlyWeekOfMonth"
                                      ]
                                    }
                                  />
                                </div>
                                <div className="col-md-4">
                                  <SelectBox
                                    label="Week Day"
                                    required
                                    placeholder="Week Day"
                                    options={Object.entries(weekDays).map(
                                      ([weekDay]) => ({
                                        label: weekDay,
                                        value: weekDay,
                                      })
                                    )}
                                    value={{
                                      label:
                                        groupMeetingDetails[
                                          "meetingMonthlyWeekDay"
                                        ],
                                      value:
                                        groupMeetingDetails[
                                          "meetingMonthlyWeekDay"
                                        ],
                                    }}
                                    onChange={(e) =>
                                      handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                        e,
                                        "meetingMonthlyWeekDay",
                                        index,
                                        groupMeetingDetails
                                      )
                                    }
                                    error={
                                      state.errorData["meetingMonthlyWeekDay"]
                                    }
                                  />
                                </div>
                                <div className="col-md-4">
                                <div className="form-group">
                                  <label>Time</label>
                                  <div className="d-flex gap-2">
                                  <TimePicker
                                    value={
                                      groupMeetingDetails[
                                        "meetingMonthlyStartTime"
                                      ]
                                    }
                                    format="hh:mm a"
                                    name="start"
                                    disableClock={true}
                                    onChange={(e) =>
                                      handleGroupMeetingDetailsChange(
                                        e,
                                        "meetingMonthlyStartTime",
                                        index
                                      )
                                    }
                                    required={true}
                                    clearIcon={null}
                                    className="form-control"
                                  />
                                  <div className="breaker pl-2 pr-2">
                                    <span>To</span>
                                  </div>
                                  <TimePicker
                                    value={
                                      groupMeetingDetails[
                                        "meetingMonthlyEndTime"
                                      ]
                                    }
                                    format="hh:mm a"
                                    name="start"
                                    disableClock={true}
                                    onChange={(e) =>
                                      handleGroupMeetingDetailsChange(
                                        e,
                                        "meetingMonthlyEndTime",
                                        index
                                      )
                                    }
                                    required={true}
                                    clearIcon={null}
                                    className="form-control"
                                  />
                                  {/* <span className="form-field-error">
                                    {state.errorData &&
                                    state.errorData["meetingMonthlyEndTime"]
                                      ? state.errorData["meetingMonthlyEndTime"]
                                      : ""}
                                  </span> */}
                                </div>
                                <span className="form-field-error">
                                    {state.errorData &&
                                    state.errorData["meetingMonthlyStartTime"]
                                      ? state.errorData[
                                          "meetingMonthlyStartTime"
                                        ]
                                      : ""}
                                  </span>
                                </div>
                              
                                </div>
                               
                              </div>
                            </>
                          )}
                          {index === state.groupMeetingDetails.length - 1 && (
                            <button
                              className="orange-btn my-4 mr-4"
                              onClick={() => {
                                handleAddNewGroupMeeting();
                              }}
                            >
                              Add new group meeting
                            </button>
                          )}
                          {state.groupMeetingDetails.length > 1 && (
                            <button
                              className="orange-outline-btn my-4"
                              onClick={() => {
                                handleDeleteNewGroupMeeting(
                                  index,
                                  groupMeetingDetails
                                );
                              }}
                            >
                              Delete group meeting
                            </button>
                          )}
                         
                        </div>
                          </div>
                      );
                    }
                  )
                : null}
              

              <div className="d-flex g-2 mb-4">
                {/* <CommonCheckbox label="Create One Time Session" /> */}
                {/* <div className="tooltip-box pl-2">
                  <div class="tooltip-custom">
                    <PrimaryInfoIcon />
                    <div class="together-tooltip">
                      <p>
                        Create one time session
                        <span class="arrow-down"></span>
                      </p>
                    </div>
                  </div>
                </div> */}
                <OneTimeEvent
                  handleCreateFreeSeminar={() => setFreeSeminarModal(true)}
                  isFreeSeminar={isFreeSeminar}
                  handleClickOnFreeSeminar={handleClickOnFreeSeminar}
                  isFreeSeminarCreated={state.isFreeSeminarCreated}
                  errorData={state.errorData}
                  formData={state.formData}
                  showHideFreeSeminarModal={showHideFreeSeminarModal}
                  handleFreeSeminarModalShowHide={
                    handleFreeSeminarModalShowHide
                  }
                  reqMessage={state.reqMessage}
                  successMessage={state.successMessage}
                  popupErrorMessage={state.popupErrorMessage}
                  eventNameError={state.eventNameError}
                  handleDateTimeChange={handleDateTimeChange}
                  handleSaveChanges={handleSaveChanges}
                  showDenyLoader={state.showDenyLoader}
                  setEventDescription={setEventDescription}
                  setEventName={setEventName}
                  setEventPrice={setEventPrice}
                  isFreePriceChecked={state.isFreePriceChecked}
                  hidePrice={hidePrice}
                  eventPriceError={state.eventPriceError}
                />
              </div>
            </div>
          </div>
          <span className="form-field-error">
            {state.errorData && state.errorData["missingDetail"]
              ? state.errorData["missingDetail"]
              : ""}
          </span>
          <div className="action-btn">
            <CommonButton
              value="Continue"
              loading={loading}
              disabled={loading}
              onClick={handleSubmit}
              buttonClass="theme-button primary-btn radius-btn"
            />
            <CommonButton
              value="Cancel"
              disabled={loading}
              onClick={() => navigate("/product-list")}
              buttonClass="theme-button dark-outline-btn radius-btn"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductConfigurationSession;
