import axios from "axios";
import { handleError } from "./commonAction";
import CryptoJS from "crypto-js";

const URL = process.env.REACT_APP_API_URL;

export const fetchPrivateFiles = (type, fileUrl, query) => async (dispatch) => {
    try {
        const file = {
            url: fileUrl,
            stream: ""
        }
        const res = await axios({
            method: "get",
            url: `${URL}/api/v1/seller/azure-service/download${ query ? "?" + (new URLSearchParams(query)).toString() : "" }`,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });
        if (res && res.data?.file){
            let fileRes = CryptoJS.AES.decrypt(res.data.file, "#$0filedownloadsecret0$#");
            fileRes = fileRes.toString(CryptoJS.enc.Utf8);
            fileRes = JSON.parse(fileRes);
            file['fileName'] = fileRes.name;
            file['contentType'] = fileRes.contentType;
            file['contentLength'] = fileRes.contentLength;
            file['type'] = fileRes.type;
            file['extension'] = fileRes.extension;
            file['stream'] = fileRes.stream;
        }

        switch (type){
            case "acsCatDocuments":
                dispatch({
                    type: "ADD_AZ_ACS_CAT_DOCUMENTS",
                    payload: {files: [ file ] }
                })
                break;
            case "acsCatImages":
                dispatch({
                    type: "ADD_AZ_ACS_CAT_IMAGES",
                    payload: {files: [ file ] }
                })
                break;
            case "acsCatAudios":
                dispatch({
                    type: "ADD_AZ_ACS_CAT_AUDIOS",
                    payload: {files: [ file ] }
                })
                break;
            case "acsCatVideos":
                dispatch({
                    type: "ADD_AZ_ACS_CAT_VIDEOS",
                    payload: {files: [ file ] }
                })
                break;
        }
        return [file];
    } catch (error) {
        console.error(error)
        dispatch(handleError(error));
        return {
            error: true,
            errorMessage: "Error Occurred"
        }
    }
}
