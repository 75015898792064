import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { BI_WEEKLY, days, GROUP, MONTHLY, ONE_TIME, WEEKLY } from "../../Constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import * as meetingActions from "../../Redux/Actions/meetingActions";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import {
  validateCancelSlot,
} from "../../Redux/Actions/meetingActions";
import { createFreeSeminar } from "../../Redux/Actions/productAction";

import { isInteger } from "lodash";
import "../../Styles/calendar.scss";
import {
  deleteSelectedEvent,
  updateMeetingStatus,
  updateSelectedEvent,
} from "../../Redux/Actions/meetingActions";
import AlertModal from "../../Components/AlertModal/AlertModal";
import {
  CLOSE_MEETING_MODAL,
  MEETING_ALREADY_EXISTS,
} from "../../Redux/ActionType";
import { useLocation } from "react-router";
import TimePicker from "react-time-picker";
import DatePicker from "react-date-picker";
import {
  cancelGroupInviteMeeting,
  updateGroupInviteMeeting,
} from "../../Redux/Actions/productAction";
import { Link } from "react-router-dom";

import axios from "axios";
let source;
source = axios.CancelToken.source();

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
const eventStyleGetter = function (event, start, end, isSelected) {
  let background = "#fff";
  let color = "#000";

  if (event.status === "pending") {
    background = "#ccc";
    color = "#000";
  }
  if ((event.meetingDurationType === ONE_TIME && (event.groupType == GROUP || event.isSlotBooked)) || (event.type == "free_event")) {
    background = "#e25e52";
    color = "#fff";
  }
  if (event.meetingDurationType === WEEKLY && (event.groupType == GROUP || event.isSlotBooked)) {
    background = "#00c12a";
    color = "#fff";
  }
  if (event.meetingDurationType === BI_WEEKLY && (event.groupType == GROUP || event.isSlotBooked)) {
    background = "#0423ff";
    color = "#fff";

  }
  if (event.meetingDurationType === MONTHLY && (event.groupType == GROUP || event.isSlotBooked)) {
    background = "#e4a900";
    color = "#fff";

  }
  var style = {
    background,
    borderRadius: "10px",
    opacity: 1,
    color,
    border: "0px",
    display: "block",
    padding: "2px 10px",
    textAlign: "center",
    margin: "3px",
    outline: "none",
  };
  return {
    style: style,
  };
};
let link = ""
let host_link = "";

const GlobalCalendar = (props) => {
  const dispatch = useDispatch();
  const sellerId = parseInt(localStorage.getItem("sellerId"));
  let { meetingExists } = useSelector((state) => state.meetingReducer);
  const location = useLocation();
  const [oneOnOneSlots, setOneOnOneSlots] = useState({});
  const [eventList, setEventList] = useState([]);
  const [view, setView] = useState("week");
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [filterType, setFilterType] = useState("all");
  const [showLoader, setShowLoader] = useState(false);
  const [meetingDurationType, setMeetingDurationType] = useState(WEEKLY);
  const [editMeeting, setEditMeeting] = useState(false);
  const [events, setEvents] = useState([]);
  const selectGroupFrequency = ["Weekly", "Bi weekly", "Monthly"];
  const weekOfMonth = ["First", "Second", "Third"];
  const [isGroupMeetingChecked, setIsGroupMeetingChecked] = useState(true);
  const [editMeetingState, setEditMeetingState] = useState({
    isOpen: false,
    data: "",
  });
  const [cancelMeeting, setCancelMeeting] = useState(false);
  const [originalMeetingState, setOriginalMeetingState] = useState({
    type: "",
    duration: "",
    slot: {},
  });
  let [eventSelectedDay, setEventSelectedDay] = useState(new Date());
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    singleButton: true,
  });
  const [createMeetingState, setCreateMeetingState] = useState({});
  const [formData, setFormData] = useState({
    start: "",
    end: "",
  });
  const [showAcceptDetail, setShowAcceptDetail] = useState(false);
  const [showDeclineDetail, setShowDeclineDetail] = useState(false);
  const [declineNotes, setDeclineNotes] = useState("");
  const [btnDisabled, setButtonDisabled] = useState(false);
  const { timezone } = JSON.parse(localStorage.getItem("sellerData"));
  const [apiTracker, setApiTracker] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [originalMeetingType, setOriginalMeetingType] = useState(0);
  const sellerData = JSON.parse(localStorage.getItem("sellerData"));
  const [oneOnOneMeetingErrorData, setOneOnOneMeetingErrorData] = useState({
    selectedOneOnOneFrequency: [],
    trialCost: [],
    subscriptionCost: [],
    sessionHour: [],
    selectedOneOnOneMeetingSlots: [],
  });
  const [sessionHour, setSessionHour] = useState("00");
  const [sessionMinute, setSessionMinute] = useState("00");
  const [disableEditMeetingButton, setDisableEditMeetingButton] =
    useState(false);
  const [meetingType, setMeetingType] = useState(0); // meeting type = 0 for group and 1 for one-one-one
  const [editMeetingType, setEditMeetingType] = useState(0); // meeting type = 0 for recurring and 1 for one time
  const [originalGroupMeetingDetails, setOriginalGroupMeetingDetails] = useState({});
  const [seminarFormData, setSeminarFormData] = useState({
    start: "",
    end: "",
    date: "",
    name: "",
    price: null,
    id: null,
  })

  const [showHideFreeSeminarModal, setShowHideFreeSeminarModal] = useState(false)
  const [isFreeSeminarCreated, setIsFreeSeminarCreated] = useState(false)
  const [isFreeSeminar, setIsFreeSeminar] = useState(false)
  const [reqMessage, setReqMessage] = useState()
  const [successMessage, setSuccessMessage] = useState("")
  const [eventNameError, setEventNameError] = useState("")
  const [popupErrorMessage, setPopupErrorMessage] = useState("")
  const [isFreePriceChecked, setIsFreePriceChecked] = useState(false)
  const [eventPriceError, setEventPriceError] = useState("")
  const [showDenyLoader, setShowDenyLoader] = useState(false)
  const [isSeminarEdited, setIsSeminarEdited] = useState(false)


  const sessionHours = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const sessionMinutes = ["00", "15", "30", "45"];
  const [originalMeetingDurationType, setOriginalMeetingDurationType] =
    useState(WEEKLY);

  const selectOneOnOneFrequency = [
    "All",
    "One Time",
    "Weekly",
    "Bi weekly",
    "Monthly",
  ];
  const [selectedFrequency, setSelectedFrequency] = useState({
    All: false,
    "One Time": false,
    Weekly: false,
    "Bi weekly": false,
    Monthly: false,
  });

  const [zoomLink, setZoomMeetingLink] = useState("");
  const [originalSlot, setOriginalSlot] = useState({
    start: "",
    end: "",
  });

  const [oneOnOneId, setOneOnOneId] = useState(null);


  const [trialCost, setTrialCost] = useState(0);
  const [subscriptionCost, setSubscriptionCost] = useState(0);

  const [isOneOnOneMeetingChecked, setIsOneOnOneMeetingChecked] =
    useState(false);
  const [groupMeetingDetails, setGroupMeetingDetails] = useState({
    id: null,
    meetingName: "",
    subscriptionCost: 0,
    sessionHour: "00",
    sessionMinute: "00",
    meetingMonthlyStartTime: "",
    meetingMonthlyEndTime: "",
    meetingMonthlyWeekDay: "",
    meetingMonthlyWeekOfMonth: "",
    groupMeetingFrequency: "",
    isMonthlyGroupFrequencyChecked: false,
    notDeletePreviousEntries: true,
    isWeekDaySelected: {
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
    },
    slots: {},
  });

  const weekDays = {
    Sun: "Sunday",
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
  };

  const [isWeekDaySelected, setIsWeekDaySelected] = useState({
    Sun: false,
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
  });


  const [groupMeetingErrorData, setGroupMeetingErrorData] = useState({
    meetingName: [],
    selectedOneOnOneFrequency: [],
    subscriptionCost: [],
    sessionHour: [],
    sessionMinute: [],
    selectedWeekDay: [],
    meetingMonthlyWeekDay: [],
    meetingMonthlyStartTime: [],
    meetingMonthlyWeekOfMonth: [],
    selectedOneOnOneMeetingSlots: [],
  });

  useEffect(() => {
    if (meetingExists && location.pathname.includes("/global-calendar")) {
      alertModal({
        message:
          "For this slot meeting already Exists! Do you still want to continue?",
        singleButton: false,
        positiveButton: "Yes, Continue",
        negativeButton: "No, Cancel",
        id: 1,
        data: [{}, 0],
      });
    }
  }, [meetingExists]);

  useEffect(() => {
    fetchGlobalEvents();
  }, [selectedDay, view, filterType]);



  useEffect(() => {
    if (isOneOnOneMeetingChecked && events && events?.length > 0) {
      events.map((productDetail, index) => {
        if (productDetail.groupType === "one_on_one") {
          setOneOnOneId(productDetail.id);
          setSubscriptionCost(productDetail.subscriptionCost);
          setTrialCost(productDetail.oneTimeCost);
          let sessionDuration = JSON.parse(productDetail.sessionDuration);
          setSessionHour(sessionDuration.sessionHour);
          setSessionMinute(sessionDuration.sessionMinute);

          let tempSelectedFrequency = { ...selectedFrequency };
          let oneOnOneFreq = productDetail.frequency.split(",");
          oneOnOneFreq.map((frequency) => {
            tempSelectedFrequency[frequency] = true;
          });

          setSelectedFrequency(tempSelectedFrequency);

          let tempSlots = { ...oneOnOneSlots };
          let oneOnOneSelectedWeekDays = { ...isWeekDaySelected };

          let meetingSlot = productDetail.availability
            ? JSON.parse(productDetail.availability)
            : null;
          if (meetingSlot) {
            tempSlots = { ...meetingSlot };
            Object.entries(tempSlots).map(([weekDay, timeSlot]) => {
              oneOnOneSelectedWeekDays[weekDay] = true;
            });
          }
          setOneOnOneSlots(tempSlots);
        }
      });
    }
  }, [isOneOnOneMeetingChecked])




  const fetchGlobalEvents = async () => {
    if (source) {
      source.cancel("Landing Component got props changed");
    }
    source = axios.CancelToken.source();

    setShowLoader(true);
    const { timezone } = JSON.parse(localStorage.getItem("sellerData"));
    let startOfWeekTime, endOfWeekTime;
    if (view === "week") {
      startOfWeekTime = moment(selectedDay)
        .startOf("week")
        .format("YYYY-MM-DD HH:mm:ss");
      endOfWeekTime = moment(selectedDay)
        .endOf("week")
        .format("YYYY-MM-DD HH:mm:ss");
    } else if (view === "month") {
      startOfWeekTime = moment(selectedDay)
        .startOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
      endOfWeekTime = moment(selectedDay)
        .endOf("month")
        .format("YYYY-MM-DD HH:mm:ss");
    }
    const response = await dispatch(
      meetingActions.getGlobalMeetingList(
        sellerId,
        filterType,
        startOfWeekTime,
        endOfWeekTime,
        timezone,
        undefined,
        source.token
      )
    );
    if (response && response.success) {
      setEvents(response.data)
      setEventList(
        response.data.map((i) => ({
          ...i,
          start: new Date(i.start.replace(/-/g, "/")),
          end: new Date(i.end.replace(/-/g, "/")),
        }))
      );

      setShowLoader(false);

    }
  };
  const handleFillTime = (event) => {
    // handleOriginalSlotData(event)
    setSelectedSlot(event ? JSON.stringify(event) : null)
    // setEvents(event)
    if (event) {
      let { start, end } = event;
      let diff = moment(start).diff(new Date(), "days");
      if (diff >= 0 && start && end) {
        setEventSelectedDay(new Date(start));
        let startTime = moment(new Date(start), "hh:mm A").format("HH:mm");
        let endTime = moment(new Date(end), "hh:mm A").format("HH:mm");
        formData["start"] = startTime;
        formData["end"] = endTime;
        let type = event.groupType === "group" ? 0 : 1;
        setMeetingType(type);

        setEditMeetingType(0);
        if (event.groupType === "group") {
          setIsGroupMeetingChecked(true);
          setIsOneOnOneMeetingChecked(false);
        } else {
          setIsOneOnOneMeetingChecked(true);
          setIsGroupMeetingChecked(false);
        }
        setOriginalMeetingType(event?.meetingType);

        if (event?.meetingDurationType) {
          setMeetingDurationType(event.meetingDurationType);
          setOriginalMeetingDurationType(event.meetingDurationType);
        } else if (event?.frequency) {
          let meetingTypee = event.frequency.split(",")[0].toLowerCase();
          if (meetingTypee === "bi weekly") meetingTypee = "bi_weekly";
          if (meetingTypee === "one time") meetingTypee = "one_time";

          setMeetingDurationType(meetingTypee);
          setOriginalMeetingDurationType(meetingTypee);
        }
        setZoomMeetingLink(event?.zoomLink);
        setOriginalSlot({ ...formData });
        setFormData({ ...formData });
        if (event.groupType === "group") {
          let groupSessionDuration = event?.sessionDuration
            ? JSON.parse(event?.sessionDuration)
            : null;
          let tempGroupMeetingObj = { ...groupMeetingDetails };
          tempGroupMeetingObj["id"] = event?.meetingId ? event?.meetingId : null;
          tempGroupMeetingObj["meetingName"] = event?.meetingName;
          tempGroupMeetingObj["subscriptionCost"] = event?.subscriptionCost;
          tempGroupMeetingObj["sessionHour"] = groupSessionDuration?.sessionHour;
          tempGroupMeetingObj["sessionMinute"] =
            groupSessionDuration?.sessionMinute;
          tempGroupMeetingObj["groupMeetingFrequency"] = event?.frequency;
          // tempGroupMeetingObj["slots"] = { ...slots };

          if (event?.frequency !== "Monthly") {
            tempGroupMeetingObj["isMonthlyGroupFrequencyChecked"] = false;
          }

          let dayKey;
          dayKey = Object.keys(groupMeetingDetails.isWeekDaySelected)[
            new Date(start).getDay()
          ];
          tempGroupMeetingObj["isWeekDaySelected"][dayKey] = true;
          let tempSlots = {};
          tempSlots = { [dayKey]: [[formData.start, formData.end]] };
          let tempGroupMeetingDetails = { ...tempGroupMeetingObj };
          if (isGroupMeetingChecked) {

            let tempAvailability = event?.availability ? JSON.parse(event?.availability) : null
            let groupMeetingLink = event?.group_meeting_link ? JSON.parse(event?.group_meeting_link) : null;

            tempAvailability && Object.entries(tempAvailability).map(([weekDay, timeslotArray]) => {
              if (weekDay === dayKey) {
                timeslotArray.map((timeslot) => {
                  let startTimeHour = +formData.start.split(':')[0]
                  let startTimeMinute = +formData.start.split(':')[1]
                  let endTimeHour = +formData.end.split(':')[0]
                  let endTimeMinute = +formData.end.split(':')[1]

                  let incStartTimeHour = +timeslot[0].split(':')[0]
                  let incStartTimeMinute = +timeslot[0].split(':')[1]
                  let incEndTimeHour = +timeslot[1].split(':')[0]
                  let incEndTimeMinute = +timeslot[1].split(':')[1]

                  if (startTimeHour == incStartTimeHour && startTimeMinute == incStartTimeMinute && endTimeHour == incEndTimeHour && endTimeMinute == incEndTimeMinute
                    && groupMeetingLink && groupMeetingLink.join_url && groupMeetingLink.start_url) {

                    link = groupMeetingLink.join_url
                    host_link = groupMeetingLink.start_url
                  }
                })
              }
            })

            tempGroupMeetingDetails.slots = {
              [dayKey]: [[formData.start, formData.end]],
            };

            if (tempGroupMeetingDetails?.groupMeetingFrequency?.toLowerCase() === "monthly") {
              if (tempAvailability) {
                tempGroupMeetingDetails["meetingMonthlyEndTime"] = formData.end
                tempGroupMeetingDetails["meetingMonthlyStartTime"] = formData.start
                tempGroupMeetingDetails["meetingMonthlyWeekDay"] = tempAvailability.weekDay
                tempGroupMeetingDetails["meetingMonthlyWeekOfMonth"] = tempAvailability.weekOfMonth
              }
              tempGroupMeetingDetails["isMonthlyGroupFrequencyChecked"] = true
            }
          }
          setOriginalGroupMeetingDetails({ ...tempGroupMeetingDetails })
          setGroupMeetingDetails({ ...tempGroupMeetingDetails });

        }
      } else {
        alertModal({
          message: `You cannot add events to past date!`,
          singleButton: true,
        });
      }
    }
  };

  const handleToolTip = (event) => {
    let { start, end } = event;
    let startTime = moment(new Date(start), "hh:mm A").format("HH:mm");
    let endTime = moment(new Date(end), "hh:mm A").format("HH:mm");
    formData["start"] = startTime;
    formData["end"] = endTime;
    setFormData({ ...formData });

    if (event.type == "free_event") {
      setShowHideFreeSeminarModal(true)
      setSeminarFormData({
        ...seminarFormData,
        note: event.note,
        start: moment(new Date(start), "hh:mm A").format("HH:mm"),
        end: moment(new Date(end), "hh:mm A").format("HH:mm"),
        date: moment(event.start).format("YYYY-MM-DD"),
        name: event.name,
        price: event.price,
        id: event.meetingId,
      })

    }
    else {
      if (new Date(event.start).getTime() < Date.now()) {
        alertModal({
          message: `You cannot add/edit events to past date!`,
          singleButton: true,
        });
        return null;
      }
      setEditMeetingState({
        isOpen: true,
        meetingType: event.meetingType,
        data: { ...event },
      });
      setEventSelectedDay(new Date(event.start));
      formData["start"] = moment(new Date(event.start), "hh:mm A").format(
        "HH:mm"
      );
      formData["end"] = moment(new Date(event.end), "hh:mm A").format("HH:mm");
      setFormData({ ...formData });
      setOriginalMeetingState({
        type: event.meetingType,
        duration: event.meetingDurationType,
        slot: { ...formData },
      });
      setEditMeeting(true);

      handleFillTime(event)
    }
  }

  const handleFreeSeminarModalShowHide = (key) => {
    setIsFreeSeminar(false)
    if (key == 1 || key == 2) {
      setShowHideFreeSeminarModal(false)
    } else {
      setShowHideFreeSeminarModal(false)
      setFormData({
        start: "",
        end: "",
        date: "",
        name: ""
      })
    }
  };

  const handleDateTimeChangeForSeminar = (e, key) => {
    setPopupErrorMessage("")
    if (key == "date") {
      seminarFormData[key] = e ? moment(e).format("YYYY-MM-DD") : "";
    } else {
      seminarFormData[key] = e;
    }
    setSeminarFormData({
      ...seminarFormData
    })
  };

  const handleSelectMeetingChange = (e) => {
    const { value } = e.target;
    setEditMeetingType(+value);
  };
  const handleSelectChange = (e) => {
    const { value } = e.target;

    setGroupMeetingErrorData({});
    setOneOnOneMeetingErrorData({});

    setMeetingDurationType(WEEKLY);
    setMeetingType(+value);
    if (value === 0) {
      setIsGroupMeetingChecked(true);
      setIsOneOnOneMeetingChecked(false);
    } else {
      setIsGroupMeetingChecked(false);
      setIsOneOnOneMeetingChecked(true);
    }
  };
  const handleChangeChk = (e, key, weekDay, frequency) => {
    oneOnOneMeetingErrorData.selectedOneOnOneFrequency = [];
    if (key === "selectedFrequency") {
      let tempFrequencyArray = { ...selectedFrequency };
      tempFrequencyArray[`${frequency}`] = e.target.checked;

      let isAllFrequencySelected = 0;
      Object.entries(tempFrequencyArray)
        .slice(1, 5)
        .map(([freq, bool]) => {
          if (bool) {
            isAllFrequencySelected += 1;
          }
        });

      if (isAllFrequencySelected === 4) {
        tempFrequencyArray["All"] = true;
      } else {
        tempFrequencyArray["All"] = false;
      }

      setSelectedFrequency({ ...tempFrequencyArray });
      return;
    } else if (key === "allFrequencySelected") {
      let tempFrequencyArray = { ...selectedFrequency };
      selectOneOnOneFrequency.map((item) => {
        tempFrequencyArray[item] = e.target.checked;
      });

      setSelectedFrequency({ ...tempFrequencyArray });
    }
  };
  const handleGroupMeetingDetailsChange = (e, key) => {

    let tempGroupMeetingDetails = { ...groupMeetingDetails };
    groupMeetingErrorData[key] = [];
    if (key === "meetingMonthlyStartTime" || key === "meetingMonthlyEndTime") {
      tempGroupMeetingDetails[key] = e;
      setGroupMeetingDetails({ ...tempGroupMeetingDetails });
      if (key === "meetingMonthlyStartTime")
        setFormData({ ...formData, start: e, })
      else if (key === "meetingMonthlyEndTime")
        setFormData({ ...formData, end: e, })

      return;
    }
    tempGroupMeetingDetails[key] = e.target.value;
    setGroupMeetingDetails({ ...tempGroupMeetingDetails });
  };

  const handleSessionHourMinuteTrialSubscriptionCostChange = (e, key) => {
    if (meetingType) {
      oneOnOneMeetingErrorData[key] = [];

      if (key == "sessionHour") {
        setSessionHour(e.target.value);
      } else if (key == "Minute") {
        setSessionMinute(e.target.value);
      } else if (key == "trialCost") {
        setTrialCost(e.target.value);
      } else if (key == "subscriptionCost") {
        setSubscriptionCost(e.target.value);
      }
    } else if (key != "trialCost" && meetingType == 0) {
      groupMeetingErrorData[key] = [];

      let tempGroupMeetingDetails = { ...groupMeetingDetails };
      tempGroupMeetingDetails[key] = e.target.value;

      setGroupMeetingDetails({ ...tempGroupMeetingDetails });
    }
  };
  const handleGroupMeetingFrequencyChange = (e, freq) => {
    let freqType = freq.toLowerCase();
    if (freqType === "bi weekly") freqType = "bi_weekly";
    setMeetingDurationType(freqType);
    let tempGroupMeetingDetails = { ...groupMeetingDetails };
    setGroupMeetingErrorData((prevState) => ({
      ...prevState,
      selectedOneOnOneFrequency: [],
    }));

    tempGroupMeetingDetails["groupMeetingFrequency"] = freq;
    tempGroupMeetingDetails["slots"] = {};
    Object.entries(groupMeetingDetails["isWeekDaySelected"]).map(
      ([weekDay, bool]) => {
        tempGroupMeetingDetails["isWeekDaySelected"][weekDay] = false;
      }
    );
    tempGroupMeetingDetails["isMonthlyGroupFrequencyChecked"] =
      freq == "Monthly" ? true : false;

    tempGroupMeetingDetails["meetingMonthlyStartTime"] = formData.start
    tempGroupMeetingDetails["meetingMonthlyEndTime"] = formData.end
    setGroupMeetingDetails({ ...tempGroupMeetingDetails });
  };
  const handleGroupMeetingMonthlyWeekDayAndWeekChange = (e, key) => {
    groupMeetingErrorData[key] = [];
    let tempGroupMeetingDetails = { ...groupMeetingDetails };
    tempGroupMeetingDetails[key] = e.target.value;
    setGroupMeetingDetails({ ...tempGroupMeetingDetails });
  };

  const modal = () => (
    <Modal
      show={editMeetingState.isOpen && editMeetingState.meetingType !== 3}
      onHide={handleModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={() => handleModalShowHide()}>
          <i className="fas fa-times"></i>
        </span>
        {
          <>
            <div className="join-sub-header">
              {editMeetingState.data.zoomLink && (
                <a href={editMeetingState.data.zoomLink} target="_blank">
                  <button className="orange-btn">Join Meeting</button>
                </a>
              )}
            </div>
          </>
        }
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between bb1 mb-3">
          <div className="mb-2">
            <p className="m-0">Product Name</p>
            <p className="m-0">
              <strong>{editMeetingState.data.title?.split("|")[0]}</strong>
            </p>
          </div>
          {editMeetingState.data.title?.split("|")[2]?.trim() == "group" ? (
            <>
              <div>
                <p className="m-0">Meeting Name</p>
                <p className="m-0">
                  <strong>
                    {editMeetingState.data.title?.split("|")[1]}
                  </strong>
                </p>
              </div>
              <div>
                <p className="m-0">Meeting Type</p>
                <p className="m-0">
                  <strong>
                    {editMeetingState.data?.title
                      ?.split("|")[2]
                      ?.trim()
                      .charAt(0)
                      .toUpperCase() +
                      editMeetingState.data?.title
                        ?.split("|")[2]
                        ?.trim()
                        ?.replace(/_/g, " ")
                        .slice(1)}
                  </strong>
                </p>
              </div>
            </>
          ) : editMeetingState.data.title?.split("|")[2]?.trim() ==
            "one_on_one" ? (
            <>
              <p className="m-0">Meeting Type</p>
              <p className="m-0">
                <strong>
                  {editMeetingState.data?.title
                    ?.split("|")[2]
                    ?.trim()
                    .charAt(0)
                    .toUpperCase() +
                    editMeetingState.data?.title
                      ?.split("|")[2]
                      ?.trim()
                      ?.replace(/_/g, " ")
                      .slice(1)}
                </strong>
              </p>
            </>
          ) : null}
        </div>
        <div className="form-group">
          <label>Edit Meeting</label>

          <select
            value={editMeetingType}
            onChange={handleSelectMeetingChange}
            className="form-control"
          >
            <option value={0}>Recurring</option>
            <option value={1}>one time</option>
          </select>
        </div>
        <div className="details-slot">
          <button
            className="orange-btn"
            onClick={() => setCancelMeeting(false)}
          >
            {!cancelMeeting && <i className="far fa-check-circle"></i>}{" "}
            Reschdule
          </button>
          <button
            className="orange-btn"
            onClick={() => setCancelMeeting(true)}
          // className="orange-outline-btn"
          >
            {cancelMeeting && <i className="far fa-check-circle"></i>} Cancel
          </button>
        </div>

        <div className="details-slot">
          <p>Slot details</p>
          <h5>{moment(new Date(eventSelectedDay)).format("MMMM Do YYYY")}</h5>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label>PRODUCT NAME</label>
              <p>{editMeetingState.data.title?.split("|")[0]}</p>
            </div>

            <div className="form-group">
              <label className="custom-label-product">
                Select Type
                <div className="tooltip-custom">
                  <i
                    className="fa fa-info-circle info-icon"
                    aria-hidden="true"
                  ></i>
                  <div className="together-tooltip">
                    <p>
                      Select The Type You Would Like To Offer
                      <span class="arrow-down"></span>
                    </p>
                  </div>
                </div>
              </label>
              <select
                value={meetingType}
                disabled
                onChange={handleSelectChange}
                className="form-control"
              >
                <option value={0}>Group</option>
                <option value={1}>One-On-One</option>
              </select>
            </div>
          </div>

          {!cancelMeeting && (
            <>
              {meetingType == 1 ? (
                <div className="col-md-12">
                  <div className="form-group mb-1">
                    <label className="custom-label-product">
                      Select frequency
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Select the type of Frequency you would like to offer
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                  {selectOneOnOneFrequency.map((frequency, freqIndex) => {
                    return (
                      <div className="check-outer" key={freqIndex}>
                        <label className="outer-label mb-0">
                          <input
                            type="checkbox"
                            disabled
                            checked={selectedFrequency[frequency]}
                            onChange={(e) =>
                              handleChangeChk(
                                e,
                                frequency == `All`
                                  ? `allFrequencySelected`
                                  : `selectedFrequency`,
                                "",
                                frequency
                              )
                            }
                          />{" "}
                          {frequency}
                          <span class="checkmark" />
                        </label>
                      </div>
                    );
                  })}
                  <p className="form-field-error">
                    {oneOnOneMeetingErrorData?.selectedOneOnOneFrequency &&
                      oneOnOneMeetingErrorData.selectedOneOnOneFrequency[0]}
                  </p>
                </div>
              ) : (
                <div className="col-md-12">
                  <div className="form-group m-0">
                    <label className="custom-label-product">
                      Meeting Name
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Enter The Meeting Name
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>

                    <input
                      className="form-control"
                      type="text"
                      disabled={groupMeetingDetails.id}
                      value={groupMeetingDetails["meetingName"]}
                      onChange={(e) =>
                        handleGroupMeetingDetailsChange(e, "meetingName")
                      }
                    />
                    <p className="form-field-error">
                      {groupMeetingErrorData?.meetingName &&
                        groupMeetingErrorData.meetingName[0]}
                    </p>
                  </div>

                  <div className="form-group mb-1 mt-2">
                    <label className="custom-label-product">
                      Select frequency
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Select the type of Frequency you would like to offer
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>

                  {selectGroupFrequency.map((frequency, freqIndex) => {
                    return (
                      <div className="check-outer" key={freqIndex}>
                        <label className="outer-label mb-0">
                          <input
                            type="checkbox"
                            value={frequency}
                            id={frequency}
                            disabled={groupMeetingDetails.id}
                            checked={
                              groupMeetingDetails["groupMeetingFrequency"] ==
                              frequency
                            }
                            onChange={(e) =>
                              handleGroupMeetingFrequencyChange(e, frequency)
                            }
                          />{" "}
                          {frequency}
                          <span class="checkmark" />
                        </label>
                      </div>
                    );
                  })}
                  <p className="form-field-error">
                    {groupMeetingErrorData?.selectedOneOnOneFrequency &&
                      groupMeetingErrorData.selectedOneOnOneFrequency[0]}
                  </p>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group d-flex mb-0">
                        <label className="custom-label-product">
                          Subscription Cost
                          <div className="tooltip-custom">
                            <i
                              className="fa fa-info-circle info-icon"
                              aria-hidden="true"
                            ></i>
                            <div className="together-tooltip">
                              <p>
                                Enter the cost you would like to charge per
                                recurring session. For Example: A $20/per
                                session cost for a weekly option will create a
                                $80/month subscription offering
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                      <input
                        className="form-control"
                        min={0}
                        onChange={(e) =>
                          handleSessionHourMinuteTrialSubscriptionCostChange(
                            e,
                            "subscriptionCost"
                          )
                        }
                        disabled={groupMeetingDetails.id}
                        value={groupMeetingDetails.subscriptionCost}
                        type="number"
                      />
                      <p className="form-field-error">
                        {groupMeetingErrorData?.subscriptionCost &&
                          groupMeetingErrorData.subscriptionCost[0]}
                      </p>
                    </div>

                    <div className="col-md-8">
                      <div className="form-group d-flex mb-0">
                        <label className="custom-label-product">
                          Session Duration
                          <div className="tooltip-custom">
                            <i
                              className="fa fa-info-circle info-icon"
                              aria-hidden="true"
                            ></i>
                            <div className="together-tooltip">
                              <p>
                                Enter duration you would like to offer per
                                session
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="d-flex ">
                        <label className="d-flex align-items-center mr-2 mb-0">
                          Hours
                        </label>
                        <select
                          className="form-control mr-2"
                          disabled
                          onChange={(e) =>
                            handleSessionHourMinuteTrialSubscriptionCostChange(
                              e,
                              "sessionHour"
                            )
                          }
                          value={groupMeetingDetails.sessionHour}
                        >
                          <option value="Hours" selected disabled>
                            Hours
                          </option>
                          {sessionHours.map((hour, index) => {
                            return (
                              <option key={index} value={hour}>
                                {hour}
                              </option>
                            );
                          })}
                        </select>
                        <label className="d-flex align-items-center mr-2 mb-0">
                          Minutes
                        </label>
                        <select
                          className="form-control "
                          disabled={groupMeetingDetails.id}
                          onChange={(e) =>
                            handleSessionHourMinuteTrialSubscriptionCostChange(
                              e,
                              "sessionMinute"
                            )
                          }
                          value={groupMeetingDetails.sessionMinute}
                        >
                          <option value="Minutes" selected disabled>
                            Minutes
                          </option>
                          {sessionMinutes.map((minute, index) => {
                            return (
                              <option key={index} value={minute}>
                                {minute}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <p className="form-field-error">
                        {groupMeetingErrorData?.sessionHour &&
                          groupMeetingErrorData.sessionHour[0]}
                      </p>
                    </div>
                  </div>

                  {groupMeetingDetails["isMonthlyGroupFrequencyChecked"] && (
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-0 d-flex">
                            <label className="custom-label-product">
                              Week
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Select the week number
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="w-100">
                              <select
                                className="form-control"
                                value={
                                  groupMeetingDetails[
                                  "meetingMonthlyWeekOfMonth"
                                  ]
                                }
                                onChange={(e) =>
                                  handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                    e,
                                    "meetingMonthlyWeekOfMonth"
                                  )
                                }
                                disabled={groupMeetingDetails.id}
                              >
                                <option value="" selected disabled>
                                  --Select--
                                </option>
                                {weekOfMonth.map((week, index) => {
                                  return (
                                    <option value={week} key={index}>
                                      {week}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <p className="form-field-error">
                            {groupMeetingErrorData?.meetingMonthlyWeekOfMonth &&
                              groupMeetingErrorData
                                .meetingMonthlyWeekOfMonth[0]}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group d-flex m-0">
                            <label className="custom-label-product">
                              Week Day
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Select the day of the week
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="w-100">
                              <select
                                className="form-control"
                                value={
                                  groupMeetingDetails["meetingMonthlyWeekDay"]
                                }
                                onChange={(e) =>
                                  handleGroupMeetingMonthlyWeekDayAndWeekChange(
                                    e,
                                    "meetingMonthlyWeekDay"
                                  )
                                }
                                disabled={groupMeetingDetails.id}
                              >
                                <option value="" selected disabled>
                                  --Select--
                                </option>
                                {Object.entries(weekDays).map(
                                  ([key, weekDayVal], index) => {
                                    return (
                                      <option value={weekDayVal} key={index}>
                                        {weekDayVal}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>
                          <p className="form-field-error">
                            {groupMeetingErrorData?.meetingMonthlyWeekDay &&
                              groupMeetingErrorData.meetingMonthlyWeekDay[0]}
                          </p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group d-flex m-0">
                            <label className="custom-label-product">
                              Time
                              <div className="tooltip-custom">
                                <i
                                  className="fa fa-info-circle info-icon"
                                  aria-hidden="true"
                                ></i>
                                <div className="together-tooltip">
                                  <p>
                                    Enter The Time You Would Like To Offer
                                    <span class="arrow-down"></span>
                                  </p>
                                </div>
                              </div>
                            </label>
                            <div className="d-flex align-items-center">
                              <div className="mr-2">
                                <TimePicker
                                  value={
                                    groupMeetingDetails[
                                    "meetingMonthlyStartTime"
                                    ]
                                  }
                                  format="hh:mm a"
                                  name="start"
                                  disableClock={true}
                                  onChange={(e) =>
                                    handleGroupMeetingDetailsChange(
                                      e,
                                      "meetingMonthlyStartTime"
                                    )
                                  }
                                  required={true}
                                  clearIcon={null}
                                  className="form-control"
                                />
                              </div>
                              <p className="mb-0 mx-2">To</p>
                              <div className="ml-2">
                                <TimePicker
                                  value={
                                    groupMeetingDetails["meetingMonthlyEndTime"]
                                  }
                                  format="hh:mm a"
                                  name="start"
                                  disableClock={true}
                                  onChange={(e) =>
                                    handleGroupMeetingDetailsChange(
                                      e,
                                      "meetingMonthlyEndTime"
                                    )
                                  }
                                  required={true}
                                  clearIcon={null}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <p className="form-field-error">
                            {groupMeetingErrorData?.meetingMonthlyStartTime &&
                              groupMeetingErrorData.meetingMonthlyStartTime[0]}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}

              {meetingType === 1 ? (
                <>
                  <div className="col-md-6">
                    <div className="form-group d-flex mb-0">
                      <label className="custom-label-product">
                        Trial or 1-time cost
                        <div className="tooltip-custom">
                          <i
                            className="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div className="together-tooltip">
                            <p>
                              Enter the cost you would like to charge for a
                              trial or one time meeting
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <input
                      className="form-control"
                      min={0}
                      disabled
                      onChange={(e) =>
                        handleSessionHourMinuteTrialSubscriptionCostChange(
                          e,
                          "trialCost"
                        )
                      }
                      value={trialCost}
                      type="number"
                    />
                    <p className="form-field-error">
                      {oneOnOneMeetingErrorData?.trialCost &&
                        oneOnOneMeetingErrorData.trialCost[0]}
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group d-flex mb-0">
                      <label className="custom-label-product">
                        Subscription Cost
                        <div className="tooltip-custom">
                          <i
                            className="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div className="together-tooltip">
                            <p>
                              Enter the cost you would like to charge per
                              recurring session. For Example: A $20/per session
                              cost for a weekly option will create a $80/month
                              subscription offering
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <input
                      className="form-control"
                      min={0}
                      disabled
                      onChange={(e) =>
                        handleSessionHourMinuteTrialSubscriptionCostChange(
                          e,
                          "subscriptionCost"
                        )
                      }
                      value={subscriptionCost}
                      type="number"
                    />
                    <p className="form-field-error">
                      {oneOnOneMeetingErrorData?.subscriptionCost &&
                        oneOnOneMeetingErrorData.subscriptionCost[0]}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group d-flex mb-0">
                      <label className="custom-label-product">
                        Session Duration
                        <div className="tooltip-custom">
                          <i
                            className="fa fa-info-circle info-icon"
                            aria-hidden="true"
                          ></i>
                          <div className="together-tooltip">
                            <p>
                              Enter duration you would like to offer per session
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="d-flex">
                      <label className="d-flex align-items-center mr-2 mb-0">
                        Hours
                      </label>
                      <select
                        className="form-control mr-2"
                        disabled
                        onChange={(e) =>
                          handleSessionHourMinuteTrialSubscriptionCostChange(
                            e,
                            "sessionHour"
                          )
                        }
                        value={sessionHour}
                      >
                        <option value="Hours" selected disabled>
                          Hours
                        </option>
                        {sessionHours.map((hour, index) => {
                          return (
                            <option key={index} value={hour}>
                              {hour}
                            </option>
                          );
                        })}
                      </select>
                      <label className="d-flex align-items-center mr-2 mb-0">
                        Minutes
                      </label>
                      <select
                        className="form-control "
                        disabled
                        onChange={(e) =>
                          handleSessionHourMinuteTrialSubscriptionCostChange(
                            e,
                            "Minute"
                          )
                        }
                        value={sessionMinute}
                      >
                        <option value="Minutes" selected disabled>
                          Minutes
                        </option>
                        {sessionMinutes.map((minute, index) => {
                          return (
                            <option key={index} value={minute}>
                              {minute}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <p className="form-field-error">
                      {oneOnOneMeetingErrorData?.sessionHour &&
                        oneOnOneMeetingErrorData.sessionHour[0]}
                    </p>
                  </div>
                </>
              ) : null}
            </>
          )}

          {!cancelMeeting &&
            !groupMeetingDetails["isMonthlyGroupFrequencyChecked"] && (
              <>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="custom-label-product">
                      START TIME
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Enter The Start Time
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                    <div className="form-relative">
                      <TimePicker
                        value={formData["start"]}
                        format="hh:mm a"
                        name="start"
                        disableClock={true}
                        onChange={(e) => handleChange(e, "start")}
                        required={true}
                        clearIcon={null}
                        className="form-control"
                      />
                      <span className="clock-icon icon-unique-clock">
                        <i class="far fa-clock"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-0">
                    <label className="custom-label-product">
                      END TIME
                      <div className="tooltip-custom">
                        <i
                          className="fa fa-info-circle info-icon"
                          aria-hidden="true"
                        ></i>
                        <div className="together-tooltip">
                          <p>
                            Enter The End Time
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </label>
                    <div className="form-relative">
                      <TimePicker
                        value={formData["end"]}
                        format="hh:mm a"
                        name="end"
                        disableClock={true}
                        onChange={(e) => handleChange(e, "end")}
                        required={true}
                        clearIcon={null}
                        className="form-control"
                      />
                      <span className="clock-icon icon-unique-clock">
                        <i class="far fa-clock"></i>
                      </span>
                    </div>
                  </div>
                  <p className="form-field-error">
                    {isOneOnOneMeetingChecked
                      ? oneOnOneMeetingErrorData?.selectedOneOnOneMeetingSlots &&
                      oneOnOneMeetingErrorData.selectedOneOnOneMeetingSlots[0]
                      : groupMeetingErrorData?.selectedOneOnOneMeetingSlots &&
                      groupMeetingErrorData.selectedOneOnOneMeetingSlots[0]}
                  </p>
                </div>
              </>
            )}
          {cancelMeeting && (
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  <b>Add note</b>
                </label>
                <textarea rows="4" className="form-control" />
                <span style={{ fontSize: "12px" }}>
                  Are you sure you want to cancel?
                </span>
              </div>
              <div className="button-center">
                <button
                  onClick={handleDeleteRecurringMeeting}
                  className="orange-btn"
                >
                  Yes
                </button>
                <button
                  onClick={() => handleModalShowHide()}
                  className="orange-outline-btn"
                >
                  No
                </button>
              </div>
            </div>
          )}
          <div className="button-center w-100">
            {!cancelMeeting && (
              <button onClick={() => createNewEvent()} className="orange-btn">
                Save Changes
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  const oneOnOneMeetingDetailModal = () =>
    <Modal
      show={editMeetingState.isOpen && editMeetingState.meetingType === 3}
      centered
      backdrop="static"
      className="calendar-modal"
    >
      <Modal.Header
        className="calendar-header"
      >
        <span onClick={() => handleModalShowHide()}>
          <i className="fas fa-times"></i>
        </span>
        <br />
        <div className="join-sub-header">
          <div>
            {/*<p><b>Edit Meeting</b></p>*/}
            <p>Product Name</p>
            <p>
              <strong>{editMeetingState.data.title}</strong>
            </p>
          </div>
          <a href={editMeetingState.data.zoomLink} target="_blank">
            <button className="orange-btn">Join Meeting</button>
          </a>
        </div>
      </Modal.Header>
      {editMeetingState.data.scheduleType && (
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Meeting Type</label>
                  <p>One on One Meeting</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Meeting Detail</label>
                  <p>{editMeetingState.data?.meetingDurationType?.replace("_", " ")}</p>
                </div>
              </div>
            </div>
          </>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="mr-2">
                  <b>START TIME</b>
                </label>
                <TimePicker
                  value={formData["start"]}
                  format="hh:mm a"
                  name="start"
                  disableClock={true}
                  onChange={(e) => handleChange(e, "start")}
                  required={true}
                  disabled={true}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label className="mr-2">
                  <b>END TIME</b>
                </label>
                <TimePicker
                  value={formData["end"]}
                  format="hh:mm a"
                  name="end"
                  disableClock={true}
                  onChange={(e) => handleChange(e, "end")}
                  disabled={true}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>User</label>
                <p>{editMeetingState.data.userName}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>Meeting id</label>
                <p><Link to={`/one-on-one-meetings/${editMeetingState?.data.meetingId}`}>{editMeetingState?.data.meetingId}</Link></p>
              </div>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>

  const cancelInviteMeeting = async () => {
    setDisableEditMeetingButton(true);
    await dispatch(cancelGroupInviteMeeting(editMeetingState?.data.meetingId));
    setTimeout(() => {
      fetchGlobalEvents();
    }, 2 * 1000);
    handleModalShowHide();
    setDisableEditMeetingButton(false);
  };

  const updateInviteMeeting = async () => {
    setDisableEditMeetingButton(true);
    const eventSelectedDayString = moment(new Date(eventSelectedDay)).format(
      "MM-DD-YYYY"
    );
    let selectedStartTimeStamp = new Date(
      `${eventSelectedDayString} ${formData["start"]}`
    ).getTime();

    let selectedEndTimeStamp = new Date(
      `${eventSelectedDayString} ${formData["end"]}`
    ).getTime();
    if (selectedStartTimeStamp > selectedEndTimeStamp) {
      alertModal({
        message: "Please select time correctly",
        singleButton: true,
      });
    } else if (selectedEndTimeStamp - selectedStartTimeStamp < 60000 * 5) {
      alertModal({
        message:
          "Minimum 5 mins difference required between meeting start time and end time !",
        singleButton: true,
      });
    } else {
      let data = {
        meetingDate: moment(new Date(eventSelectedDay)).format("YYYY-MM-DD"),
        meetingStartTime: formData["start"],
        meetingEndTime: formData["end"],
        meetingId: editMeetingState.data.meetingId,
        validate: true,
      };
      await dispatch(updateGroupInviteMeeting(data, sellerId));
      setCreateMeetingState(data);
      setApiTracker("updateGroupInviteMeeting");
      setTimeout(() => {
        fetchGlobalEvents();
      }, 2 * 1000);
      handleModalShowHide();
    }
    setDisableEditMeetingButton(false);
  };

  const handleChange = (e, name) => {

    formData[name] = e ? e : "";
    setFormData({ ...formData });
  };
  const handleDeleteRecurringMeeting = async () => {
    let frequency = [];
    if (isOneOnOneMeetingChecked) {
      selectOneOnOneFrequency.map((freq, freqIndex) => {
        if (selectedFrequency[freq]) {
          let freqType = freq.toLowerCase();
          if (freqType === "bi weekly") freqType = "bi_weekly";
          if (freqType === "one time") freqType = "one_time";
          frequency.push(freqType);
        }
      });
    }
    if (isGroupMeetingChecked) {
      selectOneOnOneFrequency.map((freq) => {
        if (groupMeetingDetails.groupMeetingFrequency === freq) {
          let freqType = freq.toLowerCase();
          if (freqType === "bi weekly") freqType = "bi_weekly";
          frequency.push(freqType);
        }
      });
    }
    setCancelMeeting(false);
    const eventSelectedDayStr =
      eventSelectedDay.getFullYear() +
      "-" +
      (eventSelectedDay.getMonth() + 1) +
      "-" +
      eventSelectedDay.getDate();
    const body = {
      seller_id: `${sellerId}`,
      product_id: editMeetingState.data.productId,
      slot: [formData],
      day: days[new Date(eventSelectedDay).getDay()],
      date: eventSelectedDayStr,
      originalSlot: [originalMeetingState.slot],
      meetingType: editMeetingType ? editMeetingType : editMeetingState.data.meetingType,
      reschdule_id: editMeetingState.data?.meetingId,
      originalMeetingType: originalMeetingState.type,
      meetingDurationType: editMeetingState.data.meetingDurationType,
      originalMeetingDurationType: originalMeetingState.duration,
      isFormCalendar: true,
      frequency: frequency,
      structureId: editMeetingState?.data?.structure_id,
    }
    const res = await dispatch(validateCancelSlot(body))
    if (res?.isError) {
      handleModalShowHide()
      return await new Promise((resolve, reject) =>
        confirmAlert({
          title: "",
          message: `${res.message.includes("Cancelled") ? res.message : `${res.message}, Do you wish to cancel ?`}`,
          buttons: res.message.includes("Cancelled") ?
            [
              {
                label: "Ok",
                onClick: () => { resolve(false) },
              }
            ] :
            [
              {
                label: "Yes",
                onClick: () => {

                  dispatch(
                    deleteSelectedEvent({
                      seller_id: `${sellerId}`,
                      product_id: editMeetingState.data.productId,
                      slot: [formData],
                      day: days[new Date(eventSelectedDay).getDay()],
                      date: eventSelectedDayStr,
                      originalSlot: [originalMeetingState.slot],
                      meetingType: editMeetingType ? editMeetingType : editMeetingState.data.meetingType,
                      reschdule_id: editMeetingState.data?.meetingId,
                      originalMeetingType: originalMeetingState.type,
                      meetingDurationType: editMeetingState.data.meetingDurationType,
                      originalMeetingDurationType: originalMeetingState.duration,
                      isFormCalendar: true,
                      frequency: frequency,
                      isRunningSubscriptionCancelled: 1,
                      structureId: editMeetingState?.data?.structure_id
                    })
                  );
                  handleModalShowHide();
                  fetchGlobalEvents();
                  resolve(true)
                },
              },
              {
                label: "No",
                onClick: () => { resolve(false) },
              },
            ],
        })
      )
    }
    else {
      await dispatch(
        deleteSelectedEvent({
          seller_id: `${sellerId}`,
          product_id: editMeetingState.data.productId,
          slot: [formData],
          day: days[new Date(eventSelectedDay).getDay()],
          date: eventSelectedDayStr,
          originalSlot: [originalMeetingState.slot],
          meetingType: editMeetingType ? editMeetingType : editMeetingState.data.meetingType,
          reschdule_id: editMeetingState.data?.meetingId,
          structureId: editMeetingState?.data?.structure_id,
          originalMeetingType: originalMeetingState.type,
          meetingDurationType: editMeetingState.data.meetingDurationType,
          originalMeetingDurationType: originalMeetingState.duration,
          isFormCalendar: true,
          frequency: frequency,
        })
      );
      handleModalShowHide();
      fetchGlobalEvents();
    }
  };
  const handleModalShowHide = () => {
    setEditMeetingState({ isOpen: false, data: {} });
    setOriginalMeetingState({
      type: "",
      duration: "",
      slot: {},
    });
    setCancelMeeting(false);
    setFormData({ start: "", end: "" });
  };

  const alertModal = (data) => {
    setAlertState({ ...data, open: true });
  };


  const calculateHourMinuteDiff = (
    startEndTimeArray,
    sessionHour,
    sessionMinute
  ) => {
    let numberOfSlots = null;
    let sessionDuration = 1;
    if (startEndTimeArray[0] && startEndTimeArray[1]) {
      let startTimeHour = startEndTimeArray[0].split(":")[0];
      let startTimeMinute = startEndTimeArray[0].split(":")[1];
      let endTimeHour = startEndTimeArray[1].split(":")[0];
      let endTimeMinute = startEndTimeArray[1].split(":")[1];

      const start = new Date("August 19, 1975 23:15:30");
      const end = new Date("August 19, 1975 23:15:30");
      start.setMinutes(startTimeMinute);
      start.setHours(startTimeHour);

      end.setMinutes(endTimeMinute);
      end.setHours(endTimeHour);

      let timeDifferenceFromDates = end - start;

      if (timeDifferenceFromDates < 0) {
        return numberOfSlots;
      }

      let timeSlotDiff = parseFloat(
        (timeDifferenceFromDates * (0.001 / 3600)).toFixed(2)
      );
      sessionDuration =
        parseInt(sessionHour) + parseFloat((sessionMinute / 60).toFixed(2));
      numberOfSlots = timeSlotDiff / sessionDuration;
    }
    return numberOfSlots;
  };

  const validateMeetingInfo = () => {
    let isFormValid = true;
    if (isOneOnOneMeetingChecked) {
      const slotsNumber = calculateHourMinuteDiff(
        [formData["start"], formData["end"]],
        sessionHour,
        sessionMinute
      );

      if (!!!slotsNumber || !!!isInteger(slotsNumber)) {
        isFormValid = false;
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          selectedOneOnOneMeetingSlots: [
            "The selected slot should be as per the session duration",
          ],
        }));
      } else {
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          selectedOneOnOneMeetingSlots: [],
        }));
      }

      let notSelectedOneOnOneFrequencyCount = 0;

      Object.entries(selectedFrequency)
        .slice(1, 5)
        .map(([freq, bool]) => {
          if (!bool) {
            notSelectedOneOnOneFrequencyCount += 1;
          }
          if (notSelectedOneOnOneFrequencyCount == 4) {
            isFormValid = false;
            setOneOnOneMeetingErrorData((prevState) => ({
              ...prevState,
              selectedOneOnOneFrequency: ["Please enter frequency"],
            }));
          }
        });

      if (trialCost == 0) {
        isFormValid = false;
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          trialCost: ["Please enter trial cost"],
        }));
      }

      if (subscriptionCost == 0) {
        isFormValid = false;
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          subscriptionCost: ["Please enter subscription cost"],
        }));
      }

      if (sessionHour === "00" && sessionMinute === "00") {
        isFormValid = false;
        setOneOnOneMeetingErrorData((prevState) => ({
          ...prevState,
          sessionHour: ["Please enter session duration"],
        }));
      }
    }

    if (isGroupMeetingChecked) {
      if (!groupMeetingDetails["meetingName"].length) {
        isFormValid = false;
        setGroupMeetingErrorData((prevState) => ({
          ...prevState,
          meetingName: ["Enter meeting name"],
        }));
      }
      if (!groupMeetingDetails["subscriptionCost"]) {
        isFormValid = false;
        setGroupMeetingErrorData((prevState) => ({
          ...prevState,
          subscriptionCost: ["Enter subscription cost"],
        }));
      }
      if (
        groupMeetingDetails["sessionHour"] == "00" &&
        groupMeetingDetails["sessionMinute"] == "00"
      ) {
        isFormValid = false;
        setGroupMeetingErrorData((prevState) => ({
          ...prevState,
          sessionHour: ["Enter session duration"],
        }));
      }

      const slotsNumber = calculateHourMinuteDiff(
        [
          groupMeetingDetails["isMonthlyGroupFrequencyChecked"]
            ? (groupMeetingDetails.meetingMonthlyStartTime,
              groupMeetingDetails.meetingMonthlyEndTime)
            : formData["start"],
          formData["end"],
        ],
        groupMeetingDetails.sessionHour,
        groupMeetingDetails.sessionMinute
      );

      if (!groupMeetingDetails["isMonthlyGroupFrequencyChecked"]) {
        if (!!!slotsNumber || !!!isInteger(slotsNumber)) {
          isFormValid = false;
          setGroupMeetingErrorData((prevState) => ({
            ...prevState,
            selectedOneOnOneMeetingSlots: [
              "The selected slot should be as per the session duration",
            ],
          }));
        } else {
          setGroupMeetingErrorData((prevState) => ({
            ...prevState,
            selectedOneOnOneMeetingSlots: [],
          }));
        }
      }

      if (!groupMeetingDetails["groupMeetingFrequency"].length) {
        isFormValid = false;
        setGroupMeetingErrorData((prevState) => ({
          ...prevState,
          selectedOneOnOneFrequency: ["Select group frequency"],
        }));
      }

      if (groupMeetingDetails["isMonthlyGroupFrequencyChecked"]) {
        if (!groupMeetingDetails["isMonthlyGroupFrequencyChecked"]) {
          let notSelectedGroupWeekDayCount = 0;
          Object.entries(groupMeetingDetails["isWeekDaySelected"]).map(
            ([weekDay, bool]) => {
              if (!bool) {
                notSelectedGroupWeekDayCount += 1;
              }
              if (notSelectedGroupWeekDayCount == 7) {
                isFormValid = false;
                setGroupMeetingErrorData((prevState) => ({
                  ...prevState,
                  selectedWeekDay: ["Select week day"],
                }));
              }
            }
          );
        } else {
          if (!groupMeetingDetails["meetingMonthlyWeekDay"]) {
            isFormValid = false;
            setGroupMeetingErrorData((prevState) => ({
              ...prevState,
              meetingMonthlyWeekDay: ["Select week day"],
            }));
          }

          if (!groupMeetingDetails["meetingMonthlyWeekOfMonth"]) {
            isFormValid = false;
            setGroupMeetingErrorData((prevState) => ({
              ...prevState,
              meetingMonthlyWeekOfMonth: ["Select month week"],
            }));
          }

          const slotsNumber = calculateHourMinuteDiff(
            [
              groupMeetingDetails["meetingMonthlyStartTime"],
              groupMeetingDetails["meetingMonthlyEndTime"],
            ],
            groupMeetingDetails.sessionHour,
            groupMeetingDetails.sessionMinute
          );

          if (
            (!groupMeetingDetails["meetingMonthlyEndTime"].split(":")[0] &&
              !groupMeetingDetails["meetingMonthlyEndTime"].split(":")[1]) ||
            (!groupMeetingDetails["meetingMonthlyStartTime"].split(":")[0] &&
              !groupMeetingDetails["meetingMonthlyStartTime"].split(":")[1]) ||
            !slotsNumber ||
            !isInteger(slotsNumber)
          ) {
            isFormValid = false;
            setGroupMeetingErrorData((prevState) => ({
              ...prevState,
              meetingMonthlyStartTime: ["Select valid time"],
            }));
          } else {
            setGroupMeetingErrorData((prevState) => ({
              ...prevState,
              meetingMonthlyStartTime: [],
            }));
          }
        }
      }
    }
    return isFormValid;
  };

  const getDatesArrayOccuringOnSameDayOfMonth = (day, eventSelectedDay) => {
    try {
      // day is long day string
      let dateArray = [];
      const date = moment(new Date(eventSelectedDay)).format('YYYY-MM-DD')
      let weekDay = moment(date).startOf("month").day(day);
      if (weekDay.date() > 7) weekDay.add(7, "d");
      let month = weekDay.month();
      while (month === weekDay.month()) {
        dateArray.push(
          moment(weekDay.year().toString() +
            "-" +
            (weekDay.month() + 1).toString() +
            "-" +
            weekDay.date().toString(), "YYYY-MM-DD").format("YYYY-MM-DD"));
        weekDay.toString();
        weekDay.add(7, "d");
      }
      return dateArray

    } catch (err) {
      return err
    }
  }

  const createNewEvent = async () => {
    if (validateMeetingInfo()) {
      let dayKey;
      let tempWeekSelected = { ...isWeekDaySelected };
      setIsWeekDaySelected({ ...tempWeekSelected })
      let tempSlotsToBeShownOnCalendar = events
      if (isGroupMeetingChecked) {
        let selectedDateObj = eventSelectedDay
        let startTime = formData["start"]
        let endTime = formData["end"]
        if (groupMeetingDetails.groupMeetingFrequency.toLowerCase() == "monthly") {
          let dateArray = getDatesArrayOccuringOnSameDayOfMonth(groupMeetingDetails.meetingMonthlyWeekDay, eventSelectedDay)
          let weekDate = groupMeetingDetails.meetingMonthlyWeekOfMonth.toLowerCase() == "first"
            ? dateArray[0]
            : groupMeetingDetails.meetingMonthlyWeekOfMonth.toLowerCase() == "second"
              ? dateArray[1]
              : dateArray[2]
          let tempDate = new Date(weekDate)
          selectedDateObj = tempDate
          startTime = groupMeetingDetails.meetingMonthlyStartTime
          endTime = groupMeetingDetails.meetingMonthlyEndTime
        }
        let sessionDuration = {
          sessionHour: groupMeetingDetails.sessionHour,
          sessionMinute: groupMeetingDetails.sessionMinute,
        };
        let freqType =
          groupMeetingDetails.groupMeetingFrequency.toLowerCase();
        if (freqType === "bi weekly") freqType = "bi_weekly";
        if (freqType === "one time") freqType = "one_time";
        try {
          tempSlotsToBeShownOnCalendar.push({
            start: new Date(`${moment(new Date(selectedDateObj)).format("YYYY-MM-DD")} ${startTime}`),
            end: new Date(`${moment(new Date(selectedDateObj)).format("YYYY-MM-DD")} ${endTime}`),
            meetingName: groupMeetingDetails.meetingName,
            sessionHour: groupMeetingDetails.sessionHour,
            sessionMinute: groupMeetingDetails.sessionMinute,
            sessionDuration: sessionDuration ? JSON.stringify(sessionDuration) : null,
            subscriptionCost: groupMeetingDetails.subscriptionCost,
            availability: groupMeetingDetails.groupMeetingFrequency?.toLowerCase() == "monthly" ? JSON.stringify({
              endTime: groupMeetingDetails?.meetingMonthlyEndTime,
              startTime: groupMeetingDetails?.meetingMonthlyStartTime,
              weekDay: groupMeetingDetails?.meetingMonthlyWeekDay,
              weekOfMonth: groupMeetingDetails?.meetingMonthlyWeekOfMonth
            }) : null,
            frequency: groupMeetingDetails.groupMeetingFrequency,
            groupType: meetingType ? "one_on_one" : "group",
            meetingDurationType: freqType,
            type: freqType
          })
          setEvents(...tempSlotsToBeShownOnCalendar)

        }

        catch (error) {
          console.log("error", error)
        }

      }
      if (isOneOnOneMeetingChecked && meetingType == 1) {
        let sessionDuration = {
          sessionHour,
          sessionMinute
        }
        Object.entries(selectedFrequency).map(([key, boolVal]) => {
          if (boolVal && key !== "All") {
            let freqType = key.toLowerCase()
            if (freqType === "bi weekly") freqType = "bi_weekly";
            if (freqType === "one time") freqType = "one_time";
            let tempEventSelectedDayStart = new Date(eventSelectedDay)
            let tempEventSelectedDayEnd = new Date(eventSelectedDay)
            tempEventSelectedDayStart.setHours(formData["start"].split(':')[0])
            tempEventSelectedDayStart.setMinutes(formData["start"].split(':')[1])
            tempEventSelectedDayEnd.setHours(formData["end"].split(':')[0])
            tempEventSelectedDayEnd.setMinutes(formData["end"].split(':')[1])
            tempSlotsToBeShownOnCalendar.push({
              start: tempEventSelectedDayStart,
              end: tempEventSelectedDayEnd,
              trialCost,
              subscriptionCost,
              sessionDuration: sessionDuration ? JSON.stringify(sessionDuration) : null,
              meetingDurationType: freqType,
              type: freqType,
              groupType: "one_on_one"
            })
          }
        })

        setEvents(...tempSlotsToBeShownOnCalendar)
      }

      if (isOneOnOneMeetingChecked && meetingType == 1) {
        dayKey = Object.keys(isWeekDaySelected)[eventSelectedDay.getDay()];
        tempWeekSelected[dayKey] = true;
        setIsWeekDaySelected({ ...tempWeekSelected });
      } else {
        dayKey = Object.keys(groupMeetingDetails.isWeekDaySelected)[
          eventSelectedDay.getDay()
        ];
        let tempGroupDetails = { ...groupMeetingDetails };
        tempGroupDetails["isWeekDaySelected"][dayKey] = true;
        setGroupMeetingDetails(tempGroupDetails);
      }

      let tempGroupMeetingDetails = { ...groupMeetingDetails };
      // let tempAllGroupMeetingDetails = [];
      if (isGroupMeetingChecked) {
        tempGroupMeetingDetails.slots = {
          [dayKey]: [[formData.start, formData.end, link, host_link]],
        };

        setGroupMeetingDetails(tempGroupMeetingDetails);
      }

      const dateOfEvent = new Date(eventSelectedDay).getDate();

      let selectedStartTimeStamp = new Date(
        `${moment(new Date(eventSelectedDay)).format("MM-DD-YYYY")} ${formData["start"]
        }`
      ).getTime();

      let selectedEndTimeStamp = new Date(
        `${moment(new Date(eventSelectedDay)).format("MM-DD-YYYY")} ${formData["end"]
        }`
      ).getTime();

      if (dateOfEvent > 28 && meetingDurationType === MONTHLY) {
        alertModal({
          message: `Some months have fewer than ${dateOfEvent} days, so meeting will appear at end date of month.`,
          singleButton: true,
        });
      }

      if (selectedStartTimeStamp > selectedEndTimeStamp) {
        alertModal({
          message: "Please select time correctly",
          singleButton: true,
        });
      } else if (selectedEndTimeStamp - selectedStartTimeStamp < 60000 * 5) {
        alertModal({
          message:
            "Minimum 5 mins difference required between meeting start time and end time !",
          singleButton: true,
        });
      } else {
        let SelectedDateList = events.filter(
          (item) =>
            moment(new Date(item.start)).format("MM-DD-YYYY") ===
            moment(new Date(eventSelectedDay)).format("MM-DD-YYYY")
        );
        let isError = false;

        if (editMeeting) {

          for (const event of SelectedDateList) {
            if (
              isGroupMeetingChecked &&
              groupMeetingDetails.id === event.meetingId
            ) {
              let sessionDuration = event?.sessionDuration
                ? JSON.parse(event?.sessionDuration)
                : null;

              let slotsNumber = calculateHourMinuteDiff(
                [formData["start"], formData["end"]],
                sessionDuration?.sessionHour,
                sessionDuration?.sessionMinute
              );
              let startTimeStamp = new Date(event.start).getTime();
              let endTimeStamp = new Date(event.end).getTime();
              if (!!!slotsNumber || !!!isInteger(slotsNumber)) {
                isError = true;
                alertModal({
                  message:
                    "Please select time slots according to session duration",
                  singleButton: true,
                });
                return;
              }
              if (
                selectedStartTimeStamp >= startTimeStamp &&
                selectedStartTimeStamp <= endTimeStamp
              ) {
                isError = true;
                break;
              } else if (
                startTimeStamp >= selectedStartTimeStamp &&
                startTimeStamp <= selectedEndTimeStamp
              ) {
                isError = true;
                break;
              }
              if (
                selectedEndTimeStamp <= endTimeStamp &&
                selectedEndTimeStamp >= startTimeStamp
              ) {
                isError = true;
                break;
              } else if (
                endTimeStamp <= selectedEndTimeStamp &&
                endTimeStamp >= selectedStartTimeStamp
              ) {
                isError = true;
                break;
              }
            }
          }
        }

        const eventSelectedDayStr =
          eventSelectedDay.getFullYear() +
          "-" +
          (eventSelectedDay.getMonth() + 1) +
          "-" +
          eventSelectedDay.getDate();



        if (!editMeeting) {
          setGroupMeetingErrorData((prevState) => ({
            ...prevState,
            selectedOneOnOneFrequency: [],
          }));

        } else {

          let frequency = [];
          if (isOneOnOneMeetingChecked) {
            selectOneOnOneFrequency.map((freq) => {
              if (selectedFrequency[freq]) {
                let freqType = freq.toLowerCase();
                if (freqType === "bi weekly") freqType = "bi_weekly";
                if (freqType === "one time") freqType = "one_time";
                frequency.push(freqType);
              }
            });
          }
          if (isGroupMeetingChecked) {
            selectOneOnOneFrequency.map((freq) => {
              if (groupMeetingDetails.groupMeetingFrequency === freq) {
                let freqType = freq.toLowerCase();
                if (freqType === "bi weekly") freqType = "bi_weekly";
                frequency.push(freqType);
              }
            });
          }
          let body = {
            seller_id: `${sellerId}`,
            product_id: editMeetingState.data.productId,
            slot: [formData],
            day: days[new Date(eventSelectedDay).getDay()],
            date: eventSelectedDayStr,
            originalSlot: [originalMeetingState.slot],
            meetingType: editMeetingType ? editMeetingType : editMeetingState.data.meetingType,
            reschdule_id: editMeetingState.data?.meetingId,
            structureId: editMeetingState?.data?.structure_id,
            originalMeetingType: originalMeetingState.type,
            meetingDurationType: editMeetingState.data.meetingDurationType,
            originalMeetingDurationType: originalMeetingState.duration,
            validate: true,
            meetingId: editMeetingState.data.meetingId,
            frequency: frequency,
            isFormCalendar: true,
          };

          let tempEventList = [...events]
          tempEventList.map((item, index) => {
            let tempItem = item ? JSON.stringify(item) : null
            let tempSelectedSlot = selectedSlot ? JSON.parse(selectedSlot) : null
            let startItem = new Date(item.start).getTime()
            let endItem = new Date(item.end).getTime()
            let startSelectedSlot = new Date(tempSelectedSlot.start).getTime()
            let endSelectedSlot = new Date(tempSelectedSlot.end).getTime()
            if (tempItem === selectedSlot || (item.groupType == "one_on_one" && startItem == startSelectedSlot && endItem == endSelectedSlot)) {
              let tempStartDate = new Date(item["start"])
              let tempEndDate = new Date(item["end"])
              if (groupMeetingDetails?.isMonthlyGroupFrequencyChecked && groupMeetingDetails?.groupMeetingFrequency?.toLowerCase() == "monthly") {
                tempStartDate.setHours(groupMeetingDetails.meetingMonthlyStartTime?.split(':')[0])
                tempStartDate.setMinutes(groupMeetingDetails.meetingMonthlyStartTime?.split(':')[1])
                tempEndDate.setHours(groupMeetingDetails.meetingMonthlyEndTime?.split(':')[0])
                tempEndDate.setMinutes(groupMeetingDetails.meetingMonthlyEndTime?.split(':')[1])
                body["slot"] = { start: moment(tempStartDate).format("HH:mm"), end: moment(tempEndDate).format("HH:mm") }
              } else {
                tempStartDate.setHours(formData["start"].split(":")[0])
                tempStartDate.setMinutes(formData["start"].split(":")[1])
                tempEndDate.setHours(formData["end"].split(":")[0])
                tempEndDate.setMinutes(formData["end"].split(":")[1])
              }
              item["start"] = tempStartDate
              item["end"] = tempEndDate
              tempEventList[index] = { ...item }
            }
          })

          setCreateMeetingState(body);
          setFormData({ start: "", end: "" });
          setMeetingDurationType(WEEKLY);

          setIsGroupMeetingChecked(true);
          setIsOneOnOneMeetingChecked(false);
          handleModalShowHide();
          setApiTracker("updateSelectedEvent");
          await dispatch(updateSelectedEvent(body));
          setSelectedSlot('');
          setTimeout(() => {
            fetchGlobalEvents();
          }, 2 * 1000);
        }

        setGroupMeetingDetails({
          id: null,
          meetingName: "",
          subscriptionCost: 0,
          sessionHour: "00",
          sessionMinute: "00",
          meetingMonthlyStartTime: "",
          meetingMonthlyEndTime: "",
          meetingMonthlyWeekDay: "",
          meetingMonthlyWeekOfMonth: "",
          groupMeetingFrequency: "",
          isMonthlyGroupFrequencyChecked: false,
          notDeletePreviousEntries: true,
          isWeekDaySelected: {
            Sun: false,
            Mon: false,
            Tue: false,
            Wed: false,
            Thu: false,
            Fri: false,
            Sat: false,
          },
          slots: {},
        })
        setIsGroupMeetingChecked(true);
        setIsOneOnOneMeetingChecked(false);
      }
    }
  };

  const closeAlertModal = () => {
    setAlertState({
      open: false,
      message: "",
      singleButton: true,
    });
  };

  const handleCloseMeetingExistsModal = () => {
    handleModalShowHide();
    dispatch({ type: CLOSE_MEETING_MODAL, payload: false });
    dispatch({
      type: MEETING_ALREADY_EXISTS,
      payload: false,
    });
  };

  const handleSetMeetingType = (e, type, eventData) => {
    if (apiTracker === "updateSelectedEvent") {
      dispatch(
        updateSelectedEvent({
          ...createMeetingState,
          validate: false,
          storeEmail: sellerData.storeEmail,
          storeName: sellerData.storename,
        })
      );
    } else if (apiTracker === "updateMeetingStatus") {
      dispatch(
        updateMeetingStatus({
          ...createMeetingState,
          validate: false,
          storeEmail: sellerData.storeEmail,
          storeName: sellerData.storename,
        })
      );
    } else if (apiTracker === "updateGroupInviteMeeting") {

      dispatch(
        updateGroupInviteMeeting({ ...createMeetingState, validate: false })
      );
    }

    setTimeout(() => {
      fetchGlobalEvents();
      setApiTracker("");
      setCreateMeetingState({});
    }, 2 * 1000);
    handleCloseMeetingExistsModal();
  };

  const handleAlertResponse = (type, id, data) => {
    if (id === 1) {
      if (type === "positive") {
        handleSetMeetingType(data[0], data[1]);
      } else {
        handleCloseMeetingExistsModal();
      }
    }
    closeAlertModal();
  };
  const setEventDescription = (note) => {
    setSeminarFormData({
      ...seminarFormData,
      note
    })
  }
  const hidePrice = (e) => {
    if (e.target.checked) {
      setSeminarFormData({
        ...seminarFormData,
        price: ""
      })
    }
    setEventPriceError("")

    setIsFreePriceChecked(e.target.checked)

  }
  const setEventName = (name) => {
    setEventNameError("")

    setSeminarFormData({
      ...seminarFormData,
      name
    })
  }
  const setEventPrice = (price) => {
    setEventPriceError("")
    setSeminarFormData({
      ...seminarFormData,
      price
    })
  }

  const handleSubmitFreeSeminar = async (productId = seminarFormData.id) => {
    console.log("productIdproductId", productId, seminarFormData, seminarFormData.id);
    const timezone = JSON.parse(localStorage.getItem("sellerData")).timezone;
    const res = await dispatch(createFreeSeminar(
      seminarFormData,
      localStorage.getItem("sellerId"),
      productId,
      timezone,
      null
    ))
    if (res?.status == 201) {
      setReqMessage("Seminar created successfully")

      setTimeout(() => {
        setReqMessage("")
        handleFreeSeminarModalShowHide();
      }, 1500);
      fetchGlobalEvents();
    }
    setShowDenyLoader(false)
  };

  const handleSaveChanges = () => {
    const startTimeDateMilliseconds = new Date(
      seminarFormData.date?.split("-")[0],
      seminarFormData.date?.split("-")[1] - 1,
      seminarFormData.date?.split("-")[2],
      +formData.start?.split(":")[0],
      +formData.start?.split(":")[1]
    ).getTime();
    const endTimeDateMilliseconds = new Date(
      seminarFormData.date?.split("-")[0],
      seminarFormData.date?.split("-")[1] - 1,
      seminarFormData.date?.split("-")[2],
      +formData.end?.split(":")[0],
      +formData.end?.split(":")[1]
    ).getTime();
    if (
      !!!startTimeDateMilliseconds ||
      !!!endTimeDateMilliseconds ||
      startTimeDateMilliseconds >= endTimeDateMilliseconds ||
      startTimeDateMilliseconds <= Date.now()
    ) {
      setPopupErrorMessage("enter valid date and time")
      setShowDenyLoader(false)
      return;
    }
    if (seminarFormData.name == "" || seminarFormData.name == null) {
      setEventNameError("Enter Event Name")

      setShowDenyLoader(false)
      return;
    }
    if (!isFreePriceChecked && seminarFormData.price == "" || seminarFormData.price == null) {
      setEventPriceError("Enter The Event Price")
      setShowDenyLoader(false)
      return;
    }

    if (isSeminarEdited || formData.start || formData.end || seminarFormData.date) {
      handleSubmitFreeSeminar(seminarFormData.id);
    }
    handleFreeSeminarModalShowHide(1);
    handleIsSeminarUpdated();
  };

  const handleIsSeminarUpdated = () => {
    setIsSeminarEdited(true)
  };

  const handleDateTimeChange = (e, key, type) => {

    const oldDate = new Date(editMeetingState.data[key]);
    let newDate;
    if (type === "date") {
      e = moment(e).format("YYYY-MM-DD");
      newDate = new Date(e + " " + moment(oldDate).format("HH:mm"));
    } else {
      newDate = new Date(moment(oldDate).format("YYYY-MM-DD") + " " + e);
    }

    setEditMeetingState((prevState) => ({
      isOpen: prevState.isOpen,
      data: {
        ...prevState.data,
        [key]: moment(newDate).format("YYYY-MM-DD HH:mm"),
      },
    }));
  };

  const freeSeminarModal = () => (
    <Modal
      show={showHideFreeSeminarModal
        ? showHideFreeSeminarModal : isFreeSeminar
      }
      onHide={() => handleFreeSeminarModalShowHide(2)}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={() => handleFreeSeminarModalShowHide(2)}>
          <i className="fas fa-times"></i>
        </span>
        {reqMessage && (
          <div className="success-msg">
            <p>{reqMessage}</p>
          </div>
        )}
        <h5 className="text-center w-100 m-0">
          {!!!isFreeSeminarCreated
            ? `Create Free One-Time Session`
            : `Edit Seminar Details`}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              {successMessage && (
                <div className="success-msg">
                  <p>{successMessage}</p>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label>
                <p className="form-field-error">
                  {eventNameError}
                </p>
                <b>Vendor Name:</b>
              </label>
              <input
                placeholder="Enter Event Name"
                value={seminarFormData["name"]}
                onChange={(e) => setEventName(e.target.value)}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-12 dash-date">
            <div className="form-group">
              <label>
                <p className="form-field-error">
                  {popupErrorMessage}
                </p>
                <b>Date</b>
              </label>
              <DatePicker
                className="form-control"
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                value={
                  seminarFormData.date &&
                    seminarFormData.date.length > 0
                    ? new Date(seminarFormData.date)
                    : ""
                }
                onChange={(event) => handleDateTimeChangeForSeminar(event, "date")}
              />
            </div>
          </div>
          <>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>START TIME</b>
                </label>
                <TimePicker
                  value={seminarFormData["start"]}
                  format="hh:mm a"
                  locale="sv-sv"
                  name="start"
                  disableClock={true}
                  onChange={(e) => handleDateTimeChangeForSeminar(e, "start")}
                  required={true}
                  clearIcon={null}
                  className="form-control"
                />
                <span className="clock-icon">
                  <i class="far fa-clock"></i>
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>END TIME</b>
                </label>
                <TimePicker
                  value={seminarFormData["end"]}
                  format="hh:mm a"
                  locale="sv-sv"
                  name="end"
                  disableClock={true}
                  onChange={(e) => handleDateTimeChangeForSeminar(e, "end")}
                  required={true}
                  clearIcon={null}
                  className="form-control"
                />
                <span className="clock-icon">
                  <i class="far fa-clock"></i>
                </span>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="checkbox"
                  checked={isFreePriceChecked}
                  onChange={(e) => hidePrice(e)}
                />

                <label>
                  <b> Free</b>
                </label>
              </div>
            </div>

            {!isFreePriceChecked && (
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    <p className="form-field-error">
                      {eventPriceError}
                    </p>
                    <b>Meeting Price:</b>
                  </label>
                  <input
                    placeholder="Enter Event Price"
                    type={Number}
                    min={0}
                    value={seminarFormData.price}
                    onChange={(e) => setEventPrice(+e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
            )}

            <div className="col-md-12">
              <div className="form-group">
                <label>
                  <b>Meeting DESCRIPTION :</b>
                </label>
                <textarea
                  placeholder="Event Description"
                  value={seminarFormData["note"]}
                  onChange={(e) => setEventDescription(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
          </>
        </div>
        <div className="button-center">
          <button
            onClick={() => handleSaveChanges()}
            className="orange-btn"
          >
            save changes
            {(() => {
              if (showDenyLoader) {
                return (
                  <span class="spinner-border spinner-border-sm ml-1"></span>
                );
              }
            })()}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const acceptModal = () => (
    <Modal
      show={showAcceptDetail}
      onHide={() => setShowAcceptDetail(false)}
      className="calendar-modal"
      centered
    >
      <Modal.Header closeButton onClick={() => setShowAcceptDetail(false)}>
        <b>Accept Request</b>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="form-group"></div>
          <>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>START DATE</b>
                </label>
                <DatePicker
                  name="date"
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  required
                  clearIcon={null}
                  onChange={(e) => handleDateTimeChange(e, "start", "date")}
                  className="form-control"
                  value={
                    new Date(
                      moment(editMeetingState.data?.start).format("YYYY-MM-DD")
                    )
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>END DATE</b>
                </label>
                <DatePicker
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  name="date"
                  required
                  clearIcon={null}
                  onChange={(e) => handleDateTimeChange(e, "end", "date")}
                  className="form-control"
                  value={
                    new Date(
                      moment(editMeetingState.data?.end).format("YYYY-MM-DD")
                    )
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>START TIME</b>
                </label>
                <TimePicker
                  value={moment(editMeetingState.data.start).format("HH:mm")}
                  format="hh:mm a"
                  name="start"
                  disableClock={true}
                  clearIcon={null}
                  onChange={(e) => handleDateTimeChange(e, "start", "time")}
                  required={true}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>
                  <b>END TIME</b>
                </label>
                <TimePicker
                  value={moment(editMeetingState.data.end).format("HH:mm")}
                  format="hh:mm a"
                  name="end"
                  disableClock={true}
                  clearIcon={null}
                  onChange={(e) => handleDateTimeChange(e, "end", "time")}
                  required={true}
                />
              </div>
            </div>
          </>
        </div>
        <div className="button-center">
          <button
            onClick={handleAcceptModalSubmit}
            className="orange-btn"
            disabled={btnDisabled}
          >
            Share Link
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );


  const handleAcceptModalSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    if (
      !editMeetingState.data.date ||
      editMeetingState.data.date.trim().length === 0
    ) {
      alertModal({ message: "Please select a date", singleButton: true });
    } else if (
      !editMeetingState.data.start ||
      editMeetingState.data.start.toString().trim().length === 0
    ) {
      alertModal({ message: "Please select a start time", singleButton: true });
    } else if (
      !editMeetingState.data.end ||
      editMeetingState.data.end.toString().trim().length === 0
    ) {
      alertModal({ message: "Please select a end time", singleButton: true });
    } else if (
      new Date(editMeetingState.data.start).getTime() >=
      new Date(editMeetingState.data.end).getTime()
    ) {
      alertModal({
        message: "End time must be greater than start time",
        singleButton: true,
      });
    } else if (
      new Date(editMeetingState.data.end).getTime() -
      new Date(editMeetingState.data.start).getTime() <
      60000 * 5
    ) {
      alertModal({
        message: "Meeting duration should be at least 5 mins!",
        singleButton: true,
      });
    } else if (
      Math.ceil(
        Math.abs(
          new Date(editMeetingState.data.start) -
          new Date(editMeetingState.data.end)
        ) /
        (1000 * 60 * 60 * 24)
      ) > 1
    ) {
      alertModal({ message: "Meeting length too long!", singleButton: true });
    } else {
      const data = {
        userId: sellerId,
        meeting_id: editMeetingState.data.meetingId,
        customerTimezone: editMeetingState.data.customer_timezone,
        status: "accepted",
        date: editMeetingState.data.date,
        startTime: editMeetingState.data.start,
        endTime: editMeetingState.data.end,
        timezone,
        validate: true,
        productId: editMeetingState.data.productId,
        storeEmail: sellerData.storeEmail,
        storeName: sellerData.storename,
      };
      setCreateMeetingState(data);
      setApiTracker("updateMeetingStatus");
      const res = await dispatch(updateMeetingStatus(data));
      if (res && !res.isError) {
        setShowAcceptDetail(false);
      }
    }
    setButtonDisabled(false);
    setShowAcceptDetail(false);
  };

  const declineModal = () => (
    <Modal
      show={showDeclineDetail}
      onHide={() => setShowDeclineDetail(false)}
      className="calendar-modal"
      centered
    >
      <Modal.Header closeButton onClick={() => setShowDeclineDetail(false)}>
        {" "}
        <b>
          {editMeetingState.data.status === "accepted" ? (
            <>Cancel</>
          ) : (
            <>Decline</>
          )}{" "}
          Request
        </b>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <p>
              Are you sure you want to{" "}
              {editMeetingState.data.status === "accepted" ? (
                <>Cancel</>
              ) : (
                <>Decline</>
              )}
              ?
            </p>
          </div>
          <>
            <div className="col-md-12">
              <div className="form-group">
                <label>
                  <b>
                    {editMeetingState.data.status === "accepted" ? (
                      <>Cancel</>
                    ) : (
                      <>Decline</>
                    )}{" "}
                    note
                  </b>
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  name="decline note"
                  placeholder={`Please enter ${editMeetingState.data.status === "accepted"
                    ? "Cancel"
                    : "Decline"
                    } note.`}
                  required
                  onChange={(e) => setDeclineNotes(e.target.value)}
                  className="form-control"
                  value={declineNotes}
                />
              </div>
            </div>
          </>
        </div>
        <div className="button-center">
          <button
            disabled={declineNotes === ""}
            onClick={() => {
              handleUpdateStatus(null, "rejected");
            }}
            className="orange-btn"
          >
            {editMeetingState.data.status === "accepted" ? (
              <>Cancel</>
            ) : (
              <>Decline</>
            )}
          </button>
          {editMeetingState.data.status !== "accepted" && (
            <button
              onClick={() => {
                setShowDeclineDetail(false);
              }}
              className="orange-btn"
            >
              Cancel
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );

  const handleUpdateStatus = async (meeting_id, status) => {
    setShowDeclineDetail(false);
    if (meeting_id) {
      await dispatch(
        updateMeetingStatus({
          userId: sellerId,
          meeting_id,
          status,
          declineNotes,
        })
      );
    } else {
      await dispatch(
        updateMeetingStatus({
          userId: sellerId,
          meeting_id: editMeetingState.data["meeting_id"],
          status,
          declineNotes,
        })
      );
    }
    setTimeout(() => fetchGlobalEvents(), 1000);
  };

  return (
    <>
      <h2 style={{ color: "#000000", fontSize: "18px", marginBottom: "15px" }}>
        GLOBAL CALENDAR
      </h2>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group d-flex align-items-center">
            <label htmlFor="sel1">Filter By Meetings:</label>
            <select
              className="form-control"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value={"all"}>All</option>
              <option value={"recurring"}>Product Scheduled</option>
              <option value={"requested"}>Meeting Request</option>
              <option value={"group_invite"}>Group Invite</option>
              <option value={"one_on_one"}>One-On-One Meetings</option>
              <option value={"free_event"}>Free One-time Sessions</option>
            </select>
            {showLoader && (
              <span className="spinner-border spinner-border-sm ml-2" style={{ minWidth: '1rem', minHeight: '1rem' }}></span>
            )}
          </div>

        </div>
      </div>
      <div className="calendar-colorbox">
        <ul className="represent-status">
          <li>
            <div className="color-bar green-color" />
            <p>Weekly</p>
          </li>
          <li>
            <div className="color-bar purple-color" />
            <p>Biweekly</p>
          </li>
          <li>
            <div className="color-bar yellow-color" />
            <p>Monthly</p>
          </li>
          <li>
            <div className="color-bar red-color" />
            <p>One-Time</p>
          </li>
        </ul>
        <Calendar
          localizer={localizer}
          events={eventList}
          showMultiDayTimes={true}
          style={{ height: 700 }}
          selectable
          startAccessor="start"
          endAccessor="end"
          step={30}
          view={view}
          onView={(view) => setView(view)}
          views={["week", "month"]}
          onSelectEvent={(event) => handleToolTip(event)}
          eventPropGetter={eventStyleGetter}
          date={selectedDay}
          onNavigate={(day) => {
            setSelectedDay(day);
          }}
          popup={true}
          dayLayoutAlgorithm="no-overlap"
          messages={{ previous: "Previous" }}
        />
      </div>
      {modal()}
      {oneOnOneMeetingDetailModal()}
      <AlertModal
        data={alertState}
        closeAlertModal={closeAlertModal}
        handleAlertResponse={(type, id, data) =>
          handleAlertResponse(type, id, data)
        }
      />
      {acceptModal()}
      {declineModal()}
      {freeSeminarModal()}
    </>
  );
};

export default GlobalCalendar;
