import React from "react";

import { cloneDeep } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";

import InputBox from "../../Components/FormElements/InputBox";
import RadioBox from "../../Components/FormElements/RadioBox";
import SelectBox from "../../Components/FormElements/SelectBox";
import CommonButton from "../../Components/FormElements/CommonButton";

import { AddIcon } from "../../Components/svgElements/AddIcon";
import { BackIcon } from "../../Components/svgElements/BackIcon";
import { PrimaryInfoIcon } from "../../Components/svgElements/PrimaryInfoIcon";

import ProductHeader from "./ProductHeader";

import {
  addProductConfiguration,
  getProductDetailNew,
} from "../../Redux/Actions/productAction";
import {
  getChildCategories,
  hierarchyList,
} from "../../Redux/Actions/searchCategoryAction";
import {
  ANNUAL,
  COURIER_LIST,
  MONTHLY,
  ONE_TIME,
  PRODUCT_CONFIGURATION,
  WEEKLY,
} from "../../Constant/Constant";

import "./AddProduct.scss";

const ProductConfiguration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const productId = location.state.productId;
  const categoryId = location.state.categoryId;
  const status = location.state.sectionStatus;
  const isCategoryChanged = location.state.isCategoryChanged;
  
  const [loading, setLoading] = React.useState(false);
  const [errorData, setErrorData] = React.useState({});
  const [allCategories, setAllCategories] = React.useState([]);
  const [productDetail, setProductDetail] = React.useState({});
  const [isFreeShipping, setIsFreeShipping] = React.useState(0);
  const [productDimensions, setProductDimensions] = React.useState({});
  const [customizedProduct, setCustomizedProduct] = React.useState({
    productType: 0,
  });
  const [customizedQuestions, setCustomizedQuestions] = React.useState({
    questions: [], // Array to store the values of the input boxes
  });
  const [productTimeFrame, setProductTimeFrame] = React.useState(ONE_TIME);
  const [sectionStatus, setSectionStatus] = React.useState();
  React.useEffect(() => {
    if (status) {
      setSectionStatus(status);
    }
  }, [status]);

  React.useEffect(() => {
    if(!isCategoryChanged){
      fetchProductDetails();
    }
  }, []);

  const fetchProductDetails = async () => {
    if (productId) {
      localStorage.removeItem("categoryId");
      const { data } = await dispatch(getProductDetailNew(productId));
      if (data.data) {
        await fetchSearchHierarchyList(data.data.searchCategoryId);
        setProductDetail(data.data);
        setProductState(data.data);
      }
    }
  };

  const setProductState = React.useCallback((product) => {
    setProductTimeFrame(
      product?.timeFrame ? product?.timeFrame?.split(",")[1] : ONE_TIME
    );
    setIsFreeShipping(
      product?.is_shipping_free ? product?.is_shipping_free : 0
    );
    setProductDimensions({
      productWidth: product?.width === 0 ? null : product?.width,
      productWeight: product?.weight === 0 ? null : product?.weight,
      productLength: product?.length === 0 ? null : product?.length,
      productHeight: product?.height === 0 ? null : product?.height,
      courier: JSON.parse(product.couriers_option),
      productQuantity: product?.order_limit
    });
    setCustomizedProduct({
      productType: product.is_digital,
      productCreationTime: product.customize_delivery_time,
    });
    setCustomizedQuestions({
      questions: JSON.parse(product.customize_questions),
    });
  }, []);

  const fetchSearchHierarchyList = async (searchCategoryId) => {
    if (!searchCategoryId) {
      return null;
    }
    const response = await dispatch(hierarchyList([searchCategoryId]));
    if (response && Object.keys(response.data).length !== 0) {
      let tempArray = [];
      Object.entries(response.data).map((item) => {
        tempArray.push({ latestOptions: [], selectedValues: item[1] });
      });
      setAllCategories(tempArray);
    }
  };

  React.useEffect(() => {
    setErrorData((prevErrorData) => ({
      ...prevErrorData,
      courier: productDimensions.courier ? "" : prevErrorData.courier,
      productWidth: productDimensions.productWidth
        ? ""
        : prevErrorData.productWidth,
      productLength: productDimensions.productLength
        ? ""
        : prevErrorData.productLength,
      productWeight: productDimensions.productWeight
        ? ""
        : prevErrorData.productWeight,
      productHeight: productDimensions.productHeight
        ? ""
        : prevErrorData.productHeight,
      productQuantity: productDimensions.productQuantity
        ? ""
        : prevErrorData.productQuantity,
      productCreationTime: customizedProduct?.productCreationTime
        ? ""
        : prevErrorData.productCreationTime,
    }));
  }, [
    productDimensions.courier,
    productDimensions.productHeight,
    productDimensions.productLength,
    productDimensions.productQuantity,
    productDimensions.productWeight,
    productDimensions.productWidth,
    customizedProduct?.productCreationTime,
  ]);

  /**
   * The function `categoryValidation` checks if a specific category has any selected values and
   * returns an error message if not.
   * @param index - The `index` parameter in the `categoryValidation` function is used to specify the
   * index of the category in the `allCategories` array that needs to be validated.
   * @returns A JSX element is being returned. It is a `<span>` element with the class name
   * "form-field-error" and the text content being the value of `errorData.selectedCategory`.
   */
  // const categoryValidation = (index) => {
  //   if (!allCategories[index].selectedValues.length) {
  //     return (
  //       <span className="form-field-error">{errorData.selectedCategory}</span>
  //     );
  //   }
  // };
  const validateInfo = () => {
    let isFormValid = true;
    let errorObj = {};
    if (!productTimeFrame) {
      isFormValid = false;
      errorObj["productTimeFrame"] = `Product time-frame is required.`;
    }
    if (!productDimensions.productQuantity) {
      isFormValid = false;
      errorObj["productQuantity"] = `Product qunatity is required.`;
    }

    if (
      +customizedProduct.productType !== 1 &&
      !productDimensions.productHeight
    ) {
      isFormValid = false;
      errorObj["productHeight"] = `Product height is required.`;
    }
    if (
      +customizedProduct.productType !== 1 &&
      !productDimensions.productWeight
    ) {
      isFormValid = false;
      errorObj["productWeight"] = `Product weight is required.`;
    }
    if (
      +customizedProduct.productType !== 1 &&
      !productDimensions.productLength
    ) {
      isFormValid = false;
      errorObj["productLength"] = `Product length is required.`;
    }
    if (
      +customizedProduct.productType !== 1 &&
      !productDimensions.productWidth
    ) {
      isFormValid = false;
      errorObj["productWidth"] = `Product width is required.`;
    }
    if (
      +customizedProduct.productType !== 1 &&
      (!productDimensions.courier || productDimensions.courier.length === 0)
    ) {
      isFormValid = false;
      errorObj["courier"] = `Courier service is required.`;
    }
    // if (allCategories.length && !allCategories?.selectedValues?.length > 0) {
    //   isFormValid = false;
    //   errorObj["selectedCategory"] = `Category is required.`;
    // }
    if (categoryId === 119 && !customizedProduct?.productCreationTime) {
      isFormValid = false;
      errorObj["productCreationTime"] = `Product creation time is required.`;
    }
    setErrorData(errorObj);
    return isFormValid;
  };
  /**
   * The function `handleSearchCategoryChange` updates the list of categories based on the selected
   * category and its child categories.
   * @param e - The parameter `e` in the `handleSearchCategoryChange` function is typically an event
   * object, such as a change event that is triggered when a user interacts with a form input or select
   * element. It contains information about the event that occurred, such as the target element and the
   * value of the element
   * @param index - The `index` parameter in the `handleSearchCategoryChange` function likely
   * represents the index of the category being modified in the `allCategories` array. This index is
   * used to access and update specific category information within the array.
   */
  const handleSearchCategoryChange = async (e, index) => {
    const preAll = [...allCategories];
    const result = await dispatch(
      getChildCategories({ categoryId: categoryId, childCategoryId: e.id })
    );
    if (!result.isError) {
      preAll[index].latestOptions = result.data;
      preAll[index].selectedValues.push(e);
    }
    setAllCategories(preAll);
  };

  /**
   * The function `handleAddSearchCategory` asynchronously adds seacrh category to the existing list
   * of categories.
   */
  const handleAddSearchCategory = async () => {
    const preAll = [...allCategories];
    const result = await dispatch(
      getChildCategories({ categoryId: categoryId })
    );
    if (!result?.isError) {
      preAll.push({ selectedValues: [], latestOptions: result?.data });
    }
    setAllCategories(preAll);
  };

  /**
   * The function `removeSearchCategory` removes a selected category at a specific index and updates the
   * available options based on the selected values.
   * @param index - The `index` parameter in the `removeSearchCategory` function represents the index of
   * the category you want to remove from the list of all categories.
   * @param cIndex - The `cIndex` parameter in the `removeSearchCategory` function represents the index
   * of the category that you want to remove from the selected values array within a specific category
   * in the `allCategories` object.
   */
  const removeSearchCategory = async (index, cIndex) => {
    const preAll = cloneDeep(allCategories);
    let getPreId = 0;
    let params = { categoryId: categoryId };
    if (cIndex > 0) {
      getPreId =
        preAll[index].selectedValues[cIndex > 0 ? cIndex - 1 : cIndex].id;
      params["childCategoryId"] = getPreId;
    }
    const result = await dispatch(getChildCategories(params));
    if (!result.isError) {
      preAll[index].selectedValues.splice(cIndex, 1);
      preAll[index].latestOptions = result.data;
    }
    setAllCategories(preAll);
  };

  /**
   * The function `handleDeleteSearchCategory` removes a category from a list of categories based on its
   * index.
   * @param parentIndex - The `parentIndex` parameter in the `handleDeleteSearchCategory` function
   * likely refers to the index of the category within the `allCategories` array that you want to
   * delete.
   */
  const handleDeleteSearchCategory = async (parentIndex) => {
    const preAll = [...allCategories];
    preAll.splice(parentIndex, 1);
    setAllCategories(preAll);
  };

  /**
   * The onChangeHandler function updates product dimensions based on specific keys in a JavaScript
   * application.
   * @param e - The parameter `e` in the `onChangeHandler` function is typically an event object that
   * represents the change event triggered by a user action, such as typing into an input field or
   * selecting an option in a dropdown. This event object contains information about the event, such as
   * the target element that triggered the
   * @param key - The `key` parameter in the `onChangeHandler` function is used to determine which
   * property of the `PRODUCT_CONFIGURATION` object is being updated based on the event (`e`) that is
   * triggered.
   */
  const onChangeHandler = (e, key) => {
    const { value } = e;
    if (
      key === PRODUCT_CONFIGURATION.PRODUCT_LENGTH ||
      key === PRODUCT_CONFIGURATION.PRODUCT_HEIGHT ||
      key === PRODUCT_CONFIGURATION.PRODUCT_WEIGHT ||
      key === PRODUCT_CONFIGURATION.PRODUCT_WIDTH ||
      key === PRODUCT_CONFIGURATION.PRODUCT_QUANTITY ||
      key === PRODUCT_CONFIGURATION.COURIER
    ) {
      setProductDimensions((pre) => ({ ...pre, [key]: value }));
    }
  };

  /**
   * The function generates an array of options from 1 to either 100 or 1000 based on a key parameter
   * and handles radio button changes by setting the product time frame.
   * @param key - The `key` parameter in the `options` function is used to determine the length of the
   * array of options generated. If `key` is truthy, the array will contain options from 1 to 1000 for
   * product quantity. Otherwise, the array will contain options from 1 to
   * @returns The `options` function returns an array of options for product quantity. The array
   * contains objects with `value` and `label` properties ranging from 1 to either 100 or 1000 based on
   * the `key` parameter. The `handleRadioChange` function sets the product time frame based on the
   * value selected in a radio input.
   */
  const options = (key) => {
    // Generate an array of options from 1 to 100 or 1000 for product quantity
    return Array.from({ length: key ? 1000 : 100 }, (_, i) => ({
      value: i + 1,
      label: `${i + 1}`,
    }));
  };
  const handleRadioChange = (e) => {
    const { value } = e.target;
    setProductTimeFrame(value);
  };

  const customizedProductHandler = (e, key, index) => {
    const { value } = e.target;
    if (key === "productType" || key === "productCreationTime") {
      
      const updatedValue = key === "productCreationTime" && value < 0 ? 0 : value;
      setCustomizedProduct({ ...customizedProduct, [key]: updatedValue });
    } else if (key === "customizedQuestions") {
      const updatedQuestions = [...customizedQuestions.questions]; // Clone the current array
      updatedQuestions[index] = value; // Update the specific question by index
      setCustomizedQuestions({
        ...customizedQuestions,
        questions: updatedQuestions, // Set the updated array in the state
      });
    }
  };

  const handleAddCustomizeQuestion = () => {
        setCustomizedQuestions((prev) => ({
          ...prev,
          questions: [...(prev.questions||[]), ""], // Add an empty string to represent a new question
        }));
  };

  const handleSubmit = async () => {
    const selectedCategoryIds = allCategories.map(
      (item) => item?.selectedValues[item.selectedValues?.length - 1]?.id
    );
    if (validateInfo()) {
      const state = {
        productId,
        isFreeShipping,
        productTimeFrame,
        productDimensions,
        customizedProduct,
        customizedQuestions,
        selectedCategoryIds: selectedCategoryIds.join(","),
      };
      setLoading(true);
      const result = await dispatch(addProductConfiguration(state));
      setLoading(false);
      if (result.status === 201) {
        const updatedSectionStatus = {
          ...sectionStatus,
          productConfiguration: true,
        };
        setSectionStatus(updatedSectionStatus);
        navigate("/product-attributes", {
          state: {
            isCategoryChanged,
            productId: productId,
            categoryId: categoryId,
            slug: productDetail?.slug,
            sectionStatus: updatedSectionStatus,
          },
        });
      }
    }
  };
  return (
    <>
      <div className="add-product-page">
        <div className="page-heading">
          <button
            onClick={() =>
              navigate("/basic-details", {
                state: { sectionStatus: sectionStatus, categoryId, productId },
              })
            }
            className="theme-button dark-outline-btn radius-sm back-btn"
          >
            <BackIcon />
          </button>
          <h2>Add Product</h2>
        </div>
        <div className="white-card">
          <ProductHeader
            state={{
              productId: productId,
              categoryId: categoryId,
              sectionStatus: status,
            }}
          />
          <div className="select-category-section">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="form-inner-hrading mb-2">
                  <div className="name">
                    <h4>Product Time-Frame</h4>
                  </div>
                  <div className="inline-radio">
                    <RadioBox
                      label="Weekly"
                      name="timeFrame"
                      value={WEEKLY}
                      checked={productTimeFrame === WEEKLY}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    <RadioBox
                      label="Monthly"
                      name="timeFrame"
                      value={MONTHLY}
                      checked={productTimeFrame === MONTHLY}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    <RadioBox
                      label="Annual"
                      name="timeFrame"
                      value={ANNUAL}
                      checked={productTimeFrame === ANNUAL}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    <RadioBox
                      label="One Time"
                      name="timeFrame"
                      value={ONE_TIME}
                      checked={productTimeFrame === ONE_TIME}
                      onChange={(e) => handleRadioChange(e)}
                    />
                  </div>
                  <span className="form-field-error">
                    {errorData?.productTimeFrame
                      ? errorData?.productTimeFrame
                      : ""}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="form-group">
                  <div className="d-flex g-2">
                    <label>
                      Product Order Limit<sup>*</sup>
                    </label>
                    <div className="tooltip-box pl-2">
                      <div class="tooltip-custom">
                        <PrimaryInfoIcon />
                        <div class="together-tooltip">
                          <p>
                            Set the total number of units available for this
                            product.
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <SelectBox
                    required
                    options={options(PRODUCT_CONFIGURATION.PRODUCT_QUANTITY)}
                    onChange={(e) =>
                      onChangeHandler(e, PRODUCT_CONFIGURATION.PRODUCT_QUANTITY)
                    }
                    parentClass="mb-0"
                    value={{
                      label: productDimensions?.productQuantity,
                      value: productDimensions?.productQuantity,
                    }}
                  />
                  <span className="form-field-error">
                    {errorData?.productQuantity
                      ? errorData?.productQuantity
                      : ""}
                  </span>
                </div>
              </div>
              {categoryId === 119 ? (
                <div className="col-md-12 col-lg-12">
                  <div className="form-inner-hrading mb-2">
                    <div className="name">
                      <h4>Product Type</h4>
                      <div className="tooltip-box">
                        <div class="tooltip-custom">
                          <PrimaryInfoIcon />
                          <div class="together-tooltip">
                            <p>
                              Choose the type of product.
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inline-radio">
                      <RadioBox
                        label="Physical"
                        name="customizedProductType"
                        value={0}
                        checked={+customizedProduct.productType === 0}
                        onChange={(e) =>
                          customizedProductHandler(e, "productType")
                        }
                      />
                      <RadioBox
                        label="Digital"
                        name="customizedProductType"
                        value={1}
                        checked={+customizedProduct.productType === 1}
                        onChange={(e) =>
                          customizedProductHandler(e, "productType")
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              {categoryId === 119 ? (
                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <div className="d-flex g-2">
                      <label>
                        Product Creation Time (In Days)<sup>*</sup>
                      </label>
                      <div className="tooltip-box pl-2">
                        <div class="tooltip-custom">
                          <PrimaryInfoIcon />
                          <div class="together-tooltip">
                            <p>
                              Time To Completion.
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <InputBox
                      type="number"
                      parentClass="mb-0"
                      placeholder="Enter product creation time"
                      error={errorData?.productCreationTime}
                      value={customizedProduct?.productCreationTime}
                      onChange={(e) =>
                        customizedProductHandler(e, "productCreationTime")
                      }
                    />
                  </div>
                </div>
              ) : null}

              <div className="col-md-12 col-lg-12">
                <div className="form-inner-hrading">
                  <div className="name">
                    <h4>Seacrh Category</h4>
                    <div className="tooltip-box">
                      <div class="tooltip-custom">
                        <PrimaryInfoIcon />
                        <div class="together-tooltip">
                          <p>
                            Select a category to filter your product. This will
                            help customers find items relevant to their needs
                            more easily.
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {allCategories.map((item, index) => {
                    return (
                      <div key={index}>
                        {item.selectedValues?.length > 0 && (
                          <label className="mt-2">
                            Search Category{" "}
                            {item.selectedValues?.length ? index + 1 : ""}
                          </label>
                        )}
                        {item.selectedValues?.length > 0 && (
                          <i
                            className="fa fa-trash fa-sm add-section-cursor ml-2"
                            aria-hidden="true"
                            onClick={() => handleDeleteSearchCategory(index)}
                          ></i>
                        )}
                        <ul className="search-pills-category mt-1">
                          {item?.selectedValues?.map((category, cIndex) => (
                            <li key={cIndex}>
                              {category?.name}
                              <span>
                                <i
                                  className="fas fa-times"
                                  onClick={() =>
                                    removeSearchCategory(index, cIndex)
                                  }
                                />
                              </span>
                            </li>
                          ))}
                        </ul>
                        <SelectBox
                          required
                          parentClass="mb-2"
                          label="Select Search Category"
                          options={item?.latestOptions?.map((i) => {
                            return { ...i, label: i.name };
                          })}
                          onChange={(e) => handleSearchCategoryChange(e, index)}
                          value=""
                        />
                        {/* {categoryValidation(index)} */}
                      </div>
                    );
                  })}

                  <div className="add-product-btn">
                    <button
                      onClick={() => handleAddSearchCategory()}
                      type="button"
                    >
                      <AddIcon /> Add New Category
                    </button>
                  </div>
                </div>
              </div>

              {categoryId === 119 ? (
                <div className="col-md-12 col-lg-12">
                  <div className="form-inner-hrading">
                    <div className="name">
                      <h4>Customization Questions</h4>
                      <div className="tooltip-box">
                        <div class="tooltip-custom">
                          <PrimaryInfoIcon />
                          <div class="together-tooltip">
                            <p>
                              Required information from customers for customized
                              offering.
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {customizedQuestions?.questions && customizedQuestions?.questions.map((item, index) => {
                      return (
                        <div key={index}>
                          <InputBox
                            type="text"
                            value={item}
                            maxLength={1000}
                            name="customizedQuestions"
                            label={`Question ${0 + 1}`}
                            placeholder="Enter customized question"
                            onChange={(e) => {
                              customizedProductHandler(
                                e,
                                "customizedQuestions",
                                index
                              );
                            }}
                          />
                        </div>
                      );
                    })}
                    <div className="add-product-btn">
                      <button
                        type="button"
                        onClick={() => handleAddCustomizeQuestion()}
                      >
                        <AddIcon /> Add New Question
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {+customizedProduct.productType !== 1 && (
                <div className="col-md-12 col-lg-12">
                  <div className="form-inner-hrading">
                    <div className="name">
                      <h4>Product Dimensions</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="d-flex g-2">
                          <label>
                            Product Package Height (In Inches)<sup>*</sup>
                          </label>
                          <div className="tooltip-box pl-2">
                            <div class="tooltip-custom">
                              <PrimaryInfoIcon />
                              <div class="together-tooltip">
                                <p>
                                  Enter the height of the package from base to
                                  top.
                                  <span class="arrow-down"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <SelectBox
                          required
                          parentClass="mb-0"
                          options={options()}
                          placeholder="Product package height"
                          error={errorData?.productHeight}
                          onChange={(e) =>
                            onChangeHandler(
                              e,
                              PRODUCT_CONFIGURATION.PRODUCT_HEIGHT
                            )
                          }
                          value={{
                            label: productDimensions?.productHeight,
                            value: productDimensions?.productHeight,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="d-flex g-2">
                          <label>
                            Product Package Width (In Inches)<sup>*</sup>
                          </label>
                          <div className="tooltip-box pl-2">
                            <div class="tooltip-custom">
                              <PrimaryInfoIcon />
                              <div class="together-tooltip">
                                <p>
                                  Enter the width of the package from side to
                                  side.
                                  <span class="arrow-down"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <SelectBox
                          required
                          parentClass="mb-0"
                          options={options()}
                          error={errorData?.productWidth}
                          placeholder="Product package width"
                          onChange={(e) =>
                            onChangeHandler(
                              e,
                              PRODUCT_CONFIGURATION.PRODUCT_WIDTH
                            )
                          }
                          value={{
                            label: productDimensions?.productWidth,
                            value: productDimensions?.productWidth,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="d-flex g-2">
                          <label>
                            Product Package Length (In Inches)<sup>*</sup>
                          </label>
                          <div className="tooltip-box pl-2">
                            <div class="tooltip-custom">
                              <PrimaryInfoIcon />
                              <div class="together-tooltip">
                                <p>
                                  Enter the length of the package from front to
                                  back.
                                  <span class="arrow-down"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <SelectBox
                          required
                          parentClass="mb-0"
                          options={options()}
                          error={errorData?.productLength}
                          placeholder="Product package length"
                          onChange={(e) =>
                            onChangeHandler(
                              e,
                              PRODUCT_CONFIGURATION.PRODUCT_LENGTH
                            )
                          }
                          value={{
                            label: productDimensions?.productLength,
                            value: productDimensions?.productLength,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="d-flex g-2">
                          <label>
                            Product Package Weight (In Pounds)<sup>*</sup>
                          </label>
                          <div className="tooltip-box pl-2">
                            <div class="tooltip-custom">
                              <PrimaryInfoIcon />
                              <div class="together-tooltip">
                                <p>
                                  Enter the weight of the package.
                                  <span class="arrow-down"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <SelectBox
                          required
                          parentClass="mb-0"
                          options={options()}
                          error={errorData?.productWeight}
                          placeholder="Product package weight"
                          onChange={(e) =>
                            onChangeHandler(
                              e,
                              PRODUCT_CONFIGURATION.PRODUCT_WEIGHT
                            )
                          }
                          value={{
                            label: productDimensions?.productWeight,
                            value: productDimensions?.productWeight,
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="d-flex g-2">
                          <label>
                            Couriers Service<sup>*</sup>
                          </label>
                          <div className="tooltip-box pl-2">
                            <div class="tooltip-custom">
                              <PrimaryInfoIcon />
                              <div class="together-tooltip">
                                <p>
                                  Choose the courier service for shipping.
                                  <span class="arrow-down"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <SelectBox
                          required
                          parentClass="mb-0"
                          options={COURIER_LIST}
                          error={errorData?.courier}
                          placeholder="Select courier service"
                          onChange={(e) =>
                            onChangeHandler(e, PRODUCT_CONFIGURATION.COURIER)
                          }
                          value={{
                            label: productDimensions?.courier,
                            value: productDimensions?.courier,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {+customizedProduct.productType !== 1 && (
                <div className="col-md-12 col-lg-12">
                  <div className="form-inner-hrading mb-2">
                    <div className="name">
                      <h4>Free Shipping</h4>
                      <div className="tooltip-box">
                        <div class="tooltip-custom">
                          <PrimaryInfoIcon />
                          <div class="together-tooltip">
                            <p>
                              Offer free shipping on this product.
                              <span class="arrow-down"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="inline-radio">
                      <RadioBox
                        label="Yes"
                        value={1}
                        name="freeShipping"
                        checked={isFreeShipping === 1}
                        onChange={() => setIsFreeShipping(1)}
                      />
                      <RadioBox
                        label="No"
                        value={0}
                        name="freeShipping"
                        checked={isFreeShipping === 0}
                        onChange={() => setIsFreeShipping(0)}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="action-btn">
            <CommonButton
              value="Continue"
              loading={loading}
              disabled={loading}
              onClick={() => handleSubmit()}
              buttonClass="theme-button primary-btn radius-btn"
            />
            <CommonButton
              value="Cancel"
              onClick={() => navigate("/product-list")}
              buttonClass="theme-button dark-outline-btn radius-btn"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductConfiguration;
