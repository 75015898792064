import React from "react";

export const FileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M13.5955 13.6964H6.37549"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5955 9.93665H6.37549"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.13098 6.17737H6.37598"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.60986 10.0001C1.60986 16.9371 3.70786 19.2501 10.0009 19.2501C16.2949 19.2501 18.3919 16.9371 18.3919 10.0001C18.3919 3.06312 16.2949 0.750122 10.0009 0.750122C3.70786 0.750122 1.60986 3.06312 1.60986 10.0001Z"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
