import React from "react";

const InputBox = (props) => {
  const {
    label,
    value,
    type = "text",
    onChange,
    parentClass = "form-group",
    inputClass = "form-control",
    required = true,
    error,
    placeholder,
    ...extraProps
  } = props;

  return (
    <div className={parentClass}>
      {label ? (
        <label>
          {label}
          {required ? <sup>*</sup> : null}
        </label>
      ) : null}
      <input
        type={type}
        maxLength="15"
        value={value || ""}
        onChange={onChange}
        className={inputClass}
        {...extraProps}
        placeholder={placeholder}
      />
      <span className="form-field-error">{error ? error : ""}</span>
    </div>
  );
};

export default React.memo(InputBox);
