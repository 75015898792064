import React, { useEffect, useRef, useState } from "react";
import "./Onboarding.scss";
import InputBox from "../../Components/FormElements/InputBox";
import CommonButton from "../../Components/FormElements/CommonButton";
import TextareaBox from "../../Components/FormElements/TextareaBox";
import { PrimaryInfoIcon } from "../../Components/svgElements/PrimaryInfoIcon";
import SelectBox from "../../Components/FormElements/SelectBox";
import TimePickerBox from "../../Components/FormElements/TimePickerBox";
import ProfileImage from "../../assets/images-2/profile-img.png";
import { UploadLogoIcon } from "../../Components/svgElements/UploadLogoIcon";
import { BackIcon } from "../../Components/svgElements/BackIcon";
import RadioBox from "../../Components/FormElements/RadioBox";
import CommonCheckbox from "../../Components/FormElements/CommonCheckbox";
import { useDispatch, useSelector } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  updateProfile,
  getCountriesList,
  getStatesList,
  getCitiesListByStateCode,
} from "../../Redux/Actions/userAction";
import HeaderParent from "../../Components/Header/HeaderParent";
import StepsView from "./StepsView";
import { useNavigate } from "react-router";

const timezoneList = [
  { value: "AKDT", label: "Alaska Daylight Time - AKDT" },
  { value: "CDT", label: "Central Daylight Time - CDT" },
  { value: "EDT", label: "Eastern Daylight Time - EDT" },
  { value: "HST", label: "Hawaii-Aleutian Standard Time - HST" },
  { value: "MDT", label: "Mountain Daylight Time - MDT" },
  { value: "MST", label: "Mountain Standard Time - MST" },
  { value: "PDT", label: "Pacific Daylight Time - PDT" },
];

const StoreDetails = (props) => {
  const authData = useSelector((state) => state.userReducer.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const imageRef = useRef();

  const [countriesList, setCountriesList] = useState([]);
  const [logoData, setLogoData] = useState("");
  const [selectedImg, setSelectedImg] = useState(null);
  const [bottomMessage, setBottomMessage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1,
  });
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [returnCitiesList, setReturnCitiesList] = useState([]);

  const [errorData, setErrorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [state, setState] = useState({
    userId: "",
    imageName: "",
    storeName: "",
    storeOverView: "",
    storeEmail: "",
    isChatActive: 1,
    timezone: "",
    availableFrom: "",
    availableTo: "",
    addressId: null,
    address: "",
    address_2: "",
    countriesList: [],
    defaultSelectedCountry: [],
    country: "",
    countryCode: "",
    state: "",
    stateCode: "",
    city: "",
    zipCode: "",
    return_shipment_address: "",
    returnShipmentAddress_2: "",
    returnCountry: "",
    returnCountriesList: [],
    returnDefaultSelectedCountry: [],
    returnState: "",
    returnStateCode: "",
    returnCity: "",
    returnShipmentZipCode: "",
    defaultSelectedState: [],
    defaultSelectedCity: [],
    returnDefaultSelectedState: [],
    returnDefaultSelectedCity: [],
    sellerStoreId: "",
    zoomApiKey: "",
    zoomApiSecret: "",
  });

  const setUserState = React.useCallback(() => {
    const returnShipmentDetails = authData?.return_shipment_address
      ? JSON.parse(authData.return_shipment_address)
      : {};

    const defaultSelectedCountry = authData?.country
      ? [
          {
            value: JSON.stringify({
              country_code: "US",
              country_name: authData.country,
            }),
            label: authData.country,
          },
        ]
      : [];

    const defaultSelectedState = authData?.state
      ? [
          {
            value: JSON.stringify({
              stateCode: authData.state_code,
              stateName: authData.state,
            }),
            label: `${authData.state}`,
          },
        ]
      : [];

    const defaultSelectedCity = authData?.city
      ? [
          {
            value: JSON.stringify({ cityName: authData.city }),
            label: authData.city,
          },
        ]
      : [];

    const returnDefaultSelectedCountry = authData?.return_shipment_address
      ? [
          {
            value: JSON.stringify({
              country_code: "us",
              country_name: returnShipmentDetails?.returnCountry || null,
            }),
            label: returnShipmentDetails?.returnCountry || null,
          },
        ]
      : [];

    const returnDefaultSelectedState = returnShipmentDetails?.returnState
      ? [
          {
            value: JSON.stringify({
              stateCode: returnShipmentDetails?.stateCode,
              stateName: returnShipmentDetails?.returnState || null,
            }),
            label: returnShipmentDetails?.returnState || null,
          },
        ]
      : [];

    const returnDefaultSelectedCity = returnShipmentDetails?.returnCity
      ? [
          {
            value: JSON.stringify({
              cityName: returnShipmentDetails?.returnCity,
            }),
            label: returnShipmentDetails?.returnCity || null,
          },
        ]
      : [];

    const timezone = timezoneList?.find(
      (item) => item.value === authData?.timezone
    );

    setState((prevState) => ({
      ...prevState,
      userId: authData?.id,
      imageName: authData?.imagename,
      storeName: authData?.storename,
      storeOverView: authData?.store_overview,
      storeEmail: authData?.storeEmail,
      email: authData?.email,
      phoneNumber: authData?.phonenumber,
      isChatActive: authData?.isChatActive || 1,
      timezone: timezone
        ? timezone
        : { value: "EDT", label: "Eastern Daylight Time - EDT" },
      availableFrom: authData?.available_from || authData?.availableFrom,
      availableTo: authData?.available_to || authData?.availableTo,
      addressId: authData?.addressId,
      address: authData?.address,
      address_2: authData?.address_2,
      country: authData?.country,
      countryCode: authData?.country_code || "US",
      state: authData?.state,
      stateCode: authData?.state_code,
      defaultSelectedCountry: defaultSelectedCountry,
      defaultSelectedState: defaultSelectedState,
      defaultSelectedCity: defaultSelectedCity,
      city: authData?.city,
      zipCode: authData?.zipCode,
      return_shipment_address: returnShipmentDetails?.return_shipment_address,
      returnShipmentAddress_2: returnShipmentDetails?.returnShipmentAddress_2,
      returnCountry: returnShipmentDetails?.returnCountry,
      returnState: returnShipmentDetails?.returnState,
      returnStateCode: returnShipmentDetails?.returnStateCode,
      returnCity: returnShipmentDetails?.returnCity,
      returnShipmentZipCode: returnShipmentDetails?.returnShipmentZipcode,
      returnDefaultSelectedCountry: returnDefaultSelectedCountry,
      returnDefaultSelectedState: returnDefaultSelectedState,
      returnDefaultSelectedCity: returnDefaultSelectedCity,
      sellerStoreId: authData?.sellerStoreId,
      zoomApiKey: authData?.zoomApiKey,
      zoomApiSecret: authData?.zoomApiSecret,
    }));
  }, [authData]);

  useEffect(() => {
    fetchCountriesList();
    fetchStatesList();
  }, []);

  useEffect(() => {
    if (authData?.state_code) {
      fetchCitiesListByStateCode(authData?.state_code);
    }
  }, [authData?.state_code]);

  useEffect(() => {
    const code = authData?.return_shipment_address
      ? JSON.parse(authData.return_shipment_address).returnStateCode
      : "";
    if (code) {
      fetchReturnCitiesListByStateCode(code);
    }
  }, [authData?.return_shipment_address]);

  useEffect(() => {
    setUserState();
  }, [authData, setUserState]);

  const fetchCitiesListByStateCode = async (state_code) => {
    let citiesList = await dispatch(getCitiesListByStateCode(state_code));
    if (citiesList?.data?.data?.length > 0) {
      createCitiesSelectOptions(citiesList?.data?.data);
    }
  };

  const fetchReturnCitiesListByStateCode = async (state_code) => {
    let citiesList = await dispatch(getCitiesListByStateCode(state_code));
    if (citiesList?.data?.data?.length > 0) {
      createReturnCitiesSelectOptions(citiesList?.data?.data);
    }
  };

  const createCitiesSelectOptions = (allData = [...citiesList]) => {
    let selectOptions = allData.map((city) => {
      return {
        value: `${JSON.stringify({ cityId: city.id, cityName: city.city })}`,
        label: `${city.city}`,
      };
    });
    setCitiesList(selectOptions);
  };

  const createReturnCitiesSelectOptions = (allData = [...citiesList]) => {
    let selectOptions = allData.map((city) => {
      return {
        value: `${JSON.stringify({ cityId: city.id, cityName: city.city })}`,
        label: `${city.city}`,
      };
    });
    setReturnCitiesList(selectOptions);
  };

  const fetchStatesList = async () => {
    let statesList = await dispatch(getStatesList(1));
    if (statesList?.data?.data?.length > 0) {
      createStateSelectOptions(statesList.data.data);
    }
  };

  const createStateSelectOptions = (allData = [...statesList]) => {
    let selectOptions = allData.map((item) => {
      return {
        value: `${JSON.stringify({
          stateId: item.id,
          stateCode: item.state_code,
          stateName: item.state_name,
        })}`,
        label: `${item.state_name}`,
      };
    });
    setStatesList(selectOptions);
  };

  async function fetchCountriesList() {
    let countries = await dispatch(getCountriesList());
    if (countries?.data?.data?.length > 0) {
      createCountrySelectOptions(countries.data.data);
    }
  }

  const createCountrySelectOptions = (allData = [...countriesList]) => {
    let selectOptions = allData.map((item) => {
      return {
        value: `${JSON.stringify({
          countryId: item.id,
          country_code: item.country_code,
          country_name: item.name,
        })}`,
        label: `${item.name}`,
      };
    });
    setCountriesList(selectOptions);
  };

  const copyAddress = (value) => {
    if (value.target.checked) {
      setState((prevState) => ({
        ...prevState,
        returnCountry: prevState.country,
        returnDefaultSelectedCountry: prevState.defaultSelectedCountry,
        return_shipment_address: prevState.address,
        returnShipmentAddress_2: prevState.address_2,
        returnState: prevState.state,
        returnStateCode: prevState.stateCode,
        returnCity: prevState.city,
        returnDefaultSelectedState: prevState.defaultSelectedState,
        returnDefaultSelectedCity: prevState.defaultSelectedCity,
        returnShipmentZipCode: prevState.zipCode,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        return_shipment_address: "",
        returnShipmentAddress_2: "",
        returnState: "",
        returnStateCode: "",
        returnCity: "",
        returnDefaultSelectedState: [],
        returnDefaultSelectedCity: [],
        returnShipmentZipCode: "",
      }));
    }
  };

  const handleChange = (e, key) => {
    const errors = { ...errorData };
    errors[key] = "";
    let value =
      key === "availableTo" || key === "availableFrom" ? e : e.target?.value;

    if (key === "zipCode" || key === "returnShipmentZipCode") {
      value = value?.replace(/\D+/g, "");
    }
    setErrorData(errors);
    setState((prevState) => ({
      ...prevState,
      [key]: key === "timezone" ? e : value,
    }));
  };

  const isEmailAddress = (email) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(String(email).toLowerCase()); // returns a boolean
  };

  const validateUserInfo = () => {
    let isFormValid = true;
    let errorObj = {};
    if (!state.storeName || !state.storeName.trim().length) {
      isFormValid = false;
      errorObj["storeName"] = "Store name field can not be empty.";
    }

    if (!state.storeOverView || !state.storeOverView.trim().length) {
      isFormValid = false;
      errorObj["storeOverView"] = "Store overview field can not be empty.";
    }

    if (
      !state.storeEmail ||
      !state.storeEmail.trim().length ||
      !isEmailAddress(state.storeEmail)
    ) {
      isFormValid = false;
      errorObj["storeEmail"] = "Store email is not a valid email address.";
    }

    if (!state.timezone || !state.timezone?.value) {
      isFormValid = false;
      errorObj["timezone"] = "Timezone field can not be empty.";
    }

    if (!state.availableFrom) {
      isFormValid = false;
      errorObj["availableFrom"] = "From field can not be empty.";
    }

    if (!state.availableTo) {
      isFormValid = false;
      errorObj["availableTo"] = "To field can not be empty.";
    }

    if (!state.address || !state.address.trim().length) {
      isFormValid = false;
      errorObj["address"] = "Address field can not be empty.";
    }

    // if (!state.address_2 || !state.address_2.trim().length) {
    //   isFormValid = false;
    //   errorObj["address_2"] = "Address field can not be empty.";
    // }

    if (!state.country || !state.country.trim().length) {
      isFormValid = false;
      errorObj["country"] = "Country field can not be empty.";
    }

    if (!state.state || !state.state.trim().length) {
      isFormValid = false;
      errorObj["state"] = "State field can not be empty.";
    }

    if (!state.city || !state.city.trim().length) {
      isFormValid = false;
      errorObj["city"] = "City field can not be empty.";
    }

    if (!state.zipCode || !state.zipCode.trim().length) {
      isFormValid = false;
      errorObj["zipCode"] = "Zip code field can not be empty.";
    }

    if (
      !state.return_shipment_address ||
      !state.return_shipment_address.trim().length
    ) {
      isFormValid = false;
      errorObj["return_shipment_address"] =
        "Return shipment address field can not be empty";
    }

    // if (
    //   !state.returnShipmentAddress_2 ||
    //   !state.returnShipmentAddress_2.trim().length
    // ) {
    //   isFormValid = false;
    //   errorObj["returnShipmentAddress_2"] =
    //     "Return shipment address field can not be empty";
    // }

    if (!state.returnState || !state.returnState.trim().length) {
      isFormValid = false;
      errorObj["returnState"] = "State field can not be empty.";
    }

    if (!state.returnCountry || !state.returnCountry.trim().length) {
      isFormValid = false;
      errorObj["returnCountry"] = "Country field can not be empty.";
    }

    if (!state.returnCity || !state.returnCity.trim().length) {
      isFormValid = false;
      errorObj["returnCity"] = "City field can not be empty.";
    }

    if (
      !state.returnShipmentZipCode ||
      !state.returnShipmentZipCode.trim().length
    ) {
      isFormValid = false;
      errorObj["returnShipmentZipCode"] = "Zip code field can not be empty.";
    }

    setErrorData(errorObj);
    if (!isFormValid) {
      setBottomMessage("Please fill all the required details");
    }
    return isFormValid;
  };

  const handleSubmit = async () => {
    if (validateUserInfo()) {
      setLoading(true);
      const returnShipmentAddress = {
        return_shipment_address: state.return_shipment_address,
        returnShipmentAddress_2: state.returnShipmentAddress_2,
        returnCountry: state.returnCountry,
        returnState: state.returnState,
        returnStateCode: state.returnStateCode,
        returnCity: state.returnCity,
        returnShipmentZipcode: state.returnShipmentZipCode,
      };
      const profile = { ...state };
      profile["return_shipment_address"] = JSON.stringify(
        returnShipmentAddress
      );
      profile.timezone = state.timezone?.value;
      if (selectedImg) {
        profile["selectedImg"] = selectedImg;
      }
      const result = await dispatch(
        updateProfile({ ...profile }, "updateProfile-step-two")
      );
      if (result.status === 201) {
        localStorage.setItem("timezone", profile.timezone);
        localStorage.setItem("availableFrom", profile.availableFrom);
        localStorage.setItem("availableTo", profile.availableTo);
        setSuccessMessage("Profile updated successfully!");
        navigate("/payment-configuration");
      } else {
        setErrorMessage("Something went wrong!");
      }
      setLoading(false);
    }
  };

  const handleCountryChange = async (event, key) => {
    const countryValue = JSON.parse(event.value);
    if (key === "fullfillment_country") {
      setState((prevState) => ({
        ...prevState,
        country: countryValue.country_name,
        countryCode: countryValue.country_code.toUpperCase(),
        defaultSelectedCountry: event,
      }));
    }
    if (key === "return_shipment_country") {
      setState((prevState) => ({
        ...prevState,
        returnCountry: countryValue.country_name,
        returnCountryCode: countryValue.country_code.toUpperCase(),
        returnDefaultSelectedCountry: event,
      }));
    }
  };

  const handleStateChange = async (event, key) => {
    const stateValue = JSON.parse(event.value);
    let citiesList = await dispatch(
      getCitiesListByStateCode(stateValue.stateCode)
    );
    const cityList = citiesList?.data?.data;
    if (cityList?.length > 0) {
      if (key === "fullfillment_state") {
        setState((prevState) => ({
          ...prevState,
          defaultSelectedCity: [],
          defaultSelectedState: event,
          state: stateValue.stateName,
          stateCode: stateValue.stateCode,
        }));
        createCitiesSelectOptions(cityList);
      } else if (key === "return_shipment_state") {
        setState((prevState) => ({
          ...prevState,
          returnDefaultSelectedCity: [],
          returnDefaultSelectedState: event,
          returnState: stateValue.stateName,
          returnStateCode: stateValue.stateCode,
        }));
        createReturnCitiesSelectOptions(cityList);
      }
    }
  };

  const handleCityChange = (event, key) => {
    const cityValue = JSON.parse(event.value);
    if (key === "fullfillment_city") {
      setState((prevState) => ({
        ...prevState,
        defaultSelectedCity: event,
        city: cityValue.cityName,
      }));
    } else if (key === "return_shipment_city") {
      setState((prevState) => ({
        ...prevState,
        returnDefaultSelectedCity: event,
        returnCity: cityValue.cityName,
      }));
    }
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setLogoData(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoaded = (image) => {
    imageRef.current = image.target;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageURL = await getCroppedImg(
        imageRef.current,
        crop,
        "newFile.jpeg"
      );
      setSelectedImg(croppedImageURL);
      setCroppedImageUrl(croppedImageURL);
    }
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      var dataURL = canvas.toDataURL();
      resolve(dataURL);
    });
  };

  const goBack = () => {
    navigate("/personal-details");
  };

  const cancelPage = () => {
    navigate("/");
  };

  return (
    <>
      <HeaderParent />
      <div className="container">
        <div className="onboarding-page">
          {successMessage ? (
            <div className="alert alert-success">{successMessage}</div>
          ) : errorMessage ? (
            <div className="alert alert-danger">{errorMessage}</div>
          ) : null}
          <div className="page-heading">
            <button
              onClick={goBack}
              className="theme-button dark-outline-btn radius-sm back-btn"
            >
              <BackIcon />
            </button>
            <h2>Onboarding</h2>
          </div>
          <StepsView step={2} />
          <div className="page-inner">
            <div className="row">
              <div className="col-md-8 col-lg-9 col-xl-9 order-2 order-md-1">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <InputBox
                      label="Store Name"
                      value={state.storeName}
                      onChange={(event) => handleChange(event, "storeName")}
                      type="text"
                      error={errorData?.storeName}
                      placeholder="Enter store name"
                    />
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <TextareaBox
                      label="Store Overview"
                      value={state.storeOverView}
                      onChange={(event) => handleChange(event, "storeOverView")}
                      error={errorData?.storeOverView}
                      placeholder="Enter store description"
                      rows="3"
                      maxLength={500}
                    />
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <InputBox
                      label="Email To Use For Contact Form"
                      value={state.storeEmail}
                      maxLength={50}
                      error={errorData?.storeEmail}
                      onChange={(event) => handleChange(event, "storeEmail")}
                      placeholder="Enter email address"
                    />
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="form-inner-hrading">
                      <div className="name">
                        <h4>Allow Customers to Contact Through Chat</h4>
                        <div className="tooltip-box">
                          <div class="tooltip-custom">
                            <PrimaryInfoIcon />
                            <div class="together-tooltip">
                              <p>
                                Allow customers to contact you via chat to
                                manage direct communications.
                                <span class="arrow-down"></span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="inline-radio">
                        <RadioBox
                          label="Active"
                          value="1"
                          checked={Number(state.isChatActive) === 1}
                          onChange={(event) =>
                            handleChange(event, "isChatActive")
                          }
                        />
                        <RadioBox
                          label="Inactive"
                          value="2"
                          checked={Number(state.isChatActive) === 2}
                          onChange={(event) =>
                            handleChange(event, "isChatActive")
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <SelectBox
                      label="Timezone"
                      options={timezoneList}
                      value={state.timezone}
                      onChange={(event) => handleChange(event, "timezone")}
                      error={errorData?.timezone}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <TimePickerBox
                      label="Available From"
                      value={state.availableFrom ? state.availableFrom : null}
                      onChange={(event) => handleChange(event, "availableFrom")}
                      required={true}
                      error={errorData?.availableFrom}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <TimePickerBox
                      label="Available Up-to"
                      value={state.availableTo ? state.availableTo : null}
                      onChange={(event) => handleChange(event, "availableTo")}
                      required={true}
                      error={errorData?.availableTo}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="form-inner-hrading">
                      <div className="name">
                        <h4>Fulfillment Address</h4>
                      </div>
                      <InputBox
                        label="Address Line 01"
                        type="text"
                        placeholder="Enter address"
                        value={state?.address}
                        onChange={(event) => handleChange(event, "address")}
                        error={errorData?.address}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <InputBox
                      label="Address Line 02"
                      type="text"
                      placeholder="Enter address"
                      value={state?.address_2}
                      onChange={(event) => handleChange(event, "address_2")}
                      error={errorData?.address_2}
                      maxLength={100}
                      required={false}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <SelectBox
                      label="Country"
                      value={state.defaultSelectedCountry}
                      options={countriesList}
                      onChange={(event) =>
                        handleCountryChange(event, "fullfillment_country")
                      }
                      error={errorData?.country}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <SelectBox
                      label="State"
                      value={state.defaultSelectedState}
                      options={statesList}
                      onChange={(event) =>
                        handleStateChange(event, "fullfillment_state")
                      }
                      error={errorData?.state}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <SelectBox
                      label="City"
                      value={state.defaultSelectedCity}
                      options={citiesList}
                      onChange={(event) =>
                        handleCityChange(event, "fullfillment_city")
                      }
                      error={errorData?.city}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <InputBox
                      label="Zip Code"
                      type="number"
                      placeholder="Enter zip code"
                      value={state?.zipCode}
                      onChange={(event) => handleChange(event, "zipCode")}
                      error={errorData?.zipCode}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div className="form-inner-hrading">
                      <div className="name">
                        <h4>Return Shipment Address</h4>
                        <CommonCheckbox
                          onClick={copyAddress}
                          label="Same As Fulfillment Address"
                        />
                      </div>
                      <InputBox
                        label="Address Line 01"
                        type="text"
                        placeholder="Enter address"
                        value={state.return_shipment_address}
                        onChange={(event) =>
                          handleChange(event, "return_shipment_address")
                        }
                        error={errorData?.return_shipment_address}
                        maxLength={100}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-12">
                    <InputBox
                      label="Address Line 02"
                      type="text"
                      error={errorData?.returnShipmentAddress_2}
                      placeholder="Enter address"
                      value={state?.returnShipmentAddress_2}
                      onChange={(event) =>
                        handleChange(event, "returnShipmentAddress_2")
                      }
                      maxLength={100}
                      required={false}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <SelectBox
                      label="Country"
                      value={state.returnDefaultSelectedCountry}
                      options={countriesList}
                      onChange={(event) =>
                        handleCountryChange(event, "return_shipment_country")
                      }
                      error={errorData?.returnCountry}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <SelectBox
                      label="State"
                      value={state.returnDefaultSelectedState}
                      options={statesList}
                      onChange={(event) =>
                        handleStateChange(event, "return_shipment_state")
                      }
                      error={errorData?.returnState}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <SelectBox
                      label="City"
                      value={state.returnDefaultSelectedCity}
                      options={returnCitiesList}
                      onChange={(event) =>
                        handleCityChange(event, "return_shipment_city")
                      }
                      error={errorData?.returnCity}
                      required
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <InputBox
                      label="Zip Code"
                      type="number"
                      value={state?.returnShipmentZipCode}
                      onChange={(event) =>
                        handleChange(event, "returnShipmentZipCode")
                      }
                      placeholder="Enter zip code"
                      error={errorData?.returnShipmentZipCode}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-xl-3 order-1 order-md-2 mb-4">
                <div className="onboard-img-section">
                  <div className="onboard-img">
                    {croppedImageUrl ? (
                      <img alt="Crop" src={croppedImageUrl} />
                    ) : (
                      <img
                        alt="Profile"
                        src={state.imageName ? state.imageName : ProfileImage}
                      />
                    )}
                  </div>
                  <div className="mb-3">
                    {logoData && (
                      <ReactCrop
                        crop={crop}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                      >
                        <img src={logoData} onLoad={onImageLoaded} alt=""  />
                      </ReactCrop>
                    )}
                  </div>

                  <div className="upload-img-btn">
                    <button
                      type="button"
                      className="theme-button primary-outline-btn radius-btn"
                    >
                      <UploadLogoIcon />
                      Upload Logo
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={onSelectFile}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p style={{ color: "red" }}>{bottomMessage}</p>
          </div>
          <div className="action-btn">
            <CommonButton
              value="Continue"
              loading={loading}
              onClick={handleSubmit}
              buttonClass="theme-button primary-btn radius-btn"
            />
            <CommonButton
              value="Cancel"
              onClick={cancelPage}
              buttonClass="theme-button dark-outline-btn radius-btn"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreDetails;
