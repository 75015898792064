import React, { Component } from "react";
import "../../Styles/order-detail.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {decryptAccessToken, fileFormats,getFileSizeByByteLength} from "../../Utils/helper";
import {
  getOrderDetail,
  updateOrderDetail,
  getOrderTracking,
  updateOrderStatusByTrackingId,
  refundOrderReturnsByOrderId,
} from "../../Redux/Actions/orderAction";
import {
  addFiles,
  uploadFiles,
} from "../../Redux/Actions/fileUploadAction";
import { updateOrderReturnRequest } from "../../Redux/Actions/orderReturnRequestAction";
import { getShopifyDetails, getWooCommerceDetails } from "../../Redux/Actions/userAction";
import AlertModal from "../../Components/AlertModal/AlertModal";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { weekDayValue } from "../../Constant/Constant";
import moment from "moment";
import withNavigate from "../../Components/customHooks/navigate";
class Order_Detail extends Component {
  statePrevCopy = {};

  constructor(props) {
    super(props);
    this.state = {
      orderDetail: {},
      refundableAmount: null,
      customerTrackingDetails: {},
      shippingAddress: {},
      sessionDetails: null,
      isEditForm: false,
      errorData: {},
      isShippingChargeEnable: false,
      showRefundableBar: false,
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      orderTrackType: [],
      showLoader: false,
      showHideReturnOrderModal: false,
      service: "",
      sameTrackingError: null,
      userId: localStorage.getItem("sellerData")
        ? JSON.parse(localStorage.getItem("sellerData")).id
        : null,
      shop: null,
      accessToken: null,
      shopify_fullfillment_id: null,
      location_id: null,
      alertModalData: {
        open: false,
        message: "",
        singleButton: true,
      },
      isOrderCompleted: false,
      disputeStatus: null,
      showRejectLoader: false,
      showAcceptLoader: false,
      showApproveLoader: false,
      isAcceptReturnRequestClicked: false,
      showModal: false,
      show: false,
      files: [],
      fileFormat:[],
      digitalDoc: [],
      loader: false,
    };
  }
   fileUploadVideo = "";
  fileUploadImage = "";
   fileUploadAddMore = "";
  fileUploadDoc = "";

  componentDidMount() {
    this.fetchOrderDetail();
    this.fetchOrdertrackInfo(true);
  }

  fetchOrderDetail = async () => {
    const result = await this.props.getOrderDetail(
      this.props.params.orderId
    );

    if (result?.data?.data) {
      await this.setState({
        productId: result?.data?.data?.productId,
        isDigital : result?.data?.data?.isDigital,
        digitalUrl: result?.data?.data?.digital_product_url ? JSON.parse(result?.data?.data?.digital_product_url) : null,
        returnAddress: result.data.data.return_shipment_address ? 
        JSON.parse( result.data.data.return_shipment_address)
          : null,
        orderDetail: result.data.data,
        customerTrackingDetails: result.data.data.trackingDetails
          ? JSON.parse(result.data.data.trackingDetails)
          : null,
        shippingAddress: result.data.data.address
          ? JSON.parse(result.data.data.address)
          : null,
        billingAddress: result.data.data.billing_Info
          ? JSON.parse(result.data.data.billing_Info)
          : null,
          newCardDetails: localStorage.getItem("new_billing_address") ? JSON.parse(localStorage.getItem("new_billing_address")) : null,
        sessionDetails: result.data.data.booked_session_details
          ? JSON.parse(result.data.data.booked_session_details)
          : null,

        showRefundableBar: result.data.data.orders_status_history
          ? JSON.parse(result.data.data.orders_status_history).some((status) =>
              [`canceled`, `returned`].includes(status.status)
            )
          : false,
        isShippingChargeEnable: result.data.data.orders_status_history
          ? JSON.parse(result.data.data.orders_status_history).some((status) =>
              [`shipped`, "completed", "returned"].includes(status.status)
            )
          : false,
        shopify_fullfillment_id: result.data?.data.shopify_fullfillment_id,
        isOrderCompleted: result.data.data.orders_status_history
          ? JSON.parse(result.data.data.orders_status_history).some((status) =>
              [`completed`].includes(status.status)
            ) || result.data.data.status === "completed"
          : false,
        disputeStatus: result.data.data.disputeStatus,
        refundableAmount: result.data.data.refunded_response
          ? result.data.data.method === "STRIPE"
            ? (
                JSON.parse(result.data.data.refunded_response)
                  ?.amount_refunded / 100
              )?.toFixed(2)
            : JSON.parse(result.data.data.refunded_response)?.amount?.total
          : null,
          seminarDetails: result.data.data.free_seminar_details 
            ? JSON.parse(result.data.data.free_seminar_details)
            : null,
          isShopifyProduct: result.data.data.shopify_order_id ? true : false,
          isWoocommerceProduct: result.data.data.woocommerce_order_id ? true : false
      }, async () => {
        if (this.state.isShopifyProduct){
          this.fetchShopifyDetails();
        }else if (this.state.isWoocommerceProduct){
          const response = await this.props.getWooCommerceDetails(this.state.userId, "woocommerce")

          this.setState({
            shop: response.data?.data.shop,
            accessToken: response.data?.data.access_token,
            location_id: response.data?.data.vendor_location_id,
          });
        }
      });

      // this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
    }
    this.hideButton();
  };

  getDatesArrayOccuringOnSameDayOfMonth = (day) => {
    // day is long day string
    let dateArray = [];
    const date = moment(new Date()).format("YYYY-MM-DD");
    let weekDay = moment(date).startOf("month").day(day);

    if (weekDay.date() > 7) weekDay.add(7, "d");
    let month = weekDay.month();
    while (month === weekDay.month()) {
      dateArray.push(
        moment(
          weekDay.year().toString() +
            "-" +
            (weekDay.month() + 1).toString() +
            "-" +
            weekDay.date().toString(),
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD")
      );
      weekDay.toString();
      weekDay.add(7, "d");
    }
    return dateArray;
  };

  hideButton = () => {
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const currentTime = new Date()
    const convertObj = new Date()
    let currentDay = weekday[currentTime.getDay()].slice(0,3)

    this.state.sessionDetails &&  Object.entries(this.state.sessionDetails?.customerTime).map(([weekDay, slotsArray]) => {
          slotsArray && slotsArray.map((slot) => {
            if(this.state.sessionDetails.type == "group" && this.state.sessionDetails.sessionFrequency === "Monthly"){
              const dateArray =this.getDatesArrayOccuringOnSameDayOfMonth(weekDayValue[weekDay]);
            
              const selectedDayOfMonth = slot[2] == "First" 
                                                 ? 0 
                                                 : slot[2] == "Second"
                                                    ? 1 
                                                    : 2
              const startTime = new Date(dateArray[selectedDayOfMonth].split("-")[0],dateArray[selectedDayOfMonth].split("-")[1]-1,dateArray[selectedDayOfMonth].split("-")[2],
              slot[0].split(":")[0],slot[0].split(":")[1])
              const endTime = new Date(dateArray[selectedDayOfMonth].split("-")[0],dateArray[selectedDayOfMonth].split("-")[1]-1,dateArray[selectedDayOfMonth].split("-")[2],
              slot[1].split(":")[0],slot[1].split(":")[1])
              

              if (currentTime.getTime() < startTime.getTime() || currentTime.getTime() > endTime.getTime()){
                this.setState({
                  hideButton: true
                })
               }
            } else {
              convertObj.setHours(slot[0].split(":")[0])
              convertObj.setMinutes(slot[0].split(":")[1])
  
              const startTime = convertObj.getTime()
              convertObj.setHours(slot[1].split(":")[0])
              convertObj.setMinutes(slot[1].split(":")[1])
              const endTime = convertObj.getTime()
              
             if ((currentDay !== weekDay)||(currentDay === weekDay && (currentTime.getTime() < startTime || currentTime.getTime() > endTime))){
              this.setState({
                hideButton: true
              })
             }
            }
          })
      })
  }

  fetchShopifyDetails = async () => {
    const res = await this.props.getShopifyDetails(this.state.userId);
    if (res?.data.isFound) {
      const access_token = decryptAccessToken(res.data?.data.access_token)
      this.setState({
        shop: res.data?.data.shop,
        accessToken: access_token,
        location_id: res.data?.data.vendor_location_id,
      });
    }
  };

  handleRefundForOrderReturns = async () => {
    this.setState({
      showApproveLoader: true,
    });
    const res = await this.props.refundOrderReturnsByOrderId(
      this.props.params.orderId
    );
    if (res?.data?.data.statusCode == 201) {
      await this.fetchOrderDetail();
    }
    this.setState({
      showApproveLoader: false,
    });
  };

  onEdit = () => {
    this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
    this.setState({
      isEditForm: true,
    });
  };

  onApproveRequest = () => {
    confirmAlert({
      title: "",
      message: `Are you sure to refund amount for this shipment?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.handleRefundForOrderReturns();
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  handleReturnProductModalShowHide = () => {
    this.setState({
      showHideReturnOrderModal: false,
      returnOrderMessage: "",
    });
  };

  handleReturnOrderAlert = () => {
    this.setState({
      showHideReturnOrderModal: true,
    });
  };

  handleReturnRequestStatus = async () => {
    // if (!!!this.state.returnOrderMessage) {
    //   return;
    // }
    this.setState({
      showDenyLoader: true,
    });
    const res = this.handleAcceptRejectReturnRequest(
      this.state.isAcceptReturnRequestClicked,
      this.state.isAcceptReturnRequestClicked ? "accepted" : "rejected",
      this.props.params.orderId,
      this.state.returnOrderMessage ? this.state.returnOrderMessage : null
    );
    if (!res?.data?.isError) {
      await this.fetchOrderDetail();
      this.setState({
        successMessage: "Updated successfully!",
      });
    } else {
      this.setState({ errorMessage: "Something went wrong" });
    }
    this.setState({
      showDenyLoader: false,
      showHideReturnOrderModal: false,
    });
    setTimeout(() => {
      this.setState({
        successMessage: "",
        errorMessage: "",
      });
    }, 2000);
  };

  groupTimeSlotsModal = () => (
    <Modal show={this.state.showModal} centered>
      <Modal.Header
        closeButton
        onClick={() => {
          this.setState({
            showModal: false,
          });
        }}
      >
        <b>Time slots</b>
      </Modal.Header>
      <Modal.Body className="time-slot-modal">
      <div>
      {
          <>
{this.state.sessionDetails && this.state.sessionDetails.type == "group" && this.state.sessionDetails?.timeSlots ? (
                                    <>
                                      {
                                        Object.entries(this.state.sessionDetails?.timeSlots).map(([weekDay, slotsArray]) => {
                                          return <>
                                          <p className="mb-2"><strong>{weekDayValue[weekDay]}</strong></p>
                                          <ul>{
                                            slotsArray && slotsArray.map((slot) => {
                                              return <li>{moment(slot[0],"HH:mm").format("hh:mm A")} 
                                              {" "}to {moment(slot[1], "HH:mm").format("hh:mm A")} {slot[2] ? `(Every ${slot[2]} ${weekDayValue[weekDay]} Of The Month)` : null}</li>
                                            })
                                          }
                                          </ul>
                                          </>
                                        
                                        
                                        
                                        })

                                      }
                                  </>
                                  ) : null}
          </>
      }
      </div>
      </Modal.Body>
    </Modal>
  );

  returnOrderModal = () => (
    <Modal
      show={this.state.showHideReturnOrderModal}
      onHide={this.handleReturnProductModalShowHide}
      className="calendar-modal"
      centered
    >
      <Modal.Header className="calendar-header">
        <span onClick={this.handleReturnProductModalShowHide}>
          <i className="fas fa-times"></i>
        </span>
        <h5 className="text-center w-100 m-0">Return order request</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <p className="text-center">
                {`Are you sure to ${
                  this.state.isAcceptReturnRequestClicked ? `accept` : `reject`
                } this return request ?`}
              </p>
              {!!!this.state.isAcceptReturnRequestClicked && (
                <>
                  <label>
                    <b>Reason</b>
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Enter text message for vendor"
                    onChange={(e) =>
                      this.setState({ returnOrderMessage: e.target.value })
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="button-center">
          <button
            onClick={() => this.handleReturnRequestStatus()}
            className="orange-btn"
          >
            yes
            {(() => {
              if (this.state?.showDenyLoader) {
                return (
                  <span class="spinner-border spinner-border-sm ml-1"></span>
                );
              }
            })()}
          </button>
          <button
            onClick={() => this.handleReturnProductModalShowHide()}
            className="orange-btn"
          >
            no
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  deleteSelectedFile = (e, index) => {
    e.preventDefault();
    this.state.files.splice(index, 1);
    this.setState({
      files : [...this.state.files]
    });
  };


  fileUploadAddMoreClick = (e) => {
    e.preventDefault();
    this.fileUploadAddMore.click();
    return false;
  };

   fileUploadImageClick = (e) => {
    e.preventDefault();
    this.setState(fileFormats.image);
    this.fileUploadImage.click();
    return false;
  };
   fileUploadDocClick = (e) => {
    e.preventDefault();
    this.setState(fileFormats.doc);
    this.fileUploadDoc.click();
    return false;
  };

  fileUploadVideoClick = (e) => {
    e.preventDefault();
    this.setState(fileFormats.video);
    this.fileUploadVideo.click();
    return false;
  };

  handleChange = (event, field, alertPopUp, newValue) => {
    event.persist();
    if (
      this.state.errorData &&
      this.state.errorData[field] &&
      this.state.errorData[field][0]
    ) {
      this.state.errorData[field][0] = null;
    }
    if (!alertPopUp) {
      this.setState((prevState) => {
        return (prevState.orderDetail[field] = event.target.value);
      });
    } else {
      this.setState((prevState) => {
        return (prevState.orderDetail[field] = newValue);
      });
    }
  };

  handleCompleteStatusChange = (event, field) => {};

  handleAlertStatusChange = async (event, field, trackingCompany) => {
    if (
      this.state.orderDetail?.paymentStatus === "pending" &&
      field === "status"
    ) {
      return this.updateSubscription();
    }
    let newValue = event.target.value;

    let isOrderDelivered = await this.updateOrderStatus();
    if (
      newValue === "completed" &&
      trackingCompany &&
      field === "status" &&
      !isOrderDelivered
    ) {
      return this.orderCompleteModal();
    }
    confirmAlert({
      title: "",
      message: `Are you sure to change order status as  ${newValue}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => this.handleChange(event, field, true, newValue),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
    // this.alertModal({
    //   message: `Are you sure to change order status as  ${newValue}?`,
    //   singleButton: false,
    //   positiveButton : "Yes",
    //   data : [event, field, true, newValue],
    //   id : 1,
    //   negativeButton : "No"
    // })
  };

  validateUpdate = () => {
    let isFormValid = true;
    this.state.errorData = {
      trackingCompany: [],
      trackingId: [],
      files: [],
    };

    if(this.state.isDigital && this.state.orderDetail.status === "completed" && this.state.files.length ===0){
      {
        isFormValid = false;
        this.state.errorData.files = [
          "Please upload digital product. ",
        ];
      }
    }

    if (
      this.state.orderDetail.trackingId &&
      this.state.orderDetail.trackingId.length > 0
    ) {
      if (
        !this.state.orderDetail.trackingCompany ||
        this.state.orderDetail.trackingCompany.trim().length === 0
      ) {
        isFormValid = false;
        this.state.errorData.trackingCompany = [
          "Tracking company is required.",
        ];
      }
    }

    if (this.state.orderDetail.status != "canceled") {
      if (
        this.state.orderDetail.trackingCompany &&
        this.state.orderDetail.trackingCompany.length > 0
      ) {
        if (
          !this.state.orderDetail.trackingId ||
          this.state.orderDetail.trackingId.trim().length === 0
        ) {
          isFormValid = false;
          this.state.errorData.trackingId = ["Tracking Id is required."];
        }
      }
    }

    let tempState = this.state;

    this.setState({
      ...tempState,
    });

    return isFormValid;
  };

  uploadDigitalImage = async(type,
    fileUploadPathEndPoint,
    index,
    isPrivate,
    subCatIndex)=> {
    return new Promise(async (resolve,reject)=>{
      const files = 
      subCatIndex === null ? this.state?.files : null;
      const file = files[index];
      if (file) {
        file["status"] = "Uploading";
        this.props.addFiles(type, files);
        const res = await this.props.uploadFiles(
          "/api/v1/seller/product/upload",
          null,
          null,
          type,
          index,
          {
            productId: this.state.productId,
            file: file,
            fileUploadPathEndPoint: fileUploadPathEndPoint,
            isPrivate: isPrivate,
            fileName: "",
          }
        );
        this.setState({
          fileResponse: res
        })
        if (!res.isError && res?.data && res?.data?.imageUrl) {
          file["status"] = "Uploaded";
          this.props.addFiles(type, files);
          resolve (res.data.imageUrl);
        } else {
          file["status"] = "Error Occurred";
          this.props.addFiles(type, files);
          reject (false);
        }
      }
    })
    }
    uploadDigitalVideo = async( type,
      fileUploadPathEndPoint,
      index,
      isPrivate,
      subCatIndex) => {
        return new Promise(async (resolve,reject)=>{
          const files =
          subCatIndex === null
          ? this.state?.files
          : this.state.files;
        const file = files[index];
        if (file) {
          file["status"] = "Uploading";
          this.props.addFiles(type, files);
          const res = await this.props.uploadFiles(
            "/api/v1/seller/product/upload",
            null,
            null,
            type,
            index,
            {
              productId: this.state.productId,
              file: file,
              fileUploadPathEndPoint: fileUploadPathEndPoint,
              isPrivate: isPrivate,
              fileName:
                subCatIndex === null
                  ? ""
                  : this.state.files[subCatIndex][type + "Titles"] &&
                    this.state.files[subCatIndex][type + "Titles"][
                      index
                    ].trim(),
            }
          );
          this.setState({
            fileResponse: res
          })
          if (!res.isError && res.data && res.data.imageUrl) {
            file["status"] = "Uploaded";
            this.props.addFiles(type, files);
            resolve (res.data.imageUrl);
          } else {
            file["status"] = "Error Occurred";
            this.props.addFiles(type, files);
            reject (false);
          }
        }
        })
    }


  uploadAllSelectedFile = async () => {
    let actualType = [];
   let subCatIndex = 0
    let errors = [];
    return await new Promise(async (resolve, reject) => {
          const imageUrlList = [];
          let finalResult = [];
          for (let i = 0; i < this.state.files.length; i++) {
            const value = this.state.files[i];
            const index = i;
            let imageUrl;
              
            if (fileFormats.image.includes(value?.type?.split("/")[1]) && value?.stream?.includes("base64")) {
              imageUrl = this.uploadDigitalImage(
                "images",
                "/image_gallery",
                index,
                false,
                null
              );
            } 
            else if (fileFormats.video.includes(value?.type?.split("/")[1]) && value?.stream?.includes("base64")){
              imageUrl = this.uploadDigitalVideo(
                "videos",
                "/video_gallery",
                index,
                false,
                null
              );
            }  else if (fileFormats.doc.includes(value?.type?.split("/")[1]) && value?.stream?.includes("base64")) {
              imageUrl = this.uploadDigitalVideo(
                "acsCatDocuments",
                "/access/doc",
                index,
                false,
                null
              );
            }
            
            else {
              imageUrl = value.stream
            }
            finalResult.push(imageUrl)
          }
          const resolved = await Promise.allSettled(finalResult)
          resolved.map((item)=>{
            if(item){
              imageUrlList.push({URL: item.value})
            } else {
              errors.push("Error Occurred in file :" + item.name);
            }
          })
          this.setState({
            digitalDoc : [...imageUrlList]
          })
      if (errors && errors.length > 0) {
        reject({ isError: true, errorMessages: errors.join("\n") });
      } else {
        resolve({ isError: false, imageUrlList });
      }
    })
  }

  uploadDocuments = async() => {  
    this.setState({
      loader: true
    })
    if(this.state.files && this.state.files.length){
      const urls = await this.uploadAllSelectedFile()
      return urls
    }
  }

  onSaveForm = async () => {
    if (this.validateUpdate()) {
      let res;
      let orderHistoryStatus = [{ status: "confirmed" }];
      if (this.state.orderDetail.orders_status_history !== null) {
        orderHistoryStatus = JSON.parse(
          this.state.orderDetail.orders_status_history
        );
      }
      let orderStatusHistory = [
        ...orderHistoryStatus,
        { status: this.state.orderDetail.status },
      ];
      let isShippingChargeEnable = orderStatusHistory.some((status) =>
        ["shipped", "completed"].includes(status.status)
      );
      await this.setState({
        isEditForm: false,
        isShippingChargeEnable,
      });
      orderStatusHistory = JSON.stringify(orderStatusHistory);

      if(this.state.isDigital && this.state.orderDetail.status ==="completed" && this.state.files.length){
        const url = await this.uploadDocuments() 
        if(url){
           res = await this.props.updateOrderDetail({
            ...this.state.orderDetail,
            orders_status_history: orderStatusHistory,
            isShippingChargeEnable,
            accessToken: this.state.accessToken,
            shop: this.state.shop,
            shopify_fullfillment_id: this.state.shopify_fullfillment_id,
            digitalDoc : url.imageUrlList
          });
        }
      }
      if(!this.state.isDigital || this.state.orderDetail.status !=="completed" ){
        res = await this.props.updateOrderDetail({
         ...this.state.orderDetail,
         orders_status_history: orderStatusHistory,
         isShippingChargeEnable,
         accessToken: this.state.accessToken,
         shop: this.state.shop,
         shopify_fullfillment_id: this.state.shopify_fullfillment_id,
       });
      }
 
      if (res && res.data && !res.data.isError) {
        window.location.reload()
        this.statePrevCopy = JSON.parse(JSON.stringify(this.state));
        await this.fetchOrderDetail();
        this.setState({
          sameTrackingError: "",
          loader: false
        });
      } else if (
        res &&
        res.data &&
        res.data.isError &&
        res.data.data.data.code === "ER_DUP_ENTRY"
      ) {
        this.setState({
          sameTrackingError:
            "Same Tracking Id already exists with another order Id.",
          isEditForm: true,
        });
      } else {
        this.setState({
          ...this.statePrevCopy,
        });
      }
    }
  };

  onCancel = async () => {
    await this.setState({
      ...this.statePrevCopy,
    });
  };

  updateSubscription() {
    confirmAlert({
      title: "",
      message: "Order status cannot be changed as the payment is pending!",
      buttons: [
        {
          label: "Ok",
          onClick: async () => {},
        },
      ],
    });
    // this.alertModal({
    //   message: "Order status cannot be changed as the payment is pending!",
    //   singleButton : true
    // })
  }
  orderCompleteModal() {
    confirmAlert({
      title: "",
      message:
        "Order status cannot be changed to Completed currently order is not deliverable",
      buttons: [
        {
          label: "Ok",
          onClick: async () => {
            console.log(`click`);
          },
        },
      ],
    });
    // this.alertModal({
    //   message: "Order status cannot be changed to Completed currently order is not deliverable",
    //   singleButton : true
    // })
  }
  fetchOrdertrackInfo = async (firstFetch) => {
    this.setState({
      orderTrackRecord: [],
      orderTrackStatusRecord: [],
      orderTrackingTemplate: [],
      showLoader: firstFetch ? false : true,
    });
    const result = await this.props.getOrderTracking(
      this.props.params.orderId
    );

    if (!result?.data?.isError && result?.data?.data) {
      if (
        result.data.data?.trackRes?.CompletedTrackDetails?.[0]
          ?.TrackDetails?.[0]?.Events?.length > 0
      ) {
        this.createFedexTrackingDetail(
          result.data.data.trackRes.CompletedTrackDetails[0].TrackDetails[0]
            .Events
        );
      } else if (result.data.data.trackRes?.elements?.length > 0) {
        this.createUspsTrackingDetail(result.data.data.trackRes);
      } else if (
        result.data.data.package &&
        result.data.data?.package[0]?.activity
      ) {
        this.createUpsTrackingDetail(result.data.data?.package[0]?.activity);
      } else {
        this.setState({
          // orderDetail: result.data.data,
          showLoader: false,
        });
      }
    } else {
      this.setState({
        showLoader: false,
      });
    }
  };

  createFedexTrackingDetail = async (trackDetail) => {
    let trackStatus = [];
    const trackingDetails = trackDetail.map((trackItem, index) => {
      trackStatus.push(trackItem.EventDescription);
      const timestamp = trackItem.Timestamp
        ? new Date(trackItem.Timestamp).toISOString().substring(0, 10)
        : `-`;
      const address = trackItem.Address;
      return (
        <tr key={index}>
          <td>
            <div className="track-time">
              {/* <p><span></span> Monday, June 8, 2020 04:07 PM, Seattle wa us</p> */}
              <p>
                <span></span> {timestamp}, {address.City ? address.City : null}{" "}
                {address.StateOrProvinceCode
                  ? address.StateOrProvinceCode
                  : null}{" "}
                {address.CountryCode ? address.CountryCode : "-"}
              </p>
            </div>
          </td>
          <td>
            <div className="track-status">
              <p>
                {trackItem.EventDescription ? trackItem.EventDescription : "-"}
              </p>
            </div>
          </td>
        </tr>
      );
    });

    await this.setState({
      orderTrackRecord: trackDetail,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Fedex",
    });
    if (this.state.orderDetail?.trackingCompany) {
      this.updateOrderStatus();
    }
    return;
  };

  createUspsTrackingDetail = (trackDetail) => {
    let trackStatus = [];
    const trackingDetails = trackDetail.elements.map((trackItem, index) => {
      trackStatus.push(trackItem?.elements?.[0]?.text);
      return (
        <tr key={index}>
          <td>
            <div className="track-time">
              <p>
                <span></span>
              </p>
            </div>
          </td>
          <td>
            <div className="track-status">
              <p>
                {trackItem?.elements?.[0].text
                  ? trackItem.elements[0].text
                  : "-"}
              </p>
            </div>
          </td>
        </tr>
      );
    });
    this.setState({
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      showLoader: false,
      service: "Usps",
    });
  };

  createUpsTrackingDetail = (trackDetail) => {
    let trackStatus = [];
    let trackType = [];
    const trackingDetails = trackDetail.map((trackItem, index) => {
      trackStatus.push(trackItem.status.description);
      trackType.push(trackItem.status.type);
      const timestamp = trackItem.date;
      // ? new Date(trackItem.date).toISOString().substring(0, 10)
      // : `-`;
      const address = trackItem.location.address;
      return (
        <tr key={index}>
          <td>
            <div className="track-time">
              {/* <p><span></span> Monday, June 8, 2020 04:07 PM, Seattle wa us</p> */}
              <p>
                <span></span> {timestamp}, {address.city ? address.city : null}{" "}
                {address.stateProvince ? address.stateProvince : null}{" "}
                {address.country ? address.country : null}
              </p>
            </div>
          </td>
          <td>
            <div className="track-status">
              <p>
                {trackItem.status?.description
                  ? trackItem.status?.description
                  : null}
              </p>
            </div>
          </td>
        </tr>
      );
    });

    this.setState({
      orderTrackRecord: trackDetail,
      orderTrackStatusRecord: trackStatus,
      orderTrackingTemplate: trackingDetails,
      orderTrackType: trackType,
      showLoader: false,
      service: "Ups",
    });

    return;
  };

  updateOrderStatus = async () => {
    let orderStatus = this.state.orderTrackStatusRecord?.includes("Delivered")
      ? true
      : false;
    let Uspsstage4 = 
      this.state?.service === "Usps"
        ? this.state.orderTrackStatusRecord?.find((a) => a.includes("delivered"))
        : false;
    let Upsstage4 =
      this.state.service === "Ups"
        ? this.state.orderTrackType?.find((a) => a.includes("D"))
        : false;
    return orderStatus || Uspsstage4 || Upsstage4;
  };

  alertModal = (data) => {
    this.setState({
      alertModalData: { ...data, open: true },
    });
  };

  closeAlertModal = () => {
    this.setState({
      alertModalData: {
        open: false,
        message: "",
        singleButton: true,
      },
    });
  };

  fileSelectHandler = (e) => {
    e.preventDefault();
    this.setState({
      show:false}
      );
    this.fileUploadHandler(e.target.files, false);
  };
   
  fileUploadHandler = (fileList, addNew) => {
    const errors = [];
    let fileArray = [...this.state.files];
    if(fileList[0]?.size > 10000000){
      this.setState({
        largeFileError : "You Can Not Upload More Then 10 MB Size. "
      })
      return
    }
  
    for (const file of fileList) {
      //read file stream and set in state
      const readFile=(file)=> {
        const fileReader = new FileReader();
        // fileReader.onload = function (event) {
          fileReader.onloadend =async (event) => {
          fileArray.push({
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            stream: event.currentTarget.result,
          });
          if (addNew) {
           this.setState((prevState) => {
              return {
                files: fileArray,
              };
            });
            // this.setState([...this.state.files, ...fileArray]);
          } else {
             this.setState((prevState) => {
              return {
                files: 
                  // [...prevState.files,
                  fileArray,
                  // ]
              };
            });        
            }
        };
        fileReader.readAsDataURL(file);
      }
      let imgType = file?.type?.split("/");
      if (
        fileFormats.video.includes(imgType[1]) ||
        fileFormats.image.includes(imgType[1]) ||
        fileFormats.doc.includes(imgType[1])
      ) {
        if (
          !this.state.files.find(
            (item) => item.name === file.name && item.type === file.type
          )
        ) {
          readFile(file);
        }
      } else {
        errors.push("File [" + file.name + "] Format is not valid.");
      }
    }
  };
  handleAlertResponse = (type, id, data) => {
    if (id === 1) {
      if (type === "positive") {
        this.handleChange(data[0], data[1], data[2], data[3]);
      }
    }
    this.closeAlertModal();
  };

  download = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.jpg");
      document.body.appendChild(link);
      link.click();
    });
  };

  getFileName = (url) => {
    let name = url.split("/");
    return name[4] + ".jpg";
  };

  async handleAcceptRejectReturnRequest(
    value,
    returnStatus,
    orderId,
    vendorMessage
  ) {
    value
      ? this.setState({
          showAcceptLoader: true,
        })
      : this.setState({
          showRejectLoader: true,
        });
    const result = await this.props.updateOrderReturnRequest(
      returnStatus,
      orderId,
      vendorMessage
    );
    await this.fetchOrderDetail();
    this.setState({
      showAcceptLoader: false,
      showRejectLoader: false,
    });
  }

  async handleAcceptRejectButtonClick(val) {
    this.setState({
      showHideReturnOrderModal: true,
    });
    val
      ? this.setState({
          isAcceptReturnRequestClicked: true,
        })
      : this.setState({
          isAcceptReturnRequestClicked: false,
        });
  }

  render() {
    const groupMeetingLink = this.state?.orderDetail?.group_meeting_link ? 
    JSON.parse(this.state?.orderDetail?.group_meeting_link)?.start_url:
    null;
    const date = this.state.orderDetail.createTS
      ? new Date(this.state.orderDetail.createTS).toISOString().substring(0, 10)
      : null;
    let variationKey = [];
    let variationValue = [];
    const currentTime = new Date().getTime()
    const seminardate = this.state.seminarDetails?.date
     const seminarStartTime = this.state.seminarDetails?.start
     const seminarEndTime = this.state.seminarDetails?.end
     const parsedStartTime = new Date(seminardate?.split('-')[0],
    seminardate?.split('-')[1] - 1,
    seminardate?.split('-')[2],
    seminarStartTime?.split(':')[0],
    seminarStartTime?.split(':')[1]).getTime()

    const parsedEndTime = new Date(seminardate?.split('-')[0],
    seminardate?.split('-')[1] - 1,
    seminardate?.split('-')[2],
    seminarEndTime?.split(':')[0],
    seminarEndTime?.split(':')[1]).getTime()
    if (this.state.orderDetail.variant_values) {
      const variants = JSON.parse(this.state.orderDetail.variant_values);
      for (let key in variants) {
        variationKey.push(key);
        variationValue.push(variants[key]);
      }
    }
    const orderItems =
      this.state.orderDetail.items &&
      this.state.orderDetail.items.length > 0 ? (
        this.state.orderDetail.items.map((i) => {
          return (
            <div key={i.id} className="cart-box  w-100">
              <div className="cart-box-detail  w-100">
                <div className="cart-box-img">
                  <img src={i.imageName} />
                </div>
                <div className="cart-box-item-detail  w-100">
                  <h2>{i.name}</h2>
                  {
                    this.state.orderDetail.isDigital ?
                      <p>{"Digital Product"}</p>
                      : null}
                 {this.state.orderDetail.subscription_timeframe &&  
                 <p>
                    <span>Frequency: </span>
                    {this.state.orderDetail.is_seminar_event === 0
                      ? this.state.orderDetail.subscription_timeframe
                      : "One Time"}
                  </p>}
                  {i.customizationNote && i.customizationNote.length !== 0 && (
                    <p>
                      <span>Customization Note: </span>
                      {i.customizationNote}
                    </p>
                  )}
                  {i.customizationImage && i.customizationImage.length !== 0 && (
                    <p className="d-flex align-items-center justify-content-between">
                      <div>
                        <span>Customization Image:</span>{" "}
                        {this.getFileName(i.customizationImage)}
                      </div>
                      <span>
                        <a href={i.customizationImage} target="_blank">
                          View
                        </a>
                        <a onClick={() => this.download(i.customizationImage)}>
                          Download
                        </a>
                      </span>
                    </p>
                  )}
                  {variationKey.length > 0 && (
                    <p>
                      <strong>Variation:- </strong> <br />
                      {variationKey.map((key, index) => (
                        <div className="margin-variation">
                          <strong>{key}: </strong>{" "}
                          <span>{variationValue[index]}</span>
                        </div>
                      ))}
                    </p>
                  )}
                </div>
              </div>
              {/* <div className="cart-box-item-price">
                  <p><span>${(i.pricePerUnit).toFixed(2)}</span> x <span>{i.orderedQuantity}</span></p>
                  <p>${(i.orderedQuantity * i.pricePerUnit).toFixed(2)}</p>
                </div> */}
            </div>
          );
        })
      ) : (
        <h3>Loading...</h3>
      );

    return (
      <div className="admin-card">
        <div className="order-heading">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <h2 className="mb-0">order details</h2>
              <p>Check your order details.</p>
            </div>
            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-end">
              <div className="d-flex align-items-center ">
               
                {!this.state.isOrderCompleted && this.state.orderDetail.paymentStatus !== "Refunded" &&  (
                  <div className="order-edit">
                     {(() => {
                          if (this.state?.loader) {
                            return (
                              <span className="spinner-border spinner-border-sm ml-1"></span>
                            );
                          }
                        })()}
                    {(() => {
                      if (!this.state.isEditForm) {
                        return (
                          <button
                            onClick={this.onEdit}
                            type="text"
                            className="orange-outline-btn mr-2"
                          >
                            <i className="far fa-edit"></i> Update
                          </button>
                        );
                      } else {
                        return (
                          <>
                            <button
                              onClick={this.onSaveForm}
                              type="text"
                              className="orange-outline-btn"
                            >
                              <i className="fa fa-check"></i> Save
                            </button>
                            <button
                              onClick={this.onCancel}
                              type="text"
                              className="orange-outline-btn"
                            >
                              <i className="fa fa-times"></i> Cancel
                            </button>
                          </>
                        );
                      }
                    })()}
                  </div>
                )}
                        {this.state.orderDetail.id && this.state.orderDetail.isCustomizeCategoryAvailable
                          ? (
                              <button
                                onClick={() => {
                                  this.props.navigate("/messages", {
                                    message_source_type: "order",
                                    message_source_id:
                                      this.state.orderDetail.id,
                                    source_detail:
                                      this.state.orderDetail.orderNumber,
                                      buyer_name: this.state.orderDetail.buyer_name,
                                    seller_id:
                                      this.state.orderDetail?.seller_id,
                                    buyer_id: this.state.orderDetail?.buyer_Id
                                    // storename:
                                    //   this.state.sellerDetail.storename,
                                    // timezone: this.state.sellerDetail.timezone,
                                    // availableFrom:
                                    //   this.state.sellerDetail.availableFrom,
                                    // availableTo:
                                    //   this.state.sellerDetail.availableTo,
                                  });
                                }}
                              >
                                <span>Contact Customer</span>
                              </button>
                            )
                          : null}
                          
                {this.state?.customerTrackingDetails?.customer_trackingId &&
                  this.state?.orderDetail?.paymentStatus !== "Refunded" &&
                  this.state?.orderDetail?.orderReturnStatus == "accepted" && (
                    <button
                      onClick={this.onApproveRequest}
                      type="text"
                      className="orange-outline-btn mr-2"
                    >
                      <i className="far fa-edit"></i> Approve
                      {(() => {
                        if (this.state?.showApproveLoader) {
                          return (
                            <span className="spinner-border spinner-border-sm ml-1"></span>
                          );
                        }
                      })()}
                    </button>
                  )}
                {this.state.disputeStatus && (
                  <div className="order-edit">
                    <Link to={`/dispute/${this.state.orderDetail.id}`}>
                      <button type="text" className="orange-outline-btn">
                        <i className="far fa-edit"></i>view dispute
                      </button>
                    </Link>
                  </div>
                )}
                {this.state.orderDetail.orderReturnId &&
                  this.state.orderDetail.orderReturnStatus == "pending" && (
                    <>
                      <button
                        className="mr-2"
                        onClick={() => this.handleAcceptRejectButtonClick(1)}
                      >
                        Accept return
                        {(() => {
                          if (this.state?.showAcceptLoader) {
                            return (
                              <span className="spinner-border spinner-border-sm ml-1"></span>
                            );
                          }
                        })()}
                      </button>
                      <button
                        onClick={() => this.handleAcceptRejectButtonClick(0)}
                      >
                        Reject return
                        {(() => {
                          if (this.state?.showRejectLoader) {
                            return (
                              <span className="spinner-border spinner-border-sm ml-1"></span>
                            );
                          }
                        })()}
                      </button>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="admin-card-box">
          <section className="track-section">
            <div className="container">
              {this.state.successMessage && (
                <div className="alert alert-success">
                  {this.state.successMessage}
                </div>
              )}
              {orderItems}

              {(this.state.orderDetail?.disputeStatus === "open" ||
                this.state.orderDetail?.disputeStatus === "pending") && (
                <p>
                  Note : You cannot update your order status until the
                  respective dispute is closed!{" "}
                  <Link to={`/dispute/${this.state.orderDetail.id}`}>view</Link>
                </p>
              )}
              <div className="order-detail">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="order-detail-left">
                      <div className="table-responsive">
                        <table className="table">
                          <tr>
                            <td>Order No.:</td>
                            <td>
                              #
                              {this.state.orderDetail.orderNumber
                                ? this.state.orderDetail.orderNumber
                                : this.state.orderDetail.id}
                            </td>
                          </tr>
                          <tr>
                            <td>Recipient:</td>
                            <td>
                              {this.state.shippingAddress.firstname
                                ? `${this.state.shippingAddress.firstname} ${this.state.shippingAddress.lastname}`
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                            <td>Shipping Address:</td>
                            <td>
                              {this.state.shippingAddress.address
                                ? `${
                                    this.state.shippingAddress.address_2
                                      ? this.state.shippingAddress.address_2 +
                                        ", "
                                      : ""
                                  } ${this.state.shippingAddress.address}, ${
                                    this.state.shippingAddress.city
                                  }, ${this.state.shippingAddress.state}, ${
                                    this.state.shippingAddress.country
                                  } - ${this.state.shippingAddress.zipCode}`
                                : `-`}
                            </td>
                          </tr>
                          <tr>
                                    <td>Billing Recipient:</td>
                                    <td>
                                      {this.state?.billingAddress?.name
                                        ? `${this.state.billingAddress?.name}`
                                        : this.state?.newCardDetails?.name ? `${this.state?.newCardDetails?.name}`
                                        :  this.state.shippingAddress?.firstname
                                          ? `${this.state.shippingAddress?.firstname} ${this.state.shippingAddress?.lastname}`
                                          : `-`}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Billing Address:</td>
                                    <td>
                                      {this.state.billingAddress?.address
                                        ? `${
                                            this.state.billingAddress?.billingAddress_2
                                              ? this.state.billingAddress
                                                  ?.billingAddress_2 + ", "
                                              : ""
                                          } ${
                                            this.state.billingAddress?.address
                                          }, ${
                                            this.state.billingAddress?.city
                                          }, ${
                                            this.state.billingAddress?.state
                                          }, 
                                          ${
                                            this.state.billingAddress?.zipCode
                                          }`
                                          : this.state?.newCardDetails?.address ? `${this.state?.newCardDetails.address}, ${this.state?.newCardDetails.city}, ${this.state?.newCardDetails.state}, ${this.state?.newCardDetails.zipCode}`

                                        : this.state.shippingAddress?.address
                                          ? `${
                                              this.state.shippingAddress?.address_2
                                                ? this.state?.shippingAddress
                                                    ?.address_2 + ", "
                                                : ""
                                            } ${
                                              this.state.shippingAddress?.address
                                            }, ${
                                              this.state.shippingAddress?.city
                                            }, ${
                                              this.state.shippingAddress?.state
                                            }, ${
                                              this.state.shippingAddress?.country
                                            } - ${
                                              this.state.shippingAddress?.zipCode
                                            }`
                                          : `-`}
                                    </td>
                                  </tr>
                          {this.state.sessionDetails && this.state.sessionDetails.type !== "group" && this.state.sessionDetails?.timeSlots? (
                            <>
                              <tr>
                                <td>Time Slot:</td>
                                {this.state.sessionDetails?.timeSlots[0] ? 
                                <td>{`${moment(this.state.sessionDetails?.timeSlots[0][0],"HH:mm").format("hh:mm A")}-${moment(this.state.sessionDetails?.timeSlots[0][1],"HH:mm").format("hh:mm A")}`}</td>
                                : `-`}
                                </tr>
                              <tr>
                                <td>Frequency</td>
                                <td>
                                  {this.state.sessionDetails.sessionFrequency}
                                </td>
                              </tr>
                              {/* <tr>
                                <td>Type</td>
                                <td>{this.state.sessionDetails.type.charAt(0).toUpperCase() + this.state.sessionDetails.type.slice(1)}</td>
                              </tr> */}
                            </>
                          ) : null}
                          {/* {this.state.sessionDetails && this.state.sessionDetails.type == "group" && this.state.sessionDetails?.timeSlots ? (
                                    <>
                                    <td>Time Slot:</td>
                                      {
                                        Object.entries(this.state.sessionDetails?.timeSlots).map(([weekDay, slotsArray]) => {
                                          return <tr>
                                          <td>
                                          {[weekDay]}
                                          </td>
                                          {
                                            slotsArray && slotsArray.map((slot) => {
                                              return <td>{moment(slot[0],"HH:mm").format("hh:mm A")} to {moment(slot[1], "HH:mm").format("hh:mm A")}</td>
                                            })
                                          }
                                          </tr>
                                        
                                        
                                        
                                        })

                                      }
                                  </>
                                  ) : null} */}
                                  {!this.state.orderDetail.is_seminar_event && 
                                    this.state.sessionDetails?.timeSlots && this.state.sessionDetails.type == "group" ? 
                                    <tr>
                                    <td>Time Slot:</td>
                                      <div className="month-freq">
                                        <div onClick={() => this.setState({ showModal: true})}>
                                        <button className="orange-outline-btn mt-2">view details</button>
                                                    </div>
                                      </div>
                                      </tr>
                                      :
                                      null
                                  }

                                   {!this.state.orderDetail.is_seminar_event && this.state.sessionDetails?.type == "group" && groupMeetingLink &&
                                    !this.state.hideButton &&
                                  <tr>
                                    <td>Zoom link :</td>
                                    <a href={groupMeetingLink} target="_blank">
                                     <button className="my-2">Join Now</button> 
                                    </a>
                                  </tr>}

                          <tr>
                            <td>Process Date:</td>
                            <td>{date ? date : `-`}</td>
                          </tr>
                          <tr>
                            <td>Order Status:</td>
                            {(() => {
                              if (!this.state.isEditForm) {
                                return (
                                  <td>
                                    {this.state.orderDetail.status
                                      ? this.state.orderDetail.status
                                          .charAt(0)
                                          .toUpperCase() +
                                        this.state.orderDetail.status.slice(1)
                                      : `-`}
                                  </td>
                                );
                              } else {
                                return (
                                  <select
                                    disabled={
                                      this.props.orderStatus === "canceled" ||
                                      this.props.orderStatus === "completed" ||
                                      this.state.orderDetail.disputeStatus ===
                                        "open" ||
                                      this.state.orderDetail.disputeStatus ===
                                        "pending"
                                    }
                                    onChange={(event) => {
                                      this.handleAlertStatusChange(
                                        event,
                                        "status",
                                        this.state.orderDetail.trackingCompany
                                      );
                                    }}
                                    value={this.state.orderDetail.status}
                                    className="edit-input form-control"
                                  >
                                    <option value="pending">Pending</option>
                                    <option value="confirmed">Confirmed</option>
                                    <option value="canceled">Canceled</option>
                                    <option value="processing">
                                      Processing
                                    </option>
                                    {this.state.orderDetail.trackingCompany && (
                                      <option value="shipped"> Shipped</option>
                                    )}
                                    <option value="completed">Completed</option>
                                    {/* <option value="refund">Refund</option> */}
                                  </select>
                                );
                              }
                            })()}
                            {/* <td>{this.state.orderDetail.status ? this.state.orderDetail.status : `-`}</td> */}
                          </tr>
                          {this.state.orderDetail?.is_seminar_event ? <>
                            <tr>
                            <td>Event Date:</td>
                            <td>{this.state.seminarDetails?.date}</td>
                          </tr>
                          <tr>
                            <td>Event Time:</td>
                            <td>{moment(this.state.seminarDetails?.start,"HH:mm").format("hh:mm A")} to {moment(this.state.seminarDetails?.end, "HH:mm").format("hh:mm A")}</td>
                          </tr>
                          <tr>
                            <td>Meeting Link:</td>
                            <td><a href={this.state.seminarDetails?.host_link} target="_blank">
                            <button className="orange-outline-btn disable-btn"
                            disabled ={currentTime < parsedStartTime || currentTime > parsedEndTime}>Join Now</button>
                              </a></td>
                          </tr>
                          </> : null}
                          <tr>
                            <td>Payment Status:</td>
                            <td>
                              {this.state.orderDetail.paymentStatus
                                ? this.state.orderDetail.paymentStatus
                                    .charAt(0)
                                    .toUpperCase() +
                                  this.state.orderDetail.paymentStatus.slice(1)
                                : `-`}
                            </td>
                          </tr>
                          {this.state.sessionDetails?.host_link &&
                                  <tr>
                                    <td>Zoom link</td>
                                    <a href={this.state.sessionDetails?.host_link} target="_blank">
                                     <button>Join Now</button> 
                                    </a>
                                  </tr>}
                          <tr>
                            <td>Receipts Voucher:</td>
                            <td>-</td>
                          </tr>
                          {this.state.orderDetail.trackingCompany && (
                            <>
                              <tr>
                                <td>Tracking Company:</td>
                                {(() => {
                                  if (!this.state.isEditForm) {
                                    return (
                                      <td>
                                        {this.state.orderDetail.trackingCompany
                                          ? this.state.orderDetail
                                              .trackingCompany
                                          : `-`}
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <select
                                        onChange={(event) => {
                                          this.handleChange(
                                            event,
                                            "trackingCompany"
                                          );
                                        }}
                                        disabled={
                                          this.state.orderDetail.status ===
                                            "completed" ||
                                          this.state.orderDetail.status ===
                                            "canceled"
                                        }
                                        value={
                                          this.state.orderDetail.trackingCompany
                                        }
                                        className="edit-input form-control"
                                      >
                                        <option
                                          selected="true"
                                          disabled="disabled"
                                        >
                                          Select a tracking company
                                        </option>
                                        <option value="Fedex">Fedex</option>
                                        <option value="USPS">USPS</option>
                                        <option value="UPS">UPS</option>
                                      </select>
                                    );
                                  }
                                })()}
                                <span className="form-field-error">
                                  {this.state.errorData?.trackingCompany &&
                                    this.state.errorData.trackingCompany[0]}
                                </span>
                                {/* <span className="form-field-error">
                              {this.state.sameTrackingError}
                            </span> */}
                              </tr>
                              <tr>
                                <td>Delivery Plan:</td>
                                <td>
                                  {this.state.orderDetail.delivery_plan
                                    ? this.state.orderDetail.delivery_plan
                                        .split("_")
                                        .join(" ")
                                    : `-`}
                                </td>
                              </tr>
                              {this.state.orderDetail?.orderReturnStatus ==
                            "accepted" &&
                            this.state.orderDetail.return_shipment_address && (
                              <tr>
                                <td>
                                 Return shipment address:
                                </td>
                                <td>{
                                  `${this.state.returnAddress?.return_shipment_address}
                                  ${this.state.returnAddress?.returnShipmentAddress_2 ? `,${this.state.returnAddress?.returnShipmentAddress_2}`: ``}
                                  ${this.state.returnAddress?.returnCountry ? `,${this.state.returnAddress?.returnCountry}`:``},
                                  ${this.state.returnAddress?.returnState},
                                  ${this.state.returnAddress?.returnCity},
                                  ${this.state.returnAddress?.returnShipmentZipcode}
                                  `}
                                </td>
                              </tr>
                            )}
                              {!this.state.orderDetail.customize_delivery_time ==0 &&
                              <tr>
                                <td>Product Creation Time:</td>
                                <td>
                                {this.state.orderDetail?.customize_delivery_time == 1
                                 ? `${this.state.orderDetail?.customize_delivery_time} Day`
                                 : `${this.state.orderDetail?.customize_delivery_time} Days`}
                                </td>
                              </tr>}
                              <tr>
                                { this.state.orderDetail.customize_questions && this.state.orderDetail.customize_questions !== "undefined" && JSON.parse(this.state.orderDetail.customize_questions)?.length ? (
                                  <>
                                    <td>Customize Questions:</td>
                                    <ol>
                                      {JSON.parse(this.state.orderDetail.customize_questions).map(
                                        (item, index) => (
                                          <li>{item}</li>
                                        )
                                      )}
                                    </ol>
                                  </>
                                ) : null}
                              </tr>

                              <tr>
                                <td>Tracking Number:</td>
                                {(() => {
                                  if (!this.state.isEditForm) {
                                    return (
                                      <td>
                                        {this.state.orderDetail.trackingId
                                          ? this.state.orderDetail.trackingId
                                          : `-`}
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <input
                                        type="text"
                                        className="edit-input form-control"
                                        disabled={
                                          this.state.orderDetail.status ===
                                            "completed" ||
                                          this.state.orderDetail.status ===
                                            "canceled"
                                        }
                                        value={
                                          this.state.orderDetail.trackingId
                                        }
                                        onChange={(event) => {
                                          this.handleChange(
                                            event,
                                            "trackingId"
                                          );
                                        }}
                                      />
                                    );
                                  }
                                })()}
                                <span className="form-field-error">
                                  {this.state.errorData?.trackingId &&
                                    this.state.errorData.trackingId[0]}
                                </span>

                                <span className="form-field-error">
                                  {this.state.sameTrackingError}
                                </span>

                                {/* <td>-</td> */}
                                {/* <td><input type="text" /></td> */}
                              </tr>
                            </>
                          )}
                          {/* <td>Recepits Voucher:</td>
                                                        <td>-</td> */}
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="order-detail-right">
                      <div className="table-responsive">
                        <table className="table">
                          <tr>
                            <td>Item Price</td>
                            <td>
                              {this.state.orderDetail?.items?.length > 0
                                ? `$${this.state.orderDetail.items[0].pricePerUnit
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Quantity</td>
                            <td>
                              {this.state.orderDetail?.items?.length > 0
                                ? `${this.state.orderDetail.items[0].orderedQuantity}`
                                : "-"}
                            </td>
                          </tr>
                       
                          {this.state.orderDetail.trackingCompany && (
                            <tr>
                              <td>Shipping</td>
                              <td>
                                {this.state.orderDetail.shipping_amount
                                  ? `$${this.state.orderDetail.shipping_amount
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : "Free"}
                              </td>
                            </tr>
                          )}
                          {this.state.orderDetail.discount &&
                          +this.state.orderDetail.discount > 0 ? (
                            <tr>
                              <td>Discount</td>
                              <td>{`-$${(+this.state.orderDetail.discount)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                            </tr>
                          ) : null}
                             <tr>
                            <td>Subtotal</td>
                            <td>
                              {this.state.orderDetail.subTotal
                                ? `$${this.state.orderDetail.subTotal
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Tax</td>
                            <td>
                              {this.state.orderDetail.tax
                                ? `$${this.state.orderDetail.tax
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                : "$0.00"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Total</b>
                            </td>
                            <td>
                              <b>
                                {this.state.orderDetail.total
                                  ? `$${this.state.orderDetail.total
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                  : "-"}
                              </b>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {(() => {
                  if (
                    !this.state.isEditForm &&
                    this.state.orderDetail.trackingId
                  ) {
                    return (
                      <div className="text-right mb-4">
                        <Link
                          to={`/orders/track/${this.state.orderDetail.id}`}
                          type="text"
                          className="orange-outline-btn"
                        >
                          <i className="far fa-edit"></i> Track this order
                        </Link>
                      </div>
                    );
                  }
                })()}
              </div>
                {/* Upload Digital Product strat */}
              <div className="upload-digital-product">
              {this.state.isDigital && this.state.orderDetail.isCustomizeCategoryAvailable && this.state.orderDetail.status === "completed" && !this.state.digitalUrl?.length ?
                <label>Upload Digital Product</label> 
                : null }
                <div class="dropdown" 
                onClick={() => this.setState({
                  show: true
                })}
                > 
                  {this.state.isDigital && this.state.orderDetail.isCustomizeCategoryAvailable && this.state.orderDetail.status === "completed" && !this.state.digitalUrl?.length ?
                  <button className="dropdown-btn" type="button" data-toggle="dropdown">
                    <i className="fas fa-plus-circle" ></i>
                  </button> 
                  : null} 
                  <div class="dropdown-menu">
                  {this.state.isDigital ? (
                    <>
                    <input
                    type="file"
                    hidden
                    multiple
                    ref={(input) => {
                      this.fileUploadVideo = input;
                    }}
                    accept={fileFormats.video
                      .map((item) => "." + item)
                      .join(",")}
                    onChange={(e) => this.fileSelectHandler(e)}
                  />
                  <input
                    type="file"
                    hidden
                    multiple
                    ref={(input) => {
                      this.fileUploadImage = input;
                    }}
                    accept={fileFormats.image
                      .map((item) => "." + item)
                      .join(",")}
                    onChange={(e) => this.fileSelectHandler(e)}
                  />
                  <input
                    type="file"
                    hidden
                    multiple
                    ref={(input) => {
                      this.fileUploadDoc = input;
                    }}
                    accept={fileFormats.doc
                      .map((item) => "." + item)
                      .join(",")}
                    onChange={(e) => this.fileSelectHandler(e)}
                  />
                    <span
                    className="dropdown-item"
                      onClick={this.fileUploadVideoClick}
                    >

                      <i className="fas fa-video"></i>
                      Video
                    </span>
                    <span
                      onClick={this.fileUploadImageClick}
                      className="dropdown-item"
                    >

                      <i className="fas fa-image"></i>
                      Image
                    </span>
                    <span
                      onClick={this.fileUploadDocClick}
                      className="dropdown-item"
                    >

                      <i className="fas fa-file-alt"></i>
                      File
                    </span>
                    </>
                    ) : null}
                  </div>
                </div>
              </div>
                {/* Upload Digital Product end */}

              {this.state.isDigital && this.state.files.length > 0 ?
                <div className="upload-gallery">
                  {
                    this.state.files?.length > 0 && this.state.files.map((item, index) => (
                      <div key={index}>
                        {this.state.digitalUrl?.length !== 0 && fileFormats.image.includes(item?.type?.split("/")[1]) ?
                          <div>
                            <div className="upload-gallery-card">
                              <img width="200" height="150"
                                src={item?.stream}
                                alt="preview" />
                              <div className="close-icon"
                                onClick={(e) =>
                                  this.deleteSelectedFile(e, index)}>
                                <i className="far fa-times-circle">
                                  {" "}
                                </i>
                              </div>
                            </div>
                          </div>
                          : fileFormats.video.includes(item?.type?.split("/")[1]) ?
                            <div>
                              <div className="upload-gallery-card">
                                <video width="200" height="150" controls>
                                <source src={item.stream}/>
                                </video>
                                <div className="close-icon" onClick={(e) =>
                                    this.deleteSelectedFile(e, index)}>
                                  <i className="far fa-times-circle">
                                    {" "}
                                  </i>
                                </div>
                              </div>
                            </div>
                            : fileFormats.doc.includes(item?.type?.split("/")[1]) ?
                              <div>
                                <div className="upload-gallery-card">
                                  <div className="file-name">
                                    <span>
                                      {item.name}
                                    </span>
                                  </div>
                                  <div className="close-icon"
                                    onClick={(e) =>
                                      this.deleteSelectedFile(e, index)}>
                                    <i className="far fa-times-circle">
                                      {" "}
                                    </i>
                                  </div>
                                </div>
                              </div>
                              : null}
                      </div>
                    ))
                  }
                </div>: null
              }      
              <span className="form-field-error">
                {this.state.largeFileError ?
                  this.state.largeFileError
                  : null}
              </span>

              <span className="form-field-error">
                {this.state.errorData?.files  ? 
                  this.state.errorData.files[0] : null}
              </span>

              <div className="upload-gallery mb-4">
                <div className="d-flex">
                {this.state.isDigital && this.state.digitalUrl ? this.state.digitalUrl.map((item, index) => {
                    if (item.URL.includes("video_gallery/")) {
                      return <div className="upload-gallery-card">
                          <video width="200" height="150" controls>
                          <source src={item.URL}/>
                          </video>
                      </div>
                      
                    } else if (item.URL.includes("image_gallery/")){
                      return <div className="upload-gallery-card">
                      <a href={item.URL} target="blank"><img width="200" height="150" src={item.URL} /></a> 
                      </div>
                    } else if (item.URL.includes("doc/")){
                      const docNameArray=item.URL.split("/")
                      const docName=docNameArray[docNameArray.length-1].split("?")
                    return <div className="upload-gallery-card">
                      <a href={item.URL} target="blank">
                        <div className="file-name">
                          <span>
                          {docName[0]} 
                          </span>
                        </div>              
                      </a>
                    </div>
                    }
                }) : null}
                </div>
            </div>

              <div className="order-detail-right">
                <div className="table-responsive">
                  {this.state.orderDetail?.admin_commission_percent ? <table className="table">
                    <tr>
                      <td>
                        Subsciety Commission (
                        {this.state.orderDetail?.admin_commission_percent
                          ? `${this.state.orderDetail.admin_commission_percent}%`
                          : "-"}
                        )
                      </td>
                      <td>
                        {!this.state.showRefundableBar
                          ? this.state.orderDetail?.admin_commission_percent &&
                            this.state.orderDetail.total
                            ? `$${(
                                (this.state.orderDetail.total *
                                  this.state.orderDetail
                                    .admin_commission_percent) /
                                100
                              )
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : "-"
                          : `$${0}.00`}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Transaction Fee (
                        {this.state.orderDetail?.transaction_fee_percent
                          ? `${this.state.orderDetail.transaction_fee_percent}%`
                          : "-"}
                        )
                      </td>
                      <td>
                        {this.state.orderDetail?.transaction_fee_percent &&
                        this.state.orderDetail.total
                          ? `$${(
                              (this.state.orderDetail.total *
                                this.state.orderDetail
                                  .transaction_fee_percent) /
                              100
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Additional Transaction Fee</td>
                      <td>
                        {this.state.orderDetail.additional_transaction_fee
                          ? `$${this.state.orderDetail.additional_transaction_fee
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : "-"}
                      </td>
                    </tr>
                    {this.state.showRefundableBar && (
                      <tr>
                        <td>Total Refundable Amount</td>
                        <td>
                          {this.state.isShippingChargeEnable
                            ? this.state.orderDetail.total
                              ? `$${(
                                  this.state.orderDetail.total -
                                  (this.state.orderDetail
                                    ?.additional_transaction_fee +
                                    this.state.orderDetail.shipping_amount +
                                    (this.state.orderDetail.total / 100) *
                                      this.state.orderDetail
                                        .transaction_fee_percent)
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : "-"
                            : this.state.orderDetail.total
                            ? `$${(
                                this.state.orderDetail.total -
                                (this.state.orderDetail
                                  ?.additional_transaction_fee +
                                  (this.state.orderDetail.total / 100) *
                                    this.state.orderDetail
                                      .transaction_fee_percent)
                              )
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                            : "-"}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <b>Total Subtractable Amount</b>
                      </td>
                      <td>
                        <b>
                          $
                          {!this.state.showRefundableBar
                            ? this.state.orderDetail.subtractable_amount
                              ? `${this.state.orderDetail.subtractable_amount
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : "-"
                            : this.state.isShippingChargeEnable
                            ? (
                                parseFloat(
                                  (
                                    this.state.orderDetail
                                      ?.additional_transaction_fee +
                                    (this.state.orderDetail.total / 100) *
                                      this.state.orderDetail
                                        .transaction_fee_percent
                                  ).toFixed(2)
                                ) +
                                parseFloat(
                                  this.state.orderDetail.total -
                                    (
                                      this.state.orderDetail
                                        ?.additional_transaction_fee +
                                      this.state.orderDetail.shipping_amount +
                                      (this.state.orderDetail.total / 100) *
                                        this.state.orderDetail
                                          .transaction_fee_percent
                                    ).toFixed(2)
                                )
                              ).toFixed(2)
                            : `${this.state.orderDetail.total.toFixed(2)}`}
                        </b>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <b>Receivable Amount</b>
                      </td>
                      <td>
                        <b>
                          $
                          {!this.state.showRefundableBar
                            ? this.state.orderDetail.total &&
                              this.state.orderDetail.subtractable_amount
                              ? `${(
                                  this.state.orderDetail.total -
                                  this.state.orderDetail.subtractable_amount
                                )
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : "-"
                            : this.state.isShippingChargeEnable
                            ? `${this.state.orderDetail.shipping_amount.toFixed(
                                2
                              )}`
                            : `${0}.00`}
                        </b>
                      </td>
                    </tr>

                    {this.state.showRefundableBar &&
                      this.state.refundableAmount && (
                        <tr>
                          <td>
                            <b>Refunded amount</b>
                          </td>
                          <td>
                            <b>
                              {this.state.refundableAmount &&
                                `$${this.state.refundableAmount}`}
                            </b>
                          </td>
                        </tr>
                      )}
                  </table> : null}
                </div>
              </div>
              {this.state.orderDetail.orderReturnId && (
                <div className="table-responsive border-table-status">
                  <table className={`table`}>
                    {this.state.orderDetail.orderReturnStatus && (
                      <tr>
                        <td>
                          <strong>Order return status:</strong>
                        </td>
                        <td
                          className={`${
                            this.state.orderDetail.orderReturnStatus ==
                            "pending"
                              ? `pendingColor`
                              : this.state.orderDetail.orderReturnStatus ==
                                "rejected"
                              ? `rejectedColor`
                              : `approvedColor`
                          }`}
                        >
                          {this.state.orderDetail.orderReturnStatus
                            ? this.state.orderDetail.orderReturnStatus
                                .charAt(0)
                                .toUpperCase() +
                              this.state.orderDetail.orderReturnStatus.slice(1)
                            : "-"}
                        </td>
                      </tr>
                    )}
                    {this.state.orderDetail.message && (
                      <tr>
                        <td>
                          <strong>Customer's Message:</strong>
                        </td>
                        <td>{this.state.orderDetail.message}</td>
                      </tr>
                    )}
                    {this.state.orderDetail.vendor_message && (
                      <tr>
                        <td>
                          <strong>Message:</strong>
                        </td>
                        <td>{this.state.orderDetail.vendor_message}</td>
                      </tr>
                    )}
                    {this.state.customerTrackingDetails
                      ?.shipping_company_by_customer && (
                      <tr>
                        <td>
                          <strong>Shipping Company:</strong>
                        </td>
                        <td>
                          {
                            this.state.customerTrackingDetails
                              ?.shipping_company_by_customer
                          }
                        </td>
                      </tr>
                    )}
                    {this.state.customerTrackingDetails
                      ?.customer_trackingId && (
                      <tr>
                        <td>
                          <strong>Tracking Id:</strong>
                        </td>
                        <td>
                          {
                            this.state.customerTrackingDetails
                              ?.customer_trackingId
                          }
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              )}
            </div>
          </section>
          <AlertModal
            data={this.state.alertModalData}
            closeAlertModal={this.closeAlertModal}
            handleAlertResponse={(type, id, data) =>
              this.handleAlertResponse(type, id, data)
            }
          />
          {this.returnOrderModal()}
          {this.groupTimeSlotsModal()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    orderStatus: state.cartReducer.orderStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrderDetail,
      updateOrderDetail,
      getOrderTracking,
      updateOrderStatusByTrackingId,
      getShopifyDetails,
      updateOrderReturnRequest,
      refundOrderReturnsByOrderId,
      getWooCommerceDetails,
      uploadFiles,
      addFiles
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Order_Detail));
