import axios from "axios";
import { handleError } from "./commonAction";
const URL = process.env.REACT_APP_API_URL;

export const hierarchyList = (childIdList) => async (dispatch) => {
  try {
    const res = await axios({
      method: "post",
      url: `${URL}/api/v1/seller/search-category/hierarchy`,
      data: childIdList,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getChildCategories =
  (data) =>
  async (dispatch) => {
    try {
      const res = await axios({
        method: "post",
        url: `${URL}/api/v1/seller/search-category/child-category`,
        data: data,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      return res.data;
    } catch (error) {
      dispatch(handleError(error));
    }
  };
