import React, { Component } from "react";
import "../../Styles/dashboard.scss";
import { Link } from "react-router-dom";

import Chart from "react-apexcharts";
import { confirmAlert } from "react-confirm-alert";

import { ProductIcon } from "../../Components/svgElements/ProductIcon";

import ratioGraph from "../../assets/images-2/ratio-graph.jpg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logoutUser } from "../../Redux/Actions/authAction";
import { toggleSidebar } from "../../Redux/Actions/commonAction";
import { getOrdersList } from "../../Redux/Actions/orderAction";
import { getStatisticsData } from "../../Redux/Actions/dashboardAction";
import { debounce } from "lodash";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import withNavigate from "../../Components/customHooks/navigate";

class Dashboard extends Component {
  sellerId;
  monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      limit: 5,
      items: [],
      filter: "monthly",
      startDate: "",
      endDate: "",
      totalPayments: 0,
      totalProducts: 0,
      totalReceivableAmount: 0,
      totalOrders: 0,
      series: [],
      options: {},
    };
  }

  componentDidMount() {
    this.sellerId = localStorage.getItem("sellerId");
    this.debounceStatisticsCall();
    this.fetchMoreData();
  }

  handleFilterChange = (filterName) => {
    this.setState({
      filter: filterName,
    });

    this.debounceStatisticsCall();
  };

  handleDateChange = (event, key) => {
    this.setState({
      [key]: event ? moment(event).format("YYYY-MM-DD") : "",
      filter: event ? "date" : "monthly",
    });

    this.debounceStatisticsCall();
  };

  debounceStatisticsCall = debounce(() => {
    this.fetchStatisticsData();
  }, 1000);

  fetchStatisticsData = async () => {
    // call action here

    if (this.sellerId) {
      let result;

      if (this.state.filter === "date") {
        result = await this.props.getStatisticsData(
          this.sellerId,
          this.state.filter,
          this.state.startDate,
          this.state.endDate
        );
      } else {
        result = await this.props.getStatisticsData(
          this.sellerId,
          this.state.filter
        );
      }

      if (result?.data?.isFound && result.data.data) {
        this.createSeriesData(result.data.data);
      }
    } else {
      this.props.navigate("/login");
    }
  };

  createSeriesData = (statsData) => {
    let paymentData = statsData.payments;
    let orderData = statsData.orders;
    let totalPayments = 0;
    let totalReceivableAmount = 0;
    let totalOrders = 0;
    let maxPaymentValue = 0;

    let seriesData =
      paymentData.length > 0
        ? paymentData.map((item) => {
          totalPayments += item.payments;

          totalReceivableAmount += item.subtractable_amount;

          let parsedDate = new Date(item.createTS);

          if (
            this.state.filter === "weekly" ||
            this.state.filter === "monthly" ||
            this.state.filter === "date"
          ) {
            let todate = new Date(parsedDate).getDate();
            let tomonth = this.monthArray[new Date(parsedDate).getMonth()];
            parsedDate = `${todate} ${tomonth}`;
          } else if (this.state.filter === "annual") {
            parsedDate = this.monthArray[new Date(parsedDate).getMonth()];
          } else if (this.state.filter === "all") {
            parsedDate = new Date(parsedDate).getFullYear();
          }

          maxPaymentValue =
            item.payments > maxPaymentValue ? item.payments : maxPaymentValue;

          if (typeof parsedDate == "number") {
            parsedDate = parsedDate.toString();
          }

          return {
            x: parsedDate,
            y: item.payments?.toFixed(2),
          };
        })
        : [];

    orderData.forEach((item) => {
      totalOrders += item.orders;
    });

    maxPaymentValue = Math.ceil(maxPaymentValue / 100) * 100;

    this.setState({
      totalPayments,
      totalProducts: statsData?.products?.total_product || 0,
      totalReceivableAmount: totalPayments - totalReceivableAmount,
      totalOrders,
      series: [
        {
          name: "Total Payments",
          data: seriesData,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
            },
          },
        },
        fill: {
          colors: ["#F44336"],
        },
        colors: ["#E25E52"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Sales Overview",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "$" + value;
            },
          },
          title: {
            text: "Amount",
          },
          min: 0,
          max: maxPaymentValue,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    });
  };

  fetchMoreData = async () => {
    const result = await this.props.getOrdersList(
      this.sellerId,
      this.state.offset,
      this.state.limit
    );
    if (result?.data?.data?.length > 0) {
      this.setState({
        items: result.data.data,
      });
    }
  };

  handleLogout = async (event) => {
    event.preventDefault();
    this.props.logoutUser();
    this.props.navigate("/login");
  };

  goToAddProduct = async () => {
    if (!this.props?.userInfo?.stripe_details_submitted || !this.props?.userInfo?.phonenumber || this.props?.userInfo?.phonenumber === 'null' || !this.props?.userInfo?.storename || this.props?.userInfo?.storename === 'null') {
      confirmAlert({
        title: "",
        message: 'Please Complete Your Profile!',
        buttons: [
          {
            label: "Okay",
          },
        ],
      });
    } else {
      this.props.navigate("/category-selection");
    }
  };

  render() {
    const orderItems = this.state.items.map((i) => {
      const fullAddress = i.address ? JSON.parse(i.address) : null;
      const date = i.createTS
        ? new Date(i.createTS).toISOString().substring(0, 10)
        : null;
      return (
        <tr key={i.id}>
          <td key={i.id} align="center">
            #{i.orderNumber ? i.orderNumber : i.id}
          </td>
          <td>{i.fullname ? i.fullname : "-"}</td>
          <td>{date}</td>
          <td>{fullAddress ? fullAddress.city : null}</td>
          <td>${i.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
          <td>
            <p className="orange">{i.status}</p>
          </td>
          <td align="center" style={{ display: "grid" }}>
            <Link to={`/orders/${i.id}`} className="orange">
              order detail
            </Link>
            {i.trackingId ? (
              <Link className="orange" to={`/orders/track/${i.id}`}>
                track this order
              </Link>
            ) : null}
          </td>
        </tr>
      );
    });

    return (
      <div className="admin-card">
        <div className="admin-card-head">
          <h2>dashboard</h2>
          <div className="admin-card-head-right">
            <div className="dash-time">
              <ul>
                <li>
                  <a
                    onClick={() => this.handleFilterChange("all")}
                    className={this.state.filter === "all" ? "active" : null}
                    href="javascript:;"
                  >
                    all
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.handleFilterChange("weekly")}
                    className={this.state.filter === "weekly" ? "active" : null}
                    href="javascript:;"
                  >
                    weekly
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.handleFilterChange("monthly")}
                    className={
                      this.state.filter === "monthly" ? "active" : null
                    }
                    href="javascript:;"
                  >
                    monthly
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => this.handleFilterChange("annual")}
                    className={this.state.filter === "annual" ? "active" : null}
                    href="javascript:;"
                  >
                    annual
                  </a>
                </li>
              </ul>
            </div>
            <div className="dash-date">
              <p>Start Date:</p>
              <DatePicker
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                value={
                  this.state.startDate && this.state.startDate.length > 0
                    ? new Date(this.state.startDate)
                    : ""
                }
                onChange={(event) => this.handleDateChange(event, "startDate")}
                maxDate={
                  this.state.endDate && this.state.endDate.length > 0
                    ? new Date(this.state.endDate)
                    : new Date()
                }
              />
              <p>End Date:</p>
              <DatePicker
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                value={
                  this.state.endDate && this.state.endDate.length > 0
                    ? new Date(this.state.endDate)
                    : ""
                }
                onChange={(event) => this.handleDateChange(event, "endDate")}
                minDate={
                  this.state.startDate && this.state.startDate.length > 0
                    ? new Date(this.state.startDate)
                    : ""
                }
                maxDate={new Date()}
              />{" "}
            </div>
          </div>
        </div>

        <div className="admin-dash-ratio">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xl-3">
              <div className="admin-dash-ratiobox">
                <div className="admin-dash-ratiobox-top">
                  <div className="admin-dash-ratiobox-icon">
                    <i className="fas fa-wallet"></i>
                  </div>
                  <div className="admin-dash-ratiobox-detail">
                    <p>Total Sale</p>
                    <h3>
                      $
                      {this.state.totalPayments
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h3>
                  </div>
                </div>
                <div className="admin-dash-ratiobox-bottom">
                  <div className="admin-dash-ratiobox-graph">
                    <img src={ratioGraph} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xl-3">
              <div className="admin-dash-ratiobox">
                <div className="admin-dash-ratiobox-top">
                  <div className="admin-dash-ratiobox-icon">
                    <i className="fas fa-comment-dollar"></i>
                  </div>
                  <div className="admin-dash-ratiobox-detail">
                    <p>Receivable Amount</p>
                    <h3>
                      $
                      {this.state.totalReceivableAmount
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    </h3>
                  </div>
                </div>
                <div className="admin-dash-ratiobox-bottom">
                  <div className="admin-dash-ratiobox-graph">
                    <img src={ratioGraph} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xl-3">
              <div className="admin-dash-ratiobox">
                <div className="admin-dash-ratiobox-top">
                  <div className="admin-dash-ratiobox-icon">
                    <i className="fas fa-cart-plus"></i>
                  </div>
                  <div className="admin-dash-ratiobox-detail">
                    <p>Total Orders</p>
                    <h3>{this.state.totalOrders} </h3>
                  </div>
                </div>
                <div className="admin-dash-ratiobox-bottom">
                  <div className="admin-dash-ratiobox-graph">
                    <img src={ratioGraph} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xl-3">
              <div className="admin-dash-ratiobox">
                <div className="admin-dash-ratiobox-top">
                  <div className="admin-dash-ratiobox-icon">
                    <ProductIcon />
                  </div>
                  <div className="admin-dash-ratiobox-detail">
                    <p>Total Products</p>
                    <h3>{this.state.totalProducts} </h3>
                  </div>
                </div>
                <div className="admin-dash-ratiobox-bottom mb-0">
                  <button onClick={this.goToAddProduct} type="text" className="orange-outline-btn w-100">
                    + Add Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="admin-dash-graph">
          <div className="admin-dash-graph-top">
            <div className="row">
              <div className="col-md-6 co-lg-6">
                <div className="admin-dash-graph-heading">
                  <h3>Sales Overview</h3>
                </div>
              </div>
              <div className="col-md-6 co-lg-6">
                <div className="admin-dash-graph-ratio">
                  <p>Total Sale</p>
                  <h3>
                    $
                    {this.state.totalPayments
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="admin-dash-graph-img">
            <div id="chart">
              <Chart
                options={this.state.options}
                series={this.state.series}
                type="area"
                height={400}
              />
            </div>
          </div>
        </div>

        <div className="admin-card-box">
          <div className="vendor-table">
            <div className="vendor-table-head">
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <div className="entries-drop">
                    <label>ORDERS LIST</label>
                  </div>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="vendor-table-head-right">
                    <div className="download-statement-btn">
                      <Link to={"/orders"}>
                        <button type="button" className="hyper-link-btn">
                          see all
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vendor-table-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <td align="center">Order No.</td>
                      <td>customer</td>
                      <td>sold on</td>
                      <td>ship to</td>
                      <td>price</td>
                      <td>status</td>
                      <td align="center">Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {orderItems}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userReducer.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { logoutUser, toggleSidebar, getOrdersList, getStatisticsData },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Dashboard));
