import React from "react";

import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import RadioBox from "../../Components/FormElements/RadioBox";
import CommonButton from "../../Components/FormElements/CommonButton";

import PlaceholderImage from "../../assets/images-2/dummyUser.png";

import { BackIcon } from "../../Components/svgElements/BackIcon";
import { ImageIcon } from "../../Components/svgElements/ImageIcon";
import { VideoIcon } from "../../Components/svgElements/VideoIcon";
import { DeleteIcon } from "../../Components/svgElements/DeleteIcon";
import { PrimaryInfoIcon } from "../../Components/svgElements/PrimaryInfoIcon";

import imageCompression from "browser-image-compression";

import ProductHeader from "./ProductHeader";
import { File_SIZE, VIDEO_SIZE } from "../../Constant/Constant";
import { uploadProductFiles } from "../../Redux/Actions/fileUploadAction";
import {
  addProductMarketingContent,
  getProductDetailNew,
} from "../../Redux/Actions/productAction";

import "./AddProduct.scss";

const MarketingContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const productId = location.state.productId;
  const categoryId = location.state.categoryId;
  const status = location.state.sectionStatus;
  const isCategoryChanged = location.state.isCategoryChanged;

  const imageUpdateRef = React.useRef();
  const videoUpdateRef = React.useRef();
  const [images, setImages] = React.useState({
    imageBase64: [],
    imageFile: [],
  });
  const [videos, setVideos] = React.useState({
    videoBase64: [],
    videoFile: [],
  });
  const [isActive, setIsActive] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [errorData, setErrorData] = React.useState({});
  const [sectionStatus, setSectionStatus] = React.useState();
  const [productDetail, setProductDetail] = React.useState({});
  const [updateImageIndex, setUpdateImageIndex] = React.useState(-1);
  const [showModel, setShowModel] = React.useState(false);

  React.useEffect(() => {
    if (status) {
      setSectionStatus(status);
    }
  }, [status]);
  React.useEffect(() => {
    if (!isCategoryChanged) {
      fetchProductDetails();
    }
  }, []);

  const fetchProductDetails = async () => {
    if (productId) {
      localStorage.removeItem("categoryId");
      const { data } = await dispatch(getProductDetailNew(productId));
      if (data.data) {
        setProductState(data.data);
        setProductDetail(data.data);
      }
    }
  };

  const setProductState = React.useCallback(async (product) => {
    setIsActive(product?.isActive === null ? 1 : product?.isActive);
    if (product.galleryImages && product.galleryImages.length) {
      setImages({
        imageBase64: JSON.parse(product.galleryImages),
        imageFile: [],
      });
    }
    if (product.gallery_videos) {
      const galleryVideosArray = product.gallery_videos.split(",");
      setVideos({
        videoBase64: galleryVideosArray,
        videoFile: [],
      });
    }
  }, []);

  const onSelectFile = async (e) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const files = Array.from(e.target.files); // Convert FileList to Array

    if (files.length > 0) {
      for (const file of files) {
        if (file.size > File_SIZE) {
          setErrorData((prevErrorData) => ({
            ...prevErrorData,
            imageError: "File size should not be more than 10MB.",
          }));
          continue;
        }
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          imageError: "",
        }));
        const reader = new FileReader();
        const compressedFile = await imageCompression(file, options);

        reader.addEventListener("load", () => {
          setImages((prevData) => ({
            ...prevData,
            imageBase64: [...prevData.imageBase64, reader.result],
            imageFile: [...prevData.imageFile, { file: compressedFile }],
          }));
        });

        reader.readAsDataURL(file);
      }
    }
  };

  const handleUpdateImage = async (e, index) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const file = e.target.files[0];
    const reader = new FileReader();
    const compressedFile = await imageCompression(file, options);

    reader.addEventListener("load", () => {
      setImages((prevData) => {
        const updatedBase64 = [...prevData.imageBase64];
        const updatedFiles = [...prevData.imageFile];

        // Replace the imageBase64 and imageFile at the specific index
        updatedBase64[index] = reader.result;
        updatedFiles[index] = { file: compressedFile };

        return {
          ...prevData,
          imageBase64: updatedBase64,
          imageFile: updatedFiles,
        };
      });
    });

    reader.readAsDataURL(file);
  };

  const handleRemoveImage = (index) => {
    setImages((prevData) => {
      const updatedBase64 = [...prevData.imageBase64];
      const updatedFiles = [...prevData.imageFile];
      updatedBase64.splice(index, 1);
      updatedFiles.splice(index, 1);
      return {
        ...prevData,
        imageBase64: updatedBase64,
        imageFile: updatedFiles,
      };
    });
  };

  const uploadSelectedFile = async (
    type,
    fileUploadPathEndPoint,
    index,
    isPrivate,
    subCatIndex,
    file
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (file) {
          const res = await dispatch(
            uploadProductFiles(
              "/api/v1/seller/product/files/upload",
              null,
              null,
              type,
              index,
              {
                productId: productId,
                file: file,
                fileUploadPathEndPoint: fileUploadPathEndPoint,
                isPrivate: isPrivate,
                fileName: "",
              }
            )
          );

          if (res && res.imageUrl) {
            resolve(res.imageUrl); // Resolve with the imageUrl
          } else {
            reject("Image URL not found in response.");
          }
        } else {
          reject("File is undefined.");
        }
      } catch (error) {
        reject(error); // Reject the promise on error
      }
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const imageArray = [...images.imageBase64];
    const videoArray = [...videos.videoBase64];
    if (imageArray.length > 0) {
      for (let i = 0; i < images.imageFile.length; i++) {
        if (images.imageFile[i]) {
          const imageUrl = await uploadSelectedFile(
            "images",
            "/image_gallery",
            i,
            false,
            null,
            images.imageFile[i].file
          );
          imageArray[i] = imageUrl;
        }
      }
      setImages((prev) => ({
        ...prev,
        imageBase64: imageArray,
      }));
    }
    if (videoArray.length > 0) {
      for (let i = 0; i < videos.videoFile.length; i++) {
        if (videos.videoFile[i]) {
          const videoUrl = await uploadSelectedFile(
            "videos",
            "/video_gallery",
            i,
            false,
            null,
            videos.videoFile[i].file
          );
          videoArray[i] = videoUrl;
        }
      }
      setVideos((prev) => ({
        ...prev,
        videoBase64: videoArray,
      }));
    }

    const state = {
      productId,
      isActive,
      videos: videoArray,
      images: imageArray,
    };
    const result = await dispatch(addProductMarketingContent(state));
    if (result.data.isUpdated) {
      if (images.imageBase64.length > 0 || videos.videoBase64.length > 0) {
        const updatedSectionStatus = {
          ...sectionStatus,
          marketingContent: true,
        };
        setSectionStatus(updatedSectionStatus);
        setLoading(false);
        navigate("/product-list", {
          state: { sectionStatus: updatedSectionStatus },
        });
      } else {
        setLoading(false);
        navigate("/product-list", {
          state: { sectionStatus: sectionStatus },
        });
      }
    } else {
      setLoading(false);
    }
  };

  const handleModalSubmit = () => {
    setIsActive(0);
    setShowModel(false);
  };

  const handleVideoChange = async (e) => {
    const files = Array.from(e.target.files);
    let totalMediaSize = 0;
    files &&
      files.forEach((item) => {
        totalMediaSize += item?.size;
      });
    if (files.length > 0) {
      for (const file of files) {
        if (file.size > VIDEO_SIZE || totalMediaSize > VIDEO_SIZE) {
          setErrorData((prevErrorData) => ({
            ...prevErrorData,
            videoError: "File size should not be more than 20MB.",
          }));
          continue;
        }
        setErrorData((prevErrorData) => ({
          ...prevErrorData,
          videoError: "",
        }));
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setVideos((prevData) => ({
            ...prevData,
            videoBase64: [...prevData.videoBase64, reader.result],
            videoFile: [...prevData.videoFile, { file: file }],
          }));
        });

        reader.readAsDataURL(file);
      }
    }
  };

  // const handleUpdateVideo = (e,index) => {
  //   const selectedFiles = e.target.files;
  //   if(selectedFiles){
  //     const prevState = [...videos]
  //     prevState[index] = selectedFiles[0]
  //     setVideos(prevState)
  //   }
  // }

  const handleRemoveVideo = (index) => {
    setVideos((prevData) => {
      const updatedBase64 = [...prevData.videoBase64];
      const updatedFiles = [...prevData.videoFile];
      updatedBase64.splice(index, 1);
      updatedFiles.splice(index, 1);
      return {
        ...prevData,
        videoBase64: updatedBase64,
        videoFile: updatedFiles,
      };
    });
  };

  return (
    <>
      <div className="add-product-page">
        <div className="page-heading">
          <button
            className="theme-button dark-outline-btn radius-sm back-btn"
            onClick={() =>
              navigate(
                categoryId !== 3
                  ? "/product-attributes"
                  : "/subscription-content",
                {
                  state: {
                    sectionStatus: sectionStatus,
                    categoryId,
                    productId,
                  },
                }
              )
            }
          >
            <BackIcon />
          </button>
          <h2>Add Product</h2>
        </div>
        <div className="white-card">
          <ProductHeader
            state={{
              productId: productId,
              categoryId: categoryId,
              sectionStatus: status,
            }}
          />
          <div className="select-category-section">
            <div className="primary-note input-note mb-4">
              <p>
                <b>Note:</b> Content uploaded on this page will be shown on the
                product page.
              </p>
            </div>
            <br />
            {productDetail.type === "shopify" ||
            productDetail.type === "woocommerce" ? (
              <div className="primary-note input-note mb-4">
                <p>
                  <b>Note:</b> You cannot update content for Shopify/Woocommerce
                  products from Subsciety.
                </p>
              </div>
            ) : null}

            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <div className="d-flex g-2">
                    <label>
                      Upload Image
                      {/* <sup>*</sup> */}
                    </label>
                    {/* <div className="tooltip-box pl-2">
                      <div class="tooltip-custom">
                        <PrimaryInfoIcon />
                        <div class="together-tooltip">
                          <p>
                            Would you like to make this a recurring option?
                            Select options
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="upload-box mb-3">
                    <input
                      multiple
                      id="file"
                      type="file"
                      accept="image/*"
                      className="inputfile"
                      onChange={(e) => onSelectFile(e)}
                      data-multiple-caption="{count} files selected"
                      disabled={
                        productDetail.type === "shopify" ||
                        productDetail.type === "woocommerce"
                      }
                    />
                    <div className="box-content">
                      <ImageIcon />
                      <p>Upload Image</p>
                      <span>
                        (upload .jpeg, .jpg or .png - max file size 10 mb)
                      </span>
                    </div>
                  </div>
                  <span className="form-field-error">
                    {errorData?.imageError ? errorData?.imageError : ""}
                  </span>
                  <div className="upload-list">
                    {images &&
                      images.imageBase64 &&
                      images.imageBase64.map((item, index) => {
                        return (
                          <div key={index} className="upload-item">
                            <div className="item-img">
                              <img
                                src={item ?? PlaceholderImage}
                                alt="PlaceholderImage"
                              />
                            </div>
                            <div className="item-action">
                              <CommonButton
                                value="Update"
                                onClick={() => {
                                  setUpdateImageIndex(index);
                                  imageUpdateRef.current.click();
                                }}
                                buttonClass="theme-button primary-outline-btn radius-btn mb-3"
                                disabled={
                                  productDetail.type === "shopify" ||
                                  productDetail.type === "woocommerce"
                                }
                              />
                              <button
                                type="button"
                                className="link-button primary-btn"
                                onClick={() => handleRemoveImage(index)}
                                disabled={
                                  productDetail.type === "shopify" ||
                                  productDetail.type === "woocommerce"
                                }
                              >
                                <DeleteIcon /> Remove
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-12">
                <div className="form-group">
                  <div className="d-flex g-2">
                    <label>
                      Upload Video
                      {/* <sup>*</sup> */}
                    </label>
                    {/* <div className="tooltip-box pl-2">
                      <div class="tooltip-custom">
                        <PrimaryInfoIcon />
                        <div class="together-tooltip">
                          <p>
                            Would you like to make this a recurring option?
                            Select options
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="upload-box mb-3">
                    <input
                      multiple
                      id="file"
                      type="file"
                      className="inputfile"
                      onChange={(e) => handleVideoChange(e)}
                      data-multiple-caption="{count} files selected"
                      accept="video/mp4,video/m4v,video/webm,video/ogg"
                      disabled={
                        productDetail.type === "shopify" ||
                        productDetail.type === "woocommerce"
                      }
                    />
                    <div className="box-content">
                      <VideoIcon />
                      <p>Upload Video</p>
                      <span>
                        (upload .mp4 or .m4v file - max file size 20 mb)
                      </span>
                    </div>
                  </div>
                  <span className="form-field-error">
                    {errorData?.videoError ? errorData?.videoError : ""}
                  </span>
                  <div className="upload-list">
                    {videos.videoBase64.map((video, index) => {
                      return (
                        <div className="upload-item" key={Math.random()}>
                          <div className="item-img">
                            <video width="200" height="150" controls>
                              <source src={video} />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                          <div className="item-action">
                            {/* <CommonButton
                            value="Update"
                            onClick={()=> {
                              setUpdateVideoIndex(index)  
                              videoUpdateRef.current.click()
                            }}
                            buttonClass="theme-button primary-outline-btn radius-btn mb-3"
                          /> */}
                            <button
                              type="button"
                              className="link-button primary-btn"
                              onClick={() => handleRemoveVideo(index)}
                              disabled={
                                productDetail.type === "shopify" ||
                                productDetail.type === "woocommerce"
                              }
                            >
                              <DeleteIcon /> Remove
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-12">
                <div className="form-inner-hrading mb-2">
                  <div className="name">
                    <h4>Activate Offering ?</h4>
                    <div className="tooltip-box">
                      <div class="tooltip-custom">
                        <PrimaryInfoIcon />
                        <div class="together-tooltip">
                          <p>
                            Set your product as active or inactive on Subsciety.
                            <span class="arrow-down"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inline-radio">
                    <RadioBox
                      label="Inactive"
                      value={0}
                      checked={!isActive}
                      onChange={() => setShowModel(true)}
                    />
                    <RadioBox
                      label="Active"
                      value={1}
                      checked={isActive}
                      onChange={() => setIsActive(1)}
                    />

                    {showModel && (
                      <div className="custom-popover">
                        <div className="heading">
                          <p>Do you want to deactivate the Product?</p>
                        </div>
                        <div className="inline-button">
                          <CommonButton
                            value="Continue"
                            buttonClass="theme-button primary-btn radius-btn"
                            onClick={handleModalSubmit}
                          />
                          <CommonButton
                            value="Cancel"
                            buttonClass="theme-button dark-outline-btn radius-btn"
                            onClick={() => setShowModel(false)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="action-btn">
            <CommonButton
              value="Submit"
              loading={loading}
              disabled={loading}
              onClick={() => handleSubmit()}
              buttonClass="theme-button primary-btn radius-btn"
            />
            <CommonButton
              value="Cancel"
              disabled={loading}
              onClick={() => navigate("/product-list")}
              buttonClass="theme-button dark-outline-btn radius-btn"
            />
          </div>
        </div>
      </div>
      <input
        hidden
        multiple
        type="file"
        accept="image/*"
        ref={imageUpdateRef}
        onChange={(e) => handleUpdateImage(e, updateImageIndex)}
      />
      <input
        hidden
        multiple
        type="file"
        accept="video/mp4,video/m4v,video/webm,"
        ref={videoUpdateRef}
        // onChange={(e) => handleUpdateVideo(e, updateVideoIndex)}
      />
    </>
  );
};

export default MarketingContent;
