import React from "react";
import "./Onboarding.scss";
import CommonButton from "../../Components/FormElements/CommonButton";
import OnboardStepImg from "../../assets/images-2/onboard-step-img.png";
import HeaderParent from "../../Components/Header/HeaderParent";
import { useNavigate } from "react-router";

const OnboardStep = (props) => {
  const navigate = useNavigate();
  const goToNext = () => {
    navigate("/personal-details");
  };

  return (
    <>
    <HeaderParent/>
    <div className="container">
    <div className="onboarding-page">
      <div className="onboarding-main-section">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-5">
            <div className="onboard-img">
              <img
                src={OnboardStepImg}  
                alt="OnboardStepImg"
                width="640"
                height="320"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-7">
            <div className="section-contnt">
              <div className="heading">
                <h2>Step by Step Guide to Onboard your Store</h2>
              </div>
              <div className="section-content">
                <ul>
                  <li>
                    <h4>Tell Us A Bit About Yourself</h4>
                    <p>
                      Provide your business details and introduce yourself to
                      our community.
                    </p>
                  </li>
                  <li>
                    <h4>Open Your Shop</h4>
                    <p>
                      Set up your shop by choosing your preferences and adding
                      essential details.
                    </p>
                  </li>
                  <li>
                    <h4>Meet Your Guide</h4>
                    <p>
                      Explore our resources and support to help you get started
                      smoothly.
                    </p>
                  </li>
                  <li>
                    <h4>Get After Your First Sale</h4>
                    <p>
                      List your products and start engaging with customers to
                      make your first sale.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="action-btn">
                <CommonButton
                  value="Start Onboarding"
                  onClick={goToNext}
                  buttonClass="theme-button primary-btn radius-btn"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default OnboardStep;
