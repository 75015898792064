import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PrimaryCheckboxIcon } from "../../Components/svgElements/PrimaryCheckboxIcon";

const StepsView = ({ step }) => {
  const { userInfo } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const isStripeConnected = JSON.parse(
    localStorage.getItem("sellerData")
  ).stripe_details_submitted;

  const redirectTo = (url) => {
    navigate(`/${url}`);
  };

  const getStepList = (condition, stepNum, redirectToValue, label) => {
    if (condition) {
      return (
        <li onClick={() => redirectTo(redirectToValue)} className="active">
          <PrimaryCheckboxIcon />
          {label}
        </li>
      );
    } else if (step === stepNum) {
      return <li className="half-active">{label}</li>;
    } else {
      return <li>{label}</li>;
    }
  };

  return (
    <div className="onboarding-tab">
      <ul>
        {getStepList(
          userInfo?.phonenumber,
          1,
          "personal-details",
          "Personal Details"
        )}
        {getStepList(userInfo?.storename, 2, "store-details", "Store Details")}
        {getStepList(
          isStripeConnected ||
            userInfo?.stripe_details_submitted ||
            userInfo?.stripe_details_submitted === 1,
          3,
          "payment-configuration",
          "Payment Configuration"
        )}
        {getStepList(
          userInfo?.storename,
          4,
          "onboarding-subscription",
          "Subscription"
        )}
        {getStepList(userInfo?.storename, 5, "store-preview", "Store Preview")}
      </ul>
    </div>
  );
};

export default StepsView;
