import React from "react";

export const ImageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <g id="Image 2">
        <path
          id="Stroke 1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.74 0.75H6.21332C3.24494 0.75 1.3833 2.884 1.3833 5.904V14.05C1.3833 17.07 3.23706 19.204 6.21332 19.204H14.7351C17.7163 19.204 19.5691 17.07 19.5691 14.05V5.904C19.573 2.884 17.7192 0.75 14.74 0.75Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Stroke 3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.22154 6.78496C9.22154 7.80496 8.4075 8.63096 7.40227 8.63096C6.39803 8.63096 5.58301 7.80496 5.58301 6.78496C5.58301 5.76496 6.39803 4.93896 7.40227 4.93896C8.40652 4.93996 9.22055 5.76596 9.22154 6.78496Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Stroke 5"
          d="M19.5733 12.951C18.6637 12.001 16.9144 10.082 15.0123 10.082C13.1093 10.082 12.0124 14.315 10.1823 14.315C8.35222 14.315 6.68965 12.401 5.2232 13.628C3.75675 14.854 2.36914 17.361 2.36914 17.361"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
