import React from "react";

export const AudioIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g opacity="0.5" clipPath="url(#clip0_378_604)">
        <path
          d="M13.9472 0.878834C13.6688 0.746034 13.3408 0.781234 13.1008 0.974834L5.3184 7.20043H1.6C0.7184 7.20043 0 7.91883 0 8.80043V15.2004C0 16.082 0.7184 16.8004 1.6 16.8004H5.3184L13.0992 23.026C13.2448 23.1412 13.4224 23.2004 13.6 23.2004C13.7184 23.2004 13.8368 23.1748 13.9472 23.122C14.224 22.9876 14.4 22.7076 14.4 22.4004V1.60043C14.4 1.29323 14.224 1.01323 13.9472 0.878834ZM4.8 15.2004H1.6V8.80043H4.8V15.2004ZM12.8 20.7348L6.4 15.6148V8.38603L12.8 3.26603V20.7348Z"
          fill="#333333"
        />
        <path
          d="M18.4511 6.34287C18.1359 6.03087 17.6303 6.03567 17.3199 6.34927C17.0095 6.66447 17.0127 7.17007 17.3263 7.48047C18.5343 8.67567 19.1999 10.2805 19.1999 12.0005C19.1999 13.7205 18.5343 15.3253 17.3263 16.5205C17.0111 16.8309 17.0079 17.3365 17.3199 17.6517C17.4767 17.8101 17.6831 17.8885 17.8879 17.8885C18.0911 17.8885 18.2943 17.8117 18.4511 17.6565C19.9663 16.1605 20.7999 14.1525 20.7999 12.0005C20.7999 9.84847 19.9663 7.84047 18.4511 6.34287Z"
          fill="#333333"
        />
        <path
          d="M20.707 4.08848C20.3934 3.77808 19.8862 3.77968 19.5758 4.09328C19.2654 4.40688 19.267 4.91408 19.5806 5.22448C21.3982 7.02768 22.3998 9.43408 22.3998 12.0005C22.3998 14.5669 21.3982 16.9733 19.5806 18.7765C19.267 19.0869 19.2654 19.5941 19.5758 19.9077C19.7326 20.0661 19.9374 20.1445 20.1438 20.1445C20.347 20.1445 20.5502 20.0677 20.707 19.9125C22.8302 17.8069 23.9998 14.9973 23.9998 12.0005C23.9998 9.00368 22.8302 6.19408 20.707 4.08848Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_378_604">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
