import React from "react";

const RadioBox = (props) => {
  const { label, value, checked, onChange, name, ...extraProps } = props;

  return (
    <label className="custon-radio">
      {label}
      <input
        type="radio"
        value={value}
        name={name ? name : "radio"}
        checked={checked}
        onChange={onChange}
        {...extraProps}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default React.memo(RadioBox);
